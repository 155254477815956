import { Injectable, OnDestroy } from "@angular/core";
import { Auth, User as AuthUser, GoogleAuthProvider, UserCredential, authState, signInWithCustomToken, signInWithPopup, user } from "@angular/fire/auth";
import { Firestore, doc, setDoc } from "@angular/fire/firestore";
import moment from "moment";
import { Observable, Subject, Subscription } from "rxjs";
import { Business, CollectionName, User } from "../_global/_interfaces";
import { UserService } from "../_services/user.service";

@Injectable({
  providedIn: "root",
})
export class IsUserLoginService implements OnDestroy {
  subscription: Subscription | null = null;
  private subject = new Subject<User>();

  constructor(private userService: UserService) {}

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.subscription?.unsubscribe();
  }

  onLogin(user: User): void {
    localStorage.setItem("@user", JSON.stringify(user));
    this.subject.next(user);
  }

  onLogout(): void {
    localStorage.removeItem("@user");

    this.subject.next({});
    this.subscription?.unsubscribe();
  }

  getUser(): Observable<User> {
    return this.subject.asObservable();
  }
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;
  userSubscription: Subscription | null = null;
  authUser$ = user(this.auth);

  currentUserId: string | null = null;
  // private authUser: AuthUser | null = this.auth.currentUser;

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.authStateSubscription.unsubscribe();
    this.userSubscription?.unsubscribe();
  }
  constructor(
    private auth: Auth,
    private userService: UserService,
    private firestore: Firestore,
    private isUserLoginService: IsUserLoginService,
  ) {
    this.auth.useDeviceLanguage();
    this.authStateSubscription = this.authState$.subscribe((aUser) => {
      // not need to check || !aUser.emailVerified
      if (!aUser) {
        this.isUserLoginService.onLogout();
        this.userSubscription?.unsubscribe();
        this.currentUserId = null;
        return;
      }

      if (this.currentUserId !== aUser.uid) {
        this.userSubscription = this.userService.getUser(aUser.uid).subscribe((user) => {
          this.isUserLoginService.onLogin(user);
        });
        this.currentUserId = aUser.uid;
      }
    });
  }

  public async sendSignInLinkToEmail(email: string, business: Business | undefined): Promise<boolean> {
    return this.userService.signInWithEmailLink({ email, brandName: business?.brandName || business?.name, logo: business?.logo?.url });
  }

  public async isSignInWithEmailLink(actionType: string, token: string): Promise<boolean> {
    if (!actionType || actionType !== "signInLink" || !token) {
      return false;
    }
    return await this.signInWithEmailLink(token);
  }

  private async signInWithEmailLink(token: string): Promise<boolean> {
    const credential = await signInWithCustomToken(this.auth, token);

    return this.setAuthUser(credential);
  }

  async loginGoogleUser(): Promise<any> {
    const credential = await signInWithPopup(this.auth, new GoogleAuthProvider());
    return this.setAuthUser(credential);
  }

  private async setAuthUser(credential: UserCredential): Promise<any> {
    const userResult = credential.user;

    const user: User = {
      id: userResult?.uid,
      email: userResult?.email || undefined,
      createdAt: moment().valueOf(),
      roles: {
        customer: true,
      },
    } as User;

    if (!userResult || !user.id) {
      throw new Error("This email link is not valid anymore. Please retry or use it on the same device.");
    } else if (!user.email) {
      throw new Error("No user find, or not email provided. make sure you're not login in under a private session.");
    }

    if (userResult.displayName) {
      user.firstName = userResult.displayName.split(" ")[0];
      if (userResult.displayName.split(" ").length > 1) {
        user.lastName = userResult.displayName.split(" ")[1];
      }
    }

    const docRef = doc(this.firestore, CollectionName.users, user.id);
    await setDoc(docRef, user, { merge: true });
    return true;
  }

  async logout(): Promise<void> {
    localStorage.removeItem("@user");
    localStorage.removeItem("@business");
    this.isUserLoginService.onLogout();
    return await this.auth.signOut();
  }
  getAuthUser(): Promise<AuthUser | null> {
    return new Promise((resolve) => {
      this.authState$.subscribe((authUser) => {
        resolve(authUser);
      });
    });
  }

  // onSendEmailVerification(email: string): Promise<any> {
  //   // this.af.auth.getAuth().auth.sendEmailVerification()
  //   const authUser = this.auth.currentUser;
  //   if (authUser?.email === email && !authUser.emailVerified) {
  //     return sendEmailVerification(authUser);
  //   } else {
  //     throw new Error(authUser?.emailVerified ? "Your email has been already validated" : "Email no validated");
  //   }
  // }

  // async handleResetPassword(actionCode: string, newPassword: string): Promise<string> {
  //   try {
  //     await confirmPasswordReset(this.auth, actionCode, newPassword);
  //     return "Your can now sign in with your new password.";
  //   } catch (error: any) {
  //     return error?.message || "Error occurred during confirmation. The code might have expired or the password is too weak.";
  //   }
  // }
}
