import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { LoaderService } from "../../_component/loader/loader.service";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { User } from "../../_global/_interfaces";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { AuthenticationComponent } from "../../authentication/authentication.component";
import { AuthenticationService } from "../../authentication/is-user-login.service";
import { ContactUsComponent } from "../../contact-us/contact-us.component";
import { PrivacyPolicyComponent } from "../../privacy-policy/privacy-policy.component";
import { TermsComponent } from "../../terms/terms.component";
import { AccountDetailsComponent } from "../details/details.component";
import { LanguageComponent } from "../language/language.component";
import { AccountPaymentMethodComponent } from "../payment-method/payment-method.component";

@Component({
  selector: "app-account",
  standalone: true,
  imports: [AuthenticationComponent, AlertComponent, RouterModule, TranslateModule, SvgIconComponent],
  templateUrl: "./account.component.html",
  styleUrl: "./account.component.scss",
})
export class AccountComponent implements OnInit, OnDestroy {
  user?: User;
  subscription: Subscription;

  faTimes = faTimes;
  private offcanvasService = inject(NgbOffcanvas);
  alertAccount: Alert = { type: "info" };
  isLoading = false;
  private appService = inject(AppService);
  get hasUnreadNotifications(): boolean {
    return localStorage.getItem("@hasUnreadNotification") === "true";
  }
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    const { user, userSubscription } = this.appService.getLocalStorageAndSubscrition();
    this.user = user;
    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // no loading screen
    this.route.queryParams.subscribe((params) => {
      // this.param1 = params['param1'];
      // this.param2 = params['param2'];
      const actionType = params["actionType"];
      const token = params["token"];
      if (actionType && token) {
        this.checkValidationEmail(actionType, token).catch((error) => {
          this.alertAccount = {
            type: "danger",
            message: error.message,
          };
          return false;
        });
      }
    });

    const queryParams = this.route.snapshot.queryParams;

    switch (queryParams["redirect_status"]) {
      case "succeeded": {
        this.alertAccount = {
          type: "success",
          message: "Success! Your payment method has been saved.",
        };
        break;
      }

      case "processing": {
        this.alertAccount = {
          type: "info",
          message: "Processing payment details. We'll update you when processing is complete.",
        };
        break;
      }

      case "requires_payment_method": {
        this.alertAccount = {
          type: "danger",
          message: "Failed to process payment details. Please try another payment method.",
        };
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        break;
      }
    }
  }

  async onLogout() {
    this.loaderService.show();
    this.isLoading = true;
    try {
      await this.authService.logout();
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.hide();
      this.isLoading = false;
    }
  }

  openOffcanvas(content: "login" | "contact-us" | "privacy-policy" | "terms" | "account-details" | "payment-methods" | "language") {
    let component;
    switch (content) {
      case "login":
        component = AuthenticationComponent;
        break;
      case "contact-us":
        component = ContactUsComponent;
        break;
      case "privacy-policy":
        component = PrivacyPolicyComponent;
        break;
      case "terms":
        component = TermsComponent;

        break;
      case "account-details":
        component = AccountDetailsComponent;

        break;
      case "payment-methods":
        component = AccountPaymentMethodComponent;
        break;

      case "language":
        component = LanguageComponent;
        break;

      default:
        return;
    }
    this.offcanvasService.open(component, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
  }

  async checkValidationEmail(actionType: string, token: string): Promise<boolean> {
    const isValid = await this.authService.isSignInWithEmailLink(actionType, token);
    if (isValid) {
      // refresh the page to show the uset account page
      return true;
    }
    this.alertAccount = {
      type: "danger",
      message: "Email link not valid or expired. Please try again.",
    };
    return false;
  }
}
