<div class="">
  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 ">
        <a [routerLink]="['/account']" class="btn py-0 ">
          <app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg>
        </a>
        <h2 class="my-auto mx-3"> <span translate="account.main.notifications"></span></h2>
      </div>
    </div>
  </nav>
  <hr class="mt-0">
  <section class="container-md mb-4">

    @if(hasNotifications){
    <section class="container-md my-4">
      @for (item of notifsPerMonth ; track item.monthLabel;){
      <h5 class="px-3">{{item.monthLabel}}</h5>

      <div class="list-group list-group-flush">
        @for (notif of item.notifications ; track notif.id;){
        <a [routerLink]="[user?.id ? '/account/orders': '/cart']" class="list-group-item list-group-item-action " aria-current="true">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1 fw-bold">{{notif.title}}</p>
            <p class="text-muted caption mb-0">{{notif.createdAt | timeUntilNow : 'milliseconds'}}</p>
          </div>

          <p class="mb-1 small">{{notif.body}}</p>
          <p class="mb-0 small"> <span class="badge bg-info-subtle text-info me-2 "> {{notif.businessName}}</span></p>
        </a>
        }

      </div>

      }
    </section>
    }@else {
    <section class="container-md my-5 text-center">
      <h3 class=" mb-4" translate="order.empty-state-title"></h3>
      <p class="mb-4" translate="order.empty-state-subtitle"></p>
    </section>
    }

  </section>

</div>