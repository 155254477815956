@if(business && business.id){

<!-- <section #recommended class="container-md my-4">
    <h4>What we recommend you</h4>
  </section> -->

<div id="pageContent" class="d-flex flex-column ">
  <div id="poweredByDineasBanner" class=" text-primary">
    <p class="mb-0"><span translate="landing-page.powered-by"></span> <b class="brand-font"><span>D</span><span>ineas</span></b> </p>
  </div>
  <header class=" position-relative">
    <div class="w-100">
      @if (business.images) {
      <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">
        @for (image of business.images; track index; let index = $index) {
        <ng-template ngbSlide>
          <div class="image-hero">
            <div class="img-fluid " [lazyLoad]="image.url || ''" [style.min-height]="'150px'"></div>
          </div>

        </ng-template>
        }

      </ngb-carousel>
      }

      <div id="optionSection" class="position-absolute top-0 bottom-0 end-0 start-0 dir-{{languageDir}}" [style.min-height]="'150px'">
        <div class="container-md position-relative " [style.min-height]="'100%'">
          <div class=" position-absolute top-0 py-4 px-2 end-0">
            <div class="btn-group-blur  rounded-pill">

              @if(business.branding?.facebookPage){
              <a [href]="business.branding?.facebookPage" target="_blank" class="btn btn-icon text-white"><fa-icon [icon]="faFacebook"></fa-icon></a>
              }
              @if(business.branding?.instagramPage){
              <a [href]="business.branding?.instagramPage" target="_blank" class="btn btn-icon text-white"><fa-icon [icon]="faInstagram"></fa-icon></a>
              }

              @if(business.phone){
              <a [href]="'tel:'+business.phone.prefix+business.phone.number" target="_blank" class="btn btn-icon text-white"><app-icon-svg color="#FFFFFF" iconName="phone"></app-icon-svg></a>
              <a [href]="'https://wa.me/'+business.phone.prefix+business.phone.number" target="_blank" class="btn btn-icon text-white"><app-icon-svg color="#FFFFFF" iconName="whatsapp"></app-icon-svg>
              </a>
              }

              @if(business.website){
              <a [href]="business.website" target="_blank" class="btn btn-icon text-white"><app-icon-svg color="#FFFFFF" iconName="world"></app-icon-svg></a>
              }
              <!-- <a [href]="business.website" target="_blank" class="btn btn-icon  text-white"><app-icon-svg color="#FFFFFF" iconName="info-menu-horizontal"></app-icon-svg></a> -->
            </div>
          </div>

        </div>
      </div>

    </div>
  </header>
  <section id="businessNameSection" class="container position-relative px-3">
    <div class="card ">
      <div class="card-body ">
        <div class="d-flex w-100 justify-content-between">
          <h1 class=" my-auto ">{{business.brandName|| business.name }}</h1>
          <button class="btn btn-outline-primary btn-sm rounded-pill px-3"><app-icon-svg color="#FFFFFF" iconName="plus"></app-icon-svg> Follow</button>
        </div>
        <hr class="border-2" />
        <p class="text-muted small">{{ businessTranslated?.description || business.description }}</p>
      </div>
    </div>

    <div class="row my-4">
      <div class="col-6">
        <div class="card card-body  p-0">
          <a [routerLink]="['/menu','category']" class="btn btn-icon p-4 border-0"><app-icon-svg [width]="50" [height]="50" [strokeWidth]="1" [iconName]="'menu'"></app-icon-svg>
            <span class="d-block caption mt-1 fw-light" translate="landing-page.explore-menu"></span>
          </a>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-body p-0">

          <button type="button" class="btn btn-icon p-4 border-0" (click)="openOffcanvas(callWaiterOffcanvas, 'top')">
            <app-icon-svg [width]="50" [height]="50" [strokeWidth]="1" [iconName]="'waiter-bell'"></app-icon-svg>
            <span class="d-block caption mt-1 fw-light" translate="landing-page.call-waiter"></span>

          </button>

        </div>
      </div>
    </div>

    <div class="card text-bg-primary my-4 primary-linear-gradient">
      <div class="card-body">
        <div class="d-flex w-100 justify-content-between">
          <div>
            <h4 class=" ">{{business.brandName|| business.name }}</h4>
            <p class="small  my-0">{{business.address?.addressLine}}, {{business.address?.suburb || business.address?.city}}</p>
          </div>
          <div>
            <a [href]="directionUrl" target="_blank" class="btn btn-icon text-white"><app-icon-svg color="#FFFFFF" iconName="navigation"></app-icon-svg>
            </a>
          </div>

        </div>

      </div>
    </div>
  </section>

</div>
}

<ng-template #callWaiterOffcanvas let-offcanvas>
  <div class="container-md">
    <div class="offcanvas-header text-center">
      <h3 class="offcanvas-title w-100" id="offcanvas-basic-title" translate="menu-category.call-waiter">
      </h3>
    </div>
    <div class="offcanvas-body">
      <div class="row text-center mt-3">
        <div class="col-6">
          <button type="button" class="btn btn-outline-primary w-100" (click)="offcanvas.dismiss('Cross click')" translate="form.cancel"></button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary w-100" (click)="onCallWaiter()" [disabled]="isLoading" translate="menu-category.call"></button>
        </div>
      </div>

    </div>
  </div>
</ng-template>