import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { LoaderService } from "../../_component/loader/loader.service";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Checkout, User } from "../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE, parseUserPreview } from "../../_global/_services/app.service";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { CurrentCheckoutService } from "../../_services/order.service";
import { UserService } from "../../_services/user.service";
import { AuthenticationComponent } from "../../authentication/authentication.component";

@Component({
  selector: "app-cart-customer",
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, ReactiveFormsModule, AlertComponent, TranslateModule, SvgIconComponent],
  templateUrl: "./details.component.html",
  styleUrl: "./details.component.scss",
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  activeOffcanvas = inject(NgbActiveOffcanvas);
  private offcanvasService = inject(NgbOffcanvas);

  user?: User;
  subscription: Subscription;
  isLoading = false;

  languageDir;

  customerForm: FormGroup = this.formBuilder.group({
    id: [""],
    email: ["", [Validators.required, Validators.email]],
    firstName: ["", Validators.required],
    lastName: [""],
    stripeCustomerId: [""],
    phone: this.formBuilder.group({
      prefix: [""],
      number: [""],
    }),
  });
  alertMessage: Alert = { type: "info" };

  isPhoneNumberValid = true;

  get isCustomerValid(): boolean {
    return this.checkout && this.checkout.customer && this.checkout.customer.email && this.checkout.customer.firstName ? true : false;
  }

  business?: Business;
  subscriptionBusiness: Subscription;

  checkout?: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);
  private appService = inject(AppService);
  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";

    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    this.user = user;
    this.setFormFromUserOrCheckout(this.user, this.checkout);

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      this.setFormFromUserOrCheckout(user, this.checkout);
    });

    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      this.setFormFromUserOrCheckout(this.user, checkout);
    });
  }

  setFormFromUserOrCheckout(user: User | undefined, checkout: Checkout | undefined): void {
    let userPreview = checkout?.customer || {};
    if (user && user.id) {
      userPreview = parseUserPreview(user);
    }

    this.customerForm.patchValue(userPreview);
    const phone = userPreview?.phone;
    this.isPhoneNumberValid = phone?.number ? this.appService.phoneNumberValidator(phone?.prefix || "", phone?.number || "") : true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  ngOnInit(): void {}

  async saveChange() {
    // todo if the user is login update the user details
    this.loaderService.show();
    this.isLoading = true;
    try {
      let checkout: Checkout = { ...this.checkout, isTabClosed: this.checkout?.isTabClosed || false, isGroupTab: this.checkout?.isGroupTab || false };
      checkout.customer = { ...this.customerForm.value, address: checkout.customer?.address || null };
      this.currentCheckoutService.set(checkout);
      if (checkout.customer && checkout.customer.id) {
        await this.userService.update(checkout.customer);
      }
      this.activeOffcanvas.dismiss("valid contact");
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = true;
      this.loaderService.hide();
    }
  }

  onPhoneChanges(): void {
    const phone = this.customerForm.get("phone")?.value;
    this.isPhoneNumberValid = phone?.number ? this.appService.phoneNumberValidator(phone?.prefix || "", phone?.number || "") : true;
  }

  openOffcanvas(content: "login") {
    let component;
    switch (content) {
      case "login":
        component = AuthenticationComponent;

        break;
      default:
        return;
    }
    this.offcanvasService.open(component, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
  }
}
