<div class="card mb-3 border-0" (click)="openOrderOffcanvas(order)">

  <div class="row g-0">
    <div class="col-auto  ">
      <div class="image-ratio-1">
        <div class="img-fluid rounded" [lazyLoad]="imageUrl || ''" [defaultImage]="'assets/images/temps/placeholder-image.svg'"></div>
      </div>

    </div>
    <div class="col col-content">
      <div class="card-body py-0 pe-0" [class.pe-0]="languageDir==='ltr'" [class.ps-0]="languageDir==='rtl'">
        <div class="row">
          <h5 class="card-title text-truncate mb-0">{{order.business?.name}} - <span [innerHTML]="order.total?.amountWithTipAndFee | currency : order.business?.currency"></span></h5>
          @if(orderDescription){
          <p class="mb-0 text-truncate-2 caption text-muted">{{orderDescription}}</p>
          }
          <p class="small my-1">
            @switch (order.status) {
            @case ('REQUESTED') {
            <span class="badge bg-info-subtle text-info me-2  " translate="order-type.requested"> </span>
            }
            @case ('CANCELLED') {
            <span class="badge bg-danger-subtle text-danger me-2 " translate="order-type.cancelled"> </span>
            }
            @case ('COMPLETED') {
            <span class="badge bg-success-subtle text-success me-2  " translate="order-type.completed"> </span>
            }
            @case ('PROCESSING') {
            <span class="badge bg-warning-subtle text-warning  me-2  " translate="order-type.processing"></span>
            }
            }
            @switch (order.type) {
            @case ('takeAway') {
            <span class="badge  bg-dark-subtle text-dark " translate="order-type.take-out"> </span>
            }
            @default {
            <span class="badge  bg-dark-subtle text-dark " translate="order-type.dine-in"></span>
            }
            }
            <span class="text-muted caption" style="vertical-align: middle;">
              @switch (order.status) {
              @case ('REQUESTED') {
              - {{order.requestedAt | timeToLabel : 'milliseconds' : "DD/MM/YY, H:mm" }}
              }
              @case ('CANCELLED') {
              - {{order.cancelledAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm"}}
              }
              @case ('COMPLETED') {
              - {{order.completedAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm" }}
              }
              @case ('PROCESSING') {
              - {{order.processingAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD/MM/YY, H:mm" }}
              }
              }
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row g-0">
    <div class="col-3 col-sm-2 position-relative">
      <div class="img-fluid rounded" [lazyLoad]="imageUrl || ''" [defaultImage]="'assets/images/temps/placeholder-image.svg'"></div>

    </div>
    <div class="col-9 col-sm-10">
      <div class="card-body  py-2">
        <div class="row">
          <h6 class="card-title text-truncate mb-1">{{order.business?.name}} - <span class="text-primary">{{order.total?.amountWithTipAndFee | currency : order.business?.currency}}</span></h6>
          <p class=" text-truncate small mb-0 text-muted">
            {{orderDescription}}
          </p>
          <p class=" text-truncate-2 small mb-0">
            <span class="text-muted ">
              @switch (order.status) {
              @case ('REQUESTED') {
              <span class="text-success" translate="order-type.requested"> </span>
              - {{order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm" }}
              }
              @case ('CANCELLED') {
              <span class=" text-danger " translate="order-type.cancelled"> </span>
              - {{order.cancelledAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm"}}
              }
              @case ('COMPLETED') {
              <span class="text-primary" translate="order-type.completed"> </span>
              - {{order.completedAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm" }}
              }
              @case ('PROCESSING') {
              <span class="text-warning " translate="order-type.processing"></span>
              - {{order.processingAt || order.requestedAt | timeToLabel : 'milliseconds' : "DD MMM YYYY, H:mm" }}

              }
              }
            </span>
          </p>
        </div>
      </div>
    </div>
  </div> -->

</div>