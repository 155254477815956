<div id="loginView" class="d-flex flex-column justify-content-center" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">

  <nav class="navbar my-3" (click)="activeOffcanvas.dismiss('Cross click')">
    <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
      <div class="d-flex w-100 justify-content-between">
        <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="auth.title"></span></h3>
      </div>
    </div>
  </nav>
  <hr class="mt-0" />

  <section class="container-md ">
    <h5 class="" translate="auth.sub-title"></h5>
    <form [formGroup]="signinForm">
      <p class="small mb-2" translate="auth.will-send-login-link"></p>
      <div class=" form-floating">
        <input type="email" class="form-control" id="email" formControlName="email" [class.is-invalid]="signinForm.get('email')?.invalid" [placeholder]="'form.email-placeholder' | translate"
          [disabled]="emailSent" [readOnly]="emailSent" required>
        <label class="col-form-label " for="email" translate="form.email-label"></label>
        <div class="invalid-feedback" translate="form.filed-required"></div>
      </div>
      <p class="caption my-2" translate="auth.click-on-link-tips"></p>
    </form>
  </section>

  <section class="container-md ">
    <small>
      <app-alert [message]="alertSignin.message" [dismissible]="false" [active]="alertSignin.active" [type]="alertSignin.type"></app-alert>
    </small>

    <div class="mt-2" [hidden]="emailSent">

      <button type="button" class="btn btn-primary w-100 " (click)="signin()" [disabled]=" isLoading || signinForm.invalid" translate="account.details.login-signup"></button>

      <div>
        <hr class="my-2">

      </div>
      <button type="button" class="btn btn-google w-100 " (click)="loginGoogleUser()">
        <span class="google-logo">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg">
            <g>
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </g>
          </svg>
        </span>
        <span class="google-label">With Google</span>
      </button>
    </div>

    <h6 class=" text-center my-3">
      {{ 'auth.click-and-agree.0' | translate }}
      <span (click)="openOffcanvas('terms')" class="text-primary py-0 px-1" translate="auth.click-and-agree.1"></span>
      {{'auth.click-and-agree.2' | translate }}
      <span (click)="openOffcanvas('privacy-policy')" class="text-primary py-0 px-1" translate="auth.click-and-agree.3"></span>
    </h6>
  </section>
</div>