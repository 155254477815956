import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "../../environments/environment";
import { Business } from "../_global/_interfaces";
import { AppService } from "./app.service";

@Injectable({
  providedIn: "root",
})
export class ManifestService {
  baseUrl = environment.menuUrl;
  dineasDomain = ["https://dineas-menu-dev.web.app", "https://dineas-menu.web.app"];
  defaultManifest = {
    name: "Dineas",
    short_name: "dineas",
    theme_color: "#0DC3B7",
    background_color: "#0DC3B7",
    version: "1.0.0",
    display: "standalone",
    scope: "./",
    start_url: "./app",
    id: "app",
    icons: [
      {
        src: `${this.baseUrl}/assets/icons/icon-72x72.png`,
        sizes: "72x72",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-96x96.png`,
        sizes: "96x96",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-128x128.png`,
        sizes: "128x128",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-144x144.png`,
        sizes: "144x144",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-152x152.png`,
        sizes: "152x152",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-192x192.png`,
        sizes: "192x192",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-384x384.png`,
        sizes: "384x384",
        type: "image/png",
        purpose: "any",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-512x512.png`,
        sizes: "512x512",
        type: "image/png",
        purpose: "any",
      },

      {
        src: `${this.baseUrl}/assets/icons/icon-72x72.png`,
        sizes: "72x72",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-96x96.png`,
        sizes: "96x96",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-128x128.png`,
        sizes: "128x128",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-144x144.png`,
        sizes: "144x144",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-152x152.png`,
        sizes: "152x152",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-192x192.png`,
        sizes: "192x192",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-384x384.png`,
        sizes: "384x384",
        type: "image/png",
        purpose: "maskable",
      },
      {
        src: `${this.baseUrl}/assets/icons/icon-512x512.png`,
        sizes: "512x512",
        type: "image/png",
        purpose: "maskable",
      },
    ],
    screenshots: [
      {
        src: `${this.baseUrl}/assets/screenshots/screenshots_1.jpg`,
        sizes: "1512x1093",
        type: "image/jpg",
        form_factor: "wide",
        label: "Application",
      },
      {
        src: `${this.baseUrl}/assets/screenshots/screenshots_2.jpg`,
        sizes: "1512x1093",
        type: "image/jpg",
        form_factor: "wide",
        label: "Menu",
      },
      {
        src: `${this.baseUrl}/assets/screenshots/screenshots_3.jpg`,
        sizes: "540x1233",
        type: "image/jpg",
        form_factor: "narrow",
        label: "Application",
      },
      {
        src: `${this.baseUrl}/assets/screenshots/screenshots_4.jpg`,
        sizes: "540x1233",
        type: "image/jpg",
        form_factor: "narrow",
        label: "Menu",
      },
    ],
  };

  constructor(
    @Inject(DOCUMENT) private documentApp: Document,
    private appService: AppService,
    private swUpdate: SwUpdate,
  ) {
    // this.baseUrl = "http://127.0.0.1:8080";
    this.baseUrl = this.documentApp.location.hostname;
    if (this.baseUrl === "localhost") {
      this.baseUrl = "http://localhost:4200";
    } else {
      this.baseUrl = `https://${this.baseUrl}`;
    }
    this.defaultManifest.start_url = this.baseUrl + "/menu";
    this.defaultManifest.scope = this.baseUrl;

  }

  // dineasSubDomain = [".menu-dev.dineas.com", ".menu.dineas.com"];

  getManifest(business: Business, current_domain: string): any {
    this.baseUrl = current_domain;
    const currentSubDomainValue = this.appService.getSubdomain(current_domain);

    // if (!currentSubDomainValue || business.branding?.subdomain !== currentSubDomainValue) {
    //   console.log("defaultManifest", current_domain);

    //   return this.defaultManifest;
    // }

    return {
      name: business.brandName || business?.name || this.defaultManifest.name,
      short_name: business.brandName || business?.name || this.defaultManifest.short_name,
      theme_color: business.branding?.primaryColor || this.defaultManifest.theme_color,
      background_color: business.branding?.primaryColor || this.defaultManifest.theme_color,
      display: this.defaultManifest.display,
      scope: `${this.baseUrl}/` || this.defaultManifest.scope,
      id: business?.id ? `app/${business.id}` : null,
      start_url: business?.id ? `${this.baseUrl}/app/${business.id}?b=${business.id}` : `${this.baseUrl}/app`,
      icons: this.getIcons(business) || this.defaultManifest.icons,
      screenshots: [
        {
          src: `${this.baseUrl}/assets/screenshots/screenshots_1.jpg`,
          sizes: "1512x1093",
          type: "image/jpg",
          form_factor: "wide",
          label: "Application",
        },
        {
          src: `${this.baseUrl}/assets/screenshots/screenshots_2.jpg`,
          sizes: "1512x1093",
          type: "image/jpg",
          form_factor: "wide",
          label: "Menu",
        },
        {
          src: `${this.baseUrl}/assets/screenshots/screenshots_3.jpg`,
          sizes: "540x1233",
          type: "image/jpg",
          form_factor: "narrow",
          label: "Application",
        },
        {
          src: `${this.baseUrl}/assets/screenshots/screenshots_4.jpg`,
          sizes: "540x1233",
          type: "image/jpg",
          form_factor: "narrow",
          label: "Menu",
        },
      ],
    };
  }

  getIcons(business: Business | undefined) {
    if (!business || !business.branding || !business.branding.setIcons?.length) {
      return null;
    }
    const icons: { src: string; sizes: string; type: string; purpose: string }[] = [];
    for (const icon of business.branding.setIcons) {
      icons.push({
        src: icon.url,
        sizes: `${icon.width}x${icon.height}`,
        type: "image/png",
        purpose: "any",
      });
    }
    return icons;
  }
}
