<!-- @if(!checkout || !checkout.items || checkout.items.length === 0){
<div>
  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 justify-content-between">
        <h2 class="mb-0"><app-icon-svg [iconName]="'shopping-cart'"></app-icon-svg><span class="ms-2" translate="cart.title"></span></h2>
      </div>
    </div>
  </nav>
  <div class="container d-flex view-height">
    <div class=" my-auto text-center">
      @if(business && business.logo){
      <div class="business-logo mx-auto mb-6">
        <div class="img-fluid logo-display " [lazyLoad]="business.logo.url || ''"></div>
      </div>
      }
      <h3 class="error-message mb-4" translate="cart.empty-cart-title"></h3>
      <p class="error-contact" translate="cart.empty-cart-sub-title"></p>
      @if(business && business.id){
      <a [routerLink]="['/menu','category']" class="btn btn-outline-primary" translate="cart.explore-menu"></a>
      }
    </div>
  </div>
</div>
} @else { -->

<div id="cartView" class="d-flex flex-column justify-content-center">
  @if(isAdminTable){
  <div id="poweredByDineasBanner" class=" text-primary">
    <p class="mb-0" translate="cart.you-are-table-admin"></p>
  </div>
  }

  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 justify-content-between">
        <h2 class="my-auto"><app-icon-svg [iconName]="'shopping-cart'"></app-icon-svg><span class="ms-2" translate="cart.title"></span></h2>

        @if(checkout && checkout.tag?.label){
        <h3 class="my-auto text-muted"> {{checkout.tag?.label}} </h3>
        }

        @if(isAdminTable && (customers?.length || 1) > 1){
        <button type="button" class="btn-link border-0 position-relative btn p-0 me-3" (click)="open(customerList, 'top')">
          <app-icon-svg [iconName]="'users'"></app-icon-svg>

          <span class="position-absolute top-0 start-100 translate-middle p-1 bg-warning border border-light rounded-circle" [hidden]="!hasPendingGuest">
            <span class="visually-hidden">New alerts</span>
          </span>
        </button>

        }

      </div>
    </div>
  </nav>
  <hr class="mt-0">

  @if(checkout?.orderId && !productItemsPending.length){
  <section class="container-md">
    <h4 class="mb-1" translate="cart.order-confirm"></h4>
    <p class="mb-3 small text-muted" translate="cart.order-confirm-tip"></p>
    <hr />
  </section>
  } @else if(checkout?.orderId && productItemsPending.length){
  <section class="container-md">
    <h4 class="mb-1" translate="cart.new-items"></h4>
  </section>
  }
  @if(isAdminTable && hasPendingGuest ){
  <section class="container-md">
    <ngb-alert [dismissible]="false" (click)="open(customerList, 'top')">
      <span class="small" translate="cart.tab.has-pending-requests"></span>
    </ngb-alert>
  </section>
  }

  @if(productItemsPending.length){
  <section class="container-md mb-0">
    <ul class="list-group list-group-flush">
      @for (product of productItemsPending; track product.id;){
      <li class="list-group-item"><app-product-item-card [canEdit]="true" (onUpdateQuantity)="onUpdateQuantity($event, product)" [product]="product" [currency]="currency"></app-product-item-card>
      </li>
      }
    </ul>
    <hr />
  </section>

  }
  @if(!requiredCustomerDetails&& productItemsOrdered.length && productItemsPending.length) {
  <section class="container-md mt-3 mb-auto">
    <button type="button" class=" btn btn-primary w-100" [disabled]="isLoading" (click)="open(confirmAndAddToOrderOffset, 'top')">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>

      }@else {
      <span translate="cart.confirm-add-order"></span>
      }
    </button>
  </section>
  }
  @if(productItemsOrdered.length){
  @if( productItemsPending.length){

  <section class="container-md">
    <h4 class="mb-1" translate="cart.previous-items"></h4>
  </section>
  }
  <section class="container-md mb-0">
    <ul class="list-group list-group-flush">
      @for (product of productItemsOrdered; track product.id;){
      <li class="list-group-item  position-relative">
        <app-product-item-card class="opacity-25" [readonly]="true" (onUpdateQuantity)="onUpdateQuantity($event, product)" [product]="product" [currency]="currency"></app-product-item-card>
        <div class="position-absolute end-0 top-0 text-center">
          <p class="mb-0">
            @switch (product.status) {
            @case ('REQUESTED') {
            <span class="badge bg-info-subtle text-info mt-2  order-status" translate="order-type.requested"> </span>
            }
            @case ('CANCELLED') {
            <span class="badge bg-danger-subtle text-danger mt-2  order-status" translate="order-type.cancelled"> </span>
            }
            @case ('COMPLETED') {
            <span class="badge bg-success-subtle text-success mt-2  order-status" translate="order-type.completed"> </span>
            }
            @case ('PROCESSING') {
            <span class="badge bg-warning-subtle text-warning mt-2  order-status" translate="order-type.processing"></span>
            }
            @case ('FULFILLED') {
            <span class="badge bg-primary-subtle text-primary mt-2  order-status" translate="order-type.fulfilled"></span>
            }
            @default {
            <span class="badge text-bg-warning mt-2  order-status">{{ product.status }}</span>
            }
            }
          </p>

        </div>

      </li>
      }
    </ul>
    <hr />
  </section>

  }

  @if(!checkout?.orderId && pushSell && pushSell.products && pushSell.products.length && business && checkout?.items?.length){
  <section class="container-md  mb-4 ">
    <h3>
      {{pushSell.name}}
    </h3>
    <app-vertical-product-card-section [currency]="business.currency" [products]="pushSell.products"></app-vertical-product-card-section>
  </section>
  }

  <app-alert [message]="alertOrder.message" [dismissible]="false" [active]="alertOrder.active" [type]="alertOrder.type"></app-alert>
  @if(!checkout || !checkout.items?.length){
  <div class=" my-auto text-center">
    <app-icon-svg class="mb-4 text-primary" size="xl" [iconName]="'food-bones'"></app-icon-svg>
    <h4 class=" mb-0" translate="cart.no-items"></h4>
    <a [routerLink]="business && business.id ?['/menu','category'] :['/scanner']" class="btn small btn-link text-muted" translate="cart.start-ordering-here"></a>

  </div>
  }@else {
  <section class="container-md  mt-auto">
    @if (!dataLoaded) {
    <button type="button" class="btn btn-primary w-100 " disabled>
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>
    </button>

    }
    @else if (requiredCustomerDetails) {
    <button (click)="openCustomerDetail()" type="button" class="btn btn-primary w-100 text-capitalize " translate="cart.customer-provide-details"></button>
    }
    @else if (checkout.isGroupTab && !hasAccessToGroupedTab) {
    @switch (customerStatus) {
    @case ('REQUESTED') {
    <p class="text-muted" translate="cart.tab.request-sent"></p>
    <button translate="cart.tab.waiting-request" type="button" class="btn btn-outline-warning w-100  " disabled></button>
    }
    @case ('CANCELLED') {
    <p class="text-muted" translate="cart.tab.request-declined"></p>
    <button translate="cart.customer-status-blocked" type="button" class="btn btn-outline-danger w-100  " disabled></button>
    }
    @case ('COMFIRM') {
    <span class="badge bg-success-subtle text-success me-2  order-status" translate="cart.customer-status-confirmed"> </span>
    }
    @case (undefined) {
    <p class="text-muted" translate="cart.join-tab-tip"></p>

    <button (click)="onJoinTab()" type="button" class="btn btn-primary w-100 " [disabled]="isLoading ">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>

      }@else {
      <span translate="cart.join-tab"></span>
      }
    </button>
    }
    }
    }

    @else if( (!checkout.tag?.id || !checkout.tag?.isActive)) {
    <button (click)="openScanner()" type="button" class="btn btn-primary w-100 text-capitalize " translate="cart.confirm-order"></button>
    }
    @else if( enableCheckout && !productItemsOrdered.length) {
    <a [routerLink]="['/checkout']" class="btn btn-primary w-100 text-capitalize " translate="cart.confirm-order"></a>
    }
    @else if( productItemsOrdered.length && !productItemsPending.length && checkout.orderStatus === 'COMPLETED') {
    <!-- <a [routerLink]="['/checkout']" class="btn btn-primary w-100 text-capitalize " translate="cart.manage-order"></a> -->
    @if(!checkout.isGroupTab) {
    <button type="button" class="btn btn-outline-primary mb-3 w-100" (click)="openOrder()" translate="cart.manage-order"></button>

    <button type="button" class=" btn btn-primary w-100" (click)="open(closeTabOffset, 'top')">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>

      }@else {
      <span translate="cart.close-tab-pay"></span>
      }
    </button>
    }
    @else if(userPayment){
    <button type="button" class="btn btn-outline-primary w-100 " (click)="open(myPayment)">
      <span translate="checkout.paid"></span>
      <span class="fw-bold"> {{(userPayment.participationAmount || 0) + (userPayment.participationTip || 0)| currency: checkout.business?.currency}}</span>
    </button>
    }
    @else{
    <a [routerLink]="['/order', checkout.orderId, 'payment']" class=" btn btn-primary w-100">
      <span translate="cart.group-table.add-payment"></span>
    </a>
    }

    }
  </section>
  }

  <div id="totalNav">
    <hr class="m-0 " [class.mt-auto]="checkout && checkout.items?.length" />
    <nav class="navbar my-2" (click)="open(totalBill)">

      <div class="container-md">
        <div class="d-flex w-100 justify-content-between">
          <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-up'"></app-icon-svg><span class="ms-2" translate="checkout.total"></span></h3>

          <p class="text-primary  mb-0">
            @if(currentPaymentStatus.discount){<del class="text-muted caption">{{currentPaymentStatus.amountWithTipAndFee + currentPaymentStatus.discount | currency:
              currency}}</del>}
            {{currentPaymentStatus.amountWithTipAndFee | currency : currency}} </p>

        </div>
      </div>
    </nav>
  </div>

</div>

<ng-template #totalBill let-offcanvas>
  <div class="container-md">
    <!-- <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate="menu-category.filter-title"></h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div> -->
    <div class="offcanvas-body">
      <nav class="navbar mb-3" (click)="offcanvas.dismiss('Cross click')">
        <div class="d-flex w-100 justify-content-between">
          <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="checkout.total"></span></h3>
          <p class="mb-0 text-primary"> {{currentPaymentStatus.amountWithTipAndFee | currency: currency}}</p>
        </div>
      </nav>
      <section class="">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1 small text-muted" translate="checkout.sub-total"></p>
          <p class="mb-1 small text-muted">{{currentPaymentStatus.subtotal | currency: currency}}</p>
        </div>
        <div class="d-flex w-100 justify-content-between ">
          <p class="mb-1 small text-muted" translate="checkout.discount"></p>
          <p class="mb-1 small text-muted" dir="ltr"> {{-(currentPaymentStatus.discount) | currency: currency}}</p>
        </div>

        <div class="d-flex w-100 justify-content-between">
          <p class="mb-0 small text-muted"> <span translate="checkout.tip"></span> <span [hidden]="!currentPaymentStatus.tipPercentage" class="ps-2">({{currentPaymentStatus.tipPercentage |
              percent}})</span></p>
          <p class="mb-0 small text-muted"> {{currentPaymentStatus.tipAmount || 0 | currency: currency}}</p>
        </div>

        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1 small text-muted" translate="checkout.fee"></p>
          <p class="mb-1 small text-muted"> {{0 | currency: currency}}</p>
        </div>

        @if(currentPaymentStatus.paid ){
        <hr />
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1 small text-muted" translate="checkout.paid"></p>
          <p class="mb-1 small text-muted"> {{currentPaymentStatus.paid| currency: currency}}</p>
        </div>

        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1 small text-muted" translate="checkout.due"></p>
          <p class="mb-1 small text-muted"> {{currentPaymentStatus.due | currency: currency}}</p>
        </div>
        }

        <!-- <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1" translate="checkout.total"> </h5>
          <h5 class="mb-1 text-primary">{{currentPaymentStatus.price | currency: currency}}</h5>
        </div> -->
      </section>
    </div>
  </div>

</ng-template>

<ng-template #myPayment let-offcanvas>
  <div class="container-md">
    <!-- <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate="menu-category.filter-title"></h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div> -->
    <div class="offcanvas-body">
      <nav class="navbar mb-3" (click)="offcanvas.dismiss('Cross click')">
        <div class="d-flex w-100 justify-content-between">
          <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="split-payment.title"></span></h3>
          <p class="mb-0">@switch (userPayment?.status) {
            @case ('PENDING' ) {
            <span class="badge bg-info-subtle text-info ms-2  order-status" translate="order.payment.status-pending"></span>
            }
            @case ('INITIALISED') {
            <span class="badge bg-info-subtle text-info ms-2  order-status" translate="order.payment.status-initialised"></span>
            }

            @case ('CANCELLED') {
            <span class="badge bg-danger-subtle text-danger ms-2  order-status" translate="order.payment.status-cancelled"> </span>
            }
            @case ('FAILED') {
            <span class="badge bg-danger-subtle text-danger ms-2  order-status" translate="order.payment.status-failed"> </span>
            }
            @case ('PAID') {
            <span class="badge bg-success-subtle text-success ms-2  order-status" translate="order.payment.status-paid"> </span>
            }
            @case ('REFUND') {
            <span class="badge bg-warning-subtle text-warning ms-2  order-status" translate="order.payment.status-refund"></span>
            }
            @default {
            <span class="badge text-bg-warning ms-2  order-status">{{ userPayment?.status }}</span>
            }
            }
          </p>
        </div>
      </nav>
      @if(userPayment && checkout && checkout.orderId){
      <app-my-payment-form [orderId]="checkout.orderId" [payment]="userPayment" [currency]="checkout.business?.currency"></app-my-payment-form>
      }
    </div>
  </div>

</ng-template>

<ng-template #confirmAndAddToOrderOffset let-offcanvas>
  <div class="container-md">
    <div class="offcanvas-header text-center">
      <h3 class="offcanvas-title w-100" id="offcanvas-basic-title" translate="cart.add-items-to-order">
      </h3>
    </div>
    <div class="offcanvas-body">
      <div class="row text-center mt-3">
        <div class="col-6">
          <button type="button" class="btn btn-outline-primary w-100" (click)="offcanvas.dismiss('Cross click')" translate="form.cancel"></button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary w-100" (click)="onConfirmAndAddToOrder()" [disabled]="isLoading">
            @if(isLoading){
            <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
            <span role="status" class=" mx-2" translate="form.loading"></span>

            }@else {
            <span translate="form.confirm"></span>
            }

          </button>
        </div>
      </div>

    </div>
  </div>

</ng-template>

<ng-template #closeTabOffset let-offcanvas>
  <div class="container-md">
    <div class="offcanvas-header text-center">
      <h3 class="offcanvas-title w-100" id="offcanvas-basic-title" translate="cart.close-tab-pay">
      </h3>
    </div>
    <div class="offcanvas-body">
      <p class="text-muted small" translate="cart.close-tab-pay-tip"></p>
      <app-tip-form [maxAmount]="currentPaymentStatus.subtotal" [defaultTip]="0.12" (onTipChange)="onTipChange($event)"></app-tip-form>
      <p class="text-muted small mt-2">
        <span translate="cart.current-tip"></span>
        <span> {{checkout?.total?.tipAmount || 0 | currency: currency}}</span>
      </p>
      <div class="row text-center mt-4">
        <div class="col-6">
          <button type="button" class="btn btn-outline-primary w-100" (click)="offcanvas.dismiss('Cross click')" translate="form.cancel"></button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary w-100" (click)="onCloseTab()" [disabled]="isLoading">
            @if(isLoading){
            <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
            <span role="status" class=" mx-2" translate="form.loading"></span>

            }@else {
            <span translate="form.confirm"></span>
            }
          </button>
        </div>
      </div>

    </div>
  </div>

</ng-template>

<ng-template #customerList let-offcanvas>
  <div class="container-md">
    <!-- <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate="menu-category.filter-title"></h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div> -->
    <div class="offcanvas-body">
      <nav class="navbar mb-3" (click)="offcanvas.dismiss('Cross click')">
        <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="cart.manage-guest-below"></span></h3>
      </nav>
      <section class="">

        <ul class="list-group list-group-flush">
          @for (guest of customers; track guest.id;){
          <li class="list-group-item px-0" dir="ltr" [hidden]="guest.status ==='ADMIN'">
            <div class="d-flex w-100 justify-content-between">
              <p class="my-auto  text-capitalize">{{guest.firstName || guest.lastName || (guest.email )}}

              </p>
              <p class="my-auto text-muted text-capitalize">
                @switch (guest.status) {
                @case ('REQUESTED') {
                <span class="badge bg-info-subtle text-info me-2  order-status" translate="order-type.requested"> </span>
                }
                @case ('CANCELLED') {
                <span class="badge bg-danger-subtle text-danger me-2  order-status" translate="cart.customer-status-blocked"> </span>
                }
                @case ('COMFIRM') {
                <span class="badge bg-success-subtle text-success me-2  order-status" translate="cart.customer-status-confirmed"> </span>
                }

                }
              </p>
              <div class="d-flex">

                @switch (guest.status) {
                @case ('REQUESTED') {
                <button type="button" class="btn-outline-danger p-1 btn me-3" (click)="onInviteTabAction(guest, false)" [disabled]="isLoading">
                  <app-icon-svg [iconName]="'close-cross'"></app-icon-svg>
                </button>
                <button type="button" class="btn-outline-success p-1 btn" (click)="onInviteTabAction(guest,true)" [disabled]="isLoading">
                  <app-icon-svg [iconName]="'check'"></app-icon-svg>
                </button>
                }
                @case ('CANCELLED') {
                <button type="button" class="btn-outline-success p-1 btn" (click)="onInviteTabAction(guest,true)" [disabled]="isLoading">
                  <app-icon-svg [iconName]="'check'"></app-icon-svg>
                </button>
                }
                @case ('COMFIRM') {
                <button type="button" class="btn-outline-danger p-1 btn me-3" (click)="onInviteTabAction(guest, false)" [disabled]="isLoading">
                  <app-icon-svg [iconName]="'close-cross'"></app-icon-svg>
                </button>
                }

                }

              </div>

            </div>
          </li>
          }
        </ul>

      </section>
    </div>
  </div>

</ng-template>