@if(checkout && checkout.business){
<section>

  @if(showCustomTip){
  <form id="customTipForm" [formGroup]="tipForm">

    <div class="d-flex  ">

      <div class="input-group border-0">
        <input type="number" [min]="0" class="form-control  border-0" formControlName="tipAmount" id="tipAmount" [class.is-invalid]="tipForm.get('tipAmount')?.invalid">
        <button class="btn bg-transparent text-muted" type="button" id="button-addon2" (click)="switchCustomTipFormat()"><span
            [class.text-primary]="customTipFormat==='amount'">{{currencySymbol}}</span> / <span [class.text-primary]="customTipFormat==='percent'">%</span></button>
      </div>
      <button class="btn btn-primary ms-2" translate="form.confirm" type="button" (click)=" setTip(tipForm.value.tipAmount, true)"> </button>

    </div>
  </form>
  }@else {
  <div id="tipList" class=" d-flex">
    <!-- List of categories button to switch the selected category -->
    @for (perc of tipOptions; track perc;){
    <button class="btn btn-outline-primary me-2" (click)="setTip(perc)" [class.active]="checkout.total?.tipPercentage === perc" type="button">{{perc | percent}} </button>
    }
    <button class="btn btn-outline-primary me-2 d-flex"
      [class.active]="checkout.total?.tipPercentage && !tipOptions.includes(tipForm.value.tipAmount ? tipForm.value.tipAmount/100 : checkout.total?.tipPercentage || 0)" (click)="showCustomTip= true"
      type="button">
      @if(tipForm.value.tipAmount && customTipFormat==='amount' ){
      <span class="me-2">{{tipForm.value.tipAmount | currency: checkout.business.currency}}</span> <app-icon-svg iconName="edit"></app-icon-svg>
      }@else if(tipForm.value.tipAmount && customTipFormat==='percent' && !tipOptions.includes(tipForm.value.tipAmount ? tipForm.value.tipAmount/100 : checkout.total?.tipPercentage || 0)){
      <span class="me-2">{{tipForm.value.tipAmount/100 | percent}}</span> <app-icon-svg class="my-auto" iconName="edit"></app-icon-svg>
      }
      @else {
      <span translate="form.other"></span>
      }
    </button>
  </div>
  }
</section>
}