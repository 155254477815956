import { DOCUMENT } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { Business } from "../_global/_interfaces";
import { AppService } from "./app.service";
import { BusinessService, CurrentBusinessService } from "./business.service";
import { ManifestService } from "./manifest.service";

@Injectable({
  providedIn: "root",
})
export class AppInitializerService {
  public version!: string;
  public defaultCurrency = "USD";
  private appService = inject(AppService);

  private currentBusinessService: CurrentBusinessService = inject(CurrentBusinessService);
  private businessService: BusinessService = inject(BusinessService);
  private firestore: Firestore = inject(Firestore);
  private documentApp: Document = inject(DOCUMENT);

  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private manifestService: ManifestService,
    private domSanitizer: DomSanitizer,
  ) {
    this.getQueryParams();
  }

  initStyles(): SafeHtml {
    // this.titleService.setTitle($localize`${this.title}`);
    const document = window.document;
    const { business } = this.appService.getLocalStorageAndSubscrition();
    if (business?.branding) {
      const primaryColor = business?.branding.primaryColor;
      const fontColor = business?.branding.fontColor;
      const backgroundColor = business?.branding.backgroundColor;
      const secondaryColor = business?.branding.secondaryColor;
      let style = "";
      if (primaryColor) {
        style += `--color-primary: ${primaryColor}!important;`;
        // style += `--bs-primary-bg-subtle: ${AppInitializerService.hexToRGBA(primaryColor, 0.12)}!important;`;
        style += `--bs-primary-bg-subtle: ${AppInitializerService.lightenColor(primaryColor, 85)}!important;`;
        style += `--color-primary-light: ${AppInitializerService.lightenColor(primaryColor, 24)}!important;`;
      }
      // if (secondaryColor) {
      //   style += `--color-secondary: ${secondaryColor}!important;`;
      //   // style += `--bs-secondary-bg-subtle: ${AppInitializerService.hexToRGBA(secondaryColor, 0.12)}!important;`;
      //   style += `--bs-secondary-bg-subtle: ${AppInitializerService.lightenColor(secondaryColor, 85)}!important;`;
      //   style += `--color-secondary-light: ${AppInitializerService.lightenColor(secondaryColor, 19)}!important;`;
      // }

      if (fontColor) {
        style += `--color-font-body-subtle: ${AppInitializerService.lightenColor(fontColor, 66)}!important;`;
        style += `--bg-input-subtle: ${AppInitializerService.lightenColor(fontColor, 94)}!important;`;
        style += `--bg-hr-subtle: ${AppInitializerService.lightenColor(fontColor, 94)}!important;`;
        // style += `--color-font-body-subtle: ${AppInitializerService.hexToRGBA(fontColor, 0.44)}!important;`;

        style += `--color-font-body: ${fontColor}!important;`;
        document.documentElement.style.setProperty("--bs-body-color", fontColor || "#000");
        document.documentElement.style.setProperty("--bs-card-title-color", fontColor || "#000");
        document.documentElement.style.setProperty("--bs-list-group-action-hover-bg", fontColor || "#000");
      }
      if (backgroundColor) {
        document.documentElement.style.setProperty("--bs-body-bg", backgroundColor || "#fff");
        document.documentElement.style.setProperty("--bs-list-group-action-hover-color", backgroundColor || "#fff");
        style += `--color-body: ${backgroundColor}!important;`;
      }

      // if (darkColor) {
      //   style += `--color-darker: ${darkColor}!important;`;
      //   style += `--color-dark: ${AppInitializerService.lightenColor(darkColor, 12)}!important;`;
      //   style += `--color-medium: ${AppInitializerService.lightenColor(darkColor, 115)}!important;`;
      //   style += `--color-light: ${AppInitializerService.lightenColor(darkColor, 244)}!important;`;
      //   style += `--color-lighter: ${AppInitializerService.lightenColor(darkColor, 255)}!important;`;
      // }

      // document.documentElement.style.setProperty("--bs-btn-color", "#333");
      // document.documentElement.style.setProperty("--bs-blue", "#333");
      return this.domSanitizer.bypassSecurityTrustHtml(`<style>:root {${style}}</style>`);
    }
    return "";
  }

  getQueryParams(url?: string): { [key: string]: string } {
    const queryParamsString = url ? url.split("?")[1] : window.location.search.substring(1);
    const queryParamsArray = queryParamsString.split("&");
    const queryParams: { [key: string]: string } = {};

    queryParamsArray.forEach((param) => {
      const [key, value] = param.split("=");
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    // on detech new tagId
    const currentTagId = localStorage.getItem("@tagId");
    if (queryParams["t"]) {
      // pull the tag details and set it to local storage
      localStorage.setItem("@tagId", queryParams["t"]);
      if (currentTagId !== queryParams["t"]) {
        // pull the tag details and set it to local storage
        localStorage.removeItem("@selectedBusinessId");
      }
    }
    // http://localhost:4200/app/miCHlcvcK8ab7DVopclM?b=miCHlcvcK8ab7DVopclM

    const businessId = queryParams["b"];
    if (businessId) {
      const currentBusinessId = localStorage.getItem("@selectedBusinessId");
      if (currentBusinessId !== businessId) {
        // reset localStorage
        localStorage.removeItem("@tagId");
      }
      localStorage.setItem("@selectedBusinessId", businessId);
      // only if businessid match
      if (queryParams["t"]) {
        localStorage.setItem("@tagId", queryParams["t"]);
      }
    }
    return queryParams;
  }

  async checkBusiness(): Promise<Business> {
    // the will be determinate based on the tagId
    let businessId = localStorage.getItem("@selectedBusinessId");
    if (!businessId) {
      // if not businessId , try to get it from the tafId
      const tagId = localStorage.getItem("@tagId");
      if (tagId) {
        const tag = await this.businessService.getTagById(tagId);
        if (tag?.businessId) {
          businessId = tag.businessId;
          localStorage.setItem("@selectedBusinessId", businessId);
        }
      }
    }


    let business: Business;
    try {
      // // get and set user role to local storage
      // const url = this.documentApp.location.href;
      // // const url = "https://schnitt.menu-dev.dineas.com/menu?b=Vy9dT5LuB8KMZBZ3dTVw&t=siTahtR60rhdYQSuCsdW&ot=takeAway";

      // const currentSubDomainValue = this.appService.getSubdomain(url);

      let businessId = localStorage.getItem("@selectedBusinessId");

      // business = await this.businessService.get({ subdomain: currentSubDomainValue || undefined, id: businessId || undefined });
      business = await this.businessService.get({ id: businessId || undefined });
      if (business.id) {
        businessId = business.id;
      }
      if (!business.branding) {
        // default if the business didn't set his branding
        const defaultBusness = AppService.defaultFromBusiness(business);
        business.branding = defaultBusness.branding;
        business.brandName = defaultBusness.brandName;
      }
    } catch (error) {
      // if business not existing or user not have access then reomove the whole business data from local storage
      const defaultBusness = AppService.defaultFromBusiness(undefined);
      business = defaultBusness;
    }

    if (business) {
      localStorage.setItem("@business", JSON.stringify(business));
    }

    this.currentBusinessService.onSelectBusiness(business);
    return business;
  }

  // only on the auth guard
  // private async getById(businessId: string): Promise<Business | undefined> {
  //   const docRef = doc(this.firestore, CollectionName.businesses, businessId);
  //   const snapshot = await getDoc<Business, Business>(docRef);
  //   if (snapshot.exists()) {
  //     const data = snapshot.data();
  //     data.id = snapshot.id;
  //     return data;
  //   } else {
  //     return undefined;
  //   }
  // }

  private static componentToHex(c: number): string {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  getColorTheme(hexColor: string): "light" | "dark" {
    // Remove '#' from the beginning if present
    hexColor = hexColor.replace("#", "");

    // Convert hex to RGB
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance using the formula
    // Luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Check if the luminance is greater than a certain threshold (e.g., 128)
    // You can adjust this threshold based on your preference
    if (luminance > 128) {
      return "light";
    } else {
      return "dark";
    }
  }

  static hexToRGBA(hex: string, opacity: number): string {
    // Ensure the hex code is in the correct format
    if (hex.charAt(0) === "#") {
      hex = hex.slice(1);
    }

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    if (hex.length !== 6) {
      throw new Error("Invalid hex color code");
    }

    // Parse the hex color code
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Ensure opacity is within the valid range
    if (opacity < 0 || opacity > 1) {
      throw new Error("Opacity must be between 0 and 1");
    }

    // Convert the opacity to a hexadecimal value
    const a = Math.round(opacity * 255)
      .toString(16)
      .padStart(2, "0");

    // Combine the color components into the final hex value
    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}${a}`;
  }

  static lightenColor(hexColor: string, percent: number): string {
    // Remove '#' from the beginning if present
    hexColor = hexColor.replace("#", "");

    // Convert hex to RGB
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate percentage of each component
    r = Math.round(r + (255 - r) * (percent / 100));
    g = Math.round(g + (255 - g) * (percent / 100));
    b = Math.round(b + (255 - b) * (percent / 100));

    // Convert RGB to hex
    let hex = this.rgbToHex(r, g, b);

    return hex;
  }
  private static rgbToHex(r: number, g: number, b: number): string {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  async load(): Promise<Business> {
    const business = await this.checkBusiness();

    Object.assign(this, business);
    // https://www.positronx.io/angular-seo-set-dynamic-page-title-meta-tags-in-universal-app/
    const title = business?.brandName || "Dineas";
    const description = business?.description || "";

    if (business?.brandName) {
      this.titleService.setTitle(title);
    }
    this.metaTagService.addTags([
      // global meta
      { name: "keywords", content: business?.branding?.tagLine || "" },
      // {name: 'robots', content: 'index, follow'},
      { name: "author", content: title },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      // {name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD'},
      { name: "description", content: description },
      { charset: "UTF-8" },
      { property: "og:title", content: title }, // Facebook
      // {property: 'og:url', content: }, // Facebook
      {
        property: "og:image",
        content: business?.branding?.metaShareImage?.url || "",
      }, // Facebook
      { property: "og:description", content: description }, // Facebook
      { property: "og:locale", content: "en_US" }, // Facebook
      { name: "twitter:card", content: "twitter_card" }, // twitter
      // {name: 'twitter:url', content: }, // twitter
      // {name: 'twitter:site', content: }, // twitter twitter_handle
      { name: "twitter:title", content: title }, // twitter
      { name: "twitter:description", content: description }, // twitter
      // {name: 'twitter:creator', content: }, // twitter twitter_handle
      // {name: 'twitter:app:id:iphone', content: }, // twitter twitter_handle
      // {name: 'twitter:app:id:ipad', content: }, // twitter twitter_handle
      // {name: 'twitter:app:id:googleplay', content: }, // twitter twitter_handle
      // {name: 'twitter:app:id:country', content: }, // twitter twitter_handle
      {
        name: "twitter:image:src",
        content: business?.branding?.metaShareImage?.url || "",
      }, // twitter
      { name: "twitter:image:alt", content: title }, // twitter
      { itemprop: "name", content: title }, // Google
      { itemprop: "description", content: description }, // Google
      {
        itemprop: "image",
        content: business?.branding?.metaShareImage?.url || "",
      }, // Google
      // {name: 'product:price:amount', content: }, // on Product
      // {name: 'product:price:currency', content: }, // on Product
      // disallowPage if : subdomain
      // {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' }, // on disallowPage
      { name: "application-name", content: title }, // on Windows
      { name: "msapplication-TileColo", content: business?.branding?.primaryColor || "" }, // on Windows
      {
        name: "msapplication-TileImage",
        content: business?.branding?.metaShareImage?.url || "",
      }, // on Windows
      { name: "theme-color", content: business?.branding?.primaryColor || "#ffffff" }, // on Windows
    ]);

    const manifest = this.manifestService.getManifest(business, this.appService.domain);
    const stringManifest = JSON.stringify(manifest);

    const blob = new Blob([stringManifest], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const currentManifest: HTMLElement | null = document.getElementById("appManifest");
    if (currentManifest) {
      // currentManifest.href = url;
      currentManifest.setAttribute("href", url);
    }

    return business;
  }
}
