import { AsyncPipe, CurrencyPipe, JsonPipe } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Product, ProductOptionGroupWithUserLanguage, ProductPopulated, SubProduct, SubProductPopulated } from "../../_global/_interfaces";
import { PricingDisplayPipe } from "../../_pipes/pricing-display/pricing-display.pipe";
import { ProductService } from "../../_services/product.service";
import { OptionGroupsFormComponent } from "../option-group-form/form.component";

@Component({
  selector: "app-sub-products-form",
  standalone: true,
  imports: [FormsModule, RouterModule, ReactiveFormsModule, FontAwesomeModule, JsonPipe, AsyncPipe, CurrencyPipe, TranslateModule, PricingDisplayPipe, SvgIconComponent, OptionGroupsFormComponent],
  templateUrl: "./form.component.html",
  styleUrl: "./form.component.scss",
  providers: [], // need to use PricingDisplayPipe
})
export class SubProductFormComponent implements OnInit, OnDestroy {
  @Input() product!: Product;
  @Input() businessCurrency: string = "";
  @Output() subProductsFormChange = new EventEmitter<FormArray>();

  subProductsForm: FormArray = this.formBuilder.array([]);
  private formSubscription: Subscription = new Subscription();
  productService = inject(ProductService);
  subProducts: SubProductPopulated[] = [];
  optionGroups: ProductOptionGroupWithUserLanguage[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
  ) {}
  ngOnInit(): void {
    if (!this.product.subProducts) {
      return;
    }

    this.getData(this.product.subProducts).subscribe((subProducts) => {
      this.subProducts = subProducts;
      this.initializeSubProductsForm(subProducts);
    });
    this.formSubscription = this.subProductsForm.valueChanges.subscribe(() => {
      this.validateSubProducts();
      this.subProductsFormChange.emit(this.subProductsForm);
    });
  }

  productItemForms(form: AbstractControl): FormArray {
    return form.get("products") as FormArray;
  }
  optionGroupForms(form: AbstractControl): FormArray {
    return form.get("optionGroups") as FormArray;
  }

  optionsVariantForms(optionsForm: AbstractControl): FormArray {
    return optionsForm.get("options") as FormArray;
  }

  parseAbstractControlToFormArray(form: AbstractControl): FormGroup {
    return form as FormGroup;
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  initializeSubProductsForm(subProducts: SubProductPopulated[]) {
    if (subProducts.length) {
      for (const subProduct of subProducts) {
        this.addSubProduct(subProduct, this.subProductsForm);
      }
    }
  }

  addSubProduct(subProduct: SubProductPopulated, subProductsForm: FormArray) {
    const productsFormArray: FormArray = this.formBuilder.array([]);

    for (const product of subProduct.products) {
      const productFormGroup = this.createProductFormGroup(product);
      productsFormArray.push(productFormGroup);
    }

    const subProductFormGroup = this.formBuilder.group({
      name: [subProduct.name || ""],
      minQuantity: [subProduct.minQuantity || 0, Validators.min(0)],
      maxQuantity: [subProduct.maxQuantity || 0, Validators.min(0)],
      products: productsFormArray,
      isSelected: [false],
      selectedProduct: [null, subProduct.minQuantity === 1 && subProduct.maxQuantity === 1 ? Validators.required : null],
    });

    subProductsForm.push(subProductFormGroup);
  }

  createProductFormGroup(product: ProductPopulated): FormGroup {
    const optionGroupsFormArray: FormArray = this.formBuilder.array([]);

    if (product.optionGroups) {
      for (const optionGroup of product.optionGroups) {
        const optionGroupFormGroup = this.createOptionGroupFormGroup(optionGroup);
        optionGroupsFormArray.push(optionGroupFormGroup);
      }
    }

    return this.formBuilder.group({
      id: [product.id || ""],
      name: [product.name || ""],
      optionGroups: optionGroupsFormArray,
      isSelected: [false],
      // optionGroups: this.formBuilder.array([]),
      displayOptions: [false],
    });
  }

  createOptionGroupFormGroup(optionGroup: ProductOptionGroupWithUserLanguage): FormGroup {
    const optionVariants: FormArray = this.formBuilder.array([]);

    for (let i = 0; i < optionGroup.options.length; i++) {
      const variant = optionGroup.options[i];
      const quantitySelected = variant.quantitySelected || 0;
      const variantFormGroup = this.formBuilder.group({
        id: [variant.id || 0],
        isSelected: [quantitySelected > 0],
        quantitySelected: [quantitySelected],
        name: [variant.name || ""],
        price: [variant.price || null],
      });
      optionVariants.push(variantFormGroup);
    }

    return this.formBuilder.group({
      id: [optionGroup.id || 0],
      name: [optionGroup.name || ""],
      options: optionVariants,
      rules: this.formBuilder.group({
        type: [optionGroup.rules?.type || "unlimited"],
        maxSelectedOption: [optionGroup.rules?.maxSelectedOption || null],
        minSelectedOption: [optionGroup.rules?.minSelectedOption || null],
      }),
    });
  }

  validateSubProducts() {
    const uniqueOptionGroups: { [id: string]: ProductOptionGroupWithUserLanguage } = {};

    this.subProductsForm.controls.forEach((subProductFormGroup) => {
      const productsFormArray = subProductFormGroup.get("products") as FormArray;
      productsFormArray.controls.forEach((productFormGroup) => {
        const optionGroupsFormArray = productFormGroup.get("optionGroups") as FormArray;
        if (productFormGroup.value.isSelected) {
          productFormGroup.value.optionGroups.forEach((optionGroup: ProductOptionGroupWithUserLanguage) => {
            uniqueOptionGroups[optionGroup.id] = optionGroup;
          });
        }

        optionGroupsFormArray.controls.forEach((optionGroupFormGroup) => {
          const selectedOptions = optionGroupFormGroup.get("options") as FormArray;
          let selectedCount = 0;
          selectedOptions.controls.forEach((variantFormGroup) => {
            const quantitySelected = variantFormGroup.get("quantitySelected")?.value || 0;
            if (variantFormGroup.get("isSelected")?.value) {
              if (!quantitySelected) {
                variantFormGroup.get("quantitySelected")?.setValue(1);
              }
              selectedCount = selectedCount + (quantitySelected || 1);
            } else {
              if (quantitySelected) {
                variantFormGroup.get("quantitySelected")?.setValue(0);
              }
            }
          });
          optionGroupFormGroup.get("quantitySelected")?.setValue(selectedCount);
        });
      });
    });
  }

  onProductSelect(subProductGroup: AbstractControl, productGroup: AbstractControl) {
    const productsFormArray = subProductGroup.get("products") as FormArray;
    // Handle radio buttons
    if (subProductGroup.get("minQuantity")?.value === 1 && subProductGroup.get("maxQuantity")?.value === 1) {
      productsFormArray.controls.forEach((product) => {
        product.get("displayOptions")?.setValue(product === productGroup);
      });
    } else {
      // Handle checkboxes
      productsFormArray.controls.forEach((product) => {
        const selected = product.get("selected")?.value;
        product.get("displayOptions")?.setValue(selected);
      });
    }

    // Load option groups for the selected product if necessary
    if (productGroup.get("displayOptions")?.value && this.optionGroupForms(productGroup)?.length === 0) {
      const productId = productGroup.get("id")?.value;

      for (const subProduct of this.subProducts) {
        for (const product of subProduct.products) {
          if (product.id === productId) {
            const optionGroupsFormArray = productGroup.get("optionGroups") as FormArray;

            for (const optionGroup of product.optionGroups) {
              const optionGroupFormGroup = this.createOptionGroupFormGroup(optionGroup);
              optionGroupsFormArray.push(optionGroupFormGroup);
            }
          }
        }
      }
    }
  }

  getData(subProducts: SubProduct[]): Observable<SubProductPopulated[]> {
    // add a triger that update categories.hasProduct to true when we update the product.categoryIds
    const subProductObservables = subProducts.map((subProduct) =>
      this.productService.getFromIds(subProduct.productIds).pipe(
        switchMap((products) => {
          const optionGroupsForSubProducts$ = combineLatest(products.map((p) => (p.optionGroupIds?.length ? this.productService.getOptionGroupsFromIds(p.optionGroupIds) : of([]))));

          return optionGroupsForSubProducts$.pipe(
            map((optionGroupsArray) => {
              const productsPopulated: ProductPopulated[] = products.map(
                (product, index) =>
                  ({
                    ...product,
                    optionGroups: optionGroupsArray[index],
                  }) as ProductPopulated,
              );
              return { ...subProduct, products: productsPopulated } as SubProductPopulated;
            }),
          );
        }),
      ),
    );

    return combineLatest(subProductObservables);
  }

  onReduiceOptionsVariantQuantity(form: AbstractControl) {
    let currentValue = form.get("quantitySelected")?.value || 0;
    currentValue--;
    if (currentValue <= 0) {
      currentValue = 0;
      form.get("isSelected")?.setValue(false);
    }
    form.get("quantitySelected")?.setValue(currentValue);
  }

  onAddOptionsVariantQuantity(form: AbstractControl, maxSelectedOptionVariant: number | null) {
    let currentValue = form.get("quantitySelected")?.value || 0;
    currentValue++;
    if (maxSelectedOptionVariant && currentValue > maxSelectedOptionVariant) {
      currentValue = maxSelectedOptionVariant;
    }
    form.get("isSelected")?.setValue(true);
    form.get("quantitySelected")?.setValue(currentValue);
  }
}
