import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SvgIconService {
  private iconCache = new Map<string, SafeHtml>();

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  getIcon(iconName: string, width?: number, height?: number, strokeWidth?: number): Observable<SafeHtml> {
    const cacheKey = `${iconName}-${width || ""}-${height || ""}-${strokeWidth || ""}`;
    if (this.iconCache.has(cacheKey)) {
      return of(this.iconCache.get(cacheKey)!);
    } else {
      const url = `assets/icons-svg/${iconName}.svg`;
      return this.http.get(url, { responseType: "text" }).pipe(
        map((svg) => {
          let svgContent = svg;
          if (width && height) {
            svgContent = svgContent.replace(/width="[^"]*"/, `width="${width}"`);
            // Modify the height attribute
            svgContent = svgContent.replace(/height="[^"]*"/, `height="${height}"`);
            // Modify the viewBox attribute
            // svgContent = svgContent.replace(/viewBox="[^"]*"/, `viewBox="0 0 ${width} ${height}"`);
          }
          if (strokeWidth) {
            svgContent = svgContent.replace(/stroke-width="[^"]*"/g, `stroke-width="${strokeWidth}"`);
          }

          const sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(svgContent);
          this.iconCache.set(cacheKey, sanitizedSvg);
          return sanitizedSvg;
        }),
        catchError(() => of("")),
      );
    }
  }

  getAllIcons(): Observable<string[]> {
    // In a real application, you might want to dynamically load the list of icons.
    // For simplicity, we use a hardcoded list here.
    return of(["home", "user", "settings"]); // Add all your icon names here
  }
}
