import { AsyncPipe, CurrencyPipe, JsonPipe } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBars, faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { NgbCarouselModule, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, map, of, switchMap } from "rxjs";
import { AlertComponent } from "../_component/alert/alert.component";
import { CategoryMenuCardComponent } from "../_component/category-menu/card/card.component";
import { CategoryMenuCardPlaceholderComponent } from "../_component/category-menu/placeholder/placeholder.component";
import { ProductCardComponent } from "../_component/product/card/card.component";
import { VerticalProductSectionComponent } from "../_component/product/vertical-section/section.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { Business, CategoryMenu, Checkout, Product, ProductFilter, ProductType, ServiceMenu, User } from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode } from "../_global/_services/app.service";
import { AppService } from "../_services/app.service";
import { BusinessService } from "../_services/business.service";
import { CategoryService } from "../_services/category.service";
import { ProductService } from "../_services/product.service";
import { ServiceMenuService } from "../_services/service-menu.service";

@Component({
  selector: "app-business-menu-category",
  standalone: true,
  imports: [
    FormsModule,
    NgbCarouselModule,
    LazyLoadImageModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CategoryMenuCardComponent,
    CategoryMenuCardPlaceholderComponent,
    JsonPipe,
    AsyncPipe,
    ProductCardComponent,
    MatSliderModule,
    CurrencyPipe,
    AlertComponent,
    TranslateModule,
    SvgIconComponent,
    VerticalProductSectionComponent,
  ],
  templateUrl: "./category.component.html",
  styleUrl: "./category.component.scss",
})
export class BusinessMenuCategoryComponent implements OnInit, OnChanges, OnDestroy {
  private categoryService = inject(CategoryService);
  private serviceMenuService = inject(ServiceMenuService);
  private productService = inject(ProductService);
  private businessService = inject(BusinessService);

  private offcanvasService = inject(NgbOffcanvas);
  isLoading = false;
  userEmail?: string;

  // products?: Product[] = [];
  @Input() categoryId!: string;
  private categoryIdSubject = new BehaviorSubject<string | null>(null);
  categoryId$ = this.categoryIdSubject.asObservable();

  @Input() productType!: ProductType;
  private productTypeSubject = new BehaviorSubject<ProductType>(ProductType.food);
  productType$ = this.productTypeSubject.asObservable();

  categories?: CategoryMenu[] = [];
  selectedCategory?: CategoryMenu;

  drink = ProductType.drink;
  food = ProductType.food;
  desert = ProductType.desert;

  faSearch = faSearch;
  faBars = faBars;
  faChevronDown = faChevronDown;

  pageLoading = true;
  user?: User;
  subscription: Subscription;
  productFilter: ProductFilter;

  allProducts: Product[] = []; // get all the product from the category

  filteredProducts: Product[] = []; // only the matched product from the local filter
  recomandedProducts: Product[] = []; // only the matched product from the local filter that are recommended
  // activeServices: ServiceMenu[] = [];
  // todo update the max price based on the currency
  maxPriceSlider = 100;
  stepPriceSlider = 10;

  // validation & form
  productFilterForm: FormGroup = this.formBuilder.group({
    minPrice: [0, Validators.min(0)],
    maxPrice: [this.maxPriceSlider, Validators.max(this.maxPriceSlider)],
  });

  defaultProductFilter: ProductFilter = {
    minPrice: 0,
    maxPrice: this.maxPriceSlider,
  };

  get hasFilterApply(): boolean {
    const currentFilterString = JSON.stringify(this.productFilter);
    return currentFilterString !== "{}" && JSON.stringify(this.defaultProductFilter) !== currentFilterString;
  }

  get categorTraductionName() {
    const t = this.selectedCategory?.traduction ? this.selectedCategory?.traduction[this.languageCode] : undefined;
    return t?.name || this.selectedCategory?.name;
  }

  business?: Business;
  subscriptionBusiness: Subscription;
  private appService = inject(AppService);
  languageCode: LangCode = DEFAULT_LANG_CODE;
  checkout: Checkout | undefined;

  get displayProductCard(): boolean {
    // if we display the product as card or list
    // to do so we check if the majority of the product have a description or not (if not we display as card)
    const productWithDescription = this.allProducts.filter((product) => product.description);
    return productWithDescription.length < this.allProducts.length / 2;
  }

  constructor(private formBuilder: FormBuilder) {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.user = user;
    this.languageCode = temLang;
    this.checkout = checkout;

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    let localData = localStorage.getItem("@productFilter");
    this.productFilter = localData && localData !== "undefined" ? JSON.parse(localData) : this.defaultProductFilter;
    this.productFilterForm.patchValue(this.productFilter);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes["productType"]) {
      this.productTypeSubject.next(this.productType);
    }
    if (changes["categoryId"]) {
      this.categoryIdSubject.next(this.categoryId);
    }
  }

  async ngOnInit() {
    this.getData().subscribe(async ({ services, categories, products }) => {
      // replace the name with translation if available
      this.categories = categories.map((category) => {
        const name = category.traduction && category.traduction[this.languageCode] ? category.traduction[this.languageCode]?.name : null;
        return { ...category, name: name || category.name };
      });

      if (!products.length) {
        this.filteredProducts = [];
        this.allProducts = [];
        this.recomandedProducts = [];
      } else {
        this.allProducts = products;
        this.applyFilterOnProducts();
      }

      this.pageLoading = false;
    });
  }

  // Method to update categoryId
  setCategoryId(categoryId: string | null) {
    this.categoryIdSubject.next(categoryId);
  }

  open(content: TemplateRef<any>, position: "top" | "bottom" | "start" | "end" = "bottom", panelClass: string = "offcanvas-fit-content offcanvas-after-nav") {
    this.offcanvasService.open(content, {
      position,
      panelClass,
      backdropClass: "offcanvas-backdrop-after-nav",
    });
  }

  applyFilterOnProducts() {
    const filteredProducts = [];
    const recomandedProducts = [];
    for (const product of this.allProducts) {
      if (this.checkIfProductMatchFilter(product)) {
        if (product.isRecommended || product.isPopular) {
          recomandedProducts.push(product);
        } else {
          filteredProducts.push(product);
        }
      }
    }
    this.filteredProducts = filteredProducts;
    this.recomandedProducts = recomandedProducts;
  }

  checkIfProductMatchFilter(product: Product): boolean {
    // if product has no price or no variant, return false
    if (!product.variants || !product.variants.length) {
      return false;
    }
    // check if the product variant prices match the filter
    if (this.productFilter.minPrice && product.variants?.some((variant) => (variant.price || 0) < (this.productFilter.minPrice || 0))) {
      return false;
    } else if (this.productFilter.maxPrice && product.variants?.some((variant) => (variant.price || 0) > (this.productFilter.maxPrice || Number.MAX_VALUE))) {
      return false;
    }

    return true;
  }

  // on filter change update the local storage
  onFilterAppy() {
    this.productFilter = this.productFilterForm.value;
    localStorage.setItem("@productFilter", JSON.stringify(this.productFilter));
    this.offcanvasService.dismiss("filter apply");
    this.applyFilterOnProducts();
  }

  // on filter reset remove the local storage
  onFilterReset() {
    localStorage.removeItem("@productFilter");
    this.productFilter = this.defaultProductFilter;
    this.productFilterForm.patchValue(this.productFilter);
    this.offcanvasService.dismiss("filter reset");
    this.applyFilterOnProducts();
  }

  async onCallWaiter() {
    this.isLoading = true;

    try {
      await this.businessService.callWaiter(this.checkout);
      this.offcanvasService.dismiss("called waiter");
    } catch (error) {
      console.error("Error in onCallWaiter:", error);
    } finally {
      this.isLoading = false;
    }
  }
  getData(): Observable<{ services: ServiceMenu[]; categories: CategoryMenu[]; products: Product[] }> {
    return this.serviceMenuService.getActive().pipe(
      switchMap((services) => {
        const serviceIds = services.map((service) => service.id);
        const categories$ = combineLatest([this.productType$, of(serviceIds)]).pipe(
          switchMap(([productType, serviceIds]) => {
            if (serviceIds.length) {
              return this.categoryService.list({ serviceIds, type: productType });
            } else {
              return of([]);
            }
          }),
        );
        return combineLatest([categories$, this.categoryId$, this.productType$]).pipe(
          switchMap(([categories, categoryId, productType]) => {
            this.selectedCategory = undefined;

            if (categoryId) {
              this.selectedCategory = categories.find((category) => category.id === categoryId);
            }
            // if no selected category, get the first category of the type
            if (!this.selectedCategory && categories.length > 0) {
              this.selectedCategory = categories[0];
            }

            const products$ = this.selectedCategory ? this.productService.list({ categoryId: this.selectedCategory.id }) : of([]);

            return combineLatest([of(services), of(categories), products$]).pipe(
              map(([services, categories, products]) => {
                return {
                  services,
                  categories,
                  products,
                };
              }),
              catchError((error) => {
                console.error("Error in combineLatest:", error);
                return of({ services: [], categories: [], products: [] });
              }),
            );
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ services: [], categories: [], products: [] });
      }),
    );
  }
}
