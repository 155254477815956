import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { SvgIconComponent } from "../svg-icon/index.component";

@Component({
  selector: "app-splash",
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: "./splash.component.html",
  styleUrl: "./splash.component.scss",
  animations: [
    trigger("fadeInOut", [
      state(
        "fadeIn",
        style({
          opacity: 1,
        }),
      ),
      state(
        "fadeOut",
        style({
          opacity: 0,
        }),
      ),
      transition("fadeOut => fadeIn", [animate("1s")]),
      transition("fadeIn => fadeOut", [animate("1s")]),
    ]),
  ],
})
export class SplashComponent implements OnInit {
  icons: string[] = ["food", "drink", "dessert"]; // Add your SVG icon names here
  currentIconIndex: number = 0;
  currentState: string = "fadeIn";

  constructor() {}

  ngOnInit(): void {
    this.cycleIcons();
  }

  cycleIcons() {
    setInterval(() => {
      this.currentState = "fadeOut"; // Start fading out the current icon
      setTimeout(() => {
        this.currentIconIndex = (this.currentIconIndex + 1) % this.icons.length; // Move to the next icon in the array
        this.currentState = "fadeIn"; // Fade the next icon in
      }, 1000); // Wait for the fade-out to complete before switching icons
    }, 3000); // Change the icon every 3 seconds
  }
}
