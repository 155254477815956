import { CurrencyPipe, JsonPipe, PercentPipe } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { ProductItemCardComponent } from "../../_component/product/item-card/item-card.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Checkout, Order, OrderStatus, ProductType, QuantityType, User } from "../../_global/_interfaces";
import { AppService } from "../../_services/app.service";
import { BusinessService } from "../../_services/business.service";
import { CurrentCheckoutService, OrderService } from "../../_services/order.service";
import { AuthenticationComponent } from "../../authentication/authentication.component";

@Component({
  selector: "app-order",
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    JsonPipe,
    ProductItemCardComponent,
    ReactiveFormsModule,
    CurrencyPipe,
    AlertComponent,
    AuthenticationComponent,
    TranslateModule,
    PercentPipe,
    SvgIconComponent,
  ],
  templateUrl: "./details.component.html",
  styleUrl: "./details.component.scss",
})
export class OrderComponent implements OnInit, OnDestroy {
  @Input() orderId!: string;
  @Input() isOffcanvas!: boolean;

  isLoading = false;
  order: Order | null = null;
  pageLoading = true;
  user?: User;
  checkout?: Checkout;
  business?: Business;

  activeOffcanvas = inject(NgbActiveOffcanvas);

  subscriptionBusiness: Subscription;
  private businessService = inject(BusinessService);
  private orderService = inject(OrderService);
  private currentCheckoutService = inject(CurrentCheckoutService);
  private appService = inject(AppService);

  statusRequested = OrderStatus.requested;
  statusCancelled = OrderStatus.cancelled;
  statusFailed = OrderStatus.failed;
  statusCompleted = OrderStatus.completed;
  statusProcessing = OrderStatus.processing;

  get productItemsOrderPerType() {
    // return the list of product item orderd first by food, then drink, then desert, then other
    return (
      this.order?.items?.sort((a, b) => {
        if (a.type === ProductType.food) {
          return -1;
        } else if (a.type === ProductType.drink && b.type !== ProductType.food) {
          return -1;
        } else if (a.type === ProductType.desert && b.type !== ProductType.food && b.type !== ProductType.drink) {
          return -1;
        } else {
          return 1;
        }
      }) || []
    );
  }

  get discount(): number {
    return this.order?.total?.dealAmount || (this.order?.total?.itemsTotalOrg || 0) - (this.order?.total?.itemsTotal || 0);
  }

  constructor() {
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.user = user;
    this.business = business;
    this.checkout = checkout;
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionBusiness.unsubscribe();
  }

  async cancelOrder() {
    this.isLoading = true;
    try {
      await this.orderService.cancelOrder(this.orderId);
      // reset checkout for the user to start a new order
      if (this.checkout?.orderId && this.checkout.orderId === this.orderId) {
        this.currentCheckoutService.resetCheckout(this.checkout);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async ngOnInit() {
    try {
      this.orderService.get(this.orderId).subscribe((order: Order) => {
        this.order = order;
        for (const item of order?.items || []) {
          if (item.variant.quantityType && item.variant.quantityType !== QuantityType.unit) {
            // todo show alert about quantity type
            break;
          }
        }
      });
    } catch (error) {
      console.error(error);
      // todo redirect to 404
    } finally {
      this.pageLoading = false;
    }
  }
}
