@if(notifications.length){
<div class="notifications-section position-absolute">

  @for (alert of notifications; track alert) {
  <ngb-alert [type]="alert.type" (closed)="closeNotification(alert)">

    <div class="row">
      <div class="col">
        <b>{{ alert.title }}</b><br />{{alert.body}}
      </div>
      @if(alert.link){
      <div class="col col-auto">
        <a [href]="alert.link" class="btn btn-link" aria-label="Close" (click)="closeNotification(alert)">
          Details
        </a>
      </div>
      }

    </div>
  </ngb-alert>
  }
</div>
}