import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderState } from "./loader";
import { LoaderService } from "./loader.service";

@Component({
  selector: "app-loader",
  standalone: true,
  imports: [],
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss",
})
export class LoaderComponent implements OnInit, OnDestroy {
  show: boolean = false;
  type: "loading" | "search-business" = "loading";
  private subscription?: Subscription;

  constructor(
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState ) => {
      this.show = state.show;
      this.type = state.type;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
