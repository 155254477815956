import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, inject } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { Firestore, collection, collectionData, doc, docData, getDocs, limit, orderBy, query, where } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { Router } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { Business, BusinessPreview, BusinessTag, Checkout, CollectionName, CustomerStatus, FunctionResponse, Order, Payment, ProductItem, User, UserPreview } from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, parseBusinessPreview } from "../_global/_services/app.service";
import { BusinessService } from "./business.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private functions: Functions = inject(Functions);
  private firestore: Firestore = inject(Firestore);
  private router: Router = inject(Router);
  private auth: Auth = inject(Auth);
  domain: string;

  constructor(@Inject(DOCUMENT) private documentApp: Document) {
    this.domain = this.documentApp.location.hostname;
    if (this.domain === "localhost") {
      this.domain = "http://localhost:4200";
    } else {
      this.domain = `https://${this.domain}`;
    }
  }

  // getSelectedBusinessId(): string {
  //   const businessId = localStorage.getItem("@selectedBusinessId");
  //   if (!businessId) {
  //     throw new Error("No business selected");
  //   }
  //   return businessId;
  // }

  get(id: string): Observable<Order> {
    const docRef = doc(this.firestore, CollectionName.orders, id);
    return docData(docRef, {
      idField: "id",
    }) as Observable<Order>;
  }

  listOrders(limitToLoad: number = 100): Observable<Order[]> {
    if (!this.auth.currentUser?.email) {
      return new Observable<Order[]>();
    }
    // need observable to real time update
    const docsRef = collection(this.firestore, CollectionName.orders);
    // todo get the auth user email
    // the rules should already filter it but still qadd it in the front end
    const q = query(docsRef, where("customerEmails", "array-contains", this.auth.currentUser.email), orderBy("requestedAt", "desc"), limit(limitToLoad || 100));
    const data = collectionData(q, {
      idField: "id",
    });
    return data as Observable<Order[]>;
  }

  listCustomers(orderId: string): Observable<UserPreview[]> {
    // need observable to real time update
    const docsRef = collection(this.firestore, CollectionName.orders, orderId, CollectionName.orderCustomers);

    // todo get the auth user email
    // the rules should already filter it but still qadd it in the front end
    const data = collectionData(docsRef);
    return data as Observable<UserPreview[]>;
  }
  // listPayments(orderId: string): Observable<Payment[]> {
  //   // need observable to real time update
  //   const docsRef = collection(this.firestore, CollectionName.orders, orderId, CollectionName.orderPayments);
  //   const data = collectionData(docsRef);
  //   return data as Observable<Payment[]>;
  // }
  getMyOrderPayments(orderId: string, email: string): Observable<Payment[]> {
    // const docRef = doc(this.firestore, CollectionName.orders, orderId, CollectionName.orderPayments, email);
    // return docData(docRef, {
    //   idField: "id",
    // }) as Observable<Payment>;

    // need observable to real time update
    const docsRef = collection(this.firestore, CollectionName.orders, orderId, CollectionName.orderPayments);
    // todo get the auth user email
    // the rules should already filter it but still qadd it in the front end
    const q = query(docsRef, where("customerEmail", "==", email));

    const data = collectionData(docsRef, {
      idField: "id",
    });
    return data as Observable<Payment[]>;
  }

  async getOrderByPaymentIntent(paymentIntentId: string): Promise<Order | undefined> {
    const docsRef = collection(this.firestore, CollectionName.orders);
    const q = query(docsRef, where("paymentIntentIds", "array-contains", paymentIntentId), limit(1));
    const docs = await getDocs(q);
    let order: Order | undefined;
    if (docs.docs.length) {
      order = docs.docs[0].data() as Order;
      order.id = docs.docs[0].id;
    }
    return order;
  }

  async requestOrder(checkout: Checkout): Promise<FunctionResponse<Checkout>> {
    // will automatically create the stripe customer id if not auth user
    const payload: Checkout = { ...checkout, domain: this.domain, managedBy: "customer" };
    const messagingTokens = JSON.parse(localStorage.getItem("@messagingTokens") || "[]");
    const language = (localStorage.getItem("@languageCode") as LangCode) || DEFAULT_LANG_CODE;
    if (messagingTokens && payload.customer) {
      payload.customer.messagingTokens = messagingTokens;
      payload.customer.language = language;
    }

    const callable = httpsCallable<Checkout, FunctionResponse<Checkout>>(this.functions, "requestOrder");
    return (await callable(payload)).data;
  }

  async handleSplitPayment(checkout: Checkout, amountToCharge: number): Promise<FunctionResponse<Payment>> {
    // will automatically create the stripe customer id if not auth user
    const payload: { checkout: Checkout; amountToCharge: number } = { checkout: { ...checkout, domain: this.domain, managedBy: "customer" }, amountToCharge };
    const messagingTokens = JSON.parse(localStorage.getItem("@messagingTokens") || "[]");
    const language = (localStorage.getItem("@languageCode") as LangCode) || DEFAULT_LANG_CODE;
    if (messagingTokens && payload.checkout.customer) {
      payload.checkout.customer.messagingTokens = messagingTokens;
      payload.checkout.customer.language = language;
    }
    const callable = httpsCallable<{ checkout: Checkout; amountToCharge: number }, FunctionResponse<Payment>>(this.functions, "handleSplitPayment");
    return (await callable(payload)).data;
  }

  async updateTab(checkout: Checkout): Promise<Checkout> {
    // will automatically create the stripe customer id if not auth user
    const payload: Checkout = { ...checkout, managedBy: "customer" };
    const callable = httpsCallable<Checkout, Checkout>(this.functions, "updateTab");
    return (await callable(payload)).data;
  }

  async updateOrderPayment(orderId: string, paymentId: string, participationAmount: number, participationTip: number): Promise<void> {
    // will automatically create the stripe customer id if not auth user
    const payload = { orderId, paymentId, participationAmount, participationTip };

    const callable = httpsCallable<{ orderId: string; paymentId: string; participationAmount: number; participationTip: number }, void>(this.functions, "updateOrderPayment");
    return (await callable(payload)).data;
  }

  async joinTab(orderId: string, customer: UserPreview): Promise<void> {
    // will automatically create the stripe customer id if not auth user
    const payload = { orderId, customer };

    const callable = httpsCallable<{ orderId: string; customer: UserPreview }, void>(this.functions, "joinTab");
    return (await callable(payload)).data;
  }

  async inviteTabAction(orderId: string, email: string, status: CustomerStatus): Promise<void> {
    // will automatically create the stripe customer id if not auth user
    const payload = { orderId, email, status };

    const callable = httpsCallable<{ orderId: string; email: string; status: CustomerStatus }, void>(this.functions, "inviteTabAction");
    return (await callable(payload)).data;
  }

  async closeTab(data: { orderId: string; tipPercentage?: number }): Promise<Order> {
    // will automatically create the stripe customer id if not auth user
    const payload = { ...data };

    const callable = httpsCallable<{ orderId: string; tipPercentage?: number }, Order>(this.functions, "closeTab");
    return (await callable(payload)).data;
  }

  async cancelOrder(orderId: string): Promise<FunctionResponse<Order>> {
    // will automatically create the stripe customer id if not auth user
    const callable = httpsCallable<{ orderId: string; source: "user" }, FunctionResponse<Order>>(this.functions, "onCancelOrder");
    return (await callable({ orderId, source: "user" })).data;
  }

  async cancelProductItem(productId: string, orderId: string, addedAt: number) {
    const callable = httpsCallable<{ orderId: string; productId: string; addedAt: number }, Order>(this.functions, "cancelProductItem");
    return (await callable({ orderId, productId, addedAt })).data;
  }

  async checkCheckout(checkout: Checkout): Promise<Checkout> {
    // will automatically create the stripe customer id if not auth user
    const callable = httpsCallable<Checkout, Checkout>(this.functions, "checkCheckout");
    return (await callable({ ...checkout })).data;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class CurrentCheckoutService {
  constructor(
    private businessService: BusinessService,
    private orderService: OrderService,
  ) {}

  private subject = new Subject<Checkout>();

  private resetPayment(checkout: Checkout | undefined): Checkout {
    const newCheckout: Checkout = { ...checkout, isTabClosed: checkout?.isTabClosed || false, isGroupTab: checkout?.isGroupTab || false };

    // update the timestamp each time the checkout is updated
    newCheckout.updatedAt = new Date().valueOf();
    newCheckout.paymentIntentId = undefined;
    newCheckout.clientSecret = undefined;
    newCheckout.total = undefined;
    newCheckout.deal = undefined;
    return newCheckout;
  }

  set(checkout: Checkout, resetPayment?: boolean): void {
    let newCheckout = { ...checkout };
    // update the timestamp each time the checkout is updated
    if (resetPayment || !newCheckout.items || !newCheckout.items.length) {
      newCheckout = this.resetPayment(newCheckout);
    }
    localStorage.setItem("@checkout", JSON.stringify(newCheckout));
    this.subject.next(newCheckout);
    this.check(newCheckout);
  }

  check(checkout: Checkout | undefined): void {
    if (checkout) {
      const newCheckout = { ...checkout };
      // check tag id
      const tagId = localStorage.getItem("@tagId");
      if (tagId && (!newCheckout.tag || newCheckout.tag.id !== tagId)) {
        newCheckout.tag = { id: tagId } as BusinessTag;
      }
      // check business id
      const businessId = BusinessService.getSelectedBusinessId();
      if (!newCheckout.business || newCheckout.business.id !== businessId) {
        newCheckout.business = { id: businessId };
        newCheckout.items = [];
      }

      this.orderService
        .checkCheckout(newCheckout)
        .then((checkoutChekced) => {

          localStorage.setItem("@checkout", JSON.stringify(checkoutChekced));
          this.subject.next(checkoutChekced);
        })
        .catch((error) => {
          console.error("CartComponent -> onCheckCheckout -> error", error);
        });
    }
  }

  setCustomer(checkout: Checkout | undefined, user: User | undefined): void {
    if (!user) {
      return;
    }
    const newCheckout: Checkout = { ...checkout, isTabClosed: checkout?.isTabClosed || false, isGroupTab: checkout?.isGroupTab || false };
    // update the timestamp each time the checkout is updated
    if (!newCheckout.customer) {
      newCheckout.customer = {};
    }
    if (!newCheckout.customer.phone?.number && user.phone?.number) {
      newCheckout.customer.phone = user.phone;
    }
    if (user.email) {
      newCheckout.customer.email = user.email;
    }
    if (!newCheckout.customer.firstName && user.firstName) {
      newCheckout.customer.firstName = user.firstName;
    }
    if (!newCheckout.customer.lastName && user.lastName) {
      newCheckout.customer.lastName = user.lastName;
    }

    localStorage.setItem("@checkout", JSON.stringify(newCheckout));
    this.subject.next(newCheckout);
  }

  async setTag(checkout: Checkout | undefined, business: Business | undefined): Promise<void> {
    // if checkout doesn't have the tags, add the tags
    if (checkout && business?.id) {
      let update = false;
      const newCheckout = { ...checkout };
      const tagId = localStorage.getItem("@tagId");
      if (newCheckout && !newCheckout.tag && tagId) {
        const tag = await this.businessService.getTagById(tagId);
        newCheckout.tag = tag;
        update = true;
      }
      if (!newCheckout.business?.id || newCheckout.business.id !== business.id) {
        newCheckout.business = parseBusinessPreview(business);
        newCheckout.items = [];
        update = true;
      }
      if (update) {
        localStorage.setItem("@checkout", JSON.stringify(newCheckout));
        this.subject.next(newCheckout);
      }
    }
  }

  addItem(checkout: Checkout | undefined, business: BusinessPreview, item: ProductItem): void {
    let newCheckout = this.resetPayment(checkout);
    if (newCheckout.business?.id !== business?.id) {
      newCheckout = {
        business,
        customer: newCheckout.customer || undefined,
        items: [],
        isTabClosed: false,
        isGroupTab: newCheckout.isGroupTab || false,
      };
    }

    // check if the current cart is part of the same business. if not, clear the cart
    if (!newCheckout.items) {
      newCheckout.items = [];
    }
    newCheckout.items.push({ ...item });
    localStorage.setItem("@checkout", JSON.stringify(newCheckout));
    this.subject.next(newCheckout);
    this.check(newCheckout);
  }

  updateItem(checkout: Checkout | undefined, business: BusinessPreview, item: ProductItem): void {
    let newCheckout = this.resetPayment(checkout);
    if (!checkout || !item.id) {
      console.error("checkout or business or item is missing");
      return;
    }

    if (!newCheckout.items) {
      console.error("checkout items is missing");
      return this.addItem(newCheckout, business, item);
    }
    const productItemIndex = newCheckout.items.findIndex((i) => i.id === item.id && i.addedAt === item.addedAt);
    if (productItemIndex < 0) {
      return this.addItem(newCheckout, business, item);
    }
    // update the current cart item
    newCheckout.items[productItemIndex] = { ...item };
    localStorage.setItem("@checkout", JSON.stringify(newCheckout));
    this.subject.next(newCheckout);
    this.check(newCheckout);
  }

  deleteItem(checkout: Checkout | undefined, item: ProductItem) {
    let newCheckout = this.resetPayment(checkout);

    if (newCheckout.items) {
      const productItemIndex = newCheckout.items.findIndex((i) => i.id === item.id && i.addedAt === item.addedAt);
      if (productItemIndex >= 0) {
        // remove the current cart item
        newCheckout.items.splice(productItemIndex, 1);
        localStorage.setItem("@checkout", JSON.stringify(newCheckout));
        this.subject.next(newCheckout);
      }
    }
    this.check(newCheckout);
  }

  getCheckout(): Observable<Checkout> {
    return this.subject.asObservable();
  }

  resetCheckout(checkout: Checkout | undefined): Checkout {
    const newCheckout: Checkout = { customer: { ...checkout?.customer }, paymentMethod: checkout?.paymentMethod, paymentType: checkout?.paymentType, isTabClosed: false, isGroupTab: false };
    // update the timestamp each time the checkout is updated
    localStorage.setItem("@checkout", JSON.stringify(newCheckout));
    this.subject.next(newCheckout);
    return newCheckout;
  }
}
