import { AsyncPipe, CurrencyPipe, JsonPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { OptionRuleType, ProductItem, ProductOptionGroupWithUserLanguage } from "../../_global/_interfaces";
import { PricingDisplayPipe } from "../../_pipes/pricing-display/pricing-display.pipe";

@Component({
  selector: "app-option-groups-form",
  standalone: true,
  imports: [FormsModule, RouterModule, ReactiveFormsModule, FontAwesomeModule, JsonPipe, AsyncPipe, CurrencyPipe, TranslateModule, PricingDisplayPipe, SvgIconComponent],
  templateUrl: "./form.component.html",
  styleUrl: "./form.component.scss",
  providers: [], // need to use PricingDisplayPipe
})
export class OptionGroupsFormComponent implements OnInit, OnDestroy {
  @Input() optionGroups: ProductOptionGroupWithUserLanguage[] = [];
  @Input() selectedProductItem: ProductItem | null = null;
  @Input() businessCurrency: string = "";
  @Output() optionsFormChange = new EventEmitter<FormArray>();

  optionsForm: FormArray = this.formBuilder.array([]);
  private formSubscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initializeOptionGroupForm(this.optionGroups, this.selectedProductItem);

    this.formSubscription = this.optionsForm.valueChanges.subscribe(() => {
      this.validateOptionVariants();

      this.optionsFormChange.emit(this.optionsForm);
    });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  parseAbstractControlToFormArray(form: AbstractControl): FormGroup {
    return form as FormGroup;
  }

  initializeOptionGroupForm(optionGroups: ProductOptionGroupWithUserLanguage[], selectedProductItem: ProductItem | null) {
    const options = (selectedProductItem ? selectedProductItem.optionGroups : optionGroups) || [];

    if (options.length) {
      for (const option of options) {
        this.addOption(option as ProductOptionGroupWithUserLanguage, this.optionsForm);
      }
    }

    this.optionsFormChange.emit(this.optionsForm);
  }

  optionsRuleTypeLabel(optionForm: AbstractControl): string {
    const minValueToSelect = optionForm.value.rules.minSelectedOption || optionForm.value.rules.maxSelectedOption || 1;
    const maxValueToSelect = optionForm.value.rules.maxSelectedOption || optionForm.value.rules.minSelectedOption || 1;
    const selectedCount = optionForm.value.quantitySelected || 0;

    switch (optionForm.value.rules.type) {
      case OptionRuleType.exactly:
        if (selectedCount >= maxValueToSelect) {
          return this.translate.instant("product.you-have-chosen-x-items", { value: selectedCount });
        } else if (selectedCount) {
          return this.translate.instant("product.choose-exactly-x-additional-items", { value: minValueToSelect - selectedCount });
        }
        return this.translate.instant("product.choose-exactly-x-items", { value: minValueToSelect });
      case OptionRuleType.atLeast:
        if (selectedCount >= maxValueToSelect) {
          return this.translate.instant("product.you-have-chosen-x-items", { value: selectedCount });
        } else if (selectedCount) {
          return this.translate.instant("product.choose-at-least-x-additional-items", { value: minValueToSelect - selectedCount });
        }
        return this.translate.instant("product.choose-at-least-x-items", { value: maxValueToSelect });
      case OptionRuleType.between:
        if (selectedCount < minValueToSelect) {
          return this.translate.instant("product.choose-at-least-x-additional-items", { value: minValueToSelect - selectedCount });
        } else if (selectedCount >= maxValueToSelect) {
          return this.translate.instant("product.you-have-chosen-max-items");
        }
        return this.translate.instant("product.choose-between-x-items", { valueMin: minValueToSelect, valueMax: maxValueToSelect });
      case OptionRuleType.upTo:
        if (selectedCount >= maxValueToSelect) {
          return this.translate.instant("product.you-have-chosen-max-items");
        } else if (selectedCount) {
          return this.translate.instant("product.choose-up-to-x-additional-items", { value: maxValueToSelect - selectedCount });
        }
        return this.translate.instant("product.choose-up-to-x-items", { value: maxValueToSelect });
      default:
        return "";
    }
  }

  optionsVariantForms(optionsForm: AbstractControl): FormArray {
    return optionsForm.get("options") as FormArray;
  }

  addOption(option: ProductOptionGroupWithUserLanguage, optionsForm: FormArray) {
    if (!option.options?.length) {
      return;
    }

    let selectedCount = 0;
    const optionVariants: FormArray = this.formBuilder.array([]);

    for (let i = 0; i < option.options.length; i++) {
      const variant = option.options[i];
      variant.id = i;

      const quantitySelected = variant.quantitySelected || 0;
      selectedCount += quantitySelected;
      const variantFormGroup = this.formBuilder.group({
        id: [variant.id || 0],
        isSelected: [quantitySelected > 0],
        quantitySelected: [quantitySelected],
        name: [variant.name || ""],
        nameTranslated: [variant.nameTranslated || ""],
        price: [variant.price || null],
        isRecommended: [variant.isRecommended || false],
        posId: [variant.posId || null],
      });
      optionVariants.push(variantFormGroup);
    }

    const selectedOptionsRules = [];
    if (option.rules?.type === OptionRuleType.atLeast) {
      selectedOptionsRules.push(Validators.min(option?.rules?.minSelectedOption || 1));
    } else if (option.rules?.type === OptionRuleType.upTo) {
      selectedOptionsRules.push(Validators.max(option?.rules?.maxSelectedOption || option?.rules?.minSelectedOption || 1));
    } else if (option.rules?.type === OptionRuleType.exactly) {
      selectedOptionsRules.push(Validators.min(option?.rules?.minSelectedOption || option?.rules?.maxSelectedOption || 1));
      selectedOptionsRules.push(Validators.max(option?.rules?.minSelectedOption || option?.rules?.maxSelectedOption || 1));
    } else if (option.rules?.type === OptionRuleType.between) {
      selectedOptionsRules.push(Validators.min(option?.rules?.minSelectedOption || 1));
      selectedOptionsRules.push(Validators.max(option?.rules?.maxSelectedOption || 1));
    }

    const optionFormGroup = this.formBuilder.group({
      quantitySelected: [selectedCount, selectedOptionsRules],
      id: [option.id || 0],
      posId: [option.posId || null],
      name: [option.name || ""],
      nameTranslated: [option.nameTranslated || ""],
      options: optionVariants,
      rules: this.formBuilder.group({
        type: [option?.rules?.type || "unlimited"],
        maxSelectedOptionVariant: [option?.rules?.maxSelectedOptionVariant || null],
        maxSelectedOption: [option?.rules?.maxSelectedOption || null],
        minSelectedOption: [option?.rules?.minSelectedOption || null],
      }),
    });

    optionsForm.push(optionFormGroup);
  }

  onReduiceOptionsVariantQuantity(form: AbstractControl) {
    let currentValue = form.get("quantitySelected")?.value || 0;
    currentValue--;
    if (currentValue <= 0) {
      currentValue = 0;
      form.get("isSelected")?.setValue(false);
    }
    form.get("quantitySelected")?.setValue(currentValue);
  }

  onAddOptionsVariantQuantity(form: AbstractControl, maxSelectedOptionVariant: number | null) {
    let currentValue = form.get("quantitySelected")?.value || 0;
    currentValue++;
    if (maxSelectedOptionVariant && currentValue > maxSelectedOptionVariant) {
      currentValue = maxSelectedOptionVariant;
    }
    form.get("isSelected")?.setValue(true);
    form.get("quantitySelected")?.setValue(currentValue);
  }

  validateOptionVariants() {
    this.optionsForm.controls.forEach((optionFormGroup) => {
      const selectedOptions = optionFormGroup.get("options") as FormArray;
      let selectedCount = 0;
      selectedOptions.controls.forEach((variantFormGroup) => {
        const quantitySelected = variantFormGroup.get("quantitySelected")?.value || 0;

        if (variantFormGroup.get("isSelected")?.value) {
          if (!quantitySelected) {
            variantFormGroup.get("quantitySelected")?.setValue(1);
          }
          selectedCount = selectedCount + (quantitySelected || 1);
        } else {
          if (quantitySelected) {
            variantFormGroup.get("quantitySelected")?.setValue(0);
          }
        }
      });

      const currentSelectedOptions = optionFormGroup.get("quantitySelected")?.value;
      if (selectedCount === currentSelectedOptions) {
        return;
      }

      optionFormGroup.get("quantitySelected")?.setValue(selectedCount);
      this.checkIfOptionVariantsIsDisactivated(optionFormGroup);
    });
  }

  private checkIfOptionVariantsIsDisactivated(optionForm: AbstractControl) {
    if (![OptionRuleType.exactly, OptionRuleType.between, OptionRuleType.upTo].includes(optionForm.get("rules.type")?.value)) {
      return;
    }
    const selectedOptionsCount = optionForm.get("quantitySelected")?.value || 0;
    const maxValueToSelect = optionForm.get("rules.maxSelectedOption")?.value || optionForm.get("rules.minSelectedOption")?.value || 1;
    const selectedOptions = optionForm.get("options") as FormArray;
    selectedOptions.controls.forEach((variantFormGroup) => {
      if (selectedOptionsCount < maxValueToSelect && variantFormGroup.disabled) {
        variantFormGroup.enable();
      } else if (selectedOptionsCount >= maxValueToSelect && !variantFormGroup.get("isSelected")?.value && !variantFormGroup.disabled) {
        variantFormGroup.disable();
      }
    });
  }
}
