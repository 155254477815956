import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { AlertComponent } from "../_component/alert/alert.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../_global/_services/app.service";

@Component({
  selector: "app-terms",
  standalone: true,
  imports: [AlertComponent, FontAwesomeModule, RouterModule, TranslateModule, SvgIconComponent],
  templateUrl: "./terms.component.html",
  styleUrl: "./terms.component.scss",
})
export class TermsComponent {
  languageDir;

  activeOffcanvas = inject(NgbActiveOffcanvas);
  constructor() {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
  }
}
