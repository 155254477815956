<nav class="navbar my-3" (click)="activeOffcanvas.dismiss('Cross click')">
  <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
    <div class="d-flex w-100 justify-content-between">
      <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="contact-us.title"></span></h3>
    </div>
  </div>
</nav>
<hr class="mt-0" />
<section class="container pb-3">
  <h4 translate="contact-us.need-some-help"></h4>

  <p class="text-start small " translate="contact-us.sub-title"></p>
  <a href="mailto:yohan_balassiano@hotmail.com" (click)="activeOffcanvas.dismiss('click email us')" class="btn btn-primary w-100 my-3" translate="contact-us.email-us"></a>
</section>