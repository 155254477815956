import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";

import { DOCUMENT } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Checkout } from "../../../_global/_interfaces";
import { Alert } from "../../../_interfaces";
import { CurrentCheckoutService, OrderService } from "../../../_services/order.service";
import { PaymentService } from "../../../_services/payment.service";
import { AlertComponent } from "../../alert/alert.component";
import { LoaderService } from "../../loader/loader.service";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
  standalone: true,
  imports: [AlertComponent, FormsModule, FontAwesomeModule, TranslateModule],
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  activeOffcanvas = inject(NgbActiveOffcanvas);
  @Input() isSplitPayment?: boolean;
  @ViewChild("paymentElementForm", { static: false }) paymentElementForm?: ElementRef;
  elements: any;
  paymentElement: any;
  elementHandler = this.onChange.bind(this);
  alertPayment: Alert = { type: "info" };
  isLoading = false;
  pageLoading = true;
  checkout: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);
  domain: string;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private orderService: OrderService,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private documentApp: Document,
  ) {
    const localData = localStorage.getItem("@checkout");
    this.checkout = localData && localData !== "undefined" ? JSON.parse(localData) : {};
    this.subscriptionCheckout = this.currentCheckoutService.getCheckout().subscribe((checkout) => {
      this.checkout = checkout;
    });

    this.domain = this.documentApp.location.hostname;
    if (this.domain === "localhost") {
      this.domain = "http://localhost:4200";
    } else {
      this.domain = `https://${this.domain}`;
    }
  }

  ngOnInit(): void {
    if (this.isSplitPayment && this.checkout.total) {
      const splitPayment: { type?: "full-amount" | "specific-amount" | "split-amount"; amount?: number; tip?: number; splitBy?: number } = JSON.parse(localStorage.getItem("@split-payment") || "{}");
      if (splitPayment.tip) {
        this.checkout.total.tipAmount = splitPayment.tip;
      }
    }
  }

  async ngAfterViewInit() {
    if (!this.checkout) {
      console.error("return doesn't have a checkout or paymentIntentId");
      return;
    } else if (!this.paymentElementForm) {
      console.error("paymentElementForm is not display/active");
      return;
    }
    let checkout = { ...this.checkout };
    try {
      if (checkout.clientSecret) {
        const { paymentIntent } = await this.paymentService.retrievePaymentIntent(checkout.clientSecret);
        if (paymentIntent && ["succeeded", "requires_capture", "processing"].includes(paymentIntent.status)) {
          checkout = await this.setOrderByPaymentIntent(paymentIntent.id);
          if (checkout.orderId) {
            this.currentCheckoutService.set(checkout);
            this.activeOffcanvas.dismiss("Order existing");
            return;
          }
        }
      }
    } catch (error) {
      console.error("error on retrievePaymentIntent", error);
    }

    // get client secret to load with the stripe element
    try {
      checkout = await this.paymentService.setPaymentIntentForPayment(checkout);
      this.currentCheckoutService.set(checkout);
      const stripe = this.paymentService.stripe as any;
      this.elements = stripe.elements({
        clientSecret: checkout.clientSecret,
        // appearance: {
        //   theme: "night",
        //   labels: "above",
        //   variables: {
        //     colorPrimary: "#0D9C8F",
        //     colorBackground: "#002B38",
        //     colorBackgroundText: "#002B38",
        //     font-family: "Inter", sans-serif;
        //   },
        // },
      });
      this.paymentElement = this.elements.create("payment");
      this.paymentElement.mount(this.paymentElementForm?.nativeElement);
      this.paymentElement.addEventListener("change", this.elementHandler);
    } catch (e: any) {
      this.alertPayment = { type: "danger", message: e.message };
      console.error("error on setPaymentIntentForPayment", e);
    }
  }

  ngOnDestroy(): void {
    if (this.paymentElement) {
      this.paymentElement.removeEventListener("change", this.elementHandler);
      this.paymentElement.destroy();
    }
  }

  private async setOrderByPaymentIntent(paymentIntentId: string): Promise<Checkout> {
    // this will manage the request for the order
    // if order already existing it will recure the existing one
    this.isLoading = true;
    let checkout: Checkout = { ...this.checkout };
    try {
      const order = await this.orderService.getOrderByPaymentIntent(paymentIntentId);
      if (order && order.id) {
        checkout.orderId = order.id;
        checkout.orderStatus = order.status;
      }
    } catch (e: any) {
      this.alertPayment = { type: "danger", message: e.message };
    } finally {
      this.isLoading = false;
      return checkout;
    }
  }

  onChange(event: any): void {
    if (event.error) {
      this.alertPayment = { type: "danger", message: event.error?.message || "error on change" };
    } else {
      this.alertPayment = { type: "info" };
      this.pageLoading = false;
    }
    // Display bank name corresponding to IBAN, if available.
    this.cd.detectChanges();
  }

  async submitElement(): Promise<void> {
    this.loaderService.show();
    const stripe = this.paymentService.stripe as any;

    // get current path to redirect after payment

    const { error } = await stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${this.domain}${this.router.url}?confirm-payment=true`,
        payment_method_data: {
          billing_details: {
            name: `${this.checkout.customer?.firstName || ""} ${this.checkout.customer?.lastName || ""}`.trim(),
            email: this.checkout.customer?.email,
          },
        },
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.alertPayment = { type: "danger", message: error.message };
    } else {
      this.alertPayment = { type: "danger", message: "An unexpected error occurred." };
    }
    this.loaderService.hide();
  }
}
