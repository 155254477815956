import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation, inject } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCartShopping, faHouse, faListSquares, faQrcode, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { NgbDropdownModule, NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Business, Checkout, User } from "../../_global/_interfaces";
import { AppService } from "../../_services/app.service";
import { AuthenticationComponent } from "../../authentication/authentication.component";
import { AuthenticationService } from "../../authentication/is-user-login.service";
import { LoaderService } from "../loader/loader.service";
import { SvgIconComponent } from "../svg-icon/index.component";

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [CommonModule, RouterModule, NgbDropdownModule, FontAwesomeModule, TranslateModule, SvgIconComponent],
  templateUrl: "./navbar.component.html",
  styleUrl: "./navbar.component.scss",

  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnDestroy, AfterViewInit {
  isLoading = false;
  isTransparent = false;
  showHeaderMessage = false;
  subscription: Subscription;
  subscriptionBusiness: Subscription;
  subscriptionCheckout: Subscription;
  isHidden = false;
  faHouse = faHouse;
  faQrcode = faQrcode;
  faSearch = faSearch;
  faListSquares = faListSquares;
  faCartShopping = faCartShopping;
  faUser = faUser;
  user?: User;
  business?: Business;
  checkout?: Checkout;
  private modalService = inject(NgbModal);
  // private offcanvasService = inject(NgbOffcanvas);

  @ViewChild("mustBeOverAge", { static: true }) mustBeOverAge!: ElementRef;

  get businessName(): string {
    return this.business?.brandName || this.business?.name || "Dineas";
  }
  get cartCount(): number {
    if (!this.checkout || !this.checkout.items) {
      return 0;
    }
    let count = 0;
    for (const item of this.checkout.items) {
      count += item.quantitySelected || 0;
    }
    return count;
  }

  get hasUnreadNotifications(): boolean {
    return localStorage.getItem("@hasUnreadNotification") === "true";
  }
  
  private appService = inject(AppService);

  currentRoute: "home" | "cart" | "menu" | "user" | "qr-code" = "user";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private offcanvasService: NgbOffcanvas,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // disable all modal when route changes
      this.modalService.dismissAll();
      this.offcanvasService.dismiss();
      switch (this.router?.url) {
        case "/menu":
          this.currentRoute = "home";
          break;
        case "/menu/category":
          this.currentRoute = "menu";
          break;
        case "/cart":
        case "/checkout":
          this.currentRoute = "cart";
          break;

        case "/account":
          this.currentRoute = "user";
          break;
        default:
          this.currentRoute = "qr-code";
      }
      this.isTransparent = this.checkIsTransparent();
      // Check if the current URL ends with any route from the list
      // Function to check if the current URL ends with any route from the list
      this.isHidden = false;
    });

    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    this.user = user;

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });
    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
    });
  }

  ngAfterViewInit(): void {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if (this.router?.url?.toString().startsWith("/menu")) {
    //     this.checkModals();
    //   }
    //   window.scrollTo(0, 0);
    // });
  }

  checkModals(): void {
    // only when we are on a business page, example landing page, menu, categories, products
    let localData = localStorage.getItem("@mustBeOverAge");
    const mustBeOverAge = localData && localData === "true" ? true : false;

    if (!mustBeOverAge && this.business?.branding && this.business?.branding.showMustBeOverAgeAlert) {
      this.modalService.open(this.mustBeOverAge, { ariaLabelledBy: "modal-basic-title", backdrop: "static", centered: true }).result.then((result) => {
        localStorage.setItem("@mustBeOverAge", `${result}`);
        this.onMustBeOverAge();
      });
    } else {
      this.onMustBeOverAge();
    }
  }

  onMustBeOverAge(): void {
    let localData = localStorage.getItem("@mustBeOverAge");
    const mustBeOverAge = localData && localData === "true" ? true : false;
    if (!mustBeOverAge && this.business?.branding && this.business?.branding.showMustBeOverAgeAlert) {
      this.modalService.open(this.mustBeOverAge, { ariaLabelledBy: "modal-basic-title", backdrop: "static", centered: true }).result.then((result) => {
        localStorage.setItem("@mustBeOverAge", `${result}`);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  // open(content: TemplateRef<any>) {
  //   this.offcanvasService.open(content, { ariaLabelledBy: "offcanvas-basic-title" });
  // }

  openSignup(params: { email?: string; show?: string; alertMessage?: string; refreshValidationLink?: boolean }): void {
    const modalRef = this.modalService.open(AuthenticationComponent, {
      size: "md",
      modalDialogClass: "sign-in-modal",
    });
    modalRef.componentInstance.params = params;
  }

  logout(): void {
    this.loaderService.show();
    this.isLoading = true;
    this.authService
      .logout()
      .then(
        () => {
          return this.router.navigate(["/"]);
        },
        (error) => {
          console.error(error);
        },
      )
      .then(() => {
        this.loaderService.hide();
        this.isLoading = false;
      });
  }

  checkIsTransparent(): boolean {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // this.showHeaderMessage = offset < 30;
    if ((!this.user && this.router.url === "/") || ["/about-us", "/careers"].indexOf(this.router.url) >= 0) {
      return offset < 30;
    } else {
      return false;
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll(): void {
    this.isTransparent = this.checkIsTransparent();
  }
}
