import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LoaderComponent } from "./_component/loader/loader.component";
import { NavbarComponent } from "./_component/navbar/navbar.component";
import { NotificationPushComponent } from "./_component/notification-push/index.component";
import { NotificationRequestComponent } from "./_component/notification-request/notification.component";
import { SplashComponent } from "./_component/splash/splash.component";
import { BillingPlan, Business } from "./_global/_interfaces";
import { DEFAULT_LANG_CODE, LANG_CODE, LangCode, RTL_LANG_CODE } from "./_global/_services/app.service";
import { AppInitializerService } from "./_services/app.initializer.service";
import { AppService } from "./_services/app.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [CommonModule, RouterOutlet, NavbarComponent, LoaderComponent, SplashComponent, NotificationRequestComponent, NotificationPushComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  isLoading = false;
  title = "Dineas";
  hideNotificationRequest = localStorage.getItem("@hideNotificationRequest") === "true";

  businessLoaded: boolean = false;
  business?: Business;
  currentManifest: HTMLLinkElement | null = null;
  favIcon: HTMLLinkElement | null = null;
  gaScript: HTMLScriptElement | null = null;
  gaScript2: HTMLScriptElement | null = null;

  // chats
  tawkSnippet: HTMLScriptElement | null = null;
  intercomSnippet: HTMLScriptElement | null = null;
  zeSnippet: HTMLScriptElement | null = null;
  zeSnippetSettings: HTMLScriptElement | null = null;
  rootStyle!: SafeHtml;
  private appService = inject(AppService);
  // private userService = inject(UserService);
  languageCode = DEFAULT_LANG_CODE;
  languageDir = "ltr";
  private appInitializerService = inject(AppInitializerService);
  constructor(
    private router: Router,
    // private domSanitizer: DomSanitizer,
    public translate: TranslateService,
    // private route: ActivatedRoute,
    // private swPush: SwPush,
    // private manifestService: ManifestService,
  ) {
    this.businessLoaded = false;
    this.rootStyle = this.appInitializerService.initStyles();
    this.appInitializerService
      .load()
      .then(() => {})
      .finally(() => {
        this.onInitApp();
        this.rootStyle = this.appInitializerService.initStyles();
        this.businessLoaded = true;
      });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.subscribeToPush();
  }

  onInitApp() {
    let diviceLang = navigator.language || navigator.languages[0];
    if (diviceLang && diviceLang !== "en-US") {
      diviceLang = diviceLang.split("-")[0];
    }
    const tempLang = (localStorage.getItem("languageCode") as LangCode) || diviceLang || DEFAULT_LANG_CODE;
    this.translate.addLangs(LANG_CODE);
    this.languageCode = tempLang;
    this.languageDir = RTL_LANG_CODE.includes(tempLang) ? "rtl" : "ltr";
    this.translate.setDefaultLang(this.languageCode);

    // this.titleService.setTitle($localize`${this.title}`);
    const document = window.document;

    const { business } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;

    if (business?.branding) {
      // this.initStyles(business?.branding.primaryColor, business?.branding.secondaryColor, business?.branding.backgroundColor, business?.branding.fontColor);

      // this.businessLoaded = true;
      if (document && business?.branding.googleAnalyticsTrackingId && business?.plan && [BillingPlan.smart, BillingPlan.enterprise].includes(business.plan)) {
        this.gaScript = document.querySelector("#gaScript");
        this.gaScript2 = document.querySelector("#gaScript2");
        this.loadGoogleAnalytics(business?.branding.googleAnalyticsTrackingId);
      }
      if (business?.branding.selectedLiveChat && business?.branding.selectedLiveChatKey && business?.plan && [BillingPlan.enterprise].includes(business.plan)) {
        const color = business?.branding.primaryColor || business?.branding.secondaryColor || "#000000";
        this.loadChats(business?.branding.selectedLiveChat, business?.branding.selectedLiveChatKey, color);
      }
      const icon = business?.branding?.favIcon?.url || business?.logo?.url;

      if (icon && document) {
        this.favIcon = document.querySelector("#appIcon");
        if (this.favIcon) {
          this.favIcon.href = icon;
        }
      }

      // dinamic manifest fr pwa

      // const manifest = this.manifestService.getManifest(business);
      // const stringManifest = JSON.stringify(manifest);

      // const blob = new Blob([stringManifest], { type: "application/json" });
      // const url = URL.createObjectURL(blob);
      // // const linkElement = document.createElement("link");

      // this.currentManifest = document.querySelector("#appManifest");
      // if (this.currentManifest) {
      //   this.currentManifest.href = url;
      // }
    } else {
      // no business so use the Dineas Defaults
      // this.businessLoaded = true;
    }
  }

  // subscribeToPush(): void {
  //   this.swPush.messages.subscribe((payload) => {
  //     const data: NotificationEvent = payload as NotificationEvent;
  //     if (data.notification?.body && data.notification?.title) {
  //       this.notifications.push({
  //         body: data.notification?.body,
  //         title: data.notification?.title,
  //         type: "info",
  //         link: data.notification?.click_action || undefined,
  //       });
  //     } else {
  //       console.info("Message received. ", payload);
  //     }
  //   });

  // }

  loadGoogleAnalytics(trackingID: string): void {
    if (this.gaScript) {
      this.gaScript.setAttribute("async", "true");
      this.gaScript.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);
    }
    if (this.gaScript2) {
      this.gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;
    }
  }

  loadChats(chat: string, key: string, color: string): void {
    if (chat === "zendesk") {
      this.zeSnippetSettings = document.querySelector("#zeSnippetSettings");
      this.zeSnippet = document.querySelector("#zeSnippet");
      if (this.zeSnippet && this.zeSnippetSettings) {
        this.zeSnippetSettings.innerText = `window.zESettings = {webWidget: {color: { theme: '${color}' },}};`;
        this.zeSnippet.setAttribute("src", `https://static.zdassets.com/ekr/${key}`);
      }
    } else if (chat === "intercom") {
      this.intercomSnippet = document.querySelector("#intercomSnippet");
      if (this.intercomSnippet) {
        this.intercomSnippet.innerText = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${key}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
      }
    } else if (chat === "tawk") {
      this.tawkSnippet = document.querySelector("#tawkSnippet");
      if (this.tawkSnippet) {
        this.tawkSnippet.innerText = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];s1.async=true;s1.src='https://embed.tawk.to/${key}';s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0);})();`;
      }
    }
  }
}
