import { Injectable } from "@angular/core";
import { arrayUnion, Firestore } from "@angular/fire/firestore";
import { deleteToken, getToken, Messaging } from "@angular/fire/messaging";
import { environment } from "../../environments/environment";
import { User } from "../_global/_interfaces";
import { AppService } from "./app.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class FcmService {
  // for ios
  // https://www.youtube.com/watch?v=F4uv4jJH-Y8&ab_channel=JSgigs
  token: string | null = null;
  notificationPermissions: NotificationPermission | "request" = "default"; //Notification.permission;
  user?: User;

  // message$: Observable<any>;
  constructor(
    private msg: Messaging,
    private appService: AppService,
    private userService: UserService,
    private firestore: Firestore,
  ) {
    try {
      this.notificationPermissions = Notification.permission;
    } catch (error) {
      console.error("##### notification error", error);
      console.error("error", error);
    }
    if (!this.isChrome() && this.notificationPermissions === "default") {
      this.notificationPermissions = "request";
    }
  }

  isChrome() {
    const userAgent = navigator.userAgent;
    return userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR");
  }

  private async setToken(): Promise<string[]> {
    // only if the token is not already stored
    const tokens = this.user?.messagingTokens || [];

    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.register("./ngsw-worker.js", {
        type: "module",
      });
      this.token = await getToken(this.msg, {
        vapidKey: environment.firebase.vapidKey,
        serviceWorkerRegistration: serviceWorkerRegistration,
      });

      if (this.token && tokens?.indexOf(this.token) === -1) {
        tokens.push(this.token);
        if (this.user?.id) {
          this.userService.updateField(this.user, "messagingTokens", arrayUnion(this.token));
        }
      }
      localStorage.setItem("@messagingTokens", JSON.stringify(tokens));
      // this.notificationPermissions = Notification.permission;
    } catch (error) {
      console.error("##### setToken error", error);
    }

    return tokens;
    // const isDeleted = await deleteToken(this.msg);
    // This is a good place to then store it on your database for each user
  }

  async requestPermission(): Promise<NotificationPermission | "request"> {
    try {
      const notificationPermissions: NotificationPermission = await Notification.requestPermission();
      this.notificationPermissions = notificationPermissions;

      if (notificationPermissions === "granted") {
        await this.setToken();

        // await getToken(this.msg).then((x) => {
        //   console.info("my fcm token", x);
        //   // This is a good place to then store it on your database for each user
        // });
      } else if (notificationPermissions === "denied") {
        console.warn("Notifications are denied. Please enable them in the browser settings.");
      }
    } catch (error) {
      console.error("##### requestPermission error", error);
    }

    return this.notificationPermissions;
  }

  async onDeleteToken(): Promise<boolean> {
    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.register("./ngsw-worker.js", {
        type: "module",
      });

      if (this.token) {
        await deleteToken(this.msg);
        this.token = null;
        // Remove the token from user and local storage
        if (this.user?.id && this.user.messagingTokens) {
          const updatedTokens = this.user.messagingTokens.filter((t) => t !== this.token);
          this.userService.updateField(this.user, "messagingTokens", updatedTokens);
        }
        localStorage.removeItem("@messagingTokens");
        return true;
      }
    } catch (error) {
      console.error("##### deleteToken error", error);
    }

    return false;
  }
}
