@if(product){
<div class="row " [class.no-quantity]="!product.quantitySelected" [class.is-cancelled]="product.status === 'CANCELLED'">
  <div class="col-auto px-0" (click)="editOffcanvas()">
    <div class="image-ratio-1">
      <div class="img-fluid rounded" [lazyLoad]="product.image?.url || ''" [defaultImage]="'assets/images/temps/placeholder-image.svg'"></div>
    </div>
  </div>
  <div class="col " (click)="editOffcanvas()">
    <h5 class="mb-0">
      <span [hidden]="!readonly">{{product.quantitySelected || 0}}x </span>
      {{product.nameTranslated || product.name}}
      @if(product.variant.name && product.variant.name !== product.name){
      <span> ({{product.variant.nameTranslated || product.variant.name}})</span>
      }
    </h5>
    @if(product.status === 'CANCELLED'){
    <p class="small my-1"> <span class="badge bg-danger-subtle text-danger me-2 " translate="order-type.cancelled"> </span></p>
    }@else {
    <div class="d-flex w-100 justify-content-between text-primary">
      <div>
        @if(product.dealAmount && product.dealAmount > 0){
        <h5 class="text-primary  mb-0">{{totalPrice - product.dealAmount | currency : currency}} <del class="caption text-muted">{{totalPrice | currency: currency}}</del></h5>
        }@else {
        <h5 class="text-primary  mb-0">{{totalPrice | currency : currency}} @if(totalPrice!==totalPriceOrg){<del>{{totalPriceOrg | currency: currency}}</del>}</h5>
        }

        @for (option of product.optionGroups; track indexOption; let indexOption = $index){
        <p class="caption text-muted mb-0 text-truncate-2">{{option.nameTranslated || option.name}}:
          @for (variant of option.options; track indexOptionVariant; let indexOptionVariant = $index){
          @if((variant.quantitySelected || 0) > 0){
          @if(indexOptionVariant >0){
          <span>, </span>
          }
          <span>{{variant.quantitySelected}}x {{variant.nameTranslated ||variant.name}}</span>
          }
          }
        </p>
        }
      </div>
      <div></div>
    </div>
    }

  </div>
  @if(!readonly){
  <div class="col-auto px-0">
    <div class="input-group btn-quantity-rouded " dir="ltr">
      <button class="btn btn-light ms-auto" type="button" [disabled]="(product.quantitySelected || 0) <= 0" (click)="onUpdateQuantity.emit('minus')"><app-icon-svg
          iconName="minus"></app-icon-svg></button>
      <span class="input-group-text text-center border-0 text-bg-light">{{product.quantitySelected || '0'}}</span>
      <button class="btn btn-light" type="button" (click)="onUpdateQuantity.emit('add')"><app-icon-svg iconName="plus"></app-icon-svg></button>
    </div>
  </div>
  }

</div>
}