<!-- <nav class="navbar bg-body-header fixed-top ">
  <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
    <button type="button" (click)="activeOffcanvas.dismiss('click go back')" class="btn btn-outline-primary border-0">
      <app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg>
    </button>
    <span class="navbar-brand mx-auto text-capitalize" [style.padding]="'0 43px'" translate="account.main.terms-conditions"></span>
  </div>
</nav> -->

<nav class="navbar py-3 fixed-top" (click)="activeOffcanvas.dismiss('Cross click')">
  <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
    <div class="d-flex w-100 justify-content-between">
      <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="account.main.terms-conditions"></span></h3>
    </div>
  </div>
</nav>

<section class="container mt-4 pt-5 pb-3">
  <h6>TERMS OF USE</h6>
  <p class="text-start">
    These are the terms and conditions (<strong>Terms</strong>) on which Dineas provides services through its website <a href="https://www.dineas.com">https://www.dineas.com</a>, application, and
    related software (together the <strong>App</strong>). By accessing or using the App, you agree to these Terms with Dineas LLC.</p>
  <h6>Key Terms Summary</h6>
  <p>The App allows you to access menu information, place orders for products to a venue or restaurant (<strong>Venue</strong>), and pay for these products and services (<strong>Services</strong>).
    Without limiting the Terms, you agree that: your use of the App is subject to these Terms; you are at least 18 years old; all orders made through your App account will be paid by you; prices for
    the same menu items may vary by Venue, availability, and time; Dineas manages your experience but the Venue is responsible for your order; all payments are final and non-refundable unless
    determined otherwise by Dineas; and all payments will be processed in the local currency of the Venue's location.</p>
  <h6>Acceptance of Terms</h6>
  <p>Please read these Terms before accessing the App. By accessing or using the App, you agree to be bound by these Terms and consent to the collection and use of your information as outlined in our
    Privacy Policy. If you do not agree to these Terms, or are under 18, you may not use the App. By using the App, you confirm that you are at least 18 years old, legally capable of entering into
    binding contracts, and that all information provided by you is truthful, accurate, and complete. Dineas may update these Terms periodically by posting revised Terms on this page. Continued use of
    the App after such changes constitutes your acceptance of the updated Terms.</p>
  <h6>Limited License to Use the App</h6>
  <p>Subject to your acceptance of these Terms, Dineas grants you a limited, non-exclusive, non-assignable, non-transferable license to access and use the App for your personal, non-commercial
    purposes.</p>
  <h6>Restrictions on Use</h6>
  <p>All rights not expressly granted by these Terms are reserved by Dineas. You may not reproduce, copy, publish, sell, or exploit any part of the App or its intellectual property. If a Venue offers
    alcohol, you may be required to show identification. It is illegal for anyone under 18 to purchase or attempt to purchase alcohol. Dineas does not sell alcohol; if your order includes alcohol, it
    is provided by the Venue, which is responsible for verifying age and complying with relevant laws.</p>
  <h6>Your Account</h6>
  <p>Some areas of the App are accessible without registration. To use the Services, you must provide personal information and may need to register an account (<strong>Account</strong>). You must be
    at least 18 years old and not barred from receiving the App or an Account. Account registration requires your name and mobile number. All personal information is handled in accordance with
    relevant data protection laws and Dineas's Privacy Policy. You must provide at least one valid payment method. You are responsible for maintaining accurate and complete account information and for
    any activity through your Account. You will not sell, transfer, license, or assign your Account. Dineas may share your username with other App users who appear to be at the same table as you at
    the Venue.</p>
  <h6>Ordering and Payment Processing</h6>
  <p>The App allows you to order food or beverages from a Venue. Orders can only be placed while dining at the Venue. You are responsible for all charges (<strong>Charges</strong>) incurred, including
    the price of your order, applicable fees, taxes, and any discretionary tips. Charges are owed to the Venue, and Dineas will collect payment on behalf of the Venue. Prices for menu items may vary
    based on user, time, and promotions. If provided, email receipts can be requested for payments made through the App. Payment processing fees will be charged to the Venue, not the customer.</p>
  <h6>Amending or Cancelling Your Order</h6>
  <p>Orders placed through the App cannot be canceled and must be paid for even if you leave the Venue before receiving the items. In limited circumstances, refunds may be available as per the
    'Refunds' section. Other App users may pay for your order, but if they do not, you are liable for the payment. Dineas is not responsible for disputes regarding payment arrangements. Complaints
    about goods and services must be directed to the Venue.</p>
  <h6>Group Tabs</h6>
  <p>To open a bar tab using the Group Tab feature, you must register for an Account, set a tab limit, and credit the tab with a transaction. Dineas will charge your payment method to the value of the
    tab limit, and any unused amount will be refunded within 72 hours. It is your responsibility to protect the tab password or access link. Dineas and the Venue are not responsible for unauthorized
    use of your tab.</p>
  <h6>Refunds</h6>
  <p>Venues set their own refund policies. To inquire about canceling an order or receiving a refund, you must contact the Venue directly. If a refund is approved by the Venue, you will receive
    confirmation, and the payment may take several days to process.</p>
  <h6>Content</h6>
  <p>Unless otherwise specified, images, trademarks, service marks, logos, and icons displayed on the App are the property of Dineas or its licensors and may not be used without prior written consent.
    Unauthorized use of content may violate intellectual property rights, including copyright, trademark, and privacy laws. Feedback provided to Dineas may be used as deemed fit.</p>
  <h6>Links and Communications</h6>
  <p>Dineas will send emails, text messages, or notifications, including receipts and notices of changes, only if you have opted in. Promotions or offers may be sent by Dineas, its partners,
    affiliates, or Venues if you have opted in. The App may contain links to third-party materials not owned or controlled by Dineas. Dineas is not responsible for third-party content or services and
    any interactions with third-party sites are subject to their terms and conditions.</p>
  <h6>Prohibited Uses</h6>
  <p>You agree to use the App in accordance with all applicable laws. Dineas may investigate or terminate your use of the App for misuse or illegal activities. You agree not to: impersonate others;
    use unauthorized technology to retrieve data from the App; use any proprietary information without consent; interfere with services; transmit harmful code; or manipulate identifiers. Your
    information and interactions must not be false, infringe rights, violate laws, be defamatory, contain viruses, or create liability for Dineas.</p>
  <h6>Exclusion of Warranties / Disclaimer</h6>
  <p>The App is provided "as is" and "as available". Dineas does not warrant the accuracy, completeness, or reliability of the App, Service, or content. To the maximum extent permitted by law, Dineas
    disclaims all warranties, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement. You assume all risks associated with downloading or accessing the
    App.</p>
  <h6>Limitation of Liability</h6>
  <p>The Venue is responsible for the menu items ordered. Dineas is not liable for the quality or provision of items. This includes any failure to meet dietary requirements, allergic reactions, or
    injuries at the Venue. Dineas is not liable for direct, indirect, incidental, special, consequential, or exemplary damages, including loss of profits or data, resulting from the use or inability
    to use the App. In no event shall Dineas's total liability exceed the amount paid for accessing the App. Some jurisdictions may not allow these limitations.</p>
  <h6>Other Disclaimers</h6>
  <p>The App and services may be temporarily unavailable for maintenance. Dineas assumes no responsibility for technical issues, interruptions, or errors related to the App. Dineas is not liable for
    any loss or damage resulting from the provision or lack of goods and services by the Venue. The App content and services may be altered or discontinued without notice.</p>
  <h6>Release and Indemnification</h6>
  <p>You agree to release Dineas (including its members, managers, officers, employees, and agents) from all liability arising from your use of the App. You agree to defend and indemnify Dineas from
    any claims, damages, losses, or expenses arising out of your use of the App, breach of these Terms, infringement of intellectual property rights, or negligence.</p>
  <h6>General</h6>
  <h6>Governing Law</h6>
  <p>If you are in Israel, these Terms are governed by the laws of Israel and subject to the exclusive jurisdiction of the courts of Israel. For users in Europe, these Terms are governed by the laws
    of the respective country of the user, and any disputes will be subject to the jurisdiction of the courts of that country.</p>
  <h6>Assignment</h6>
  <p>These Terms, and any rights and licenses granted by them, may not be transferred or assigned by you but may be assigned by Dineas without restriction.</p>
  <h6>No Waiver</h6>
  <p>Dineas's failure to assert any right or provision under these Terms does not constitute a waiver of such right or provision.</p>
  <h6>Entire Agreement / Severability</h6>
  <p>These Terms, together with any amendments and any additional agreements you enter into with Dineas in connection with the Service, constitute the entire agreement between you and Dineas. If any
    provision of these Terms is deemed invalid, the invalidity of such provision will not affect the validity of the remaining provisions.</p>
  <h6>Contact</h6>
  <p>All inquiries may be directed to <a href="mailto:contact@dineas.com">{{"contact@dineas.com"}}</a>.</p>
</section>