<nav class="navbar py-3 fixed-top" (click)="activeOffcanvas.dismiss('Cross click')">
  <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
    <div class="d-flex w-100 justify-content-between">
      <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="account.main.privacy-policy"></span></h3>
    </div>
  </div>
</nav>

<section class="container mt-4 pt-5 pb-3">
  <p><strong>Overview</strong></p>
  <p>This notice is relevant to anyone who uses Dineas's website and app (App).</p>
  <p>Dineas LLC is committed to protecting the privacy of all visitors to our website and application (together the App). Please read the following policy which explains how we use and protect your
    personal information. When you visit our App and agree to our Terms of Use or otherwise interact with us, you agree to our collection, use, storage, and disclosure of your personal information in
    accordance with this policy. We will update this policy if our information handling practices change. Any revised privacy policy will take effect when it is published on the App. Please note that
    any websites linked to our App are subject to their own privacy policies.</p>
  <p><strong>Collection Statement (Website)</strong></p>
  <p>We collect personal information when you use the App, register for an account, or contact us. What we collect depends on how you'd like to use our services.</p>
  <p>Dineas collects and handles your personal information when you access our website and app (App); create an account with us; order and pay for products at a venue or restaurant; join a venue
    loyalty program; and to provide you with information about our App, Services, and promotions.</p>
  <p>We only disclose your personal information as described in this Privacy Policy, and you consent to these disclosures. Personal information and sensitive information voluntarily provided may be
    shared with our trusted venue partners. <strong>See the General Disclosure</strong> section below.</p>
  <p>We use third-party service providers in Israel and overseas to assist us with customer support, IT, web hosting, data storage, backup, and data analysis services. We require our service providers
    to have appropriate controls to protect your personal information in a manner that is consistent with this Privacy Policy and that they only use your information for authorized purposes. You don't
    have to provide all the information we ask for, but this may limit our ability to provide you with the Services. You have the right to ask us to update or correct your personal information. To
    contact us with any questions or privacy-related queries, please email <a href="mailto:contact@dineas.com">{{'contact@dineas.com'}}</a>.</p>
  <p><strong>Personal Information We Collect</strong></p>
  <p>We collect personal information when you use the App, register for an account, or contact us. What we collect depends on how you'd like to use our services.</p>
  <p>'Personal information' is any information about you, from which you can be identified. Our collection of your personal information will depend on our relationship with you, the circumstances of
    collection, and the Services you use. We only collect the information we need for the function or activity we are carrying out. We may collect personal information in the following circumstances:
  </p>
  <p>Directly from you</p>
  <p>Using the App: The main way we collect personal information about you is when you provide it to us. For example, when you use the App to make an order from a Venue, we may ask you to provide
    information about yourself, including your:
    <br />- Name;
    <br />- Email address;
    <br />- Mobile number; and
    <br />- Payment information (such as credit or debit card information).
    <br><br>
    App Registration: You provide personal information when you register for an account, place an order for the Services, access the App, or join our mailing list. The personal information you provide
    may include your name, telephone number, email address, and payment information. Communication with us: We collect the information you provide when you contact us for support or with questions,
    apply for a job with us, or seek to become a service provider. We collect personal information in person, over the telephone, on hard copy forms, and through letters and emails.
  </p>
  <p>From your device</p>
  <p>Use of the App: The information we collect from your use of the App may include Venues visited, what you have ordered and when, interaction with third-party apps, communications with us, and App
    interactions with Dineas. If you haven't registered for an account with us, we may still collect some information from your use of the App. This information may be matched to your account if you
    decide to sign up for one later. Cookies and similar technologies: A cookie is a tiny data file that resides on your device that allows us to recognize you when you use the App on the same device
    without needing to log in to your account. Cookies allow us to collect your App access data, provide you with targeted content, and help us understand and analyze the functioning of the App.
    Cookies may come from us (first-party cookies) or from third parties whose services we use (third-party cookies). Device & Log data: We may collect information including unique device identifiers,
    IP address, operating system version, device settings, time and duration of your App use, and search data.</p>
  <p><strong>Sensitive Information</strong></p>
  <p>We generally do not ask for or collect sensitive information about you unless you provide it to us voluntarily. For example, if you make a complaint about the Services, you may voluntarily
    provide sensitive information such as allergies or health conditions. If you do provide sensitive information to us voluntarily, you consent to us collecting this information. Not all of the
    information that we collect from you is personal information when we collect it, but it may become personal information when we match it with other information you have provided us or we have
    collected. You don't have to provide all the information we ask for, but this may limit how we interact with you and which services we can provide.</p>
  <p>Information We Collect from Other Sources</p>
  <p>We may obtain certain information about you from other sources, for example, information that is publicly available or from third parties. We use this information only to the extent permitted by
    data protection law.</p>
  <p class="mt-4"><strong>Use of Your Personal Information</strong></p>
  <p>We use your personal information for purposes including providing you with our services, communicating with you, and improving the App.</p>
  <p>The purpose for which we collect, use, hold, and disclose your personal information is to give effect to the interaction you have with us. This may include to:
    <br />- Provide and administer our App and related products and services;
    <br />- Provide information and notices about your App account;
    <br />- Send you a receipt;
    <br />- Understand your use of the App;
    <br />- Create a profile about you to obtain insights about market, user, and industry trends;
    <br />- Advise you of promotions we think you might like;
    <br />- Work with our venues and their sponsors to create a unique, personalized experience for you;
    <br />- Maintain our records and comply with our legal obligations; and
    <br />- For any other purpose disclosed at the time the information is collected or to which you consent.
    <br /><br />We may perform statistical analysis of user activity and characteristics to measure interest in and use of the App and Services and to inform others of such information, as well as the
    number of users who have been exposed to or have clicked on any particular areas of or offers within the App. We may also use your personal information for related purposes that you would
    reasonably expect.
  </p>
  <p><strong>Direct Marketing</strong></p>
  <p>We love to send you information that we think may interest you, but we always give you the opportunity to say 'no thanks' and unsubscribe. If required by law in your jurisdiction, we will obtain
    your consent before sending you commercial electronic messages.</p>
  <p>We may send communications to you about our activities and services. If required by law in your jurisdiction, we will seek your consent to receiving promotional and marketing material from us. If
    we use your personal information for direct marketing purposes, we will give you the opportunity to opt out of receiving any further marketing material by using our unsubscribe facilities. If you
    choose to opt-out of all communications or do not provide your consent to receive communications (if applicable), please note that we may still contact you for other reasonable purposes, including
    for example to provide information about the validity of your account with us. Sometimes we may give you the option to receive further communications from one of our venue partners (including the
    option to join venue partner loyalty programs). If you do choose to opt-in by expressly providing consent to receive communications from that third-party venue or to join their loyalty program, we
    may disclose your personal information to that third party. In this circumstance, we require that third party to agree that your personal information be dealt with in accordance with that third
    party's own privacy policy and in accordance with all applicable privacy and spam laws.</p>
  <p><strong>General Disclosure</strong></p>
  <p>We share your personal information with our trusted service providers in Israel and overseas to create a unique, personalized experience for you.</p>
  <p>We may also disclose your personal information to our trusted venue partners to deliver, administer, and support both our and venue activities. This may include delivering marketing and digital
    marketing services from which you can opt-in or out of at any time. This will be disclosed to you when using the Dineas App at a venue. Any information disclosed to a venue will be dealt with in
    accordance with that venue's Privacy Policy and in accordance with applicable privacy and spam laws. We may also disclose your personal information to third parties such as our contractors,
    agents, and service providers who help us deliver, administer, and support our activities including, for example, to deliver marketing and digital marketing services; to conduct market research
    and analysis; to host our servers and website; to conduct data analysis and data matching services; and when required by law.</p>
  <p>Some of the third parties we disclose your personal information to may be based in or have servers located overseas, including but not limited to the USA, the UK, and India. We take reasonable
    steps to ensure that our overseas service providers will only process your personal information for authorized purposes, and have appropriate technical, organizational, and contractual means to
    protect your personal information. You consent to us disclosing your personal information to our overseas service providers on this basis.</p>
  <p><strong>Security</strong></p>
  <p>We place great importance on protecting your personal information. We insist that our service providers adhere to the same data privacy and security standards.</p>
  <p>We place great importance on protecting our users from unauthorized access to, or the unauthorized alteration, disclosure, or destruction of, the information we collect. We review our information
    collection, storage, and processing practices, including physical security measures, to guard against unauthorized access to systems. Personal information that is maintained on our systems (or the
    systems of those who provide services to us or on our behalf) is protected using industry-standard security measures. However, no security measures are perfect or impenetrable, and we cannot
    guarantee that your personal information will be completely secure. We have a data breach policy in place to deal with any actual or suspected data breach.</p>
  <p><strong>Data Retention</strong></p>
  <p>We hold your personal information for as long as we need it to provide our services. We destroy or de-identify it once the purpose of our collection has ended.</p>
  <p>Your personal information will be retained for as long as is reasonably necessary for the purpose for which it was collected, or as required by law. When we no longer need your personal
    information, we have procedures in place to either destroy or permanently de-identify the information.</p>
  <p><strong>Enquiries and Corrections</strong></p>
  <p>If you wish to enquire about the personal information that we hold about you, please contact our Privacy Officer.</p>
  <p>If you wish to access the personal information that we hold about you, if your personal information changes, or if you no longer wish to receive non-account specific information from us, you can
    access, correct, update, or ask us to remove personal information about you that we have collected by using the functionality contained in the App (if any) or contacting us using the details
    below. When updating your personal information, we may ask you to verify your identity before we act on your request. Where we can provide information access and correction, we will do so for
    free, except where it would require a disproportionate effort. If you are concerned that there may have been some inappropriate use or disclosure or any other breach relating to your personal
    information, we would like to know. Please contact our Privacy Officer using the details below.</p>
  <p>If we cannot resolve your complaint to a satisfactory standard, you are entitled to lodge your complaint with the French Data Protection Authority (CNIL) at www.cnil.fr if you are in France, the
    Spanish Data Protection Agency (AEPD) at www.aepd.es if you are in Spain, the Israeli Law, Information and Technology Authority (ILITA) at www.gov.il if you are in Israel, or with the Information
    Commissioner's Office (ICO) at www.ico.org.uk if you are in the UK.</p>
</section>