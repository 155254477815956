import { LangCode } from "../_services/app.service";
import { AvailableDineasCurrency, BillingPlan, BillingStatus, BusinessType, OrderType, ProductType, UserRoles } from "./app.enum";
import { CategoryMenu, PushSells, Review, ReviewCount } from "./product.interface";
interface Businesses {
  [key: string]: Business;
}

type Phone = {
  prefix: string; // without the +
  number: string;
};

interface Subscription {
  currency?: string;
  freeUpTimes?: number;
  limitedUpTimes?: number;
  viewDownTimes?: number;
  price?: number;
  plans: Plans;
}

type Plans = {
  [key in BillingPlan]: {
    stripePriceIds?: { id: string; label: string; key: string }[];
  };
};

interface BusinessTag {
  id: string;
  label: string;
  type: OrderType; // when ever this tag is used it will be considered as a take away order
  isActive?: boolean;
  businessId: string;
}

interface Business {
  id?: string;
  representativeFirstName?: string;
  representativeLastName?: string;
  representativeDateOfBirth?: { month: number; year: number; day: number };
  representativePhone?: Phone;
  representativeEmail?: string;
  businessType?: BusinessType; // company / individual
  isPayoutValidated?: boolean;
  lastCheckedPayoutAt?: number;
  name?: string;
  keyWords?: string[];
  phone?: Phone;
  email?: string;
  description?: string;
  taxId?: string;
  website?: string;
  videoUrl?: string;
  createdAt?: number;
  updatedAt?: number;
  logo?: { url: string; path: string };
  images?: { url: string; path: string }[];
  categories?: string[];
  address?: Address;
  geohash?: string;
  plan?: BillingPlan;
  timeZone?: string;
  currency?: AvailableDineasCurrency;
  stripeCustomId?: string;
  stripeCustomerId?: string;
  stripePaymentMethodId?: string;
  stripeCustomError?: string;

  orderFlow?: OrderFlow;
  productType?: {
    [key in ProductType]: {
      hide: boolean;
    };
  };

  timeMeridian?: boolean;
  tags?: number;

  lastBillingAt?: number; // when was the last billing

  hasUnPayedInvoice?: boolean;
  needNewBillingMethode?: boolean;

  reviewCount?: number; // automatically updated on review create
  reviewCountTotals?: ReviewCount; // automatically updated on review create
  rateCount?: number; // automatically updated on review create
  rateCountTotals?: ReviewCount; // automatically updated on review create
  reviews?: Review[]; // last 5 comments // automatically updated on review create

  traduction?: {
    [K in LangCode]?: BusinessTraduction;
  };

  branding?: Branding;
  brandName?: string;

  pos?: {
    beeCommOrange?: {
      branch?: string;
      menuRevision?: string;
    };
    beeCommCloud?: {
      branch?: string;
      restaurant?: string;
      menuRevision?: string;
    };
    aviv?: {
      branch?: string;
      menuRevision?: string;
    };
  };
}

interface BusinessTraduction {
  description?: string;
  brandName?: string;
}

interface OrderFlow {
  [OrderType.dineIn]: {
    autoApproved: boolean;
    active: boolean; // this is automatically set to false when the business set tags
  };
  [OrderType.takeAway]: {
    autoApproved: boolean;
    autoCompleted: boolean;
    active: boolean; // this is automatically set to false when the business set tags
  };
}

interface BusinessPreview {
  id?: string;
  name?: string;
  phone?: Phone;
  email?: string;
  logo?: { url: string; path: string };
  currency?: AvailableDineasCurrency;
  description?: string;
  address?: Address;
  geohash?: string;
  timeZone?: string;
  plan?: BillingPlan;
  reviewCount?: number; // automatically updated on review create
  reviewCountTotals?: ReviewCount; // automatically updated on review create
}

// interface WeekDays {
//   sunday?: boolean;
//   monday?: boolean;
//   tuesday?: boolean;
//   wednesday?: boolean;
//   thursday?: boolean;
//   friday?: boolean;
//   saturday?: boolean;
// }

interface BusinessDeal {
  // details
  id: string;
  name: string; // example "staff discount"
  internalName: string;
  description: string;
  posId?: string;
  code: string;

  // type of deal
  type: "percentage" | "amount" | "unit";
  discount: number;
  unitToBuy?: number; // example 3 + 1 free
  unitToDeal?: number; // example 3 + 1 free

  // restriction
  minimumAmount?: number;
  limitPerUser?: number; // can be use per cutomer x times // 0 or null is unlimited

  // schedule
  startDate: { month: number; year: number; day: number };
  endDate?: { month: number; year: number; day: number }; // null is unlimited
  serviceIds?: string[]; //

  ExcludeDates?: string[]; // format: "YYYY-MM-DD" // Pause the promotion on certain dates

  applyOnSameProduct?: boolean;
  applyOnSameVariant?: boolean;
  categoryIds?: string[];
  productIds?: string[];

  isActive?: boolean;
  hasDiscountCode?: boolean;
  usedBy?: { [email: string]: number }; // email: number of time used
}
interface BusinessDealPopulated extends Omit<BusinessDeal, "services" | "categories"> {
  services?: ServiceMenuWithIsLive[];
  categories?: CategoryMenu[];
}

interface ServiceMenu {
  id: string;
  name: string; // example "morning", "lunch", "All day"
  tags?: string[];
  isActive?: boolean;
  pushSellIds?: string[];

  importedFromPos?: boolean; // to identify if the service has been imported from the POS
  sunday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  }; // format: "HH:mm"
  monday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
  tuesday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
  wednesday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
  thursday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
  friday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
  saturday: {
    active: boolean;
    on?: { hour: number; minute: number };
    off?: { hour: number; minute: number };
  };
}
interface ServiceMenuWithIsLive extends ServiceMenu {
  isLive?: boolean; //
}
interface ServiceMenuPopulated extends ServiceMenu {
  pushSells: PushSells[];
}

interface BusinessInvoice {
  id?: string;
  createdAt?: string;
  status?: string;
  plan?: BillingPlan;
  amountDue?: number;
  amountPaid?: number;
  amountRemaining?: number;
  monthlySales?: number;
  subscriptionId?: string;
  stripeCustomerId?: string;
  stripeCustomerPaymentMethodId?: string;
}

interface BusinessBilling {
  id: string;
  createdAt: number;
  amount: number;
  amountReceived?: number;
  capturable?: number;
  totalRefund?: number;
  currency: string;
  stripePaymentIntentId?: string;
  stripeCustomerId?: string;
  status: BillingStatus;
  statusInfo?: string;
  salesPerMonth: number;
  ordersTotalAmount: number;
  attempts?: PaymentIntentAttempt[];
}

type PaymentIntentResponse = {
  clientSecret?: string;
  paymentIntentId?: string;
  // referenceNumber: 0;
  cardNum?: string;
  // approvalNumber: number;
  exp_month?: number;
  exp_year?: number;
  // cardIssuerCode: string;
  // cardFinancerCode: string;
  cardBrandCode?: string;
  fingerprint?: string;
};

interface PaymentIntentAttempt {
  createdAt: number;
  paymentMethodHolderName?: string;
  paymentMethodLast4?: string;
  status: string;
  reason?: string;
  currency: string;
  amountDue: number;
  amountPaid: number;
  stripePaymentIntentId: string;
  businessDeveloperTransaction?: string;
  stripeCustomerPaymentMethodId: string;
}
interface BusinessPayout {
  businessId?: string;
  businessName?: string;
  bankAccountName?: string;
  last4?: string;
  bankNumber?: string;
  bankName?: string;
  bankAccountNumber?: string;
  stripeCustomId?: string;
  type?: string;
  currency?: string;
  country?: string;
}

interface BusinessUser {
  id: string;
  role?: UserRoles;
  name?: string;
  email?: string;
  validRequest?: boolean;
}

interface BusinessInvitedUser {
  role: UserRoles;
  email: string;
  businessId: string;
}

interface Address {
  name?: string;
  formattedAddress?: string;
  addressLine?: string;
  suburb?: string;
  city?: string;
  postCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  administrativeAreaLevel2?: string;
  coordinates?: { latitude: number; longitude: number };
  minLatitude?: number | null;
  minLongitude?: number | null;
  maxLatitude?: number | null;
  maxLongitude?: number | null;
  source?: string;
  type?: string;
}

enum BrandingStatus {
  created = "CREATED",
  pending = "PENDING",
  failed = "ERROR",
  live = "LIVE",
}

interface Branding {
  status?: BrandingStatus;
  statusInfo?: string;

  // businessId?: string;
  domains?: string[];
  tagLine?: string;
  // metaTitle?: string;
  // metaDescription?: string;
  metaShareImage?: { url: string; path: string };
  googleAnalyticsTrackingId?: string;
  selectedLiveChat?: string; //  todo set it up
  selectedLiveChatKey?: string; //  todo set it up
  primaryColor?: string;
  backgroundColor?: string;
  secondaryColor?: string;
  fontColor?: string;
  favIcon?: { url: string; path: string };
  setIcons?: { url: string; path: string; width: number; height: number }[];
  landingPageHeroImage?: { url: string; path: string };
  defaultCurrencyCode?: string;
  // defaultLanguageCode?: string;
  fontFamily?: string;
  subdomain?: string;
  // contactEmail?: string;
  enableCheckout?: boolean;
  disabledComments?: boolean;

  showMustBeOverAgeAlert?: boolean;
  paymentManagedByBusiness?: boolean; // allow the business to manage the payment
  hasLandingPage?: boolean;

  facebookPage?: string;
  instagramPage?: string;
  linkedinPage?: string;
}
export type {
  Address,
  Branding,
  BrandingStatus,
  Business,
  BusinessBilling,
  BusinessDeal,
  BusinessDealPopulated,
  Businesses,
  BusinessInvitedUser,
  BusinessInvoice,
  BusinessPayout,
  BusinessPreview,
  BusinessTag,
  BusinessUser,
  OrderFlow,
  PaymentIntentAttempt,
  PaymentIntentResponse,
  Phone,
  Plans,
  ServiceMenu,
  ServiceMenuPopulated,
  ServiceMenuWithIsLive,
  Subscription,
};
