import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, query, where } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { BusinessDeal, CollectionName } from "../_global/_interfaces";
import { BusinessService } from "./business.service";

@Injectable({
  providedIn: "root",
})
export class DealService {
  private firestore: Firestore = inject(Firestore);
  collectionRef;
  businessId: string;
  constructor() {
    this.businessId = BusinessService.getSelectedBusinessId();
    this.collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessDeal);
  }

  list({ serviceIds, withoutCode }: { serviceIds: string[]; withoutCode: boolean }): Observable<BusinessDeal[]> {
    const whereList = [where("isActive", "==", true)];
    if (serviceIds.length) {
      whereList.push(where("serviceIds", "array-contains-any", serviceIds));
    }
    if (withoutCode) {
      whereList.push(where("hasDiscountCode", "==", false));
    }
    const q = query(this.collectionRef, ...whereList);

    const data = collectionData(q, {
      idField: "id",
    });

    return data as Observable<BusinessDeal[]>;
    // return data.pipe(
    //   tap((deals) => console.log("Fetched deals:", deals)),
    //   map((deals) => deals as BusinessDeal[]),
    //   catchError((error) => {
    //     console.error("Error fetching deals:", error);
    //     return of([]);
    //   }),
    // );
  }
}
