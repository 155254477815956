import { Injectable, Input, inject } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthenticationService, IsUserLoginService } from "../authentication/is-user-login.service";
import { BusinessService } from "./business.service";
import { UserService } from "./user.service";

@Injectable({ providedIn: "root" })
export class AuthGuardService {
  private firestore: Firestore = inject(Firestore);
  @Input() businessId = "";
  constructor(
    public auth: AuthenticationService,
    public userService: UserService,
    public businessService: BusinessService,
    public router: Router,
    private isUserLoginService: IsUserLoginService,
  ) {}

  private async checkUser(): Promise<boolean> {
    const authUser = await this.auth.getAuthUser();
    if (!authUser || !authUser?.uid || !authUser?.emailVerified) {
      this.isUserLoginService.onLogout();
      await this.auth.logout();
      return false;
    } else {
      return true;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // if (route.params["businessId"]) {
    //   localStorage.setItem("@selectedBusinessId", route.params["businessId"]);
    // }

    const isValid = await this.checkUser();
    if (!isValid) {
      await this.router.navigate(["/"]);
      return false;
    } else {
      return true;
    }
  }
}
