import { AsyncPipe } from "@angular/common";
import { Component, inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { catchError, combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { CategoryMenuCardComponent } from "../_component/category-menu/card/card.component";
import { CategoryMenuCardPlaceholderComponent } from "../_component/category-menu/placeholder/placeholder.component";
import { ProductCardComponent } from "../_component/product/card/card.component";
import { VerticalProductSectionComponent } from "../_component/product/vertical-section/section.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { Business, BusinessDeal, CategoryMenu, Checkout, ProductType, PushSellsPopulated, ServiceMenu, User } from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../_global/_services/app.service";
import { AppService } from "../_services/app.service";
import { CategoryService } from "../_services/category.service";
import { DealService } from "../_services/deal.service";
import { PushSellService } from "../_services/push-sell.service";
import { ServiceMenuService } from "../_services/service-menu.service";

@Component({
  selector: "app-business-menu",
  standalone: true,
  imports: [
    NgbCarouselModule,
    LazyLoadImageModule,
    FontAwesomeModule,
    CategoryMenuCardComponent,
    CategoryMenuCardPlaceholderComponent,
    TranslateModule,
    AsyncPipe,
    SvgIconComponent,
    ProductCardComponent,
    VerticalProductSectionComponent,
  ],
  templateUrl: "./menu.component.html",
  styleUrl: "./menu.component.scss",
})
export class BusinessMenuComponent implements OnDestroy {
  isLoading = false;
  userEmail?: string;
  user?: User;
  subscription: Subscription;

  categories: { food: CategoryMenu[]; drink: CategoryMenu[]; desert: CategoryMenu[] } = { food: [], drink: [], desert: [] };

  faSearch = faSearch;
  faBars = faBars;
  pageLoading = true;
  // private modalService = inject(NgbModal);

  business?: Business;
  businessTranslated?: Business;

  checkout?: Checkout;
  subscriptionBusiness: Subscription;
  private appService = inject(AppService);
  private dealService = inject(DealService);
  private pushSellService = inject(PushSellService);
  private categoryService = inject(CategoryService);
  private serviceMenuService = inject(ServiceMenuService);
  // activeSerices: ServiceMenu[] = [];
  languageCode: LangCode = DEFAULT_LANG_CODE;
  languageDir: "rtl" | "ltr" = "ltr";
  get businessName(): string {
    return this.businessTranslated?.brandName || this.business?.brandName || this.business?.name || "Dineas";
  }
  businessDescriptionReadMore = false;

  get businessDescriptionIsLong(): boolean {
    const description = this.businessTranslated?.description || this.business?.description || "";
    return description.length > 130;
  }

  // activeDeals$: Observable<BusinessDealPopulated[]> = new Observable<BusinessDealPopulated[]>(); // only the matched product from the local filter
  activeServices: ServiceMenu[] = [];
  activeDeals: BusinessDeal[] = [];
  activePushSells: PushSellsPopulated[] = [];

  showReadMore() {
    this.businessDescriptionReadMore = !this.businessDescriptionReadMore;
  }

  constructor(private router: Router) {
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    this.user = user;

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
      if (!this.business || !this.business?.id) {
        // redirect to scan business code
        this.router.navigate(["/"]);
        return;
      }
    });

    if (!this.business || !this.business?.id) {
      // redirect to scan business code
      this.router.navigate(["/"]);
      return;
    }

    this.languageCode = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;

    this.languageDir = RTL_LANG_CODE.includes(this.languageCode) ? "rtl" : "ltr";
    
    this.businessTranslated = business?.traduction ? business?.traduction[this.languageCode] : undefined;

    this.getData().subscribe(async ({ services, deals, pushSells, categories }) => {
      this.activeServices = services;
      this.activeDeals = deals;
      this.activePushSells = pushSells;
      this.categories = {
        food: categories.filter((category) => category.type === ProductType.food),
        drink: categories.filter((category) => category.type === ProductType.drink),
        desert: categories.filter((category) => category.type === ProductType.desert),
      };
      this.pageLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
  }

  getData(): Observable<{ services: ServiceMenu[]; deals: BusinessDeal[]; pushSells: PushSellsPopulated[]; categories: CategoryMenu[] }> {
    return this.serviceMenuService.getActive().pipe(
      switchMap((services) => {
        const serviceIds = services.map((service) => service.id);
        const pushSellIds = services.reduce<string[]>((ids, service) => ids.concat(service.pushSellIds || []), []);
        const deals$ = serviceIds.length ? this.dealService.list({ serviceIds, withoutCode: true }) : of([]);
        const categories$ = serviceIds.length ? this.categoryService.list({ serviceIds }) : of([]);
        const pushSells$ = pushSellIds.length ? this.pushSellService.getFromIdsPopulated(pushSellIds) : of([]);
        return combineLatest([deals$, pushSells$, categories$]).pipe(
          map(([deals, pushSells, categories]) => {
            return {
              services,
              deals,
              pushSells,
              categories,
            };
          }),
          catchError((error) => {
            console.error("Error in forkJoin:", error);
            return of({ services: [], deals: [], pushSells: [], categories: [] });
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ services: [], deals: [], pushSells: [], categories: [] });
      }),
    );
  }
}
