import { Routes, mapToCanActivate } from "@angular/router";
import { AuthGuardService } from "./_services/auth-guard.service";
import { AccountComponent } from "./account/main/account.component";
import { CartComponent } from "./cart/cart.component";
import { CheckoutComponent } from "./cart/checkout/checkout.component";
import { SplitPaymentComponent } from "./cart/split-payment/index.component";
import { BusinessMenuCategoryComponent } from "./category/category.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { BusinessMenuComponent } from "./menu/menu.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { NotificationListComponent } from "./notifications/list/list.component";
import { OrderListComponent } from "./orders/list/list.component";
import { ScanCodeComponent } from "./scan-code/scan-code.component";

export const routes: Routes = [
  { path: "", component: ScanCodeComponent },
  { path: "scanner", component: ScanCodeComponent },
  { path: "app/:businessId", component: LandingPageComponent },
  { path: "menu", component: LandingPageComponent }, //
  { path: "menu/category", component: BusinessMenuComponent },
  { path: "menu/category/:productType", component: BusinessMenuCategoryComponent },
  { path: "menu/category/:productType/:categoryId", component: BusinessMenuCategoryComponent },
  { path: "account", component: AccountComponent },
  { path: "account/orders", component: OrderListComponent, canActivate: mapToCanActivate([AuthGuardService]) },
  { path: "account/notifications", component: NotificationListComponent },
  { path: "cart", component: CartComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "order/:id/payment", component: SplitPaymentComponent },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/404" },
];
