import { Component, OnDestroy, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AlertComponent } from "../_component/alert/alert.component";
import { LoaderService } from "../_component/loader/loader.service";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { Business, User } from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../_global/_services/app.service";
import { Alert } from "../_interfaces";
import { AppService } from "../_services/app.service";
import { PrivacyPolicyComponent } from "../privacy-policy/privacy-policy.component";
import { TermsComponent } from "../terms/terms.component";
import { AuthenticationService, IsUserLoginService } from "./is-user-login.service";

type Show = { signIn: boolean; signUp: boolean; forgotPass: boolean };

@Component({
  selector: "app-authentication",
  standalone: true,
  imports: [AlertComponent, ReactiveFormsModule, FontAwesomeModule, TranslateModule, SvgIconComponent],
  templateUrl: "./authentication.component.html",
  styleUrl: "./authentication.component.scss",
})
export class AuthenticationComponent implements OnDestroy {
  isLoading = false;
  activeOffcanvas = inject(NgbActiveOffcanvas);
  private offcanvasService = inject(NgbOffcanvas);

  languageDir;

  signinForm: FormGroup = this.formBuilder.group({
    email: ["", Validators.required],
  });

  emailSent = false;

  alertSignin: Alert = { type: "info" };

  business?: Business;
  subscriptionBusiness: Subscription;
  private appService = inject(AppService);
  private isUserLoginService = inject(IsUserLoginService);

  user?: User;
  subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
  ) {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.user = user;

    this.signinForm.patchValue({ email: checkout?.customer?.email || this.user?.email });

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      if (user && user.id) {
        this.activeOffcanvas.dismiss("user log in");
      }
    });

    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionBusiness.unsubscribe();
  }

  loginGoogleUser(): void {
    this.isLoading = true;
    this.loaderService.show();
    this.authService
      .loginGoogleUser()
      .then(() => {
        this.offcanvasService.dismiss();
      })
      .catch((e) => {
        this.alertSignin = {
          type: "danger",
          message: e.message,
        };
      })
      .finally(() => {
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  signin(): void {
    if (!this.signinForm.value.email) {
      this.alertSignin = {
        type: "danger",
        message: "Email is missing",
      };
      return;
    }
    this.isLoading = true;
    this.loaderService.show();

    this.authService
      .sendSignInLinkToEmail(this.signinForm.value.email,  this.business)
      .then(
        () => {
          this.emailSent = true;
          this.alertSignin = {
            type: "success",
            message: "An Sign In link has been sent to your email account. Please click on the link that has just been sent to your email account to sign in.",
          };
        },
        (error) => {
          this.alertSignin = {
            type: "danger",
            message: error.message,
          };
        },
      )
      .finally(() => {
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  openOffcanvas(content: "privacy-policy" | "terms") {
    let component;
    switch (content) {
      case "privacy-policy":
        component = PrivacyPolicyComponent;
        break;
      case "terms":
        component = TermsComponent;

        break;
      default:
        return;
    }
    this.offcanvasService.open(component, { position: "bottom", panelClass: "offcanvas-login" });
  }
}
