@if (products && products.length > 0) {
@if(products.length > 2){
<div class="row g-3 " [class.vertical-container]="products.length > 3">
  @for (product of products; track product.id;){
  <div class="col-4 col-sm-3 vertical-item">
    <app-product-card [isVertical]="true" [product]="product" [currency]="currency"></app-product-card>
  </div>
  }
</div>
}@else {
@for (product of products ; track product.id;; let indexProduct = $index){
<hr [hidden]="indexProduct===0">
<app-product-card [product]="product" [currency]="currency"></app-product-card>
}
}
}