import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { NotificationDineas, User } from "../../_global/_interfaces";
import { TimeUntilNowPipe } from "../../_pipes/timer.pipe";
import { AppService } from "../../_services/app.service";
import { UserService } from "../../_services/user.service";

@Component({
  selector: "app-notifications-list",
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, ReactiveFormsModule, AlertComponent, AsyncPipe, TranslateModule, SvgIconComponent, TimeUntilNowPipe],
  templateUrl: "./list.component.html",
  styleUrl: "./list.component.scss",
})
export class NotificationListComponent implements OnDestroy {
  subscription: Subscription;
  subscriptionCheckout: Subscription;
  user?: User;

  private targetSubject = new BehaviorSubject<{ id?: string; email?: string; limit?: number }>({ id: undefined, email: undefined, limit: 100 });
  target$ = this.targetSubject.asObservable();

  isLoading = false;

  notifsPerMonth: { monthLabel: string; notifications: NotificationDineas[] }[] = [];
  hasNotifications: boolean = true;
  private appService = inject(AppService);

  constructor(private userService: UserService) {
    const { user, userSubscription, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.user = user;
    this.targetSubject.next({ id: user?.id || checkout?.customer?.id, email: user?.email || checkout?.customer?.email });

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      this.setTarget(user.id, user.email);
    });
    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.setTarget(this.user?.id || checkout?.customer?.id, this.user?.email || checkout?.customer?.email);
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  setTarget(id?: string, email?: string): void {
    const newVal = { id, email };
    if ((newVal.id && newVal.id !== this.targetSubject.value.id) || (newVal.email && newVal.email !== this.targetSubject.value.email)) {
      this.targetSubject.next(newVal);
    }
  }

  loadMoreNotifications(): void {
    const newVal = { ...this.targetSubject.value, limit: (this.targetSubject.value.limit || 0) + 100 };
    this.targetSubject.next(newVal);
  }

  setNotificationPerMonth(notifications: NotificationDineas[]): void {
    this.hasNotifications = notifications.length > 0;
    const notificationsPerMonthMap: { [monthLabel: string]: NotificationDineas[] } = {};
    for (const notif of notifications) {
      const date = notif.createdAt;
      const monthLabel = moment(date).format("MMM YYYY");
      if (!notificationsPerMonthMap[monthLabel]) {
        notificationsPerMonthMap[monthLabel] = [];
      }
      notificationsPerMonthMap[monthLabel].push(notif);
    }

    this.notifsPerMonth = Object.keys(notificationsPerMonthMap).map((monthLabel) => {
      return { monthLabel, notifications: notificationsPerMonthMap[monthLabel] };
    });

    // now sort by month
    this.notifsPerMonth.sort((a, b) => {
      return moment(b.monthLabel, "MMM YYYY").valueOf() - moment(a.monthLabel, "MMM YYYY").valueOf();
    });
  }

  getData(): void {
    this.targetSubject.subscribe(({ id, email }) => {
      this.userService.listNotifications(20, id, email).subscribe((notifications) => {
        this.setNotificationPerMonth(notifications);
        // mark them as read
        if (notifications.length > 0) {
          this.userService.markAsRead(notifications).then((unreadNotifs) => {
          });
        }
      });
    });
    // }
  }
}
