@if(!order || !order.items || order.items.length === 0){
<div>
  <nav class="navbar my-3">
    <div class="container-md">
      <button type="button" (click)="activeOffcanvas.dismiss('click go back')" class="btn btn-outline-primary border-0"> <app-icon-svg class="icon-arrow-go-back"
          iconName="arrow-go-back"></app-icon-svg></button>
      <span class="navbar-brand mx-auto" translate="order.title"></span>
    </div>
  </nav>
  <div class="container d-flex view-height">
    <div class=" my-auto text-center">
      @if(business && business.logo){
      <div class="business-logo mx-auto mb-6">
        <div class="img-fluid logo-display" [lazyLoad]="business.logo.url || ''"></div>
      </div>
      }
      <h3 class="error-message mb-4" translate="order.order-empty"></h3>
      <a [routerLink]="['/menu','category']" class="btn btn-outline-primary" translate="order.explore-menu"> </a>
    </div>

  </div>
</div>
} @else {

<div id="cartView" class="">
  <nav class="navbar mt-3" (click)="activeOffcanvas.dismiss('Cross click')">
    <div class="container-md d-flex justify-content-between">
      <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="order.title"></span></h3>
      @if(order.status){
      <div class="d-flex ">
        @switch (order.status) {
        @case ('REQUESTED') {
        <span class="badge bg-info-subtle text-info me-2  order-status" translate="order-type.requested"> </span>
        }
        @case ('CANCELLED') {
        <span class="badge bg-danger-subtle text-danger me-2  order-status" translate="order-type.cancelled"> </span>
        }
        @case ('COMPLETED') {
        <span class="badge bg-success-subtle text-success me-2  order-status" translate="order-type.completed"> </span>
        }
        @case ('PROCESSING') {
        <span class="badge bg-warning-subtle text-warning  me-2  order-status" translate="order-type.processing"></span>
        }
        }

        @switch (order.type) {
        @case ('takeAway') {
        <span class="badge  bg-dark-subtle text-dark order-type" translate="order-type.take-out"> </span>
        }
        @default {
        <span class="badge  bg-dark-subtle text-dark order-type" translate="order-type.dine-in"></span>
        }
        }
      </div>
      }

    </div>
  </nav>

  <hr>
  @if(productItemsOrderPerType.length){
  <section class="container-md ">
    <ul class="list-group list-group-flush">
      @for (product of productItemsOrderPerType; track product.id;){
      <li class="list-group-item"><app-product-item-card [readonly]="true" [product]="product" [currency]="order.business?.currency"></app-product-item-card></li>
      }
    </ul>

  </section>

  }

  <section class="container-md mb-4 mt-auto">
    @if(order.paymentType === 'BUSINESS_MANAGED' && (order.total?.due || 0) > 0){
    <div class="alert alert-warning  small" role="alert" [innerHTML]="'order.order-not-pay-yet' | translate">
    </div>
    }

    <hr />

    @if(order.allergyInformation){
    <div>
      <p class="mb-1" translate="order.allergy-information"></p>
      <p class=" text-truncate-2 small text-muted">{{order.allergyInformation}}</p>
    </div>
    }

    <div class=" d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.sub-total"></p>
      <p class="mb-2 small text-muted">{{order.total?.itemsTotal | currency : order.business?.currency}}</p>
    </div>

    @if(discount > 0){
    <div class="d-flex w-100 justify-content-between text-primary">
      <p class="mb-2 small text-muted" translate="checkout.discount"></p>
      <p class="mb-2 small text-muted" dir="ltr">{{-discount | currency: order.business?.currency}}</p>
    </div>
    }

    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.fee"></p>
      <p class="mb-2 small text-muted"> {{0 | currency: order.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-0 small text-muted"> <span translate="checkout.tip"></span> <span class="ps-2" [hidden]="!order.total?.tipPercentage">({{order.total?.tipPercentage | percent}})</span></p>
      <p class="mb-0 small text-muted"> {{order.total?.tipAmount || 0 | currency: order.business?.currency}}</p>
    </div>
    <hr class="my-3 " />
    <div class="d-flex w-100 justify-content-between">
      <h4 class="mb-0 " translate="checkout.total"> </h4>
      <h4 class="mb-0">{{(order.total?.amountWithTipAndFee||0) | currency: order.business?.currency}}</h4>
    </div>

  </section>

  <section class="container-md mb-4">
    @if(!user || !user.id){
    <p class="text-center" translate="order.login-title"></p>
    }
    @else if(isLoading){
    <button type="button" disabled class="btn btn-outline-primary w-100 ">
      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      <span role="status" translate="form.loading"></span>
    </button>
    }@else if(order.status === statusRequested){
    <button type="button" (click)="cancelOrder()" class="btn btn-danger w-100" translate="form.cancel"></button>
    }

  </section>

</div>
}