<nav class="navbar my-3" (click)="activeOffcanvas.dismiss('Cross click')">
  <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
    <div class="d-flex w-100 justify-content-between">
      <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="account.main.select-your-language"></span></h3>
    </div>
  </div>
</nav>
<hr class="mt-0" />

<div class="container-md mb-3">
  <div class="list-group list-group-flush">

    @if(needToRealod){
    <p class="my-4 text-center">
      <span class="spinner-border spinner-border-md" aria-hidden="true"></span>
      <br />
      <span role="status" translate="form.loading"></span>
    </p>
    }@else {
    <button type="button" class="list-group-item list-group-item-action" [class.active]="languageCode === 'en-US'" (click)="selectLanguage('en-US')" aria-current="true">
      English (US)
    </button>
    <button type="button" class=" list-group-item list-group-item-action" [class.active]="languageCode === 'fr'" (click)="selectLanguage('fr')" aria-current="true">
      Francais
    </button>
    <button type="button" class=" list-group-item list-group-item-action" [class.active]="languageCode === 'he'" (click)="selectLanguage('he')" aria-current="true">
      עִברִית
    </button>
    <button type="button" class=" list-group-item list-group-item-action" [class.active]="languageCode === 'es'" (click)="selectLanguage('es')" aria-current="true">
      Española
    </button>
    <button type="button" class=" list-group-item list-group-item-action" [class.active]="languageCode === 'ar'" (click)="selectLanguage('ar')" aria-current="true">
      عربي
    </button>
    <button type="button" class=" list-group-item list-group-item-action" [class.active]="languageCode === 'ru'" (click)="selectLanguage('ru')" aria-current="true">
      русский
    </button>
    }
  </div>

</div>