<!-- <router-outlet></router-outlet> -->

<app-notification-push></app-notification-push>
@if(!hideNotificationRequest){
<app-notification-request></app-notification-request>
}

<div [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir" [lang]="languageCode">
  @if(rootStyle){
  <div [innerHTML]="rootStyle"></div>
  }

  @if(businessLoaded){
  <app-navbar></app-navbar>

  <div class="router-outlet-content pb-5">
    <router-outlet></router-outlet>
  </div>
  }@else {
  <div class="position-absolute top-0 start-0 end-0 bottom-0">
    <app-splash class="d-flex flex-column justify-content-center  text-bg-primary "></app-splash>

  </div>
  }

  <!-- <app-footer></app-footer> -->
  <app-loader></app-loader>
  <!-- <app-authentication></app-authentication> -->
</div>
