import { CurrencyPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { AvailableDineasCurrency, Product } from "../../../_global/_interfaces";
import { ProductCardComponent } from "../card/card.component";

@Component({
  selector: "app-vertical-product-card-section",
  standalone: true,
  imports: [LazyLoadImageModule, ProductCardComponent],
  templateUrl: "./section.component.html",
  styleUrl: "./section.component.scss",
  providers: [CurrencyPipe], // need to use PricingDisplayPipe
})
export class VerticalProductSectionComponent {
  @Input() products!: Product[];
  @Input() currency?: AvailableDineasCurrency;
}
