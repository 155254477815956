@if ( product && business ) {
<div class="pb-5" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
  @if (product.images && product.images.length ) {
  <header class="">
    <div class="w-100 position-relative">
      <ngb-carousel [showNavigationArrows]="product.images.length >1" [showNavigationIndicators]="product.images.length >1">
        @for (image of product.images; track index; let index = $index) {
        <ng-template ngbSlide>
          <div class="image-hero">
            <div class="img-fluid " [lazyLoad]="image.url || ''" [style.min-height]="'150px'"></div>
          </div>
        </ng-template>
        }
      </ngb-carousel>

      <div class="position-absolute top-0 bottom-0 end-0 start-0 dir-{{languageDir}}" [style.min-height]="'150px'">
        <div class="container-md position-relative" [style.min-height]="'100%'">
          <div class="go-back-btn position-absolute top-0 p-3 end-0">
            <button type="button" (click)="activeOffcanvas.dismiss('click go back')" class="btn btn-go-back"><app-icon-svg iconName="close-cross"></app-icon-svg></button>
          </div>

        </div>
      </div>

    </div>
  </header>
  }@else{
  <div class="position-absolute top-0 bottom-0 end-0 start-0 dir-{{languageDir}}" [style.min-height]="'150px'">
    <div class="container-md position-relative" [style.min-height]="'100%'">
      <div class="go-back-btn position-absolute top-0 p-2 end-0">
        <button type="button" (click)="activeOffcanvas.dismiss('click go back')" class="btn btn-go-back"><app-icon-svg iconName="close-cross"></app-icon-svg></button>
      </div>
    </div>
  </div>
  }

  <section class="container-md mb-3 mt-4">
    <div class="d-flex">
      @if(product.isPopular){
      <span class="badge text-bg-warning me-2  is-popular mb-2"> <span translate="product.popular"></span> </span>
      }
      @if(product.isRecommended){
      <span class="badge text-bg-info me-2  is-recommanded mb-2"> <span translate="product.recommanded"></span> </span>
      }
    </div>
    <h2>{{productTraductionName}}</h2>
    <h4 class="" [innerHTML]="pricings | pricingDisplay : true: business.currency"></h4>
    <p class="small text-muted">{{productTraductionDescription}}</p>
  </section>
  @if (product.variants.length > 1 || product.optionGroups.length > 0) {
  <hr class="my-3 border-4 mx-auto" />
  }

  <section class="container-md ">
    <form [formGroup]="productForm">

      <!-- @if(productItems.length > 1){

      <div class="list-group">
        @for (item of productItems; track index; let index = $index) {
        <button type="button" (click)="onSelectedProductItem(item)" class="list-group-item list-group-item-action {{selectedProductItem?.addedAt === item.addedAt &&  'bg-primary bg-opacity-10'}}"
          aria-current="true">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0"><b>{{item.quantitySelected || 0}} x {{item.variant.name || item.name }}</b></p>
            <small>{{item.price | currency : business.currency}}</small>
          </div>
          <p class="small text-muted text-truncate  mb-0">{{displaySelectedOptions(item)}}</p>
        </button>
        }

      </div>
      } -->

      @if(product.variants.length > 1){
      <!-- <h2>Product variants: </h2> -->
      <ul class="list-group list-group-flush px-0">
        @for (variant of product.variants; track index; let index = $index) {
        <li class="list-group-item px-0 py-3">
          <label class="form-check-label" [for]="'variant-'+index" [style.min-width]="'calc(100% - 35px)'">{{variant.nameTranslated || variant.name}}
            <p class="small text-muted mb-0"> <span class="small " [innerHTML]="[variant] | pricingDisplay : true : business.currency"></span></p>

          </label>
          <input class="form-check-input ms-3" type="radio" formControlName="selectedVariant" [value]="index" [id]="'variant-'+index" (change)="changeVariant(variant)">
        </li>
        }
      </ul>
      }
    </form>
  </section>

  @if(business && business.currency){
  <section class="container-md my-4">
    <app-option-groups-form (optionsFormChange)="onOptionsFormChange($event)" [optionGroups]="product.optionGroups" [selectedProductItem]="selectedProductItem"
      [businessCurrency]="business.currency"></app-option-groups-form>
  </section>
  }

  @if(business && business.currency){
  <section class="container-md my-4">
    <app-sub-products-form [product]="product" [businessCurrency]="business.currency"></app-sub-products-form>
  </section>
  }

  @if(pushSell && pushSell.products && pushSell.products.length){
  <section class="container-md  my-5 ">
    <h3>
      {{pushSell.name}}
    </h3>
    <app-vertical-product-card-section [currency]="business.currency" [products]="pushSell.products"></app-vertical-product-card-section>
  </section>
  }

  <nav class="navbar fixed-bottom border-top py-2 border-light-subtle">
    <div class="container-md ">
      @if(!product.isActive){
      <button type="button" class="btn btn-outlined-primary disabled w-100" translate="product.not-available"></button>
      }
      @else if(isSoldOut){
      <button type="button" class="btn btn-primary disabled w-100" translate="product.sold-out"></button>
      }@else {
      <div class="col-auto">
        <div class="input-group " dir="ltr">
          <button class="btn btn-light ms-auto" type="button" [disabled]="productForm.value.quantitySelected<=1" (click)="onReduiceProductQuantity()"><app-icon-svg
              iconName="minus"></app-icon-svg></button>
          <span class="input-group-text text-bg-light border-0 text-center">{{productForm.value.quantitySelected || '0'}}</span>
          <button class="btn btn-light" type="button" (click)="onAddProductQuantity()"><app-icon-svg iconName="plus"></app-icon-svg></button>
        </div>
      </div>
      <div class="col-8">
        @if(!selectedProductItem){
        <button [disabled]="optionsForm.invalid || productForm.invalid || isLoading || !product.isActive" type="button" (click)="addProductToCart()" class="btn btn-primary w-100"
          translate="product.add-for-price" [translateParams]="{price:totalPrice | currency: business.currency }"></button>
        }@else if(updateSelectedProductItem || selectedProductItem.quantitySelected !== productForm.value.quantitySelected){
        <button [disabled]="optionsForm.invalid || productForm.invalid || isLoading" type="button" (click)="updateProductToCart()" class="btn btn-primary w-100" translate="product.update-for-price"
          [translateParams]="{price:totalPrice | currency: business.currency }"></button>
        }@else {
        <button [disabled]="isLoading" type="button" (click)="removeProductFromCart()" class="btn btn-danger w-100" translate="product.remove-price"
          [translateParams]="{price:totalPrice | currency: business.currency }"></button>
        }

      </div>
      }

    </div>
    <!-- @if(showAddAnotherProductButton ){
    <div class="container-md mt-3">
      <button type="button" (click)="addAnotherProduct()" class="btn btn-outline-primary w-100" translate="product.add-another-product"></button>
    </div>
    } -->

  </nav>

</div>
}