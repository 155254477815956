@if ( pageLoading ) {
<div #placeholder>
  <nav class="navbar">
    <div class="container-md">
      <a [routerLink]="['/menu','category']" class="btn   border-0 "><app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg></a>
      <p aria-hidden="true" class="placeholder-glow m-auto" [style.width]="'120px'">
        <span class="placeholder  col-12"></span>
      </p>
      <button type="button" class="btn border-0 " (click)="open(callWaiterOffcanvas, 'top')"> <app-icon-svg iconName="waiter-bell"></app-icon-svg>
      </button>
      <button type="button" class="btn border-0 " (click)="open(productFilterOffcanvas)"> <app-icon-svg iconName="filter"></app-icon-svg>
      </button>
    </div>
  </nav>
</div>
} @else if(!selectedCategory ){
<div>
  <nav class="navbar">
    <div class="container-md">
      <a [routerLink]="['/menu','category']" class="btn  border-0"><app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg> </a>
      <button class="navbar-brand btn mx-auto" type="button" (click)="open(switchType, 'top','offcanvas-main-category')"> <span [translate]="'product-type.' + productType"></span><fa-icon class="px-2"
          [icon]="faChevronDown"></fa-icon></button>
      <button type="button" class="btn border-0 " (click)="open(callWaiterOffcanvas, 'top')">
        <app-icon-svg iconName="waiter-bell"></app-icon-svg>
      </button>
      <button type="button" class="btn border-0 " (click)="open(productFilterOffcanvas)">
        <app-icon-svg iconName="filter"></app-icon-svg>
      </button>
    </div>
  </nav>
  <div class="container d-flex view-height">
    <div class=" my-auto text-center">
      <h3 class="error-message mb-4" translate="menu-category.no-product-title"></h3>
      <p class="error-contact" translate="menu-category.no-product-sub-title"></p>
    </div>

  </div>
</div>
}@else {
<div>
  <nav class="navbar">
    <div class="container-md">
      <a [routerLink]="['/menu','category']" class="btn  border-0"><app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg></a>
      <button class="navbar-brand btn mx-auto text-capitalize" type="button" (click)="open(switchType, 'top','offcanvas-main-category')"> <span
          [translate]="'product-type.' + productType"></span><fa-icon class="px-2" [icon]="faChevronDown" size="sm"></fa-icon></button>
      <button type="button" class="btn position-relative border-0" (click)="open(callWaiterOffcanvas, 'top')"> <app-icon-svg iconName="waiter-bell"></app-icon-svg></button>
      <button type="button" class="btn position-relative border-0" (click)="open(productFilterOffcanvas)"> <app-icon-svg iconName="filter"></app-icon-svg>

        @if(hasFilterApply){
        <span class="position-absolute top-0 start-0 translate-middle p-2 bg-warning border border-light rounded-circle">
          <span class="visually-hidden">New alerts</span>
        </span>
        }

      </button>
    </div>
  </nav>
  <section id="categoriesList" class="container-md d-flex">
    <!-- List of categories button to switch the selected category -->
    <button class="btn border-0 px-1 me-3 text-nowrap selected" disabled="true" type="button" [hidden]="categories && categories.length <= 10">{{selectedCategory.name}}
    </button>

    @for (category of categories; track category.id;){
    <button class="btn border-0 rounded-0 px-1 me-3 text-nowrap text-muted  text-capitalize" (click)="setCategoryId(category.id)" [class.selected]="selectedCategory.id === category.id"
      [hidden]="categories && categories.length > 10 && selectedCategory.id === category.id" type="button">{{category.name}}
    </button>
    }

  </section>
  <hr class="mt-0" />

  @if(hasFilterApply){
  <div class="container-md mt-2">
    <div class="alert alert-warning alert-dismissible fade show small" role="alert">
      <span [innerHTML]="'menu-category.has-filter' | translate"></span> <button type="button" class="btn-close btn-sm" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
  }

  @if (recomandedProducts) {
  @if (recomandedProducts.length > 0) {

  <section class="container-md  my-4 ">
    <h2 class="mb-3" translate="menu-category.recomanded"></h2>
    <app-vertical-product-card-section [currency]="business?.currency" [products]="recomandedProducts"></app-vertical-product-card-section>
  </section>

  }

  }

  @if (filteredProducts ) {
  @if (filteredProducts.length > 0) {
  <section class="container-md my-4">
    <h2 class="mb-3 text-capitalize">{{categorTraductionName}}</h2>
    @if(displayProductCard){
    <div class="row g-3">
      @for (product of filteredProducts ; track product.id;; let indexProduct = $index){
      <div class="col-6">
        <app-product-card [isCard]="true" [categoryId]="selectedCategory.id" [product]="product" [currency]="business?.currency"></app-product-card>

      </div>
      }
    </div>
    }@else {
    @for (product of filteredProducts ; track product.id;; let indexProduct = $index){
    <hr [hidden]="indexProduct===0">
    <app-product-card [categoryId]="selectedCategory.id" [product]="product" [currency]="business?.currency"></app-product-card>
    }
    }

  </section>
  } @else {
  <div class="container-md mt-5 pt-5">
    <div class=" my-auto text-center">
      <h3 class="error-message mb-4" translate="menu-category.no-product-title"></h3>
      <p class="error-contact" translate="menu-category.no-product-sub-title"></p>
    </div>

  </div>
  }
  }

  <!-- <section class="container-md my-4">
    <h2>Sell push</h2>

  </section> -->
</div>

}

<ng-template #callWaiterOffcanvas let-offcanvas>
  <div class="container-md">
    <div class="offcanvas-header text-center">
      <h3 class="offcanvas-title w-100" id="offcanvas-basic-title" translate="menu-category.call-waiter">
      </h3>
    </div>
    <div class="offcanvas-body">
      <div class="row text-center mt-3">
        <div class="col-6">
          <button type="button" class="btn btn-outline-primary w-100" (click)="offcanvas.dismiss('Cross click')" translate="form.cancel"></button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary w-100" (click)="onCallWaiter()" [disabled]="isLoading" translate="menu-category.call"></button>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #switchType let-offcanvas>
  <div class="container-md" (click)="offcanvas.dismiss('Cross click')">
    <div class="offcanvas-header text-center">
      <h3 class="offcanvas-title w-100" id="offcanvas-basic-title" translate="menu-category.select-category">
        <app-icon-svg class="ms-2" [iconName]="'arrow-up'"></app-icon-svg>
      </h3>
    </div>
    <div class="offcanvas-body">
      <div class="row text-center my-5 justify-content-evenly">
        <div class="col-4" [hidden]="business?.productType?.drink?.hide">
          <a [routerLink]="['/menu','category', drink]" class="text-muted" aria-current="true" [class.text-primary]="productType === drink">
            <div>
              <app-icon-svg size="2xl" iconName="drink"></app-icon-svg>
              <p class="small  mb-0 mt-5" translate="menu-category.menu-type-drinks"></p>
            </div>
          </a>
        </div>
        <div class="col-4" [hidden]="business?.productType?.food?.hide">
          <a [routerLink]="['/menu','category', food]" aria-current="true" class=" text-muted" [class.text-primary]="productType === food">
            <div>
              <app-icon-svg size="2xl" iconName="food"></app-icon-svg>
              <p class="small  mb-0 mt-5" translate="menu-category.menu-type-foods"></p>
            </div>
          </a>

        </div>
        <div class="col-4" [hidden]="business?.productType?.desert?.hide">

          <a [routerLink]="['/menu','category', desert]" aria-current="true" class="text-muted" [class.text-primary]="productType === desert">
            <div>
              <app-icon-svg size="2xl" iconName="dessert"></app-icon-svg>
              <p class="small  mb-0 mt-5" translate="menu-category.menu-type-deserts"></p>
            </div>
          </a>

        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #productFilterOffcanvas let-offcanvas>
  <div class="container-md">
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate="menu-category.filter-title"></h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">

      <form id="productFilterForm" [formGroup]="productFilterForm">
        <h4 translate="menu-category.filter-price"></h4>
        <p class="mb-0 small text-muted">{{productFilterForm.value.minPrice | currency : business?.currency}} - {{productFilterForm.value.maxPrice | currency : business?.currency}}
        </p>
        <mat-slider [max]="100" [step]="10" [min]="0" #prices>
          <input matSliderStartThumb id="minPrice" formControlName="minPrice" #minPrice>
          <input matSliderEndThumb id="maxPrice" formControlName="maxPrice" #maxPrice>
        </mat-slider>

        <button type="button" class="btn btn-primary w-100 " (click)="onFilterAppy()" translate="menu-category.filter-apply"></button>
        <button type="button" class="btn btn-outline-primary w-100 my-3" (click)="onFilterReset()" translate="menu-category.filter-reset"></button>
      </form>
    </div>
  </div>

</ng-template>