import { CurrencyPipe, getCurrencySymbol, PercentPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { PaymentComponent } from "../../_component/stripe/payment/payment.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Checkout, Order, Payment, PaymentMethodPreview, PaymentStatus, PaymentType, User } from "../../_global/_interfaces";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { CurrentCheckoutService, OrderService } from "../../_services/order.service";
import { PaymentService } from "../../_services/payment.service";
import { AccountDetailsComponent } from "../../account/details/details.component";
import { AccountPaymentMethodComponent } from "../../account/payment-method/payment-method.component";
import { AuthenticationComponent } from "../../authentication/authentication.component";
import { TipFormComponent } from "../tip-form/tip-form.component";

// order/T2ae8BtWrFoT63R9YND1/payment
@Component({
  selector: "app-split-payment",
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, ReactiveFormsModule, CurrencyPipe, AlertComponent, TranslateModule, PercentPipe, SvgIconComponent, TipFormComponent],
  templateUrl: "./index.component.html",
  styleUrl: "./index.component.scss",
})
export class SplitPaymentComponent implements OnInit, OnDestroy {
  // orderId!: string;
  order!: Order;

  user?: User;
  subscription?: Subscription;

  isLoading = false;

  business?: Business;
  subscriptionBusiness?: Subscription;

  checkout!: Checkout;
  subscriptionCheckout?: Subscription;
  // private currentCheckoutService = inject(CurrentCheckoutService);

  private customerEmailSubject = new BehaviorSubject<string | null>(null);
  customerEmail$ = this.customerEmailSubject.asObservable();

  private orderIdSubject = new BehaviorSubject<string | null>(null);
  orderId$ = this.orderIdSubject.asObservable();

  private offcanvasService = inject(NgbOffcanvas);

  alertCheckout: Alert = { type: "info" };

  private appService = inject(AppService);

  get isCustomerValid(): boolean {
    return this.checkout && this.checkout.customer && this.checkout.customer.email && this.checkout.customer.firstName ? true : false;
  }

  get currencySymbol(): string {
    return getCurrencySymbol(this.business?.currency || "USD", "narrow");
  }

  get selectPaymentMethod(): PaymentMethodPreview | undefined {
    return this.checkout?.paymentMethod;
  }

  get paymentTypeAvailable() {
    return {
      stripe: this.business?.isPayoutValidated || false,
      businessManaged: false, // on split payment we can only pay with card
    };
  }
  /*
   * paid: number; the amount not captured but what will be captued
   * due: number; total Items - paid
   * captured: number; the amount captured (confirm paid)
   * subtotal: number; total Items
   * discount: number; discount
   * service fee: number; service fee
   * tip: number; tip
   */

  get currentPaymentStatus(): {
    paid: number;
    due: number;
    subtotal: number;
    discount: number;
    amountWithTipAndFee: number;
    tipAmount: number;
    tipPercentage: number;
    itemsTotal: number;
    itemsTotalOrg: number;
    totalToPay: number;
  } {
    return {
      totalToPay: this.order?.total?.totalToPay || 0,
      subtotal: this.order?.total?.itemsTotalOrg || this.order?.total?.itemsTotal || 0,
      discount: (this.order?.total?.discountItemsAmount || 0) + (this.order?.total?.dealAmount || 0),
      amountWithTipAndFee: this.order?.total?.amountWithTipAndFee || 0,
      paid: this.order?.total?.paid || 0,
      due: this.order?.total?.due || 0,
      tipAmount: this.checkout?.total?.tipAmount || 0, //  take the tip from the checkout not the order
      tipPercentage: this.checkout?.total?.tipPercentage || 0, //  take the tip from the checkout not the order
      itemsTotal: this.order?.total?.itemsTotal || 0,
      itemsTotalOrg: this.order?.total?.itemsTotalOrg || 0,
    };
  }

  get amountToCharge(): number {
    let amount = 0;

    switch (this.paymentForm.get("splitPaymentType")?.value) {
      case "full-amount":
        amount = this.currentPaymentStatus.due;
        break;
      case "specific-amount":
        amount = this.paymentForm.get("amount")?.value || 0;
        break;
      case "split-amount": {
        const splitBy: number = this.paymentForm.get("splitBy")?.value || 0;
        const totalToPay = this.currentPaymentStatus.totalToPay || this.currentPaymentStatus.due || 0;
        amount = totalToPay / splitBy;
        if (amount > this.currentPaymentStatus.due) {
          // can't charge more than due
          amount = this.currentPaymentStatus.due;
        }

        break;
      }
    }

    // add tips
    amount += this.currentPaymentStatus.tipAmount; // tip of the checkout not order
    return amount;
  }

  paymentForm: FormGroup = this.formBuilder.group({
    amount: [null],
    splitBy: [2],
    splitPaymentType: ["full-amount"],
  });
  splitOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  private currentCheckoutService = inject(CurrentCheckoutService);

  payments: Payment[] = [];

  get userPayment(): Payment | undefined {
    return this.payments.find((payment) => ![PaymentStatus.cancelled || PaymentStatus.refund].includes(payment.status));
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private translate: TranslateService,
  ) {
    const { user, userSubscription, business, subscriptionBusiness, subscriptionCheckout, checkout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    if (!business?.branding?.enableCheckout) {
      this.router.navigate(["/cart"]);
      return;
    }
    // this.checkout = this.setDefaultPaymentMethod(checkout, business);

    this.user = user;
    this.checkout = {
      ...checkout,
      paymentType: PaymentType.stripe,
      items: [],
      // customer: checkout?.customer, // get it from current customer for safty
      isTabClosed: false,
      isGroupTab: false,
      // total: { tipAmount: checkout?.total?.tipAmount, tipPercentage: checkout?.total?.tipPercentage },
    };

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      this.currentCheckoutService.setCustomer(this.checkout, user);
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });
    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      // this.setView(checkout, this.business);
    });
    // this.setView(this.checkout, this.business);
    // Call the updateValidation method to initialize validators based on current splitPaymentType
    this.updateValidation();

    // Watch for changes in splitPaymentType and update validation accordingly
    this.paymentForm.get("splitPaymentType")?.valueChanges.subscribe(() => {
      this.updateValidation();
      // save local data
      const value = this.paymentForm.value;
      localStorage.setItem("@split-payment", JSON.stringify({ type: value.splitPaymentType, amount: value.amount, tip: this.checkout.total?.tipAmount, splitBy: value.splitBy }));
    });

    let localData = localStorage.getItem("@split-payment");
    const splitPayment: { type: "full-amount" | "specific-amount" | "split-amount"; amount: number; tip: number; splitBy: number } =
      localData && localData !== "undefined" ? JSON.parse(localData) : { type: "full-amount", amount: 0, tip: 0 };
    this.paymentForm.get("splitPaymentType")?.setValue(splitPayment.type);
    if (splitPayment.type === "specific-amount") {
      this.paymentForm.get("amount")?.setValue(splitPayment.amount);
    } else if (splitPayment.type === "split-amount") {
      this.paymentForm.get("splitBy")?.setValue(splitPayment.splitBy || 2);
    }
    if (splitPayment.tip && this.checkout.total) {
      this.checkout.total.tipAmount = splitPayment.tip;
    }
  }

  updateValidation() {
    const splitPaymentType = this.paymentForm.get("splitPaymentType")?.value;

    // Reset validators
    this.paymentForm.get("amount")?.clearValidators();
    this.paymentForm.get("splitBy")?.clearValidators();

    if (splitPaymentType === "specific-amount") {
      this.paymentForm.get("amount")?.setValidators([
        Validators.required,
        Validators.max(this.currentPaymentStatus.due), // Ensure the number is greater than 1
      ]);
    }

    if (splitPaymentType === "split-amount") {
      this.paymentForm.get("splitBy")?.setValidators([
        Validators.required,
        Validators.min(2), // Ensure the number is greater than 1
      ]);
    }

    // Update the validity status of the form controls after setting new validators
    this.paymentForm.get("amount")?.updateValueAndValidity();
    this.paymentForm.get("splitBy")?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionBusiness?.unsubscribe();
    this.subscriptionCheckout?.unsubscribe();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const orderId = params.get("id")!;
      if (!orderId) {
        this.router.navigate(["/cart"]);
        return;
      } else {
        this.orderIdSubject.next(orderId);
        this.getData().subscribe(({ orderId, order, customerEmail, payments }) => {
          if (!order || !order.items?.length) {
            this.router.navigate(["/cart"]);
            return;
          }
          this.order = order;
          this.checkout = {
            ...this.checkout,
            paymentType: PaymentType.stripe,
            items: order.items,
            orderStatus: order.status,
            orderId: order.id,
            business: order.business,
            requestedAt: order.requestedAt,
            total: { ...order.total, tipAmount: this.checkout.total?.tipAmount, tipPercentage: this.checkout.total?.tipPercentage },
            isTabClosed: order.isTabClosed,
            isGroupTab: order.isGroupTab,
            managedBy: "customer",
            numberOfGuests: order.numberOfGuests,
            tag: order.tag,
          };
          this.payments = payments;
        });
      }
    });

    this.checkStatus();
  }

  getData(): Observable<{ orderId: string | null; order: Order | null; customerEmail: string | null; payments: Payment[] }> {
    return this.orderId$.pipe(
      switchMap((orderId) => {
        const order$ = orderId ? this.orderService.get(orderId) : of(null);
        const payments$ = combineLatest([this.customerEmail$, of(orderId)]).pipe(
          switchMap(([customerEmail, orderId]) => {
            if (customerEmail && orderId) {
              return this.orderService.getMyOrderPayments(orderId, customerEmail);
            } else {
              return of([]);
            }
          }),
        );
        return combineLatest([of(orderId), order$, this.customerEmail$, payments$]).pipe(
          map(([orderId, order, customerEmail, payments]) => {
            return {
              orderId,
              order,
              customerEmail,
              payments,
            };
          }),
          catchError((error) => {
            console.error("Error in combineLatest:", error);
            return of({ orderId, order: null, customerEmail: null, payments: [] });
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ orderId: null, order: null, customerEmail: null, payments: [] });
      }),
    );
  }

  async checkStatus() {
    const clientSecret = this.route.snapshot.queryParams["payment_intent_client_secret"];
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.paymentService.retrievePaymentIntent(clientSecret);

    if (!paymentIntent) {
      return;
    }
    switch (paymentIntent.status) {
      case "succeeded":
        // todo get the order id
        this.alertCheckout = { type: "success", message: "Payment succeeded!" };
        break;
      case "requires_capture":
        // todo get the order id
        this.alertCheckout = { type: "success", message: "Order requested!" };
        break;
      case "processing":
        this.alertCheckout = { type: "success", message: "Your payment is processing." };
        break;
      case "requires_payment_method":
        this.alertCheckout = { type: "danger", message: "Your payment was not successful, please try again." };
        break;
      default:
        this.alertCheckout = { type: "danger", message: "Something went wrong." };
        break;
    }
    // on reload the page get the order id
    await this.handleSplitPayment();
  }

  async onEditPaymentMethod() {
    if (!this.isCustomerValid) {
      this.alertCheckout = { type: "danger", message: "Complete your details" };
      return;
    }
    this.openOffcanvas("payment-methods");
  }

  async onSplitPayment() {
    if (!this.isCustomerValid || !this.checkout) {
      this.alertCheckout = { type: "danger", message: "Complete your contact info" };
      return;
    } else if (!this.checkout.paymentMethod || !this.checkout.paymentType) {
      this.alertCheckout = { type: "danger", message: "Select your payment method" };
      return;
    } else if (!this.checkout.orderId) {
      this.router.navigate(["/cart"]);
      return;
    } else if (!this.user?.id && this.checkout.paymentType === PaymentType.stripe) {
      const offcanvasRef = this.offcanvasService.open(PaymentComponent, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
      offcanvasRef.componentInstance.isSplitPayment = true;
      return;
    }

    // save local data
    const value = this.paymentForm.value;
    localStorage.setItem("@split-payment", JSON.stringify({ type: value.splitPaymentType, amount: value.amount, tip: this.checkout.total?.tipAmount, splitBy: value.splitBy }));

    await this.handleSplitPayment();
  }

  private async handleSplitPayment() {
    // this will manage the request for the order
    // if order already existing it will recure the existing one
    this.isLoading = true;

    let checkout: Checkout = {
      ...this.checkout,
      isTabClosed: this.checkout?.isTabClosed || false,
      isGroupTab: this.checkout?.isGroupTab || false,
      updatedAt: new Date().valueOf(),
    };

    try {
      const result = await this.orderService.handleSplitPayment(checkout, this.amountToCharge);
      // if success cleat the checkout and redirect the user to the order page
      if (!result.success || !result.data) {
        this.alertCheckout = { type: "danger", message: result.message };
        return;
      }
      checkout = { ...checkout, ...result.data };
      this.router.navigate(["/cart"]);
    } catch (e: any) {
      this.alertCheckout = { type: "danger", message: e.message };
    } finally {
      this.isLoading = false;
      this.checkout = checkout;
    }
  }

  onTipChange(event: { amount: number; percentage: number }) {
    if (this.checkout?.total) {
      this.checkout.total.tipAmount = event.amount;
      this.checkout.total.tipPercentage = event.percentage;
      // save local data
      const value = this.paymentForm.value;
      localStorage.setItem("@split-payment", JSON.stringify({ type: value.splitPaymentType, amount: value.amount, tip: event.amount, splitBy: value.splitBy }));
    }
  }

  openOffcanvas(content: "login" | "account-details" | "payment-methods" ) {
    let component;
    switch (content) {
      case "login":
        component = AuthenticationComponent;
        break;
      case "account-details":
        component = AccountDetailsComponent;

        break;
      case "payment-methods":
        component = AccountPaymentMethodComponent;

        break;
      // case "payment-guest":
      //   component = PaymentComponent;
      //   break;

      default:
        return;
    }
    this.offcanvasService.open(component, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
  }
}
