enum BillingPlan {
  basic = "BASIC",
  smart = "SMART",
  enterprise = "ENTERPRISE",
}

enum ProductType {
  food = "food",
  drink = "drink",
  desert = "desert",
}
enum OrderType {
  takeAway = "takeAway",
  dineIn = "dineIn",
  delivery = "delivery",
}

enum UserRoles {
  owner = "owner",
  admin = "admin",
  cashier = "cashier",
}

enum PosBrand {
  BeeCommOrange = "BeeCommOrange",
  BeeCommCloud = "BeeCommCloud",
  Aviv = "Aviv",
}

enum ListingType {
  restaurant = "RESTAURANT",
  hotel = "HOTEL",
  grocery = "GROCERY",
  shopping = "SHOPPING",
}
enum NotificationChannelType {
  userOrderProcessingForPickup = "notifiedUserOrderProcessingForPickup",
  userOrderProcessingForDineIn = "notifiedUserOrderProcessingForDineIn",
  userOrderCompletedForPickup = "notifiedUserOrderCompletedForPickup",
  userOrderCompletedForDineIn = "notifiedUserOrderCompletedForDineIn",
  userOrderCancelled = "notifiedUserOrderCancelled",
  businessOrderCancelled = "notifiedBusinessOrderCancelled",
  businessOrderRequested = "notifiedBusinessOrderRequested",
  userOrderItenQuantityUpdated = "notifiedUserOrderItenQuantityUpdated",
  userOrderWillBeCalledOnceReady = "userOrderWillBeCalledOnceReady",
  userOrderWillBeServedOnceReady = "userOrderWillBeServedOnceReady",
  userCallWaiter = "userCallWaiter",
}

enum BusinessType {
  company = "company",
  individual = "individual",
}

enum PaymentStatus {
  initialised = "INITIALISED",
  pending = "PENDING",
  failed = "FAILED",
  cancelled = "CANCELLED",
  paid = "PAID",
  refund = "REFUND",
}

enum BillingStatus {
  failed = "FAILED",
  requested = "REQUESTED",
  processing = "PROCESSING",
  paid = "PAID",
  cancelled = "CANCELLED",
}

enum OrderStatus {
  pending = "PENDING",
  fulfilled = "FULFILLED",

  failed = "FAILED",
  requested = "REQUESTED",
  processing = "PROCESSING",
  completed = "COMPLETED",
  cancelled = "CANCELLED",
}

enum QuantityType {
  unit = "UNIT",
  weight = "WEIGHT",
  volume = "VOLUME",
  length = "LENGTH",
}
enum ProductOptionType {
  topping = "TOPPING",
  subProduct = "SUB_PRODUCT",
  modifier = "MODIFIER",
}

enum OptionRuleType {
  unlimited = "unlimited",
  atLeast = "atLeast",
  exactly = "exactly",
  upTo = "upTo",
  between = "between",
}

enum AvailableDineasCurrency {
  ils = "ILS",
  eur = "EUR",
  gbp = "GBP",
  aud = "AUD",
  usd = "USD",
}

enum CustomerStatus {
  requested = "REQUESTED",
  confirm = "COMFIRM",
  admin = "ADMIN",
  cancelled = "CANCELLED",
}

enum PaymentType {
  businessManaged = "BUSINESS_MANAGED",
  stripe = "STRIPE",
}

enum CollectionName {
  notifications = "notifications",
  reviews = "reviews",
  products = "products",
  businessOptionGroups = "optionGroups",
  businessDeal = "deals",
  businessPushSells = "pushSells",
  businesses = "businesses",
  requestedOrderCode = "requestedOrderCode",
  billings = "billings",
  users = "users",
  orders = "orders",
  orderCustomers = "customers",
  orderPayments = "payments",
  invitedUsers = "invitedUsers",
  businessServices = "services",
  categories = "categories",
  businessTag = "tags",
}

type QueryParams = {
  orderBy?: { field: string; direction: "desc" | "asc" };
  limit?: number;
  offset?: number | string;
  where?: {
    field: string;
    query: "<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "array-contains-any" | "not-in";
    value: number | string;
  }[];
};

type Currencies = {
  exchange: boolean;
  stripe: boolean;
  symbol?: string;
  logo?: string;
  code: string;
  name: string;
  countries: string[];
};
type AvailableStripeCountry =
  | "AU"
  | "AT"
  | "BE"
  | "BG"
  | "CA"
  | "CY"
  | "CZ"
  | "DK"
  | "EE"
  | "FI"
  | "FR"
  | "DE"
  | "GR"
  | "HK"
  | "IN"
  | "IE"
  | "IT"
  | "IL"
  | "JP"
  | "LV"
  | "BR"
  | "IT"
  | "LU"
  | "MY"
  | "MT"
  | "MX"
  | "NL"
  | "NZ"
  | "NO"
  | "PL"
  | "PT"
  | "RO"
  | "SG"
  | "SK"
  | "SI"
  | "ES"
  | "CH"
  | "GB"
  | "US";

export {
  AvailableDineasCurrency,
  AvailableStripeCountry,
  BillingPlan,
  BillingStatus,
  BusinessType,
  CollectionName,
  Currencies,
  CustomerStatus,
  ListingType,
  NotificationChannelType,
  OptionRuleType,
  OrderStatus,
  OrderType,
  PaymentStatus,
  PaymentType,
  PosBrand,
  ProductOptionType,
  ProductType,
  QuantityType,
  QueryParams,
  UserRoles,
};
