import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, inject } from "@angular/core";
import { Auth, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updateProfile } from "@angular/fire/auth";
import { Firestore, collection, collectionData, doc, docData, limit, orderBy, query, setDoc, where, writeBatch } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import moment from "moment";
import { Observable, of } from "rxjs";
import { CollectionName, NotificationDineas, User } from "../_global/_interfaces";
type SignInWithEmailLinkRequest = { email: string; domain?: string | null; brandName?: string; logo?: string };

@Injectable({
  providedIn: "root",
})
export class UserService {
  private functions: Functions = inject(Functions);
  domain: string;

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    @Inject(DOCUMENT) private documentApp: Document,
  ) {
    this.domain = this.documentApp.location.hostname;
    if (this.domain === "localhost") {
      this.domain = "http://localhost:4200";
    } else {
      this.domain = `https://${this.domain}`;
    }
  }

  getUser(id: string): Observable<User> {
    const docRef = doc(this.firestore, CollectionName.users, id);
    return docData(docRef, {
      idField: "id",
    }) as Observable<User>;
  }

  async create(user: User, password: string): Promise<any> {
    if (!user.email || !password) {
      throw new Error("Email or password not provided");
    }
    try {
      const userResult = await createUserWithEmailAndPassword(this.auth, user.email, password);

      user.id = userResult.user.uid; // user auth id as user id so we can retrieve the user easier after login
      user.createdAt = moment().valueOf();

      const docRef = doc(this.firestore, CollectionName.users, user.id);

      await setDoc(docRef, user);
      if (this.auth.currentUser) {
        updateProfile(this.auth.currentUser, { displayName: user.firstName, photoURL: null });
        sendEmailVerification(this.auth.currentUser);
      }
    } catch (error) {
      throw error;
    }
  }

  list(): Observable<User[]> {
    const docsRef = collection(this.firestore, CollectionName.users);

    return collectionData(docsRef, {
      idField: "id",
    });
  }

  update(user: User): any {
    if (!user.id) {
      throw new Error("User id not provided");
    }
    const docRef = doc(this.firestore, CollectionName.users, user.id);
    const newUser: User = {
      firstName: user.firstName || undefined,
      lastName: user.lastName || undefined,
    };
    if (user.phone) {
      newUser.phone = user.phone;
    }

    return setDoc(docRef, newUser, { merge: true });
  }

  updateField(user: User, fieldName: string, data: any): Promise<void> {
    if (!user.id) {
      throw new Error("User id not provided");
    }
    const docRef = doc(this.firestore, CollectionName.users, user.id);
    const newUser: User = { [fieldName]: data || null };
    return setDoc(docRef, newUser, { merge: true });
  }

  // async get(): Promise<User> {
  //   const currentUser = this.auth.currentUser;
  //   if (!currentUser?.uid) {
  //     throw new Error("User id not provided");
  //   }
  //   const docRef = doc(this.firestore, CollectionName.users, currentUser?.uid);
  //   const userDoc = await getDoc(docRef);
  //   const user = userDoc?.data() as User;
  //   user.id = userDoc?.id;
  //   return user;
  // }

  forgotPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  async signInWithEmailLink(data: { email: string; brandName?: string; logo?: string }): Promise<boolean> {
    const payload = {
      ...data,
      domain: `${this.domain}/account`,
    };

    const callable = httpsCallable<{ email: string; domain: string | null; brandName?: string; logo?: string }, boolean>(this.functions, "signInWithEmailLink");
    return (await callable(payload)).data;
  }

  listNotifications(limitToLoad?: number, id?: string, email?: string): Observable<NotificationDineas[]> {
    const docsRef = collection(this.firestore, CollectionName.notifications);
    const maxDate = moment().subtract(10, "hours").valueOf();
    const whereList = [where("targetType", "==", "user")];
    if (id) {
      whereList.push(where("targetId", "==", id));
    } else if (email) {
      whereList.push(where("targetEmail", "==", email));
      whereList.push(where("createdAt", ">=", maxDate));
    } else {
      return of([]);
    }
    const q = query(docsRef, ...whereList, orderBy("createdAt", "desc"), limit(limitToLoad || 10));

    const data = collectionData(q, {
      idField: "id",
    }) as Observable<NotificationDineas[]>;
    return data;
  }

  async markAsRead(notifications: NotificationDineas[]): Promise<string[]> {
    const batch = writeBatch(this.firestore);
    const ids: string[] = [];
    for (const notif of notifications) {
      if (!notif.read && notif.id) {
        const docRef = doc(this.firestore, CollectionName.notifications, notif.id);
        batch.update(docRef, "read", true);
        ids.push(notif.id);
      }
    }
    if (ids.length) {
      await batch.commit();
    }
    return ids;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
