@for (subProduct of subProductsForm.controls; track index; let index = $index) {
<div [formGroup]="parseAbstractControlToFormArray(subProduct)">
  <div>
    <h3>{{ subProduct.value.name }}</h3>

    <ul class="list-group list-group-flush px-0">
      @for (variantForm of productItemForms(subProduct).controls ; track indexOv; let indexOv = $index) {
      <li class="list-group-item px-0 py-3 " [formGroup]="parseAbstractControlToFormArray(variantForm)">
        <div class="d-flex w-100 justify-content-between ">
          @if(subProduct.value.maxQuantity > 1){
          <div class=" ps-0 " [class.my-2]="!variantForm.value.price">
            <label class="form-check-label" [for]="'option-'+index + '-' + indexOv" [style.min-width]="'calc(100% - 35px)'">{{variantForm.value.name}}</label>
            @if(variantForm.value.price){
            <p class="small m-0 text-muted">+<span class="small " [innerHTML]="[ variantForm.value ] | pricingDisplay : true: businessCurrency"></span></p>
            }
          </div>
          <div class=" pe-0">
            <div dir="ltr" class="d-flex btn-quantity-rouded">
              <button class="btn btn-light ms-auto" type="button" [hidden]="variantForm.value.quantitySelected<1" [disabled]="variantForm.value.quantitySelected<1"
                (click)="onReduiceOptionsVariantQuantity(variantForm)"><app-icon-svg iconName="minus"></app-icon-svg></button>
              <span [hidden]="variantForm.value.quantitySelected<1" class=" btn-quantity-value my-auto mx-2 small text-center">{{variantForm.value.quantitySelected || '0'}} </span>
              <button class="btn btn-light" type="button" [disabled]="variantForm.value.quantitySelected >= subProduct.value.maxQuantity"
                (click)="onAddOptionsVariantQuantity(variantForm, subProduct.value.maxQuantity)"><app-icon-svg iconName="plus"></app-icon-svg></button>
            </div>
          </div>
          }@else {
          <div class="w-100" [class.my-2]="!variantForm.value.price">

            <label class="form-check-label" [for]="'option-'+index + '-' + indexOv" [style.min-width]="'calc(100% - 35px)'">{{variantForm.value.name}}
              @if(variantForm.value.price){
              <span class="small float-end  text-primary">+<span class="small " [innerHTML]="[ variantForm.value ] | pricingDisplay : true: businessCurrency"></span></span>
              }
            </label>
            <input class="form-check-input ms-3" type="checkbox" formControlName="isSelected" [value]="index" [id]="'option-'+index + '-' + indexOv">

          </div>
          }
        </div>

      </li>

      }
    </ul>

  </div>
</div>
}