<app-alert [message]="alertBilling.message" [dismissible]="true" [active]="alertBilling.active" [type]="alertBilling.type"></app-alert>
<form class="d-flex flex-column justify-content-center py-4" [formGroup]="customerForm" (ngSubmit)="submitElement()">
  <h6 translate="stripe.payment-method.title"></h6>

  <div class=" my-3 form-floating">
    <input type="email" class="form-control" id="email" formControlName="email" [class.is-invalid]="customerForm.get('email')?.invalid"
      [placeholder]="'form.email-placeholder' | translate" required>
    <label class="col-form-label " for="email" translate="form.email-label"></label>
    <div class="invalid-feedback" translate="form.filed-required"></div>
  </div>
  <div class=" my-3 form-floating">
    <input type="text" class="form-control" id="name" formControlName="name" [class.is-invalid]="customerForm.get('name')?.invalid"
      [placeholder]="'stripe.payment-method.full-name-placeholder' | translate" required>
    <label class="col-form-label " for="name" translate="stripe.payment-method.full-name-label"></label>
    <div class="invalid-feedback" translate="form.filed-required"></div>
  </div>

  <hr class="m-4" />

  <!-- <div id="card-element" #paymentElement></div> -->
  <div id="payment-element" #paymentElementForm>
    <!-- Elements will create input elements here -->
  </div>
  <!-- We'll put the error messages in this element -->
  @if(error){
  <div id="card-element-errors" class="mt-3" role="alert" style="color: #fa755a; margin-bottom: 20px;">{{ error }}</div>
  }
  <div class="row mt-auto pt-3">
    <div class="col-auto">
      <button type="button" class="btn btn-outline-dark" [disabled]="isLoading " (click)="onClose.emit()" translate="form.cancel"></button>
    </div>

    <div class="col">
      <button type="submit" class="btn btn-outline-warning w-100" [disabled]="isLoading || customerForm.invalid" translate="stripe.payment-method.save"></button>
    </div>
  </div>
</form>