import { CurrencyPipe, JsonPipe, PercentPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbAlertModule, NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { AlertComponent } from "../_component/alert/alert.component";
import { ProductItemCardComponent } from "../_component/product/item-card/item-card.component";
import { VerticalProductSectionComponent } from "../_component/product/vertical-section/section.component";
import { PaymentMethodComponent } from "../_component/stripe/payment-method/payment-method.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { Business, Checkout, CustomerStatus, Order, OrderStatus, Payment, PaymentStatus, ProductItem, ProductType, PushSells, PushSellsPopulated, User, UserPreview } from "../_global/_interfaces";
import { Alert } from "../_interfaces";
import { AppService } from "../_services/app.service";
import { CurrentCheckoutService, OrderService } from "../_services/order.service";
import { ProductService } from "../_services/product.service";
import { PushSellService } from "../_services/push-sell.service";
import { AccountDetailsComponent } from "../account/details/details.component";
import { AuthenticationComponent } from "../authentication/authentication.component";
import { OrderComponent } from "../orders/details/details.component";
import { ScanCodeComponent } from "../scan-code/scan-code.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { MyPaymentFormFormComponent } from "./my-payment-form/form.component";
import { TipFormComponent } from "./tip-form/tip-form.component";

@Component({
  selector: "app-cart",
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    JsonPipe,
    ProductItemCardComponent,
    ReactiveFormsModule,
    CurrencyPipe,
    AlertComponent,
    AuthenticationComponent,
    CheckoutComponent,
    PaymentMethodComponent,
    TranslateModule,
    SvgIconComponent,
    VerticalProductSectionComponent,
    PercentPipe,
    TipFormComponent,
    NgbAlertModule,
    MyPaymentFormFormComponent,
  ],
  templateUrl: "./cart.component.html",
  styleUrl: "./cart.component.scss",
})
export class CartComponent implements OnInit, OnDestroy {
  user?: User;
  subscription: Subscription;
  // @ViewChild("orderTypeModal", { static: true }) orderTypeModal!: ElementRef;
  private offcanvasService = inject(NgbOffcanvas);
  private orderService = inject(OrderService);

  isLoading = false;

  customers?: UserPreview[];
  selectedProduct: ProductItem | undefined;
  get isAdminTable(): boolean {
    const admin = this.customers?.find((c) => c.status === CustomerStatus.admin);
    return admin && admin.email === this.checkout?.customer?.email ? true : false;
  }

  get hasPendingGuest(): boolean {
    return this.customers?.find((c) => c.status === CustomerStatus.requested) ? true : false;
  }

  get customerStatus(): CustomerStatus | undefined {
    if (!this.checkout?.customer || !this.checkout.customer.email) {
      return undefined;
    }
    const customer = this.customers?.find((c) => c.email === this.checkout?.customer?.email);
    return customer?.status;
  }

  get requiredCustomerDetails(): boolean {
    return this.checkout?.orderId && (!this.checkout?.customer?.email || !this.checkout?.customer?.firstName) ? true : false;
  }

  get productItemsPending() {
    // return the list of product item orderd first by food, then drink, then desert, then other
    let items =
      this.checkout?.items
        ?.filter((i) => !i.status || i.status === OrderStatus.pending)
        .sort((a, b) => {
          if (a.type === ProductType.food) {
            return -1;
          } else if (a.type === ProductType.drink && b.type !== ProductType.food) {
            return -1;
          } else if (a.type === ProductType.desert && b.type !== ProductType.food && b.type !== ProductType.drink) {
            return -1;
          } else {
            return 1;
          }
        }) || [];

    return items;
  }
  get productItemsOrdered() {
    // return the list of product item orderd first by food, then drink, then desert, then other
    let items =
      this.checkout?.items
        ?.filter((i) => i.status && i.status !== OrderStatus.pending)
        .sort((a, b) => {
          if (a.type === ProductType.food) {
            return -1;
          } else if (a.type === ProductType.drink && b.type !== ProductType.food) {
            return -1;
          } else if (a.type === ProductType.desert && b.type !== ProductType.food && b.type !== ProductType.drink) {
            return -1;
          } else {
            return 1;
          }
        }) || [];

    return items;
  }

  get currentPaymentStatus(): {
    paid: number;
    due: number;
    subtotal: number;
    discount: number;
    amountWithTipAndFee: number;
    tipAmount: number;
    tipPercentage: number;
    itemsTotal: number;
    itemsTotalOrg: number;
  } {
    return {
      subtotal: this.checkout?.total?.itemsTotalOrg || this.checkout?.total?.itemsTotal || 0,
      discount: (this.checkout?.total?.discountItemsAmount || 0) + (this.checkout?.total?.dealAmount || 0),
      amountWithTipAndFee: this.checkout?.total?.amountWithTipAndFee || 0,
      paid: this.checkout?.total?.paid || 0,
      due: this.checkout?.total?.due || 0,
      tipAmount: this.checkout?.total?.tipAmount || 0,
      tipPercentage: this.checkout?.total?.tipPercentage || 0,
      itemsTotal: this.checkout?.total?.itemsTotal || 0,
      itemsTotalOrg: this.checkout?.total?.itemsTotalOrg || 0,
    };
  }

  get enableCheckout(): boolean {
    const orderTypeEnabled = this.business?.orderFlow?.dineIn.active || this.business?.orderFlow?.takeAway.active || false;
    return (orderTypeEnabled && this.business?.branding?.enableCheckout && (this.business?.branding.paymentManagedByBusiness || this.business?.isPayoutValidated)) || false;
  }
  get currency(): string | undefined {
    return this.checkout?.business?.currency || this.business?.currency;
  }

  get hasAccessToGroupedTab(): boolean {
    const customerStatus = this.customerStatus;
    return this.checkout?.isGroupTab && customerStatus && [CustomerStatus.admin, CustomerStatus.confirm].includes(customerStatus) ? true : false;
  }

  private orderIdAndcustomerEmailSubject = new BehaviorSubject<{ orderId: string | null; customerEmail: string | null }>({ orderId: null, customerEmail: null });
  orderIdAndCustomerEmail$ = this.orderIdAndcustomerEmailSubject.asObservable();
  alertOrder: Alert = { type: "info" };
  business?: Business;
  subscriptionBusiness: Subscription;
  private modalService = inject(NgbModal);
  private pushSellService = inject(PushSellService);
  private productService = inject(ProductService);

  pushSell?: PushSellsPopulated;
  checkout?: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);
  private appService = inject(AppService);
  payments: Payment[] = [];
  dataLoaded = false;
  get userPayment(): Payment | undefined {
    return this.payments.find((payment) => ![PaymentStatus.cancelled || PaymentStatus.refund].includes(payment.status));
  }
  constructor(private router: Router) {
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    this.setViewOnCheckout(checkout);
    // this.currentCheckoutService.check(checkout);
    this.user = user;
    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      this.setViewOnCheckout(checkout);
      this.dataLoaded = true;
    });
    // this.setViewOnCheckout(this.checkout);

    this.getData().subscribe(({ orderId, customerEmail, customers, pushSells, payments, order }) => {
      this.customers = customers;
      this.payments = payments;
      if (this.checkout) {
        const tempCheckout = this.mergeOrderWithCheckout(this.checkout, order);
        this.currentCheckoutService.set(tempCheckout, !tempCheckout.items || !tempCheckout.items.length ? true : false);
      }
      this.getPushSells(pushSells, this.checkout?.items || []).then((pushSell) => {
        this.pushSell = pushSell || undefined;
      });
    });
  }

  mergeOrderWithCheckout(checkout: Checkout, order: Order | null): Checkout {
    const newCheckout = { ...checkout };
    if (!order || !checkout.orderId) {
      newCheckout.orderId = undefined;
      newCheckout.orderStatus = undefined;
      newCheckout.isGroupTab = false;
      newCheckout.numberOfGuests = undefined;
    } else {
      newCheckout.total = order.total;
      newCheckout.orderStatus = order.status;
      newCheckout.orderId = order.id;
      if (order.items) {
        const mergedItems: ProductItem[] = [...(newCheckout.items || [])];
        // Iterate over the latestItems and update or add them to the localItems
        order.items?.forEach((latestItem) => {
          // Find index in the localItems by both id and addedAt timestamp
          const localItemIndex = mergedItems.findIndex((item) => item.id === latestItem.id && item.addedAt === latestItem.addedAt);
          if (localItemIndex !== -1) {
            // Item exists in the localItems, so update it
            mergedItems[localItemIndex] = {
              ...mergedItems[localItemIndex], // Keep local properties
              ...latestItem, // Update with latest properties
            };
          } else {
            // Item does not exist, so add it
            mergedItems.push(latestItem);
          }
        });

        newCheckout.items = mergedItems;
      }
    }

    return newCheckout;
  }

  onTipChange(event: { amount: number; percentage: number }) {
    if (this.checkout?.total) {
      this.checkout.total.tipAmount = event.amount;
      this.checkout.total.tipPercentage = event.percentage;
    }
  }
  setViewOnCheckout(checkout: Checkout | undefined): void {
    const newVal = { orderId: checkout?.orderId || null, customerEmail: checkout?.customer?.email || null };
    if (
      (newVal.orderId && newVal.orderId !== this.orderIdAndcustomerEmailSubject.value.orderId) ||
      (newVal.customerEmail && newVal.customerEmail !== this.orderIdAndcustomerEmailSubject.value.customerEmail)
    ) {
      this.orderIdAndcustomerEmailSubject.next(newVal);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  async ngOnInit() {
    // this.currentCheckoutService.check(this.checkout);
  }

  onInviteTabAction(guest: UserPreview, isConfirm: boolean) {
    if (guest.email && this.checkout?.orderId) {
      this.isLoading = true;
      this.orderService
        .inviteTabAction(this.checkout.orderId, guest.email, isConfirm ? CustomerStatus.confirm : CustomerStatus.cancelled)
        .then(() => {
          // display confim message waiting for the access to be accepted
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  async getPushSells(pushSells: PushSells[], checkoutItems: ProductItem[]): Promise<PushSellsPopulated | null> {
    const productIdsFromCheckout: string[] = [];
    const productTypeFromCheckout: { [type in ProductType]: boolean } = { [ProductType.desert]: false, [ProductType.drink]: false, [ProductType.food]: false };
    for (const item of checkoutItems) {
      if (item.id) {
        productIdsFromCheckout.push(item.id);
      }
      if (item.type) {
        productTypeFromCheckout[item.type] = true;
      }
    }
    const validPushSells = pushSells.map((pushSell) => {
      const notInCartProductIds: string[] = pushSell.productIds?.filter((id) => !productIdsFromCheckout.includes(id)) || [];
      const existingProductCount: number = (pushSell.productIds?.length || 0) - notInCartProductIds.length;
      const isTypeInCheckout: boolean = productTypeFromCheckout[pushSell.type];
      return {
        id: pushSell.id,
        notInCartProductIds,
        existingProductCount,
        isTypeInCheckout,
        pushSell,
      };
    });
    // now sort the validPushSells based first on the isTypeInCheckout, then on the existingProductCount and finnaly on the existingProductCount.lemgth
    validPushSells.sort((a, b) => {
      if (a.isTypeInCheckout && !b.isTypeInCheckout) {
        return 1;
      }
      if (!a.isTypeInCheckout && b.isTypeInCheckout) {
        return -1;
      }
      if (a.existingProductCount > b.existingProductCount) {
        return -1;
      }
      if (a.existingProductCount < b.existingProductCount) {
        return 1;
      }
      if (a.notInCartProductIds.length > b.notInCartProductIds.length) {
        return -1;
      }
      if (a.notInCartProductIds.length < b.notInCartProductIds.length) {
        return 1;
      }
      return 0;
    });

    if (validPushSells.length) {
      const pushSell = validPushSells[0].pushSell;
      // remove the productIdsFromCheckout
      const pushSellProductIds = (pushSell.productIds || []).filter((id) => !productIdsFromCheckout.includes(id));
      const products = await this.productService.getFromIdsPromise(pushSellProductIds);
      return { ...pushSell, products };
    }
    return null;
  }

  onUpdateQuantity(action: "minus" | "add", item: ProductItem) {
    let checkout: Checkout = { ...this.checkout, isTabClosed: this.checkout?.isTabClosed || false, isGroupTab: this.checkout?.isGroupTab || false };

    if (!checkout.items || !checkout.items.length) {
      return;
    }
    const itemIndex = checkout.items?.findIndex((i) => i.name === item.name && i.addedAt === item.addedAt && i.quantitySelected === item.quantitySelected);
    let currentQuantity = checkout.items[itemIndex].quantitySelected || 0;
    if (action === "add") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }

    if (currentQuantity < 1) {
      // remove the item from array
      checkout.items.splice(itemIndex, 1);
    } else {
      checkout.items[itemIndex].quantitySelected = currentQuantity;
    }

    this.currentCheckoutService.set(checkout);
  }

  open(content: TemplateRef<any>, position: "top" | "bottom" | "start" | "end" = "bottom") {
    this.offcanvasService.open(content, { position, panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
  }

  onJoinTab() {
    if (this.checkout?.orderId && this.checkout.customer?.email) {
      this.isLoading = true;
      this.orderService
        .joinTab(this.checkout.orderId, this.checkout.customer)
        .then(() => {
          // display confim message waiting for the access to be accepted
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  onConfirmAndAddToOrder() {
    this.offcanvasService.dismiss("confirm");
    if (this.checkout) {
      this.isLoading = true;
      this.orderService
        .updateTab(this.checkout)
        .then((checkout) => {
          // this.currentCheckoutService.set(checkout);  not needed as the checkout is already set
          this.checkout = checkout;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  onCloseTab() {
    this.offcanvasService.dismiss("confirm");
    if (this.checkout && this.checkout.orderId) {
      this.isLoading = true;
      this.orderService
        .closeTab({ orderId: this.checkout.orderId, tipPercentage: this.checkout.total?.tipPercentage })
        .then((order) => {
          this.currentCheckoutService.resetCheckout(this.checkout);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  openScanner() {
    const offcanvasRef = this.offcanvasService.open(ScanCodeComponent, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
    // offcanvasRef.componentInstance.productId = this.product.id;
  }

  openCustomerDetail() {
    const offcanvasRef = this.offcanvasService.open(AccountDetailsComponent, {
      position: "bottom",
      panelClass: "offcanvas-fit-content offcanvas-after-nav",
      backdropClass: "offcanvas-backdrop-after-nav",
    });
    // offcanvasRef.componentInstance.productId = this.product.id;
  }
  openOrder() {
    if (this.checkout?.orderId) {
      const offcanvasRef = this.offcanvasService.open(OrderComponent, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
      offcanvasRef.componentInstance.orderId = this.checkout.orderId;
    }
  }

  getData(): Observable<{ orderId: string | null; order: Order | null; customerEmail: string | null; customers: UserPreview[]; pushSells: PushSellsPopulated[]; payments: Payment[] }> {
    return this.orderIdAndCustomerEmail$.pipe(
      switchMap(({ orderId, customerEmail }) => {
        const pushSells$ = this.pushSellService.list();
        const order$ = orderId ? this.orderService.get(orderId) : of(null);

        const payments$ = customerEmail && orderId ? this.orderService.getMyOrderPayments(orderId, customerEmail) : of([]);
        const customers$ = customerEmail && orderId ? this.orderService.listCustomers(orderId) : of([]);

        return combineLatest([of(orderId), order$, pushSells$, of(customerEmail), payments$, customers$]).pipe(
          map(([orderId, order, pushSells, customerEmail, payments, customers]) => {
            return {
              orderId,
              order,
              pushSells,
              customerEmail,
              payments,
              customers,
            };
          }),
          catchError((error) => {
            console.error("Error in combineLatest:", error);
            return of({ orderId, order: null, pushSells: [], customerEmail: null, payments: [], customers: [] });
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ orderId: null, order: null, pushSells: [], customerEmail: null, payments: [], customers: [] });
      }),
    );
  }
}
