<div id="accountView" class="d-flex flex-column justify-content-center ">
  <div class="container-md mt-3">
    <app-alert [message]="alertAccount.message" [dismissible]="true" [active]="alertAccount.active" [type]="alertAccount.type"></app-alert>
    @if(!user || !user.id){
    <h1 class="mb-4" translate="account.main.hi-guest"></h1>
    }@else if(user.firstName) {
    <h1 class="mb-4" translate="account.main.hi-user-name" [translateParams]="{userName:user.firstName}"></h1>
    }
  </div>
  <section class="container-md mt-3">

    <h6 class="text-muted" translate="account.main.my-account"></h6>

    @if(user && user.id){
    <a class="d-flex w-100 justify-content-between text-decoration-none" [routerLink]="['/account/orders']">
      <h5 class="my-auto text-truncate" translate="account.main.order-history"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </a>
    <hr />

    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('payment-methods')">
      <h5 class="my-auto text-truncate" translate="account.main.payment-methods"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
    <hr />
    }

    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('account-details')">
      <h5 class="my-auto text-truncate" translate="account.main.manage-details"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
    <hr />

    <a class="d-flex w-100 justify-content-between text-decoration-none" [routerLink]="['/account/notifications']">
      <div class="d-flex">
        <h5 class="my-auto text-truncate" translate="account.main.notifications"></h5>
        @if(hasUnreadNotifications){
        <div class=" my-auto mx-2">
          <span class="translate-middle badge rounded-pill text-bg-danger p-1 ">
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>

        }
      </div>

      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </a>
    <hr />

    <div class="d-flex w-100 justify-content-between text-decoration-none">
      <h5 class="my-auto text-truncate text-muted" translate="account.main.dietary-preferences"> </h5>
      <p class="caption my-auto"><span class="badge  bg-primary-subtle text-primary" translate="account.main.coming-soon"></span></p>

      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
    <hr />
    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('language')">
      <h5 class="my-auto text-truncate" translate="account.main.select-your-language"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
  </section>
  <hr class="border-4 my-4" />
  <section class="container-md ">
    <h6 class="  text-muted" translate="account.main.support"></h6>
    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('contact-us')">
      <h5 class="my-auto text-truncate" translate="account.main.contact-us"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
  </section>
  <hr class="border-4 my-4" />
  <section class="container-md ">
    <h6 class=" text-muted" translate="account.main.support"></h6>
    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('terms')">
      <h5 class="my-auto text-truncate" translate="account.main.terms-conditions"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
    <hr />
    <div class="d-flex w-100 justify-content-between text-decoration-none" (click)="openOffcanvas('privacy-policy')">
      <h5 class="my-auto text-truncate" translate="account.main.privacy-policy"></h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>

  </section>
  <div class="container-md  mt-auto mb-3">
    @if(!user || !user.id){
    <h4 translate="account.main.login-title"></h4>
    <p class="small" translate="account.main.login-sub-title"></p>
    <button type="button" class="btn btn-primary w-100 " (click)="openOffcanvas('login')" translate="account.details.login-signup"></button>
    }@else {
    <button type="button" class="btn btn-outline-danger w-100 " (click)="onLogout()" translate="account.main.logout"></button>
    }
  </div>
</div>