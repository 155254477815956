<section>
  <section class="container-md">
    <app-alert [message]="alertPayment.message" [active]="alertPayment.active" [type]="alertPayment.type"></app-alert>
  </section>
  @if(!amountPadType){
  <section class="container-md">

    <div class="row justify-content-between ">
      <div class="col-xs-12 col-sm-6 my-5">
        <div>
          <div class="input-group">
            <span class="input-group-text fw-bold" translate="checkout.amount"></span>
            <span class="input-group-text">{{participationAmount | currency : currency}}</span>
            <button class="btn btn-outline-primary" type="button" id="button-addon2" [disabled]="!canUpdatePayment" (click)="selectAmountPadType('amount')"> <app-icon-svg
                iconName="edit"></app-icon-svg></button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 my-5">
        <div>
          <div class="input-group">
            <span class="input-group-text fw-bold" translate="checkout.tip"></span>
            <span class="input-group-text">{{participationTip | currency : currency}}</span>
            <button class="btn btn-outline-primary" type="button" id="button-addon2" [disabled]="!canUpdatePayment" (click)="selectAmountPadType('tip')"> <app-icon-svg
                iconName="edit"></app-icon-svg></button>
          </div>
        </div>
      </div>
    </div>

    <div class="text-end mb-3">
      <button class="btn btn-primary" type="button" [disabled]="isLoading || !canUpdatePayment || !amountValueUpdated" (click)=" updatePayment()">
        <span translate="form.update"></span>
        <span> {{participationAmount + participationTip | currency: currency}}</span>

      </button>

    </div>
  </section>
  }@else {
  <section class="container-md ">
    <p class="text-center" [translate]="amountPadType === 'amount' ? 'checkout.amount':'checkout.tip'"></p>
    <h3 class="text-center"> {{amountPad | currency: currency}} </h3>

    <div id="padKeyboard">
      <div class="d-flex w-100 justify-content-between my-3">
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('1')">1</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('2')">2</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('3')">3</button>
      </div>
      <div class="d-flex w-100 justify-content-between  my-3">
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('4')">4</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('5')">5</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('6')">6</button>
      </div>

      <div class="d-flex w-100 justify-content-between  my-3">
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('7')">7</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('8')">8</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('9')">9</button>

      </div>
      <div class="d-flex w-100 justify-content-between  my-3">
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('.')">.</button>
        <button type="button" class="btn btn-pad btn-light btn-lg" (click)="addAmountPad('0')">0</button>
        <button type="button" class="btn btn-pad btn-outline-dark btn-lg border-0" (click)="addAmountPad('')">
          <app-icon-svg iconName="backspace"></app-icon-svg>
        </button>
      </div>

      <button type="button" (click)="validPadAmount()" class="btn btn-dark btn-pad w-100  mb-3" [disabled]="isLoading" translate="form.confirm"></button>
      @if(checkout?.total?.due && amountPadType === 'amount' && amountPad !== (totalRest+'')){
      <button type="button" (click)="validPadFullAmount()" class="btn btn-outline-dark w-100 mb-3" [disabled]="isLoading"> <span translate="split-payment.full-amount"></span>
        <span> {{totalRest | currency: currency}}</span></button>
      }
    </div>

  </section>
  }

</section>