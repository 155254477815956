@if(!hideNotificationRequest){
<section class="container notification-request position-absolute top-0">
  @if(fcmService.notificationPermissions === 'denied'){
  @if(isChrome){
  <div class="alert alert-warning my-2" role="alert">
    <div class=" d-flex w-100 justify-content-between">
      <strong translate="notification.notification-blocked"></strong>
      <button type="button" class="btn-close" aria-label="Close" (click)="onHideNotificationRequest()"></button>
    </div>
    <br />
    <span translate=" notification.chrome.help-title"></span>
    <br /><br /><span translate="notification.chrome.help-1"></span>
    <br /><span translate="notification.chrome.help-2"></span> <strong>"Dineas"</strong>.
    <br /><span translate="notification.chrome.help-3"></span>
  </div>
  }@else {
  <div class="alert alert-warning  my-2" role="alert">
    <strong translate="notification.notification-blocked"></strong><br />
    <span [innerHTML]="'notification.other.help-1'|translate"></span>
  </div>
  }
  }
  @else if(fcmService.notificationPermissions === 'default' || fcmService.notificationPermissions === 'request'){
  <div class="alert alert-warning  my-2" role="alert">
    <div class=" d-flex w-100 justify-content-between">
      <strong translate="notification.notification-required"></strong>
      <button type="button" class="btn-close" aria-label="Close" (click)="onHideNotificationRequest()"></button>
    </div>

    <br />
    <span translate="notification.alert.help"></span>
    <br /><button type="button" (click)="onRequestPermission()" class="btn btn-link px-0" translate="notification.alert.help-btn"></button>

  </div>
  }
</section>
}