import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { ProductItem } from "../../../_global/_interfaces";
import { PricingDisplayPipe } from "../../../_pipes/pricing-display/pricing-display.pipe";
import { BusinessMenuProductComponent } from "../../../product/product.component";
import { SvgIconComponent } from "../../svg-icon/index.component";

@Component({
  selector: "app-product-item-card",
  standalone: true,
  imports: [LazyLoadImageModule, PricingDisplayPipe, CurrencyPipe, SvgIconComponent, TranslateModule],
  templateUrl: "./item-card.component.html",
  styleUrl: "./item-card.component.scss",
  providers: [CurrencyPipe], // need to use PricingDisplayPipe
})
export class ProductItemCardComponent {
  @Input() product!: ProductItem;
  @Input() currency?: string;
  @Input() readonly!: boolean;
  @Input() canEdit!: boolean;
  @Output() onUpdateQuantity: EventEmitter<"minus" | "add"> = new EventEmitter<"minus" | "add">();
  imageUrl?: string;
  private offcanvasService = inject(NgbOffcanvas);

  get totalPrice(): number {
    if (!this.product) return 0;
    let totalPrice = this.product.variant?.price || 0;
    this.product.optionGroups?.forEach((option) => {
      option.options?.forEach((variant) => {
        const quantity = variant?.quantitySelected || 0;
        const price = variant?.price || 0;
        totalPrice += price * quantity;
      });
    });
    return totalPrice * (this.product.quantitySelected || 0);
  }

  get totalPriceOrg(): number {
    if (!this.product) return 0;
    let totalPrice = this.product.variant?.price || 0;
    this.product.optionGroups?.forEach((option) => {
      option.options?.forEach((variant) => {
        const quantity = variant?.quantitySelected || 0;
        const price = variant?.priceOrg || variant?.price || 0;
        totalPrice += price * quantity;
      });
    });
    return totalPrice * (this.product.quantitySelected || 0);
  }

  editOffcanvas() {
    const product = { ...this.product };
    if (!this.canEdit) {
      product.addedAt = undefined;
    }

    if (product.id) {
      if (product.image && product.optionGroups.length) {
        const offcanvasRef = this.offcanvasService.open(BusinessMenuProductComponent, { position: "bottom", panelClass: "offcanvas-full-screen" });
        offcanvasRef.componentInstance.productId = product.id;
        offcanvasRef.componentInstance.selectedItemId = product.addedAt;
      } else {
        const offcanvasRef = this.offcanvasService.open(BusinessMenuProductComponent, { position: "bottom", panelClass: "offcanvas-fit-content" });
        offcanvasRef.componentInstance.productId = product.id;
        offcanvasRef.componentInstance.selectedItemId = product.addedAt;
      }
    }
  }
}
