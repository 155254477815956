@if(business){

<div id="poweredByDineasBanner" class=" text-primary">
  <p class="mb-0"><span translate="landing-page.powered-by"></span> <b class="brand-font"><span>D</span><span>ineas</span></b> </p>
</div>

<header class=" container-md my-4">
  <div class="d-flex w-100 ">
    @if(business.logo){
    <div class="" [class.pe-3]="languageDir === 'ltr'" [class.ps-3]="languageDir === 'rtl'">
      <div class="business-logo  m-auto ">
        <div class="img-fluid logo-display" [lazyLoad]="business.logo.url || ''"></div>
      </div>
    </div>

    }
    <div class="my-auto">
      <h2 class="mb-1">{{ businessName }}</h2>
      <p class="mb-0 text-muted caption " [class.text-truncate-3]="!businessDescriptionReadMore">{{businessTranslated?.description || business.description }}</p>
      <button type="button" [hidden]="!businessDescriptionIsLong" class="btn text-muted caption p-0 pt-1" (click)="showReadMore()"
        [translate]="businessDescriptionReadMore ?'menu-category.show-less': 'menu-category.read-more'">
      </button>
    </div>
  </div>
</header>

<hr />

<!-- @else {
<nav class="navbar my-3">
  <div class="container-md container-md ">
    <a class="navbar-brand mx-auto">{{businessTranslated?.name || business.name }}</a>
  </div>
</nav>
} -->

@if (activeDeals ) {
@if(activeDeals.length){
<section class="container-md my-3">
  @for (deal of activeDeals ; track deal.id;){
  <div class="card border-primary card-deal">
    <div class="row g-0">
      <div class="col-auto p-4">
        <div class="image-ratio-1" [style.width]="'72px'">
          <div class="img-fluid " [lazyLoad]="'assets/images/icons/champagne-emoji.png'" [defaultImage]="'assets/images/icons/champagne-emoji.png'"></div>
        </div>
      </div>
      <div class="col">
        <div class="card-body">
          <h3 class="card-title">{{deal.name}}</h3>
          <p class="card-text small">{{deal.description}}</p>
        </div>
      </div>
    </div>
  </div>
  }
</section>
}
}

@if(!activeServices.length){
<section id="businesClose" class=" container-md my-5 text-center">
  <h2 class="mt-5" translate="menu.close-title"></h2>
  <p class="my-3" translate="menu.close-sub-title"></p>
  <p translate="menu.close-tips">Please check back during our regular business hours.</p>
</section>
}@else {

@if(activePushSells){
@for (pushSell of activePushSells ; track pushSell.id;){
@if(pushSell.products && pushSell.products.length ){
<section class="container-md  my-5 ">
  <h3 class="d-flex">
    <app-icon-svg class="text-primary" size="md" iconName="star.fill"></app-icon-svg>
    <span class="my-auto mx-2">
      {{pushSell.name}}
    </span>
  </h3>
  <app-vertical-product-card-section [currency]="business.currency" [products]="pushSell.products"></app-vertical-product-card-section>
</section>
}
}
}

<section #businessDescription class="container-md container-md my-5" [hidden]="!pageLoading && !categories.food.length">
  <h3 translate="menu-category.menu-type-foods"> </h3>
  <div class="row  g-3">
    @if(pageLoading){
    @for (item of [1,2,3,4]; track item){
    <div class="col-4 col-sm-3 ">
      <app-category-menu-card-placeholder></app-category-menu-card-placeholder>
    </div> }
    }@else {
    @for (category of categories.food; track category.id;){
    <div class="col-4 col-sm-3">
      <app-category-menu-card [category]="category"></app-category-menu-card>
    </div> }
    }

  </div>
</section>

<section #businessDescription class="container-md my-5" [hidden]="!pageLoading && !categories.drink.length">
  <h3 translate="menu-category.menu-type-drinks"> </h3>
  <div class="row g-3">
    @if(pageLoading){
    @for (item of [1,2,3,4]; track item){
    <div class="col-4  col-sm-3">
      <app-category-menu-card-placeholder></app-category-menu-card-placeholder>

    </div> }
    }@else {
    @for (category of categories.drink; track category.id;){
    <div class="col-4  col-sm-3">
      <app-category-menu-card [category]="category"></app-category-menu-card>
    </div> }
    }
  </div>
</section>

<section #businessDescription class="container-md my-4" [hidden]="!pageLoading && !categories.desert.length">
  <h3 translate="menu-category.menu-type-deserts"> </h3>
  <div class="row  g-3">
    @if(pageLoading){
    @for (item of [1,2,3,4]; track item){
    <div class="col-4  col-sm-3">
      <app-category-menu-card-placeholder></app-category-menu-card-placeholder>

    </div> }
    }@else {
    @for (category of categories.desert; track category.id;){
    <div class="col-4  col-sm-3">
      <app-category-menu-card [category]="category"></app-category-menu-card>
    </div> }
    }

  </div>
</section>
}

<section class="container-md my-5 text-center">
  <!-- <h4> <span translate="landing-page.powered-by"></span> <b class="brand-font"><span>D</span><span>ineas</span></b> </h4> -->
  <p class="caption text-muted" translate="menu.powered-by-dineas-sub-title"></p>
</section>
}