import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, doc, documentId, getDoc, getDocs, orderBy, query, where } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { Router } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { Business, BusinessTag, CategoryMenu, Checkout, CollectionName, Product, ProductOptionGroup, ProductType, UserPreview } from "../_global/_interfaces";

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  private functions: Functions = inject(Functions);
  private firestore: Firestore = inject(Firestore);
  private router: Router = inject(Router);

  constructor() {}

  static getSelectedBusinessId(): string {
    const businessId = localStorage.getItem("@selectedBusinessId");
    if (!businessId) {
      throw new Error("No business selected");
    }
    return businessId;
  }

  async listCategories(serviceIds: string[]): Promise<CategoryMenu[]> {
    // note: to use Observable<User[]> here we have to set all User properties as optional with ?
    if (!serviceIds.length) return [];
    const whereList = [where("isActive", "==", true)];
    if (serviceIds.length) {
      whereList.push(where("serviceIds", "array-contains-any", serviceIds));
    }
    const businessId = BusinessService.getSelectedBusinessId();
    const docsRef = collection(this.firestore, CollectionName.businesses, businessId, CollectionName.categories);
    try {
      const q = query(docsRef, ...whereList);
      const docs = await getDocs(q);
      return docs.docs.map((doc) => {
        const data = doc.data() as CategoryMenu;
        data.id = doc.id;
        return data;
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getCategory(id: string): Promise<CategoryMenu | undefined> {
    const businessId = BusinessService.getSelectedBusinessId();
    const docRef = doc(this.firestore, CollectionName.businesses, businessId, CollectionName.categories, id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      const data = snapshot.data() as CategoryMenu;
      data.id = snapshot.id;
      return data;
    } else {
      return undefined;
    }
  }

  async getCategoriesByType(type: ProductType): Promise<CategoryMenu[]> {
    const businessId = BusinessService.getSelectedBusinessId();
    const docsRef = collection(this.firestore, CollectionName.businesses, businessId, CollectionName.categories);
    const q = query(docsRef, where("type", "==", type), orderBy("name"));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as CategoryMenu;
      data.id = doc.id;
      return data;
    });
  }

  async callWaiter(checkout?: Checkout): Promise<void> {
    const businessId = BusinessService.getSelectedBusinessId();
    // get tagId and customer from localStorage
    const callable = httpsCallable<
      {
        businessId: string;
        customer?: UserPreview;
        tagId?: string;
      },
      void
    >(this.functions, "callWaiter");
    return (await callable({ businessId: businessId, customer: checkout?.customer, tagId: checkout?.tag?.id })).data;
  }

  // getCategoryProducts(categoryId: string, activeServices: ServiceMenu[]): Observable<Product[]> {
  //   // need observable to real time update
  //   const docsRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.products);

  //   const whereList = [where("categoryIds", "array-contains", categoryId), where("isActive", "==", true)];
  //   // if (serviceIds.length) {
  //   //   // the searchByName is a string with multiple words, maximum 29 words
  //   //   // split the searchByName string into an array of words
  //   //   whereList.push(where("keyWords", "array-contains-any", searchByNameArray));
  //   // }
  //   const q = query(docsRef, ...whereList);

  //   const data = collectionData(q, {
  //     idField: "id",
  //   });
  //   return data as Observable<Product[]>;
  // }

  // async getActiveServicesMenu(): Promise<ServiceMenu[]> {
  //   // get current day example monday, tuesday, etc
  //   const currentDay = new Date().toLocaleString("en-US", { weekday: "long" }).toLowerCase() as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  //   // get the current hours and minutes
  //   const currentHour = new Date().getHours();
  //   const currentMinute = new Date().getMinutes();
  //   // the  get all the services that has the current day in the days as true
  //   const docsRefServices = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessServices);
  //   const qServices = query(docsRefServices, where(`${currentDay}.active`, "==", true));
  //   const docsServices = await getDocs(qServices);
  //   console.log(docsServices.docs.length, this.businessId);
  //   // and filter based on the start and end time
  //   return docsServices.docs
  //     .map((doc) => {
  //       const data = doc.data() as ServiceMenu;
  //       data.id = doc.id;

  //       return data;
  //     })
  //     .filter((service) => {
  //       const currentDayService = service[currentDay];
  //       const onHour = currentDayService.on?.hour || 0;
  //       const onMinute = currentDayService.on?.minute || 0;
  //       let offHour = currentDayService.off?.hour || 0;
  //       const offMinute = currentDayService.off?.minute || 0;

  //       if (onHour > offHour) {
  //         offHour += 24; // in next day add 24h
  //       }

  //       if (currentHour === onHour) {
  //         return onMinute <= currentMinute;
  //       } else if (currentHour === offHour) {
  //         return offMinute >= currentMinute;
  //       }
  //       return onHour <= currentHour && offHour >= currentHour;
  //     });
  // }

  async getById(businessId: string): Promise<Business | undefined> {
    const docRef = doc(this.firestore, CollectionName.businesses, businessId);
    const snapshot = await getDoc<Business, Business>(docRef);
    if (snapshot.exists()) {
      const data = snapshot.data();
      data.id = snapshot.id;
      return data;
    } else {
      return undefined;
    }
  }

  async getTagById(tagId: string): Promise<BusinessTag | undefined> {
    const docRef = doc(this.firestore, CollectionName.businessTag, tagId);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      const data = snapshot.data() as BusinessTag;
      data.id = snapshot.id;
      return data;
    } else {
      return undefined;
    }
  }

  getProductOptionGroup(product: Product): Observable<ProductOptionGroup[]> {
    const businessId = BusinessService.getSelectedBusinessId();
    // need observable to real time update
    const listIds = product.optionGroupIds || [];
    if (!listIds.length) {
      return of([]);
    }

    const docsRef = collection(this.firestore, CollectionName.businesses, businessId, CollectionName.businessOptionGroups);
    const q = query(docsRef, where(documentId(), "in", listIds));

    const data = collectionData(q, {
      idField: "id",
    });
    return data as Observable<ProductOptionGroup[]>;
  }

  async get(data: { id?: string; domain?: string; subdomain?: string }): Promise<Business> {
    if (!data.id && !data.domain && !data.subdomain) {
      throw new Error("Parameters are missing!");
    }
    const callable = httpsCallable<
      {
        id?: string;
        domain?: string;
        subdomain?: string;
      },
      Business
    >(this.functions, "getBusinessWithBranding");
    return (await callable(data)).data;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error, operation, result); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class CurrentBusinessService {
  constructor() {}

  private subject = new Subject<Business>();

  onSelectBusiness(business: Business): void {
    this.subject.next(business);
  }

  getBusiness(): Observable<Business> {
    return this.subject.asObservable();
  }
}
