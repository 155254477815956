import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef, inject } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBell, faClock, faCoins, faGlobe, faMapMarkerAlt, faPhone, faPlay, faRecycle, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbCarouselModule, NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { AlertComponent } from "../_component/alert/alert.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import { Business, Checkout, User } from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../_global/_services/app.service";
import { Alert } from "../_interfaces";
import { AppService } from "../_services/app.service";
import { BusinessService } from "../_services/business.service";
import { AuthenticationComponent } from "../authentication/authentication.component";

@Component({
  selector: "app-landing-page",
  standalone: true,
  imports: [AlertComponent, FontAwesomeModule, CommonModule, NgbCarouselModule, LazyLoadImageModule, RouterModule, TranslateModule, SvgIconComponent],
  templateUrl: "./landing-page.component.html",
  styleUrl: "./landing-page.component.scss",
})
export class LandingPageComponent implements OnInit, OnDestroy {
  alertGeneralLog: Alert = { type: "info" };
  alertMessage: Alert = { type: "info" };

  isLoading = false;
  userEmail?: string;
  faMapMarker = faMapMarkerAlt;
  faBell = faBell;
  faStopwatch = faClock;
  faCoins = faCoins;
  faTicketAlt = faTicketAlt;
  faRecycle = faRecycle;
  faPlay = faPlay;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faWhatsapp = faWhatsapp;
  faPhone = faPhone;
  faGlobe = faGlobe;
  user?: User;
  subscription: Subscription;
  subscriptionBusiness: Subscription;
  business?: Business;
  businessTranslated?: Business;

  svgPreviewMobile;
  svgLogoNameSlogan;
  svgPreviewApp;
  checkout: Checkout | undefined;

  private modalService = inject(NgbModal);
  private appService = inject(AppService);
  languageCode: LangCode;
  languageDir = "ltr";
  private businessService = inject(BusinessService);

  private offcanvasService = inject(NgbOffcanvas);

  get directionUrl() {
    const coordinates = this.business?.address?.coordinates;

    return `https://www.google.com/maps/dir/?api=1&destination=${coordinates?.latitude},${coordinates?.longitude}`;
  }

  constructor(private router: Router) {
    this.languageCode = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(this.languageCode) ? "rtl" : "ltr";
    this.svgPreviewMobile = this.appService.getSvg("preview-phones");
    this.svgLogoNameSlogan = this.appService.getSvg("logo-name-slogan");
    this.svgPreviewApp = this.appService.getSvg("preview-app");
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.user = user;
    this.checkout = checkout;
    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });

    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
      if (!this.business || !this.business?.id) {
        // redirect to scan business code
        this.router.navigate(["/"]);
        return;
      }
    });
    if (!this.business || !this.business?.id) {
      // redirect to scan business code
      this.router.navigate(["/"]);
      return;
    }

    this.businessTranslated = business?.traduction ? business?.traduction[this.languageCode] : undefined;
  }

  ngOnInit() {}

  open(content: any) {
    this.modalService.open(content, { size: "xl" });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
  }

  openSignup(params: { email?: string; show?: string; alertMessage?: string; refreshValidationLink?: boolean }): void {
    const modalRef = this.modalService.open(AuthenticationComponent, {
      size: "md",
      modalDialogClass: "sign-in-modal",
    });
    modalRef.componentInstance.params = params;
  }

  openOffcanvas(content: TemplateRef<any>, position: "top" | "bottom" | "start" | "end" = "bottom", panelClass: string = "offcanvas-fit-content offcanvas-after-nav") {
    this.offcanvasService.open(content, {
      position,
      panelClass,
      backdropClass: "offcanvas-backdrop-after-nav",
    });
  }

  async onCallWaiter() {
    this.isLoading = true;

    try {
      await this.businessService.callWaiter(this.checkout);
      this.offcanvasService.dismiss("called waiter");
    } catch (error) {
      console.error("Error in onCallWaiter:", error);
    } finally {
      this.isLoading = false;
    }
  }
}
