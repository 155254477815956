import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { Business } from "../_global/_interfaces";
import { AppInitializerService } from "../_services/app.initializer.service";
import { AppService } from "../_services/app.service";
@Component({
  selector: "app-scan-code",
  standalone: true,
  imports: [TranslateModule, ZXingScannerModule],
  templateUrl: "./scan-code.component.html",
  styleUrl: "./scan-code.component.scss",
})
export class ScanCodeComponent implements OnDestroy, AfterViewInit {
  // @ViewChild("videoElement") videoElement!: ElementRef;
  allowedFormats = [BarcodeFormat.QR_CODE];
  permission: boolean = false;
  isModal: boolean = false;

  business?: Business;
  subscriptionBusiness: Subscription;
  isLoaded: boolean = false;

  constructor(
    private appInitializerService: AppInitializerService,
    private appService: AppService,
    private router: Router,
  ) {
    // localStorage.removeItem("@selectedBusinessId");
    // localStorage.removeItem("@business");
    // localStorage.removeItem("@mustBeOverAge");
    // localStorage.removeItem("@tagId");

    const { business, subscriptionBusiness } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
      if (this.business && this.business?.id && !this.isModal) {
        this.router.navigate(["/menu"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionBusiness.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.router?.url?.toString().startsWith("/cart")) {
        this.isModal = true;
      } else if (this.business && this.business?.id) {
        this.router.navigate(["/menu"]);
      }
      this.isLoaded = true;
    }, 0);
  }

  async scanSuccess(code: string): Promise<void> {
    const queryParamsString = code.split("?")[1];
    if (code.startsWith(environment.menuUrl) && queryParamsString) {
      const queryParams = this.appInitializerService.getQueryParams(code);
      const businessId = queryParams["b"];
      // const tagId = queryParams["t"];
      if (businessId) {
        //  get the business
        await this.appInitializerService.checkBusiness();
        this.router.navigate(["/menu"]);
      }
    }
  }

  permissionResponse(permission: boolean): void {
    this.permission = permission;
  }
}
