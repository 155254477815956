import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faStar, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Product } from "../../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../../../_global/_services/app.service";
import { PricingDisplayPipe } from "../../../_pipes/pricing-display/pricing-display.pipe";
import { NotFoundComponent } from "../../../not-found/not-found.component";
import { BusinessMenuProductComponent } from "../../../product/product.component";

@Component({
  selector: "app-product-card",
  standalone: true,
  imports: [LazyLoadImageModule, FontAwesomeModule, PricingDisplayPipe, RouterModule, TranslateModule],
  templateUrl: "./card.component.html",
  styleUrl: "./card.component.scss",
  providers: [CurrencyPipe], // need to use PricingDisplayPipe
})
export class ProductCardComponent implements OnInit {
  @Input() product!: Product;
  @Input() currency?: string;
  @Input() isVertical?: boolean;
  @Input() isCard?: boolean;
  @Input() categoryId!: string;
  @Output() onEdit: EventEmitter<string> = new EventEmitter<string>();
  faStar = faStar;
  faThumbsUp = faThumbsUp;
  imageUrl?: string;
  businessId: string | null = null;
  languageCode: LangCode = DEFAULT_LANG_CODE;
  languageDir: "rtl" | "ltr" = "ltr";
  dominantColor = "#ffffff"; // Default background color
  isImageLandscape: boolean = false;
  private offcanvasService = inject(NgbOffcanvas);

  get productTraductionDescription() {
    const t = this.product?.traduction ? this.product?.traduction[this.languageCode] : undefined;
    return t?.description || this.product.description;
  }

  get isSoldOut(): boolean {
    return new Date().valueOf() <= (this.product?.soldOutUntil || 0);
  }

  get productTraductionName() {
    const t = this.product?.traduction ? this.product?.traduction[this.languageCode] : undefined;
    return t?.name || this.product.name;
  }

  constructor() {
    this.businessId = localStorage.getItem("@selectedBusinessId");
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageCode = temLang;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
  }
  ngOnInit(): void {
    if (this.product?.images?.length) {
      this.imageUrl = this.product.images[0].url;
      const img = new Image();
      img.src = this.imageUrl;
      img.onload = () => {
        this.isImageLandscape = img.width > img.height;
      };
    }
  }

  openOffcanvas() {
    // if product not valid show a 404 product modal
    if (!this.product || !this.product.variants?.length || !this.product.id) {
      //  || this.product.isCombo
      const offcanvasRef = this.offcanvasService.open(NotFoundComponent, { position: "bottom", panelClass: "offcanvas-50" });
      offcanvasRef.componentInstance.productId = this.product.id;
    } else if (this.product.images?.length && (this.product.optionGroupIds.length || this.product.variants.length > 1)) {
      const offcanvasRef = this.offcanvasService.open(BusinessMenuProductComponent, { position: "bottom", panelClass: "offcanvas-full-screen" });
      offcanvasRef.componentInstance.productId = this.product.id;
    } else {
      const offcanvasRef = this.offcanvasService.open(BusinessMenuProductComponent, { position: "bottom", panelClass: "offcanvas-fit-content" });
      offcanvasRef.componentInstance.productId = this.product.id;
    }
  }
}
