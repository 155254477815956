import { CurrencyPipe, getCurrencySymbol, JsonPipe, PercentPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { NotificationRequestComponent } from "../../_component/notification-request/notification.component";
import { OrderCardComponent } from "../../_component/order/card/card.component";
import { ProductItemCardComponent } from "../../_component/product/item-card/item-card.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { AvailableDineasCurrency, Checkout, Payment, PaymentStatus } from "../../_global/_interfaces";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { OrderService } from "../../_services/order.service";

@Component({
  selector: "app-my-payment-form",
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    JsonPipe,
    ProductItemCardComponent,
    ReactiveFormsModule,
    CurrencyPipe,
    AlertComponent,
    OrderCardComponent,
    TranslateModule,
    PercentPipe,
    NotificationRequestComponent,
    SvgIconComponent,
  ],
  templateUrl: "./form.component.html",
  styleUrl: "./form.component.scss",
})
export class MyPaymentFormFormComponent implements OnInit, OnDestroy {
  isLoading = false;
  // paymentForm: FormGroup = this.formBuilder.group({
  //   participationAmount: [0, Validators.required],
  //   participationTip: [0, Validators.required],
  // });
  @Input() orderId!: string;
  @Input() payment!: Payment;
  @Input() currency?: AvailableDineasCurrency;

  amountPadType: "amount" | "tip" | null = null;
  amountPad = "0";

  get currencySymbol(): string {
    return getCurrencySymbol(this.currency || "USD", "narrow");
  }

  checkout?: Checkout;
  subscriptionCheckout: Subscription;

  private appService = inject(AppService);
  private orderService = inject(OrderService);
  alertPayment: Alert = { type: "info" };

  participationAmount = 0;
  participationTip = 0;

  totalRest = 0;

  get canUpdatePayment(): boolean {

    return this.payment.id && this.payment.status && [PaymentStatus.initialised].includes(this.payment.status) ? true : false;

  }
  get amountValueUpdated(): boolean {

    return this.payment.participationAmount !== this.participationAmount || this.payment.participationTip !== this.participationTip;

  }
  constructor(private formBuilder: FormBuilder) {
    const { checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.checkout = checkout;

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      this.totalRest = (this.checkout?.total?.due || 0) + (this.payment.participationAmount || 0);
    });
  }

  ngOnInit(): void {
    this.participationAmount = this.payment.participationAmount || 0;
    this.participationTip = this.payment.participationTip || 0;
    this.totalRest = (this.checkout?.total?.due || 0) + (this.payment.participationAmount || 0);
  }
  ngOnDestroy(): void {
    this.subscriptionCheckout.unsubscribe();
  }

  updatePayment() {
    const participationAmount = this.participationAmount;
    const participationTip = this.participationTip;
    const totalToCharge = participationAmount + participationTip;
    const minChargePerCurrency: { [key in AvailableDineasCurrency]: number } = {
      [AvailableDineasCurrency.usd]: 5,
      [AvailableDineasCurrency.eur]: 5,
      [AvailableDineasCurrency.ils]: 15,
      [AvailableDineasCurrency.gbp]: 5,
      [AvailableDineasCurrency.aud]: 10,
    };
    const minCharge = minChargePerCurrency[this.currency || AvailableDineasCurrency.ils];
    if (!totalToCharge || totalToCharge < minCharge) {
      this.alertPayment = { type: "danger", message: `The minimum amount is ${minCharge} ${this.currencySymbol}` };
    } else if (totalToCharge > this.payment.capturedAmount) {
      this.alertPayment = { type: "danger", message: `You are limited to ${this.payment.capturedAmount} ${this.currencySymbol}` };
    } else if (this.payment.id) {
      this.isLoading = true;
      this.orderService
        .updateOrderPayment(this.orderId, this.payment.id, participationAmount, participationTip)
        .then(() => {
          // display confim message waiting for the access to be accepted
          this.alertPayment = { type: "success", message: `Amount updated, you will be charged ${participationAmount + participationTip} ${this.currencySymbol}` };
        })
        .catch((error: any) => {
          console.error(error);
          this.alertPayment = { type: "danger", message: error.message };
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
  selectAmountPadType(type: "amount" | "tip") {
    this.amountPad = "0";
    if (type === "amount") {
      this.amountPad = `${this.participationAmount}`;
    } else if (type === "tip") {
      this.amountPad = `${this.participationTip}`;
    }
    this.amountPadType = type;
    this.alertPayment = { type: "info" };
  }
  addAmountPad(val: string) {
    if (!val) {
      this.amountPad = `0`;
      return;
    } else if (val === ".") {
      if (!this.amountPad.includes(".")) {
        this.amountPad += val;
      }
      return;
    } else if (this.amountPad.split(".")[1]?.length > 2) {
      return;
    } else if (this.amountPad === "0") {
      this.amountPad = `${val}`;
    } else {
      this.amountPad += val;
    }

    const amount = parseFloat(this.amountPad);
    const due = (this.checkout?.total?.due || 0) + (this.payment.participationAmount || 0);
    const totalToPay = this.checkout?.total?.totalToPay || 0;

    if (!amount || amount < 0) {
      this.amountPad = "0";
    } else if (this.amountPadType === "amount" && amount && amount > due) {
      const value = parseFloat(val);
      this.amountPad = value < due ? `${val}` : `${due}`;
    } else if (this.amountPadType === "tip" && amount && amount > totalToPay) {
      const value = parseFloat(val);
      this.amountPad = value < due ? `${val}` : `${totalToPay}`;
    }

    if (this.amountPadType === "amount" && amount) {
    } else if (this.amountPadType === "tip") {
      this.participationTip = amount || 0;
    }
  }
  validPadAmount() {
    const amount = parseFloat(this.amountPad);
    if (this.amountPadType === "amount" && amount) {
      this.participationAmount = amount;
    } else if (this.amountPadType === "tip") {
      this.participationTip = amount || 0;
    }
    this.amountPadType = null;
  }
  validPadFullAmount() {
    this.participationAmount = this.totalRest;
    this.amountPadType = null;
  }
}
