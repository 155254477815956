import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Messaging } from "@angular/fire/messaging";
import { SwPush } from "@angular/service-worker";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { NotificationDineas, User } from "../../_global/_interfaces";
import { AppService } from "../../_services/app.service";
import { FcmService } from "../../_services/fcm.service";
import { UserService } from "../../_services/user.service";

interface NotificationAlert {
  type: string;
  title: string;
  body: string;
  link?: string;
  id?: string;
}

interface NotificationEvent {
  fcmMessageId: string;
  from: string;
  notification: {
    body: string;
    click_action: string;
    title: string;
  };
  priority: string;
}

@Component({
  selector: "app-notification-push",
  standalone: true,
  imports: [TranslateModule, NgbAlertModule],
  templateUrl: "./index.component.html",
  styleUrl: "./index.component.scss",
})
export class NotificationPushComponent implements OnDestroy , OnInit{
  fcmService = inject(FcmService);
  private messaging = inject(Messaging);
  private appService = inject(AppService);
  private userService = inject(UserService);

  notifications: NotificationAlert[] = [];
  get isChrome() {
    const userAgent = navigator.userAgent;
    return userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR");
  }

  subscription: Subscription;
  subscriptionCheckout: Subscription;

  private targetSubject = new BehaviorSubject<{ id?: string; email?: string }>({ id: undefined, email: undefined });
  target$ = this.targetSubject.asObservable();

  user?: User;

  constructor(private swPush: SwPush) {
    const { user, userSubscription, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.user = user;
    this.targetSubject.next({ id: user?.id || checkout?.customer?.id, email: user?.email || checkout?.customer?.email });
    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      this.setTarget(user.id, user.email);
    });
    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.setTarget(this.user?.id || checkout?.customer?.id, this.user?.email || checkout?.customer?.email);
    });
  }

  ngOnInit() {
    this.subscribeToPush();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  setTarget(id?: string, email?: string) {
    const newVal = { id, email };
    if ((newVal.id && newVal.id !== this.targetSubject.value.id) || (newVal.email && newVal.email !== this.targetSubject.value.email)) {
      this.targetSubject.next(newVal);
    }
  }

  closeNotification(notif: NotificationAlert) {
    this.notifications.splice(this.notifications.indexOf(notif), 1);
    // mark as read
    const notification = {
      id: notif.id,
    } as NotificationDineas;
    this.userService.markAsRead([notification]).then(() => {
    });
  }

  // showNotification() {
  //   let notificationOptions = {
  //     body: "Some Notification information",
  //     icon: "/thumb.png",
  //   };
  //   let notif = new Notification("My New Notification", notificationOptions);

  //   notif.onclick = () => {
  //     console.info("Notification clicked");
  //   };
  // }


  subscribeToPush(): void {
    this.swPush.messages.subscribe((payload) => {
      const data: NotificationEvent = payload as NotificationEvent;
      if (data.notification?.body && data.notification?.title) {
        this.notifications.push({
          body: data.notification?.body,
          title: data.notification?.title,
          type: "info",
          link: data.notification?.click_action || undefined,
        });
      } else {
        console.info("Message received. ", payload);
      }
    });

    this.getData().subscribe(({ notifications }) => {
      let hasUnreadNotification = false;
      for (const notif of notifications) {
        if (!notif.read) {
          hasUnreadNotification = true;
          // check if the notification is already display
          const alreadyDisplayed = this.notifications.find((x) => x.id === notif.id);

          // add it to the notification to display
          if (notif.body && notif.title && !alreadyDisplayed) {
            this.notifications.push({
              body: notif.body,
              title: notif.title,
              type: "info",
              // link: notif?.click_action || undefined,
              id: notif.id,
            });
          }
        }
      }
      localStorage.setItem("@hasUnreadNotification", hasUnreadNotification ? "true" : "false");
    });
  }

  getData(): Observable<{ notifications: NotificationDineas[]; id: string | null; email: string | null }> {
    return this.targetSubject.pipe(
      switchMap(({ id, email }) => {
        const notifications$ = this.userService.listNotifications(10, id, email);
        return combineLatest([notifications$, of(id), of(email)]).pipe(
          map(([notifications, id, email]) => {
            return {
              notifications,
              id: id || null,
              email: email || null,
            };
          }),
          catchError((error) => {
            console.error("Error in combineLatest:", error);
            return of({ notifications: [], id: id || null, email: email || null });
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ notifications: [], id: null, email: null });
      }),
    );
  }
}
