import { CurrencyPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
// import { MatSliderModule } from "@angular/material/slider";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faStar, faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveOffcanvas, NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { catchError, combineLatest, map, Observable, of, Subscription, switchMap } from "rxjs";
import { VerticalProductSectionComponent } from "../_component/product/vertical-section/section.component";
import { SvgIconComponent } from "../_component/svg-icon/index.component";
import {
  Business,
  Checkout,
  OrderStatus,
  Pricing,
  Product,
  ProductItem,
  ProductOptionGroup,
  ProductPopulated,
  ProductType,
  ProductVariant,
  PushSells,
  PushSellsPopulated,
  SubProductPopulated,
} from "../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../_global/_services/app.service";
import { PricingDisplayPipe } from "../_pipes/pricing-display/pricing-display.pipe";
import { AppService } from "../_services/app.service";
import { CurrentCheckoutService } from "../_services/order.service";
import { ProductService } from "../_services/product.service";
import { PushSellService } from "../_services/push-sell.service";
import { OptionGroupsFormComponent } from "./option-group-form/form.component";
import { SubProductFormComponent } from "./sub-product-form/form.component";

@Component({
  selector: "app-business-menu-product",
  standalone: true,
  imports: [
    FormsModule,
    NgbCarouselModule,
    LazyLoadImageModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    // CategoryMenuCardComponent,
    // CategoryMenuCardPlaceholderComponent,
    // MatSliderModule,
    CurrencyPipe,
    PricingDisplayPipe,
    TranslateModule,
    SvgIconComponent,
    VerticalProductSectionComponent,
    OptionGroupsFormComponent,
    SubProductFormComponent,
  ],
  templateUrl: "./product.component.html",
  styleUrl: "./product.component.scss",
  providers: [CurrencyPipe], // need to use PricingDisplayPipe
})
export class BusinessMenuProductComponent implements OnInit, OnDestroy {
  @Input() productId!: string;
  @Input() selectedItemId?: number;
  isLoading = false;

  faTimes = faTimes;
  faStar = faStar;
  faThumbsUp = faThumbsUp;
  pageLoading = true;
  categoryId: string | null = null;

  product: ProductPopulated | null = null;

  // optionGroups: ProductOptionGroup[] = [];
  activeOffcanvas = inject(NgbActiveOffcanvas);
  productService = inject(ProductService);
  pushSellService = inject(PushSellService);

  selectedVariant: ProductVariant | null = null;
  // validation & form based on product variant and options
  productForm: FormGroup = this.formBuilder.group({
    selectedVariant: [0, Validators.required],
    quantitySelected: [1, [Validators.required, Validators.min(1)]],
    isActive: [true],
    addedAt: [Date.now().valueOf()],
    name: [""],
  });

  optionsForm: FormArray = this.formBuilder.array([]);

  selectedProductItem: ProductItem | null = null;
  updateSelectedProductItem: boolean = false;

  subProducts: SubProductPopulated[] = [];
  pushSell: PushSellsPopulated | undefined;
  get totalPrice(): number {
    let totalPrice = this.selectedVariant?.price || 0;
    // set the first priced base on the sleectd variant
    this.optionsForm.controls.forEach((optionFormGroup) => {
      const selectedOptions = optionFormGroup.get("options") as FormArray;
      selectedOptions.controls.forEach((variantFormGroup) => {
        if (variantFormGroup.get("isSelected")?.value) {
          const quantity = variantFormGroup.get("quantitySelected")?.value || 1;
          const price = variantFormGroup.get("price")?.value || 0;
          totalPrice += price * quantity;
        }
      });
    });
    return totalPrice * this.productForm.get("quantitySelected")?.value;
  }

  get isSoldOut(): boolean {
    return new Date().valueOf() <= (this.product?.soldOutUntil || 0);
  }

  onOptionsFormChange(optionsForm: FormArray) {
    this.optionsForm = optionsForm;
    this.updateSelectedProductItem = this.selectedProductItem ? !this.compareProductItem(this.selectedProductItem, this.parseFormToProductItem()) : false;
  }

  get productTraductionDescription() {
    const t = this.product?.traduction ? this.product?.traduction[this.languageCode] : undefined;
    return t?.description || this.product?.description;
  }

  get productTraductionName() {
    const t = this.product?.traduction ? this.product?.traduction[this.languageCode] : undefined;
    return t?.name || this.product?.name;
  }

  get totalPriceOrg(): number {
    let totalPrice = this.selectedVariant?.priceOrg || this.selectedVariant?.price || 0;
    // set the first priced base on the sleectd variant
    this.optionsForm.controls.forEach((optionFormGroup) => {
      const selectedOptions = optionFormGroup.get("options") as FormArray;
      selectedOptions.controls.forEach((variantFormGroup) => {
        if (variantFormGroup.get("isSelected")?.value) {
          const quantity = variantFormGroup.get("quantitySelected")?.value || 1;
          const price = variantFormGroup.get("priceOrg")?.value || variantFormGroup.get("price")?.value || 0;
          totalPrice += price * quantity;
        }
      });
    });
    return totalPrice * this.productForm.get("quantitySelected")?.value;
  }

  get pricings(): Pricing[] {
    let pricings: Pricing[] = this.product?.variants || [];
    return pricings;
  }

  business?: Business;
  subscriptionBusiness: Subscription;

  checkout?: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);
  private appService = inject(AppService);
  languageCode: LangCode;
  languageDir = "ltr";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    const { business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
    this.languageCode = temLang;

    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionBusiness.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  resetCheckout() {
    this.currentCheckoutService.resetCheckout(this.checkout);
  }

  async ngOnInit() {
    this.categoryId = this.route.snapshot.queryParamMap.get("category");
    try {
      this.getData(this.productId).subscribe(({ product, optionGroups, pushSells, subProducts }) => {
        // if no product show 404
        if (!product) {
          throw new Error("Product not found");
        }
        this.subProducts = subProducts || [];
        const productWithUserLanguage: ProductPopulated = { ...product, subProducts: (product.subProducts as SubProductPopulated[]) || [], optionGroups: [] };
        //update the variate and options with the translated values
        productWithUserLanguage.variants = productWithUserLanguage.variants.map((variant) => {
          const nameTranlated = variant.traduction && variant.traduction[this.languageCode] ? variant.traduction[this.languageCode]?.name : null;
          return {
            ...variant,
            name: variant.name,
            nameTranslated: nameTranlated || variant.name,
          };
        });
        // populate the option groups translated values
        for (const option of optionGroups) {
          if (!option.options) {
            continue;
          }
          const nameTranlated = option.traduction && option.traduction[this.languageCode] ? option.traduction[this.languageCode]?.name : null;
          productWithUserLanguage.optionGroups.push({
            ...option,
            name: option.name,
            nameTranslated: nameTranlated || option.name,
            options:
              option.options.map((variant) => {
                const optionVariantNameTranslated = variant.traduction && variant.traduction[this.languageCode] ? variant.traduction[this.languageCode]?.name : null;
                return {
                  ...variant,
                  name: variant.name || "",
                  nameTranslated: optionVariantNameTranslated || variant.name || "",
                };
              }) || [],
          });
        }

        this.selectedProductItem = this.checkout?.items?.find((item) => item.id === this.productId && item.addedAt === this.selectedItemId) || null;
        this.product = productWithUserLanguage;
        this.initializeForm(productWithUserLanguage, this.selectedProductItem);

        const productIdsFromCheckout: string[] = [];
        const productTypeFromCheckout: { [type in ProductType]: boolean } = { [ProductType.desert]: false, [ProductType.drink]: false, [ProductType.food]: false };
        for (const item of this.checkout?.items || []) {
          if (item.id) {
            productIdsFromCheckout.push(item.id);
          }
          if (item.type) {
            productTypeFromCheckout[item.type] = true;
          }
        }
        const validPushSells = pushSells.map((pushSell) => {
          const notInCartProductIds: string[] = pushSell.productIds?.filter((id) => !productIdsFromCheckout.includes(id)) || [];
          const existingProductCount: number = (pushSell.productIds?.length || 0) - notInCartProductIds.length;
          const isTypeInCheckout: boolean = productTypeFromCheckout[pushSell.type];
          return {
            id: pushSell.id,
            notInCartProductIds,
            existingProductCount,
            isTypeInCheckout,
            pushSell,
          };
        });
        // now sort the validPushSells based first on the isTypeInCheckout, then on the existingProductCount and finnaly on the existingProductCount.lemgth
        validPushSells.sort((a, b) => {
          if (a.isTypeInCheckout && !b.isTypeInCheckout) {
            return 1;
          }
          if (!a.isTypeInCheckout && b.isTypeInCheckout) {
            return -1;
          }
          if (a.existingProductCount > b.existingProductCount) {
            return -1;
          }
          if (a.existingProductCount < b.existingProductCount) {
            return 1;
          }
          if (a.notInCartProductIds.length > b.notInCartProductIds.length) {
            return -1;
          }
          if (a.notInCartProductIds.length < b.notInCartProductIds.length) {
            return 1;
          }
          return 0;
        });

        if (validPushSells.length) {
          const pushSell = validPushSells[0].pushSell;
          // remove the productIdsFromCheckout
          const pushSellProductIds = (pushSell.productIds || []).filter((id) => !productIdsFromCheckout.includes(id));
          this.productService.getFromIds(pushSellProductIds).subscribe((products) => {
            this.pushSell = { ...pushSell, products };
          });
        }
      });
    } catch (error) {
      console.error(error);
      // todo redirect to 404
    } finally {
      this.pageLoading = false;
    }
  }

  initializeForm(product: ProductPopulated, selectedProductItem: ProductItem | null) {
    const variant = selectedProductItem ? selectedProductItem.variant : product.variants[0];
    // const options = (selectedProductItem ? selectedProductItem.options : product.options) || [];
    // get variant index
    const variantIndex = product.variants.findIndex((v) => v.name === variant.name && v.price === variant.price) || 0;
    // init the product form

    const data = {
      selectedVariant: variantIndex,
      quantitySelected: selectedProductItem?.quantitySelected || 1,
      isActive: product.isActive || false,
      addedAt: selectedProductItem?.addedAt || Date.now().valueOf(),
      name: product.name,
      nameTranslated: this.productTraductionName,
    };
    this.productForm.patchValue(data);
    this.selectedVariant = variant;
  }

  changeVariant(selectedVariant: ProductVariant) {
    this.selectedVariant = selectedVariant;
    this.updateSelectedProductItem = this.selectedProductItem ? !this.compareProductItem(this.selectedProductItem, this.parseFormToProductItem()) : false;
  }

  onSelectedProductItem(item: ProductItem) {
    if (this.product) {
      this.selectedProductItem = item;
      this.initializeForm(this.product, item);
    }
  }

  onReduiceProductQuantity() {
    let currentValue = this.productForm.get("quantitySelected")?.value || 1;
    currentValue--;
    if (currentValue <= 0) {
      currentValue = 1;
    }
    this.productForm.get("quantitySelected")?.setValue(currentValue);
  }

  onAddProductQuantity() {
    let currentValue = this.productForm.get("quantitySelected")?.value || 1;
    currentValue++;
    this.productForm.get("quantitySelected")?.setValue(currentValue);
  }

  parseFormToProductItem(): ProductItem {
    if (!this.product || !this.product.variants) {
      throw new Error("Product is not defined");
    }
    const quantitySelected = this.productForm.value.quantitySelected || 1;
    const optionGroups = this.optionsForm.value;
    const selectedVariant = this.productForm.value.selectedVariant || 0;
    const price = this.totalPrice;
    const priceOrg = this.totalPriceOrg;
    return {
      id: this.productId,
      posId: this.product.posId,
      isActive: this.productForm.value.isActive,
      name: this.productForm.value.name,
      nameTranslated: this.productTraductionName,
      type: this.product.type,
      optionGroups,
      variant: { ...this.product.variants[selectedVariant], id: selectedVariant },
      price,
      priceOrg,
      image: this.product.images ? this.product.images[0] : undefined,
      quantitySelected,
      addedAt: this.productForm.value.addedAt || Date.now().valueOf(),

      // extra fields
      isCombo: this.product.isCombo || false,
      categoryIds: this.product.categoryIds || [],
      status: OrderStatus.pending,
    };
  }

  displaySelectedOptions(productItem: ProductItem) {
    const selectedOptions = productItem.optionGroups.map((option) => {
      const selectedVariants = option.options?.filter((variant) => (variant.quantitySelected || 0) > 0) || [];
      return `${selectedVariants.map((variant) => `${variant.quantitySelected} x ${variant.name}`).join(", ")}`;
    });
    return selectedOptions.join(", ");
  }

  async addProductToCart() {
    if (this.business) {
      const productToCart: ProductItem = this.parseFormToProductItem();
      productToCart.id = this.productId;
      this.currentCheckoutService.addItem(this.checkout, this.business, productToCart);
    }
    this.activeOffcanvas.dismiss("product added");
  }
  updateProductToCart() {
    if (this.business) {
      const productToCart: ProductItem = this.parseFormToProductItem();
      productToCart.id = this.productId;

      this.currentCheckoutService.updateItem(this.checkout, this.business, productToCart);
    }
    this.activeOffcanvas.dismiss("product updated");
  }

  removeProductFromCart() {
    if (this.selectedProductItem) {
      this.selectedProductItem.id = this.productId;
      this.currentCheckoutService.deleteItem(this.checkout, { ...this.selectedProductItem });
    }

    this.activeOffcanvas.dismiss("product removed");
  }

  compareProductItem(item1: ProductItem, item2: ProductItem) {
    // Check if the main properties are different
    if (item1.quantitySelected !== item2.quantitySelected || item1.variant.price !== item2.variant.price || item1.variant.name !== item2.variant.name) {
      return false;
    }

    // Check if the options array lengths are different
    if (item1.optionGroups.length !== item2.optionGroups.length) {
      return false;
    }

    // Check if each option is the same
    for (let i = 0; i < item1.optionGroups.length; i++) {
      const option1 = item1.optionGroups[i];
      const option2 = item2.optionGroups[i];

      // Check if the main properties of the option are different
      if (option1.name !== option2.name) {
        return false;
      }

      // Check if the options array lengths are different
      if ((option1.options?.length || 0) !== (option2.options?.length || 0)) {
        return false;
      } else if (option1.options?.length && option2.options?.length) {
        // Check if each sub-option is the same
        for (let j = 0; j < option1.options.length; j++) {
          const subOption1 = option1.options[j];
          const subOption2 = option2.options[j];
          // Check if the main properties of the sub-option are different
          if (subOption1.quantitySelected !== subOption2.quantitySelected || subOption1.price !== subOption2.price) {
            return false;
          }
        }
      }
    }

    // If all checks passed, return true
    return true;
  }

  getData(productId: string): Observable<{ product: Product | undefined; pushSells: PushSells[]; optionGroups: ProductOptionGroup[]; subProducts: SubProductPopulated[] }> {
    // add a triger that update categories.hasProduct to true when we update the product.categoryIds
    return this.productService.get(productId).pipe(
      switchMap((product) => {
        const optionGroups$ = product.optionGroupIds?.length ? this.productService.getOptionGroupsFromIds(product.optionGroupIds) : of([]);
        const pushSells$ = product.pushSellIds?.length ? this.pushSellService.getFromIds(product.pushSellIds) : of([]);
        const subProducts$ =
          product.isCombo && product.subProducts?.length
            ? combineLatest(
                product.subProducts.map((subProduct) =>
                  this.productService.getFromIds(subProduct.productIds).pipe(
                    switchMap((products) => {
                      const optionGroupsForSubProducts$ = combineLatest(products.map((p) => (p.optionGroupIds?.length ? this.productService.getOptionGroupsFromIds(p.optionGroupIds) : of([]))));

                      return optionGroupsForSubProducts$.pipe(
                        map((optionGroups) => {
                          const productsPopulated: ProductPopulated[] = products.map(
                            (product, index) =>
                              ({
                                ...product,
                                optionGroups: optionGroups[index],
                              }) as ProductPopulated,
                          );
                          return { ...subProduct, products: productsPopulated };
                        }),
                      );
                    }),
                  ),
                ),
              )
            : of([]);
        // console. if product is combo pull the sub products

        return combineLatest([optionGroups$, pushSells$, subProducts$]).pipe(
          map(([optionGroups, pushSells, subProducts]) => {
            return {
              product,
              pushSells,
              optionGroups,
              subProducts,
            };
          }),
          catchError((error) => {
            console.error("Error in forkJoin:", error);
            return of({ product, optionGroups: [], pushSells: [], subProducts: [] });
          }),
        );
      }),
      catchError((error) => {
        console.error("Error in getData:", error);
        return of({ product: undefined, optionGroups: [], pushSells: [], subProducts: [] });
      }),
    );
  }
}
