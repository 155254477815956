<div id="customerView" class="d-flex flex-column justify-content-center">
  <nav class="navbar my-3" (click)="activeOffcanvas.dismiss('Cross click')">
    <div class="container-md" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
      <div class="d-flex w-100 justify-content-between">
        <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="account.details.title"></span></h3>
      </div>
    </div>
  </nav>
  <hr class="mt-0" />

  <section class="container-md mb-4">

    <h6 class="mb-3" translate="account.details.sub-title"></h6>
    <form [formGroup]="customerForm">
      <div class=" my-3 form-floating">
        <input type="email" class="form-control" id="email" formControlName="email" [class.is-invalid]="customerForm.get('email')?.invalid" [placeholder]="'form.email-placeholder' | translate"
          required>
        <label class="col-form-label" for=" email" translate="form.email-label"></label>
        <div class="invalid-feedback" translate="form.filed-required"></div>
      </div>
      <div class=" my-3 form-floating">
        <input type="text" class="form-control" id="firstName" formControlName="firstName" [class.is-invalid]="customerForm.get('firstName')?.invalid"
          [placeholder]="'form.first-name-placeholder' | translate" required>
        <label class="col-form-label " for="firstName" translate="form.first-name-label"></label>
        <div class="invalid-feedback" translate="form.filed-required"></div>
      </div>
      <div class=" my-3 form-floating">
        <input type="text" class="form-control" id="lastName" formControlName="lastName" [placeholder]="'form.last-name-placeholder' | translate" required>
        <label class="col-form-label " for="lastName" translate="form.last-name-label"></label>
      </div>
      <div class="mb-3 ">
        <p class="form-text mb-1" translate="account.details.for-sms-notif"></p>

        <div class="input-group mb-3" formGroupName="phone">
          <span class="input-group-text">+</span>
          <div class="form-floating">
            <input type="text" [maxLength]="3" class="form-control" (change)="onPhoneChanges()" formControlName="prefix" id="phonePrefix"
              [placeholder]="'form.phone-country-code-placeholder' | translate" [class.is-invalid]="customerForm.get('phone.prefix')?.invalid">
            <label for="phonePrefix" translate="form.phone-country-code-label"></label>
          </div>
          <div class="form-floating" [class.is-invalid]="!isPhoneNumberValid" [class.is-valid]="isPhoneNumberValid">
            <input type="text" [maxLength]="10" class="form-control" (change)="onPhoneChanges()" formControlName="number" id="phoneNumber" [placeholder]="'form.phone-number-placeholder' | translate"
              [class.is-invalid]="customerForm.get('phone.number')?.invalid">
            <label for="phoneNumber" translate="form.phone-number-label"></label>
          </div>

          @if(!isPhoneNumberValid){
          <div class="invalid-feedback " translate="form.phone-invalid"></div>
          }

        </div>
      </div>

    </form>

  </section>

  <section class="container-md mb-4  mt-auto">
    <button type="button" class="btn btn-primary w-100" [disabled]="customerForm.invalid" (click)="saveChange()">
      @if(user && user.id){
      <span translate="account.details.save-change"></span>
      }@else {
      <span translate="account.details.continue-as-guest"></span>
      }
    </button>
    @if(!user || !user.id){
    <div class="position-relative hr-or">
      <hr />
      <p class="position-absolute m-0 text-center small text-muted"><span class="bg-white px-3" translate="account.details.or"></span></p>
    </div>
    <button type="button" class="btn btn-primary w-100" (click)="openOffcanvas('login')" translate="account.details.login-signup">
    </button>
    }

  </section>
</div>