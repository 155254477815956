<section id="splash" class="container-md d-flex flex-column justify-content-center align-items-center h-100 text-bg-primary">
  <div class="text-center">
    <!-- Apply the fade-in/fade-out animation to the SVG icon -->
    <div [@fadeInOut]="currentState">
      <app-icon-svg size="2xl" [iconName]="icons[currentIconIndex]"></app-icon-svg>
    </div>
    <p class="py-4">
      Hang tight!<br />
      We’re getting your menu ready...
    </p>
  </div>
</section>