import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter, withComponentInputBinding } from "@angular/router";

import { HttpClient, provideHttpClient } from "@angular/common/http";
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { SETTINGS as FIRESTORE_SETTINGS } from "@angular/fire/compat/firestore";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideServiceWorker } from "@angular/service-worker";
import { connectFunctionsEmulator } from "@firebase/functions";
import { Loader } from "@googlemaps/js-api-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
// import { Business } from "./_global/_interfaces";
// import { AppInitializerService } from "./_services/app.initializer.service";
import { routes } from "./app.routes";

// export function appInit(appInitializerService: AppInitializerService): () => Promise<Business> {
//   return () => appInitializerService.load();
// }

// required for AoT
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    provideFirestore(() => getFirestore()),
    // importProvidersFrom(provideFunctions(() => getFunctions())),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), "europe-west1");
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }
      return functions;
    }),
    provideStorage(() => getStorage()),
    { provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: "AIzaSyAcqhePcxAqWHJBYU2IS9D0vnLyXTrVLQo",
        libraries: ["places"],
      }),
    },
    provideAnimationsAsync(),
    // [
    //   AppInitializerService,
    //   {
    //     provide: APP_INITIALIZER,
    //     useFactory: appInit,
    //     multi: true,
    //     deps: [AppInitializerService],
    //   },
    // ],
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: "en-US",
        loader: {
          provide: TranslateLoader,
          deps: [HttpClient],
          useFactory: HttpLoaderFactory,
        },
      }),
    ),
    provideServiceWorker("ngsw-worker.js", {
      enabled: true, ///!isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
};
