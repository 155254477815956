import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, getDocs, query, where } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { CategoryMenu, CollectionName, ProductType } from "../_global/_interfaces";
import { BusinessService } from "./business.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  private firestore: Firestore = inject(Firestore);
  collectionRef;
  businessId: string;
  constructor() {
    this.businessId = BusinessService.getSelectedBusinessId();
    this.collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.categories);
  }

  async listCategories(): Promise<CategoryMenu[]> {
    const businessId = BusinessService.getSelectedBusinessId();

    const docsRef = collection(this.firestore, CollectionName.businesses, businessId, CollectionName.categories);
    const q = query(docsRef); // , where("businessId", "==", this.businessId)
    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as CategoryMenu;
      data.id = doc.id;
      return data;
    });
  }

  list({ serviceIds, type }: { serviceIds: string[]; type?: ProductType }): Observable<CategoryMenu[]> {
    const whereList = [where("isActive", "==", true)];
    if (serviceIds.length) {
      whereList.push(where("serviceIds", "array-contains-any", serviceIds));
    }
    if (type) {
      whereList.push(where("type", "==", type));
    }
    const q = query(this.collectionRef, ...whereList);
    const data = collectionData(q, {
      idField: "id",
    });
    return data as Observable<CategoryMenu[]>;
  }
}
