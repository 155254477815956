import { Component, inject, OnInit } from "@angular/core";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { User } from "../../_global/_interfaces";
import { FcmService } from "../../_services/fcm.service";

// interface NotificationAlert {
//   type: string;
//   title: string;
//   body: string;
//   link?: string;
//   id?: string;
// }

// interface NotificationEvent {
//   fcmMessageId: string;
//   from: string;
//   notification: {
//     body: string;
//     click_action: string;
//     title: string;
//   };
//   priority: string;
// }

@Component({
  selector: "app-notification-request",
  standalone: true,
  imports: [TranslateModule, NgbAlertModule],
  templateUrl: "./notification.component.html",
  styleUrl: "./notification.component.scss",
})
export class NotificationRequestComponent implements OnInit {
  fcmService = inject(FcmService);
  hideNotificationRequest = false; //localStorage.getItem("@hideNotificationRequest") === "true";

  get isChrome() {
    const userAgent = navigator.userAgent;
    return userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR");
  }

  private targetSubject = new BehaviorSubject<{ id?: string; email?: string }>({ id: undefined, email: undefined });
  target$ = this.targetSubject.asObservable();

  user?: User;

  constructor() {}

  ngOnInit(): void {
    if ((this.isChrome && this.fcmService.notificationPermissions === "default") || this.fcmService.notificationPermissions === "granted") {
      this.onRequestPermission();
    }
  }
  onHideNotificationRequest() {
    localStorage.setItem("@hideNotificationRequest", "true");
    this.hideNotificationRequest = true;
  }

  async onRequestPermission() {
    this.fcmService.requestPermission().finally(() => {
      // this.subscribeToPush();
    });
  }
}
