import { Component, OnDestroy, OnInit, inject } from "@angular/core";

import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbActiveOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { PaymentMethodComponent } from "../../_component/stripe/payment-method/payment-method.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Checkout, PaymentMethodPreview, PaymentType, User } from "../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../../_global/_services/app.service";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { CurrentBusinessService } from "../../_services/business.service";
import { CurrentCheckoutService } from "../../_services/order.service";
import { PaymentService } from "../../_services/payment.service";

export
@Component({
  selector: "app-account-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
  standalone: true,
  imports: [AlertComponent, FormsModule, FontAwesomeModule, ReactiveFormsModule, PaymentMethodComponent, TranslateModule, SvgIconComponent],
})
class AccountPaymentMethodComponent implements OnInit, OnDestroy {
  activeOffcanvas = inject(NgbActiveOffcanvas);
  user?: User;
  alertBilling: Alert = { type: "info" };
  isLoading = false;
  languageDir;
  PAYMENT_METHOD_GUEST_CARD = {
    brand: "Card",
    expMonth: 0,
    expYear: 0,
    last4: "",
    id: PaymentType.stripe,
    name: "Pay with credit card",
  };
  PAYMENT_METHOD_CASH = {
    brand: "Cash",
    expMonth: 0,
    expYear: 0,
    last4: "",
    id: PaymentType.businessManaged,
    name: "Pay directly with the business",
  };
  selectedPaymentMethodIndex: number = 0;
  paymentMethods: PaymentMethodPreview[] = [];
  editPaymentMethod = false;

  // validation & form based on product variant and options
  paymentMethodForm: FormGroup = this.formBuilder.group({
    selectedPaymentMethod: [0, Validators.required],
  });

  checkout?: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);

  business?: Business;
  subscriptionBusiness: Subscription;
  private currentBusinessService = inject(CurrentBusinessService);

  get selectedPaymentMethod(): PaymentMethodPreview | undefined {
    return this.paymentMethods[this.paymentMethodForm.value.selectedPaymentMethod || 0];
  }

  get isCheckout(): boolean {
    return this.router.url === "/checkout";
  }

  get paymentTypeAvailable() {
    // if we are in the account view show only saved payment methods
    if (this.isCheckout) {
      return {
        stripe: this.business?.isPayoutValidated || false,
        businessManaged: this.business?.branding?.paymentManagedByBusiness || false,
      };
    }
    return {
      stripe: true,
      businessManaged: false,
    };
  }
  private appService = inject(AppService);

  constructor(
    private router: Router,

    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private translate: TranslateService,
    // private cd: ChangeDetectorRef,
    // private loaderService: LoaderService,
  ) {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = checkout;
    this.user = user;

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
    });

    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    this.translate.get("stripe.payment-method.pay-with-cc").subscribe((name) => {
      this.PAYMENT_METHOD_GUEST_CARD.name = name;
    });

    this.translate.get("stripe.payment-method.pay-with-business").subscribe((name) => {
      this.PAYMENT_METHOD_CASH.name = name;
    });
    this.translate.get("stripe.payment-method.pay-with-business-brand").subscribe((brand) => {
      this.PAYMENT_METHOD_CASH.brand = brand;
    });
  }

  ngOnInit(): void {
    this.getPaymentMethods();
  }

  changePaymentMethod() {
    if (this.selectedPaymentMethod) {
      const checkout: Checkout = {
        ...this.checkout,
        paymentMethod: { ...this.selectedPaymentMethod },
        isTabClosed: this.checkout?.isTabClosed || false,
        isGroupTab: this.checkout?.isGroupTab || false,
      };
      checkout.paymentType = this.selectedPaymentMethod?.id === PaymentType.businessManaged ? PaymentType.businessManaged : this.selectedPaymentMethod.id ? PaymentType.stripe : undefined;
      this.currentCheckoutService.set(checkout, true);
      this.activeOffcanvas.dismiss("select pm");
    }
  }

  ngOnDestroy(): void {
    this.subscriptionCheckout.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
  }

  getPaymentMethods(): void {
    this.paymentMethods = [];
    // check if business payment is managed by the business

    if (this.paymentTypeAvailable.businessManaged) {
      this.paymentMethods.push(this.PAYMENT_METHOD_CASH);
      if (this.checkout?.paymentMethod?.id === PaymentType.businessManaged) {
        this.paymentMethodForm.patchValue({ selectedPaymentMethod: this.paymentMethods.length - 1 });
      }
    }

    if (this.paymentTypeAvailable.stripe && (!this.user || !this.user.id)) {
      // add card payment method
      this.paymentMethods.push(this.PAYMENT_METHOD_GUEST_CARD);
      if (this.checkout?.paymentMethod?.id === PaymentType.stripe) {
        this.paymentMethodForm.patchValue({ selectedPaymentMethod: this.paymentMethods.length - 1 });
      }
      return;
    }
    this.isLoading = true;

    this.paymentService
      .retrievePaymentMethod()
      .then((pms) => {
        // to to existing payment methods
        this.paymentMethods = [...this.paymentMethods, ...(pms || [])];

        this.editPaymentMethod = this.paymentMethods[0] ? false : true;
        if (this.checkout?.paymentMethod) {
          const index = this.paymentMethods.findIndex((pm) => pm.id === this.checkout?.paymentMethod?.id);
          this.paymentMethodForm.patchValue({ selectedPaymentMethod: index >= 0 ? index : 0 });
        }
      })
      .catch((e: any) => {
        this.alertBilling = { type: "danger", message: e.message };
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  // async updatePaymentMethod(paymentMethodId: string): Promise<void> {
  //   if (!this.business.id) {
  //     console.error("Van not switch subscription... business id is missing");
  //     return;
  //   }
  //   try {
  //     await this.paymentService.updatePaymentMethod(this.business.id, paymentMethodId);
  //     this.alertBilling = {
  //       type: "success",
  //       message: "The payment methode as been successfully updated. the update can take up to 24h",
  //     };

  //     this.business.hasUnPayedInvoice = false;
  //     this.business.needNewBillingMethode = false;
  //     this.switchView("account");
  //   } catch (e: any) {
  //     this.error = e.message;
  //     console.error("Something is wrong:", e.message);
  //     this.alertBilling = {
  //       type: "danger",
  //       message: e.message,
  //     };
  //     return;
  //   }
  // }

  // async handlePaymentThatRequiresCustomerAction(subscription: {
  //   status: string;
  //   paymentMethodId: string;
  //   pending_setup_intent: any;
  // }): Promise<{ status: string; paymentMethodId: string; pending_setup_intent: any }> {
  //   const setupIntent = subscription.pending_setup_intent;
  //   if (setupIntent && setupIntent.status === "requires_action") {
  //     const cardSetup = await this.paymentService.stripe.confirmCardSetup(setupIntent.client_secret, { payment_method: subscription.paymentMethodId });
  //     if (cardSetup.error) {
  //       throw cardSetup;
  //     } else if (cardSetup.setupIntent?.status === "succeeded") {
  //       // There's a risk of the customer closing the window before callback
  //       // execution. To handle this case, set up a webhook endpoint and
  //       // listen to setup_intent.succeeded.
  //       return subscription;
  //     }
  //   }
  //   // No customer action needed
  //   return subscription;
  // }
}
