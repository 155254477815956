import { Injectable, inject } from "@angular/core";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { environment } from "../../environments/environment";
import { BillingPlan, Checkout, PaymentMethodPreview } from "../_global/_interfaces";

type AccountLink = { isVerified: boolean; expires_at?: string; url?: string; current_deadline?: string; disabledReason?: string };
type CreateCustomAccountResult = { businessId: string; stripeCustomId: string };
type ApiResult = { success: boolean; data: any; message: string };
type SwitchSubscriptionResult = { status: string; paymentMethodId: string; pending_setup_intent: any };
type RetrieveUpcomingInvoiceResult = { amountDue: number; amountPaid: number; amountRemaining: number };
@Injectable({
  providedIn: "root",
})
export class PaymentService {
  stripe = Stripe(environment.stripePublishableKey);

  private functions: Functions = inject(Functions);

  constructor() {}

  async createCustomAccount(businessId: string, tokenAccount: string, countryCode: string, tokenPerson: string): Promise<CreateCustomAccountResult> {
    // await UserService.hasUserRight(businessId, this.afAuth, this.db);
    // todo do it with firebase rules
    const data: any = {
      businessId,
      tokenAccount,
      countryCode,
      tokenPerson,
    };
    const callable = httpsCallable<{ businessId: string; tokenAccount: string; countryCode: string; tokenPerson: string }, CreateCustomAccountResult>(this.functions, "createStripeCustomAccount");
    return (await callable(data)).data;
  }

  async cancelSubscription(businessId: string): Promise<ApiResult> {
    // await UserService.hasUserRight(businessId, this.afAuth, this.db);

    // todo do it with firebase rules
    const data: any = {
      businessId,
    };

    const callable = httpsCallable<{ businessId: string }, ApiResult>(this.functions, "cancelSubscription");
    return (await callable(data)).data;
  }

  async switchSubscription(businessId: string, plan: BillingPlan): Promise<SwitchSubscriptionResult> {
    // await UserService.hasUserRight(businessId, this.afAuth, this.db);
    // todo do it with firebase rules
    const data: any = {
      businessId,
      plan,
    };
    const callable = httpsCallable<{ businessId: string; plan: string }, SwitchSubscriptionResult>(this.functions, "switchSubscription");
    return (await callable(data)).data;
  }

  async retrieveUpcomingInvoice(businessId: string, plan: string): Promise<RetrieveUpcomingInvoiceResult> {
    // await UserService.hasUserRight(businessId, this.afAuth, this.db);
    // todo do it with firebase rules
    const data: any = {
      businessId,
      plan,
    };

    const callable = httpsCallable<{ businessId: string; plan: string }, RetrieveUpcomingInvoiceResult>(this.functions, "retrieveUpcomingInvoice");
    return (await callable(data)).data;
  }

  async retrievePaymentMethod(): Promise<PaymentMethodPreview[]> {
    const data = {
      source: "user",
    };

    const callable = httpsCallable<
      {
        source: string;
      },
      PaymentMethodPreview[]
    >(this.functions, "retrievePaymentMethod");
    return (await callable(data)).data;
  }

  async setupIntentPaymentMethod(): Promise<string> {
    const data = {
      source: "user",
    };

    const callable = httpsCallable<{ source: string }, string>(this.functions, "setupIntentPaymentMethod");
    return (await callable(data)).data;
  }

  async setPaymentIntentForPayment(checkout: Checkout): Promise<Checkout> {
    const callable = httpsCallable<Checkout, Checkout>(this.functions, "setPaymentIntent");
    return (await callable({ ...checkout, managedBy: "customer" })).data;
  }

  async retrievePaymentIntent(clientSecret: string) {
    return await this.stripe.retrievePaymentIntent(clientSecret);
  }
}
