import { CurrencyPipe, getCurrencySymbol, JsonPipe, PercentPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { OrderCardComponent } from "../../_component/order/card/card.component";
import { ProductItemCardComponent } from "../../_component/product/item-card/item-card.component";
import { PaymentComponent } from "../../_component/stripe/payment/payment.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Checkout, OrderType, PaymentMethodPreview, PaymentType, User } from "../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode } from "../../_global/_services/app.service";
import { Alert } from "../../_interfaces";
import { AppService } from "../../_services/app.service";
import { CurrentCheckoutService, OrderService } from "../../_services/order.service";
import { PaymentService } from "../../_services/payment.service";
import { AccountDetailsComponent } from "../../account/details/details.component";
import { AccountPaymentMethodComponent } from "../../account/payment-method/payment-method.component";
import { AuthenticationComponent } from "../../authentication/authentication.component";
import { OrderComponent } from "../../orders/details/details.component";
import { TipFormComponent } from "../tip-form/tip-form.component";

@Component({
  selector: "app-checkout",
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    JsonPipe,
    ProductItemCardComponent,
    ReactiveFormsModule,
    CurrencyPipe,
    AlertComponent,
    OrderCardComponent,
    TranslateModule,
    PercentPipe,
    SvgIconComponent,
    TipFormComponent,
  ],
  templateUrl: "./checkout.component.html",
  styleUrl: "./checkout.component.scss",
})
export class CheckoutComponent implements OnInit, OnDestroy {
  user?: User;
  subscription: Subscription;

  isLoading = false;
  cartForm: FormGroup = this.formBuilder.group({
    allergyInformation: ["", Validators.maxLength(1000)],
    hasAllergy: [false],
  });
  // tipForm: FormGroup = this.formBuilder.group({
  //   tipAmount: [0],
  // });

  PAYMENT_METHOD_GUEST_CARD = {
    brand: "Card",
    expMonth: 0,
    expYear: 0,
    last4: "",
    id: PaymentType.stripe,
    name: "Pay with credit card",
  };
  PAYMENT_METHOD_CASH = {
    brand: "Cash",
    expMonth: 0,
    expYear: 0,
    last4: "",
    id: PaymentType.businessManaged,
    name: "Pay directly with the business",
  };
  tipOptions = [0.1, 0.12, 0.15, 0.2];
  checkoutView: "payment-preview" | "order-confirmed" = "payment-preview";
  customTipFormat: "percent" | "amount" = "percent";
  showCustomTip = false;
  business?: Business;
  subscriptionBusiness: Subscription;

  checkout?: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);

  private offcanvasService = inject(NgbOffcanvas);
  alertCheckout: Alert = { type: "info" };
  private appService = inject(AppService);
  get isCustomerValid(): boolean {
    return this.checkout && this.checkout.customer && this.checkout.customer.email && this.checkout.customer.firstName ? true : false;
  }

  get disabledComments(): boolean {
    return this.business?.branding?.disabledComments || false;
  }

  get isTabMode(): boolean {
    return this.checkout?.tag?.type !== OrderType.delivery;
  }

  get currencySymbol(): string {
    return getCurrencySymbol(this.business?.currency || "USD", "narrow");
  }

  get currentPaymentStatus(): {
    paid: number;
    due: number;
    subtotal: number;
    discount: number;
    amountWithTipAndFee: number;
    tipAmount: number;
    tipPercentage: number;
    itemsTotal: number;
    itemsTotalOrg: number;
  } {
    return {
      subtotal: this.checkout?.total?.itemsTotalOrg || this.checkout?.total?.itemsTotal || 0,
      discount: (this.checkout?.total?.discountItemsAmount || 0) + (this.checkout?.total?.dealAmount || 0),
      amountWithTipAndFee: this.checkout?.total?.amountWithTipAndFee || 0,
      paid: this.checkout?.total?.paid || 0,
      due: this.checkout?.total?.due || 0,
      tipAmount: this.checkout?.total?.tipAmount || 0,
      tipPercentage: this.checkout?.total?.tipPercentage || 0,
      itemsTotal: this.checkout?.total?.itemsTotal || 0,
      itemsTotalOrg: this.checkout?.total?.itemsTotalOrg || 0,
    };
  }

  get selectPaymentMethod(): PaymentMethodPreview | undefined {
    return this.checkout?.paymentMethod;
  }

  get paymentTypeAvailable() {
    return {
      stripe: this.business?.isPayoutValidated || false,
      businessManaged: this.business?.branding?.paymentManagedByBusiness || false,
    };
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,

    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private translate: TranslateService,
  ) {
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;

    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.business = business;
    this.checkout = this.setDefaultPaymentMethod(checkout, business);
    this.user = user;

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
      this.currentCheckoutService.setCustomer(this.checkout, user);
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      this.setView(checkout, this.business);
    });

    this.setView(this.checkout, this.business);
    this.translate.get("stripe.payment-method.pay-with-business").subscribe((name) => {
      this.PAYMENT_METHOD_CASH.name = name;
    });
    this.translate.get("stripe.payment-method.pay-with-business-brand").subscribe((brand) => {
      this.PAYMENT_METHOD_CASH.brand = brand;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
    this.subscriptionCheckout.unsubscribe();
  }

  ngOnInit(): void {
    if (this.checkout?.orderId) {
      // todo go back to cart
      this.router.navigate(["/cart"]);
      return;
    }
    this.checkStatus();
  }
  switchCustomTipFormat() {
    this.customTipFormat = this.customTipFormat === "percent" ? "amount" : "percent";
  }

  async checkStatus() {
    const clientSecret = this.route.snapshot.queryParams["payment_intent_client_secret"];
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.paymentService.retrievePaymentIntent(clientSecret);

    if (!paymentIntent) {
      return;
    }
    switch (paymentIntent.status) {
      case "succeeded":
        // todo get the order id
        this.alertCheckout = { type: "success", message: "Payment succeeded!" };
        break;
      case "requires_capture":
        // todo get the order id
        this.alertCheckout = { type: "success", message: "Order requested!" };
        break;
      case "processing":
        this.alertCheckout = { type: "success", message: "Your payment is processing." };
        break;
      case "requires_payment_method":
        this.alertCheckout = { type: "danger", message: "Your payment was not successful, please try again." };
        break;
      default:
        this.alertCheckout = { type: "danger", message: "Something went wrong." };
        break;
    }
    // on reload the page get the order id
    await this.requestOrder();
  }

  setView(checkout: Checkout | undefined, business: Business | undefined): void {
    if (!checkout || !checkout?.items?.length || !business?.branding?.enableCheckout) {
      this.router.navigate(["/cart"]);
      return;
    } else if (checkout?.orderId) {
      this.checkoutView = "order-confirmed";
    }
    this.cartForm.patchValue({ allergyInformation: this.checkout?.allergyInformation || this.cartForm.value.allergyInformation || "" });
    // if (!this.checkout?.total?.tipAmount) {
    //   // this.setTip(this.tipOptions[1], false);
    // } else if (this.checkout?.total?.tipPercentage && !this.tipOptions.includes(this.checkout?.total.tipPercentage)) {
    //   this.tipForm.patchValue({ tipAmount: this.checkout?.total?.tipAmount || null });
    // }
  }

  setDefaultPaymentMethod(checkout: Checkout | undefined, business: Business | undefined): Checkout | undefined {
    // todo if the business only allway to pay by card directly select the cart payment
    if (checkout && business?.branding?.enableCheckout && !checkout.paymentMethod?.id && !this.paymentTypeAvailable.stripe && this.paymentTypeAvailable.businessManaged) {
      checkout.paymentMethod = this.PAYMENT_METHOD_CASH;
      checkout.paymentMethod = this.PAYMENT_METHOD_CASH;
    }
    return checkout;
  }

  onDisplayCheckout() {
    this.checkoutView = "payment-preview";
  }

  async onEditPaymentMethod() {
    if (!this.isCustomerValid) {
      this.alertCheckout = { type: "danger", message: "Complete your details" };
      return;
    }
    this.openOffcanvas("payment-methods");
  }

  async onRequestOrder() {
    if (!this.isCustomerValid || !this.checkout) {
      this.alertCheckout = { type: "danger", message: "Complete your contact info" };
      return;
    } else if (!this.checkout.paymentMethod || !this.checkout.paymentType) {
      this.alertCheckout = { type: "danger", message: "Select your payment method" };
      return;
    } else if (this.checkout.orderId) {
      this.checkoutView = "order-confirmed";
      return;
    } else if (!this.user?.id && this.checkout.paymentType === PaymentType.stripe) {
      this.openOffcanvas("payment-guest");
      return;
    }

    await this.requestOrder();
  }

  private async requestOrder() {
    // this will manage the request for the order
    // if order already existing it will recure the existing one
    this.isLoading = true;

    let checkout: Checkout = {
      ...this.checkout,
      allergyInformation: this.cartForm.value.allergyInformation,
      isTabClosed: this.checkout?.isTabClosed || false,
      isGroupTab: this.checkout?.isGroupTab || false,
    };
    if (this.cartForm.value.hasAllergy) {
      checkout.allergyInformation = this.translate.instant("checkout.customer-has-allergy");
    }

    try {
      const result = await this.orderService.requestOrder(checkout);
      // if success cleat the checkout and redirect the user to the order page
      if (!result.success || !result.data) {
        this.alertCheckout = { type: "danger", message: result.message };
        return;
      }
      checkout = { ...checkout, ...result.data };
      this.router.navigate(["/cart"]);
    } catch (e: any) {
      this.alertCheckout = { type: "danger", message: e.message };
    } finally {
      this.isLoading = false;
      this.currentCheckoutService.set(checkout);
    }
  }

  onTipChange(event: { amount: number; percentage: number }) {
    if (this.checkout?.total) {
      this.checkout.total.tipAmount = event.amount;
      this.checkout.total.tipPercentage = event.percentage;
    }
  }

  // onUpdateQuantity(action: "minus" | "add", item: ProductItem) {
  //   let checkout: Checkout = { ...this.checkout, isTabClosed: this.checkout?.isTabClosed || false };

  //   if (!checkout.items || !checkout.items.length) {
  //     return;
  //   }
  //   const itemIndex = checkout.items?.findIndex((i) => i.name === item.name && i.addedAt === item.addedAt && i.quantitySelected === item.quantitySelected);
  //   let currentQuantity = checkout.items[itemIndex].quantitySelected || 0;
  //   if (action === "add") {
  //     currentQuantity++;
  //   } else {
  //     currentQuantity--;
  //   }

  //   if (currentQuantity < 1) {
  //     // remove the item from array
  //     checkout.items.splice(itemIndex, 1);
  //   } else {
  //     checkout.items[itemIndex].quantitySelected = currentQuantity;
  //   }

  //   // update the local storage
  //   this.currentCheckoutService.set(checkout);
  // }

  // resetCart() {
  //   this.currentCheckoutService.resetCheckout(this.checkout);
  // }

  openOffcanvas(content: "login" | "account-details" | "payment-methods" | "payment-guest" | "order") {
    let component;
    switch (content) {
      case "login":
        component = AuthenticationComponent;
        break;
      case "account-details":
        component = AccountDetailsComponent;

        break;
      case "payment-methods":
        component = AccountPaymentMethodComponent;

        break;
      case "payment-guest":
        component = PaymentComponent;
        break;
      case "order":
        component = OrderComponent;
        break;
      default:
        return;
    }
    const offcanvasRef = this.offcanvasService.open(component, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
    if (content === "order" && this.checkout?.orderId) {
      offcanvasRef.componentInstance.orderId = this.checkout.orderId;
    }
  }
  // open(content: TemplateRef<any>) {
  //   this.offcanvasService.open(content, { position: "bottom", panelClass: "offcanvas-50" });
  // }
}
