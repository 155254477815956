<div [id]="!isModal ? 'scanCodeView': 'scanCodeModal'" class="d-flex flex-column justify-content-center py-4 ">
  <section class="container-md  mt-4 mb-auto">
    @if(!isModal){
    <h1 [translate]="'scan-code.title'"></h1>
    <p class="mb-4" translate="scan-code.sub-title"></p>
    }@else if(permission) {
    <h3 [translate]="'scan-code.scan-code'"></h3>
    <p class="mb-4" translate="scanner.scanner-required.help-title"></p>
    }

  </section>

  <section class="container-md mt-4  mb-auto">

    @if(isLoaded){
    <zxing-scanner [hidden]="!permission" (scanSuccess)="scanSuccess($event)" (permissionResponse)="permissionResponse($event)" [formats]="allowedFormats"></zxing-scanner>
    }
    @if(!permission){
    <h3 [translate]="'scanner.scanner-required'" [class.mt-5]="!isModal"></h3>
    <p class="mb-4" translate="scanner.scanner-required.help-title"></p>

    }
  </section>
</div>