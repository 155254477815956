@if(!checkout ){
<p>No active checkout</p>
} @else {
<div id="checkoutView" class="d-flex flex-column justify-content-center">
  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 ">
        <a [routerLink]="['/cart']" class="btn py-0 ">
          <app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg>
        </a>
        <h2 class="my-auto mx-3"> <span [translate]="isTabMode? 'tab.title':'checkout.title'"></span></h2>
      </div>
    </div>
  </nav>
  <hr class="mt-0">
  @if(isTabMode){
  <section class="container-md">
    <h4 class="mb-1" translate="tab.subtitle"></h4>
    <p class="mb-3 small text-muted" translate="tab.title-tip"></p>
    <hr />
  </section>
  }

  <form [formGroup]="cartForm">
    @if(isCustomerValid){
    <section class="container-md ">
      <h6 class="text-muted" translate="checkout.contact-info"></h6>
      <div class="d-flex w-100 justify-content-between" (click)="openOffcanvas('account-details')">
        <h5 class="my-auto  text-truncate">{{checkout.customer?.email }}</h5>
        <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
      </div>
      <hr />
    </section>
    }

    @if(selectPaymentMethod){
    <section class="container-md ">
      <h6 class="text-muted" translate="account.main.payment-methods"></h6>

      @if(paymentTypeAvailable.businessManaged && !paymentTypeAvailable.stripe){
      <p class="mb-0" translate="checkout.payment-manage-business-tips"></p>
      }@else {
      <div class="d-flex w-100 justify-content-between" (click)="onEditPaymentMethod()">
        <h5 class="my-auto  text-truncate" dir="ltr">
          @if(selectPaymentMethod.brand === 'visa'){
          <app-icon-svg class="icon-visa" iconName="visa"></app-icon-svg>
          }@else {
          {{selectPaymentMethod.brand}}
          }

          @if(selectPaymentMethod.last4){
          <span>**** {{selectPaymentMethod.last4}}</span>
          }
        </h5>
        <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
      </div>

      }
      <hr />
    </section>
    }

    @if(disabledComments){
    <section class="container-md ">
      <div class="form-check">
        <input class="form-check-input " type="checkbox" formControlName="hasAllergy" id="hasAllergy">
        <label class="form-check-label" for="hasAllergy" translate="checkout.has-allergy">
        </label>
        <p class="form-text" translate="checkout.has-allergy-tip"></p>
      </div>
      <hr />
    </section>
    }@else {
    <section class="container-md ">
      <div class="form-floating">
        <input type="text" class="form-control form-control-sm" id="allergyInformation" formControlName="allergyInformation" [placeholder]="'form.email-placeholder' | translate">
        <!-- <textarea class="form-control" placeholder="Add allergy information" id="allergyInformation" formControlName="allergyInformation" style="height: 100px"></textarea> -->
        <label for="allergyInformation" translate="checkout.add-allergy-information"></label>
      </div>
      <hr />
    </section>
    }

    <section class="container-md ">
      <h4 class="mb-1" translate="checkout.tip-title"></h4>
      <p class="mb-3 small text-muted" translate="checkout.tip-subtitle"></p>
      <app-tip-form [defaultTip]="0.12" [maxAmount]="currentPaymentStatus.itemsTotalOrg" (onTipChange)="onTipChange($event)"></app-tip-form>
      <p class="mt-3 small text-muted" translate="tab.tip-subtitle"></p>
    </section>
  </form>

  @if(!isTabMode){
  <hr class="border-4" />
  <section class="container-md ">

    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.sub-total"></p>
      <p class="mb-2 small text-muted">{{currentPaymentStatus.itemsTotalOrg | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between text-primary">
      <p class="mb-2 small text-primary" translate="checkout.discount"></p>
      <p class="mb-2 small text-primary" dir="ltr"> {{- (currentPaymentStatus.discount) | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.fee"></p>
      <p class="mb-2 small text-muted"> {{0 | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-0 small text-muted"> <span translate="checkout.tip"></span> <span [hidden]="!currentPaymentStatus.tipPercentage" class="ps-2">({{currentPaymentStatus.tipPercentage |
          percent}})</span></p>
      <p class="mb-0 small text-muted"> {{currentPaymentStatus.tipAmount || 0 | currency: checkout.business?.currency}}</p>
    </div>
    <hr class="my-3 " />
    <div class="d-flex w-100 justify-content-between">
      <h4 class="mb-0 " translate="currentPaymentStatus"></h4>
      <h4 class="mb-0 ">{{currentPaymentStatus.amountWithTipAndFee | currency: checkout.business?.currency}}</h4>
    </div>
  </section>
  }
  <section class="container-md">
    <app-alert [message]="alertCheckout.message" [dismissible]="true" [active]="alertCheckout.active" [type]="alertCheckout.type"></app-alert>
  </section>
  <section class="container-md mt-auto mb-3">
    @if(!isCustomerValid){
    <button type="button" class="btn btn-outline-primary w-100 " (click)="openOffcanvas('account-details')" translate="account.details.title"></button>
    } @else if(!selectPaymentMethod){
    <button type="button" class="btn btn-primary w-100 " [disabled]="!isCustomerValid || isLoading" (click)="onEditPaymentMethod()">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>
      }@else {
      <span translate="checkout.select-payment-method"></span>
      }
    </button>
    }@else {
    <button type="button" class="btn btn-primary w-100 " [disabled]="isLoading" (click)="onRequestOrder()">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>
      }@else {
      <span [translate]="isTabMode?'tab.open-tab-confirm-order':'checkout.place-order'"></span>
      }
    </button>
    }
  </section>

</div>
}