import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, doc, docData, documentId, getDocs, query, where } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { CollectionName, Product, ProductOptionGroup } from "../_global/_interfaces";
import { setKeyWords } from "../_global/_services/app.service";
import { BusinessService } from "./business.service";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private firestore: Firestore = inject(Firestore);
  collectionRef;
  businessId: string;
  constructor() {
    this.businessId = BusinessService.getSelectedBusinessId();
    this.collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.products);
  }

  get(id: string): Observable<Product> {
    const docRef = doc(this.collectionRef, id);
    return docData(docRef, {
      idField: "id",
    }) as Observable<Product>;
  }

  getOptionGroups(id: string): Observable<ProductOptionGroup> {
    const docRef = doc(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessOptionGroups, id);
    return docData(docRef, {
      idField: "id",
    }) as Observable<ProductOptionGroup>;
  }

  async getFromIdsPromise(ids: string[]): Promise<Product[]> {
    // need observable to real time update
    const q = query(this.collectionRef, where(documentId(), "in", ids));

    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as Product;
      data.id = doc.id;
      return data;
    });
  }

  getFromIds(ids: string[]): Observable<Product[]> {
    if (!ids.length) {
      return of([]);
    }

    // need observable to real time update
    const q = query(this.collectionRef, where("isActive", "==", true), where(documentId(), "in", ids));
    const data = collectionData(q, {
      idField: "id",
    }) as Observable<Product[]>;

    return data;
    // return data.pipe(
    //   tap((deals) => console.log("Fetched push sell:", deals)),
    //   map((deals) => deals as PushSells[]),
    //   catchError((error) => {
    //     console.error("Error fetching push sell:", error);
    //     return of([]);
    //   }),
    // );
  }

  getOptionGroupsFromIds(ids: string[]): Observable<ProductOptionGroup[]> {
    // need observable to real time update
    const collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessOptionGroups);
    const q = query(collectionRef, where(documentId(), "in", ids));
    const data = collectionData(q, {
      idField: "id",
    });
    return data as Observable<ProductOptionGroup[]>;
  }

  async list({ serachByName, categoryId }: { serachByName?: string; categoryId?: string }): Promise<Product[]> {
    const whereList = [where("isActive", "==", true)];
    if (serachByName) {
      // the searchByName is a string with multiple words, maximum 29 words
      // split the searchByName string into an array of words
      const searchByNameArray = setKeyWords(serachByName).slice(0, 29);
      whereList.push(where("keyWords", "array-contains-any", searchByNameArray));
    }
    if (categoryId) {
      whereList.push(where("categoryIds", "array-contains", categoryId));
    }
    const q = query(this.collectionRef, ...whereList);
    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as Product;
      data.id = doc.id;
      return data;
    });
  }
}
