import { Component } from "@angular/core";
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: "app-category-menu-card-placeholder",
  standalone: true,
  imports: [LazyLoadImageModule],
  templateUrl: "./placeholder.component.html",
  styleUrl: "./placeholder.component.scss",
})
export class CategoryMenuCardPlaceholderComponent {}
