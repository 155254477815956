<div class="">
  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 ">
        <a [routerLink]="['/account']" class="btn py-0 ">
          <app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg>
        </a>
        <h2 class="my-auto mx-3"> <span translate="order.order-history"></span></h2>
      </div>
    </div>
  </nav>
  <hr class="mt-0">
  <section class="container-md mb-4">

    @if(hasOrders){
    <section class="container-md my-4">
      @for (item of ordersPerMonth ; track item.monthLabel;){
      <h5>{{item.monthLabel}}</h5>
      @for (order of item.orders ; track order.id;){
      <app-order-card [order]="order"></app-order-card>
      }
      }
    </section>
    }@else {
    <section class="container-md my-5 text-center">
      <h3 class=" mb-4" translate="order.empty-state-title"></h3>
      <p class="mb-4" translate="order.empty-state-subtitle"></p>
    </section>
    }

  </section>

</div>