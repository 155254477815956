<div id="customerView" class="d-flex flex-column justify-content-center">
  <nav class="navbar bg-body-header">
    <div class="container-md">
      <span class="navbar-brand mx-auto text-capitalize" [style.padding-right]="'43px'" translate="stripe.payment-title"></span>
    </div>
  </nav>

  @if(pageLoading){
  <section class="container-md my-5 text-center">
    <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>

  </section>
  }
  <section class="container-md">
    <app-alert [message]="alertPayment.message" [dismissible]="true" [active]="alertPayment.active" [type]="alertPayment.type"></app-alert>
  </section>

  <section class="container-md mb-4" [hidden]="pageLoading">

    <form id="payment-form" #paymentForm="ngForm" (ngSubmit)="submitElement()">
      <div id="payment-element" #paymentElementForm>
        <!-- Elements will create input elements here -->
      </div>
      <button type="submit" class="btn btn-primary w-100" [disabled]="isLoading || !paymentForm.form.valid" translate="stripe.payment-pay"></button>
    </form>
  </section>
</div>