import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { CategoryMenu } from "../../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode } from "../../../_global/_services/app.service";

@Component({
  selector: "app-category-menu-card",
  standalone: true,
  imports: [LazyLoadImageModule, RouterModule],
  templateUrl: "./card.component.html",
  styleUrl: "./card.component.scss",
})
export class CategoryMenuCardComponent {
  @Input() category!: CategoryMenu;
  @Output() onEdit: EventEmitter<string> = new EventEmitter<string>();
  businessId: string | null = null;
  languageCode: LangCode = DEFAULT_LANG_CODE;
  get categoryTraductionName() {
    const t = this.category?.traduction ? this.category?.traduction[this.languageCode] : undefined;
    return t?.name || this.category.name;
  }

  constructor() {
    this.businessId = localStorage.getItem("@selectedBusinessId");
    this.languageCode = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
  }
}
