<div class="card  border-light" [class.border-0]="!isCard" [class.shadow-sm]="isCard" [class.is-card]="isCard" [class.is-vertical]="isVertical || isCard"
  [class.opacity-25]="!product.isActive || isSoldOut" (click)="openOffcanvas()">
  <div class="row g-0" [style.background-color]="dominantColor">
    <div class="col-auto  " [class.col-12]="isVertical|| isCard">

      @if(isCard){
      <div class="image-container">
        <div class="image-background" [class.landscape]="isImageLandscape" [class.portrait]="!isImageLandscape" [lazyLoad]="imageUrl || ''"
          [defaultImage]="'assets/images/temps/placeholder-image.svg'">

        </div>
        <div class=" rounded product-image" [class.landscape]="isImageLandscape" [class.portrait]="!isImageLandscape" [lazyLoad]="imageUrl || ''"
          [defaultImage]="'assets/images/temps/placeholder-image.svg'"></div>
      </div>
      }@else {
      <div class="image-ratio-1">
        <div class="img-fluid rounded" [class.landscape]="isImageLandscape" [class.portrait]="!isImageLandscape" [lazyLoad]="imageUrl || ''"
          [defaultImage]="'assets/images/temps/placeholder-image.svg'"></div>
      </div>
      }

    </div>
    <div class="col  {{isVertical|| isCard ? 'col-12' : 'col-content'}}">
      <div class="card-body {{isVertical? 'pb-0 pt-2' :  isCard ? 'pt-2': 'py-0'}}" [class.pe-0]="!isCard && (languageDir==='ltr' || isVertical) " [class.ps-0]="languageDir==='rtl' || isVertical">
        <div class="row">
          <h4 class="card-title mb-1 {{isVertical ? 'text-truncate-2' : 'text-truncate'}}">{{productTraductionName}}</h4>
          @if(productTraductionDescription && !isCard){
          <p class="mb-1 text-truncate-2 small text-muted">{{productTraductionDescription}}</p>
          }
          @if((!isVertical|| !isCard) && (product.isPopular || product.isRecommended) ){
          <div class="d-flex my-1">
            @if(product.isPopular){
            <span class="badge text-bg-warning  is-popular me-1"> <span translate="product.popular"></span> </span>
            }
            @if(product.isRecommended){
            <span class="badge text-bg-info is-recommanded me-1"> <span translate="product.recommanded"></span> </span>
            }
          </div>
          }

          <div class="d-flex">
            <h5 class="mb-0 text-primary" [innerHTML]="product.variants || [] | pricingDisplay : true: currency"></h5>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>