import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild, inject } from "@angular/core";

import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Checkout, UserPreview } from "../../../_global/_interfaces";
import { Alert } from "../../../_interfaces";
import { CurrentCheckoutService } from "../../../_services/order.service";
import { PaymentService } from "../../../_services/payment.service";
import { AlertComponent } from "../../alert/alert.component";
import { LoaderService } from "../../loader/loader.service";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
  standalone: true,
  imports: [AlertComponent, FormsModule, FontAwesomeModule, ReactiveFormsModule, TranslateModule],
})
export class PaymentMethodComponent implements AfterViewInit, OnDestroy {
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("paymentElementForm", { static: false }) paymentElementForm?: ElementRef;
  elements: any;
  paymentElement: any;
  // showPaymentElement = true;
  elementHandler = this.onChange.bind(this);
  error: string = "";
  alertBilling: Alert = { type: "info" };
  isLoading = false;

  customerForm: FormGroup = this.formBuilder.group({
    email: ["", [Validators.required, Validators.email]],
    name: ["", Validators.required],
  });

  checkout: Checkout;
  subscriptionCheckout: Subscription;
  private currentCheckoutService = inject(CurrentCheckoutService);

  get user(): UserPreview | undefined {
    return this.checkout.customer || undefined;
  }

  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService,
  ) {
    const localData = localStorage.getItem("@checkout");
    this.checkout = localData && localData !== "undefined" ? JSON.parse(localData) : {};
    this.subscriptionCheckout = this.currentCheckoutService.getCheckout().subscribe((checkout) => {
      this.checkout = checkout;
    });
    // init form
    if (this.checkout.customer) {
      this.customerForm.patchValue({ email: this.checkout.customer.email, name: `${this.checkout.customer.firstName || ""} ${this.checkout.customer.lastName || ""}`.trim() });
    }
  }

  ngAfterViewInit(): void {
    if (!this.paymentElementForm) {
      console.error("paymentElementForm is not display/active");
      return;
    }

    // get client secret to load with the stripe element
    this.paymentService
      .setupIntentPaymentMethod()
      .then((clientSecret) => {
        const stripe = this.paymentService.stripe as any;
        this.elements = stripe.elements({
          clientSecret,
          // appearance: {
          //   theme: "night",
          //   labels: "above",
          //   variables: {
          //     colorPrimary: "#0D9C8F",
          //     colorBackground: "#002B38",
          //     colorBackgroundText: "#002B38",
          //    font-family: "Inter", sans-serif;
          //   },
          // },
        });
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount(this.paymentElementForm?.nativeElement);
        this.paymentElement.addEventListener("change", this.elementHandler);
      })
      .catch((e: any) => {
        this.alertBilling = { type: "danger", message: e.message };
      });
  }

  ngOnDestroy(): void {
    if (this.paymentElement) {
      this.paymentElement.removeEventListener("change", this.elementHandler);
      this.paymentElement.destroy();
    }
    this.subscriptionCheckout.unsubscribe();
  }

  onChange(event: any): void {
    if (event.error) {
      this.error = event.error?.message || "error on change";
    } else {
      this.error = "";
    }
    // Display bank name corresponding to IBAN, if available.
    this.cd.detectChanges();
  }

  async submitElement(): Promise<void> {
    this.loaderService.show();
    try {
      const stripe = this.paymentService.stripe as any;
      const hostname = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}`;

      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url: `${hostname}/account`,
          payment_method_data: {
            billing_details: {
              email: this.customerForm.value.email,
              name: this.customerForm.value.name,
            },
          },
        },
      });
      // todo setuop this confirmation page

      if (error) {
        this.error = error?.message || "unable to create payment method";
        console.error("Something is wrong:", error);
        return;
      }
    } catch (e: any) {
      this.error = e.message;
      console.error("Something is wrong:", e);
      return;
    } finally {
      this.loaderService.hide();
    }
  }
}
