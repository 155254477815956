import { CurrencyPipe, JsonPipe } from "@angular/common";
import { Component, Input, OnInit, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faStar, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Order } from "../../../_global/_interfaces";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../../../_global/_services/app.service";
import { PricingDisplayPipe } from "../../../_pipes/pricing-display/pricing-display.pipe";
import { TimeToLabelPipe, TimeUntilNowPipe } from "../../../_pipes/timer.pipe";
import { OrderComponent } from "../../../orders/details/details.component";

@Component({
  selector: "app-order-card",
  standalone: true,
  imports: [LazyLoadImageModule, FontAwesomeModule, PricingDisplayPipe, JsonPipe, RouterModule, CurrencyPipe, TimeUntilNowPipe, TranslateModule, TimeToLabelPipe],
  templateUrl: "./card.component.html",
  styleUrl: "./card.component.scss",
  providers: [CurrencyPipe], // need to use PricingDisplayPipe
})
export class OrderCardComponent implements OnInit {
  @Input() order!: Order;
  faStar = faStar;
  faThumbsUp = faThumbsUp;
  imageUrl?: string;
  businessId: string | null = null;
  languageCode: LangCode = DEFAULT_LANG_CODE;
  languageDir: "rtl" | "ltr" = "ltr";

  private offcanvasService = inject(NgbOffcanvas);

  get orderDescription(): string {
    return (
      this.order?.items
        ?.map((item) => {
          return `${(item.quantitySelected || 0) > 1 ? `${item.quantitySelected}x` : ""} ${item.name}`.trim();
        })
        .join(", ")
        .trim() || ""
    );
  }
  constructor() {
    this.businessId = localStorage.getItem("@selectedBusinessId");
    const temLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageCode = temLang;
    this.languageDir = RTL_LANG_CODE.includes(temLang) ? "rtl" : "ltr";
  }
  ngOnInit(): void {
    if (this.order?.items && this.order?.items[0].image) {
      this.imageUrl = this.order?.items[0].image.url;
    }
  }

  openOrderOffcanvas(order: Order) {
    const offcanvasRef = this.offcanvasService.open(OrderComponent, { position: "bottom", panelClass: "offcanvas-fit-content offcanvas-after-nav", backdropClass: "offcanvas-backdrop-after-nav" });
    offcanvasRef.componentInstance.orderId = order.id;
  }
}
