<div id="paymentMethodesView" class="d-flex flex-column justify-content-center" [class.is-rtl]="languageDir==='rtl'" [dir]="languageDir">
  <section class="container-md ">
    <nav class="navbar my-3" (click)="activeOffcanvas.dismiss('Cross click')">
      <div class="d-flex w-100 justify-content-between">
        <h3 class="mb-0"><app-icon-svg [iconName]="'arrow-down'"></app-icon-svg><span class="ms-2" translate="account.payment-method.title"></span></h3>
      </div>
    </nav>
    <app-alert [message]="alertBilling.message" [dismissible]="true" [active]="alertBilling.active" [type]="alertBilling.type"></app-alert>
    @if(paymentMethods.length && !editPaymentMethod){
    <form class="d-flex flex-column justify-content-center pb-3" [formGroup]="paymentMethodForm" (ngSubmit)="changePaymentMethod()">
      @if(isCheckout){
      <p class="small" translate="account.payment-method.select-pm-for-order"></p>
      }@else {
      <p class="small" translate="account.payment-method.select-default-pm"></p>

      }
      @if(paymentMethods.length ){
      <!-- <h4>Product variants: </h4> -->
      <ul class="list-group list-group-flush">
        @for (item of paymentMethods; track item.id; let index = $index) {
        <li class="list-group-item px-0" dir="ltr">
          <label class="form-check-label small" [for]="'pm-'+index" [style.width]="'calc(100% - 16px)'">
            <div class="d-flex w-100 justify-content-between">
              <span class="text-capitalize fw-bolder">

                @if(item.brand === 'visa'){
                <app-icon-svg class="icon-visa" iconName="visa"></app-icon-svg>
                }@else {
                {{item.brand}}
                }
                @if(item.last4){
                <span class="caption">****{{item.last4}}</span>
                }</span>

              <span [hidden]="!item.expMonth || !item.expYear" class="caption text-muted mx-2">{{item.expMonth < 10 ? '0' : '' }}{{item.expMonth}}/{{item.expYear}}</span>

            </div>
          </label>
          <input class="form-check-input " type="radio" formControlName="selectedPaymentMethod" [value]="index" [id]="'pm-'+index">

          <p class="text-muted caption mb-0 ">{{item.name}}</p>
        </li>
        }
      </ul>
      }

      @if(isLoading){
      <div class="text-center pt-3">
        <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>
      </div>
      }

      <div class="mt-auto pt-3">
        <button type="submit" class="btn btn-primary w-100  " [disabled]="paymentMethodForm.invalid">Confirm</button>

        @if(user && user.id && paymentTypeAvailable.stripe){
        <button type="button" class="px-0 btn  btn-link w-100 mt-2" (click)="editPaymentMethod = true" translate="account.payment-method.add-pm"></button>
        }
      </div>

    </form>
    }@else if(editPaymentMethod) {
    <app-payment-method (onClose)="editPaymentMethod = false"></app-payment-method>
    }@else {
    <section class="container-md my-5 text-center">
      <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>
    </section>
    }

  </section>
</div>