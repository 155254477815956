import { Component, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { SvgIconService } from "./index.service";

@Component({
  selector: "app-icon-svg",
  standalone: true,
  imports: [],
  templateUrl: "./index.component.html",
  styleUrl: "./index.component.scss",
})
export class SvgIconComponent implements OnChanges {
  @Input() iconName!: string;
  @Input() size?: "sm" | "md" | "lg" | "xl" | "2xl";
  @Input() color: string = "currentColor";
  @Input() height?: number;
  @Input() width?: number;
  @Input() strokeWidth?: number;
  svgContent: SafeHtml = "";

  get scale(): string {
    switch (this.size) {
      case "sm":
        return `scale(${0.6})`;
      case "md":
        return `scale(${0.8})`;
      case "lg":
        return `scale(${1.5})`;
      case "xl":
        return `scale(${2})`;
      case "2xl":
        return `scale(${2.5})`;
    }
    return `scale(${1})`;
  }
  constructor(
    private iconService: SvgIconService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnChanges() {
    if (this.iconName) {
      this.iconService.getIcon(this.iconName, this.width, this.height, this.strokeWidth).subscribe((svg) => {
        if (this.iconName === "menu") {
        }
        this.svgContent = svg;
      });
    }
  }
}
