import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { AlertType } from "../../_interfaces";

@Component({
  selector: "app-alert",
  standalone: true,
  imports: [NgbAlert],
  templateUrl: "./alert.component.html",
  styleUrl: "./alert.component.scss",
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() type: AlertType = "info";
  @Input() message?: string;
  @Input() active?: boolean;
  @Input() dismissible?: boolean;

  @ViewChild("staticAlert", { static: false }) staticAlert?: NgbAlert;

  constructor() {}

  ngOnInit() {
    this.active = !!this.message;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["message"] && !changes["message"].firstChange) {
      // this.message = changes["message"].currentValue;
      this.active = !!this.message;
    }
  }
}
