import { CurrencyPipe, getCurrencySymbol, JsonPipe, PercentPipe } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { NotificationRequestComponent } from "../../_component/notification-request/notification.component";
import { OrderCardComponent } from "../../_component/order/card/card.component";
import { ProductItemCardComponent } from "../../_component/product/item-card/item-card.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Checkout } from "../../_global/_interfaces";
import { AppService } from "../../_services/app.service";

@Component({
  selector: "app-tip-form",
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    JsonPipe,
    ProductItemCardComponent,
    ReactiveFormsModule,
    CurrencyPipe,
    AlertComponent,
    OrderCardComponent,
    TranslateModule,
    PercentPipe,
    NotificationRequestComponent,
    SvgIconComponent,
  ],
  templateUrl: "./tip-form.component.html",
  styleUrl: "./tip-form.component.scss",
})
export class TipFormComponent implements OnInit, OnDestroy {
  isLoading = false;
  tipForm: FormGroup = this.formBuilder.group({
    tipAmount: [null],
  });
  @Output() onTipChange: EventEmitter<{ amount: number; percentage: number }> = new EventEmitter<{ amount: number; percentage: number }>();
  @Input() maxAmount!: number;
  @Input() defaultTip?: number;
  tipOptions = [0.1, 0.12, 0.15, 0.2];
  customTipFormat: "percent" | "amount" = "percent";
  showCustomTip = false;

  checkout?: Checkout;
  subscriptionCheckout: Subscription;

  private appService = inject(AppService);

  get currencySymbol(): string {
    return getCurrencySymbol(this.checkout?.business?.currency || "USD", "narrow");
  }

  constructor(private formBuilder: FormBuilder) {
    const { checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.checkout = checkout;

    this.subscriptionCheckout = subscriptionCheckout.subscribe((checkout) => {
      this.checkout = checkout;
      this.setView(checkout);
    });

    this.setView(this.checkout);
  }

  ngOnDestroy(): void {
    this.subscriptionCheckout.unsubscribe();
  }

  ngOnInit(): void {
    // this.tipForm.get("tipAmount")?.setValidators([Validators.max(this.maxAmount)]);
  }
  switchCustomTipFormat() {
    this.customTipFormat = this.customTipFormat === "percent" ? "amount" : "percent";
  }
  setTip(value: number, isCustom: boolean = false) {
    let validValue = 0;
    const isAmount = this.customTipFormat === "amount";

    if (this.checkout && this.checkout.total) {
      validValue = !isAmount && isCustom ? value / 100 : value;
      if (isAmount && validValue > this.maxAmount) {
        validValue = this.maxAmount;
      } else if (!isAmount && validValue > 0.99) {
        validValue = 0.99;
      }
      const totalAmount = this.checkout.total.itemsTotal || 0;
      let tipAmount = isAmount ? validValue : totalAmount * validValue;
      let tipPercentage = isAmount ? validValue / totalAmount : validValue;
      tipAmount = Number(tipAmount.toFixed(2));
      tipPercentage = Number(tipPercentage.toFixed(2));
      this.onTipChange.emit({ amount: tipAmount, percentage: tipPercentage });
      this.checkout.total.tipAmount = tipAmount;
      this.checkout.total.tipPercentage = tipPercentage;
    }
    if (isCustom) {
      this.showCustomTip = false;
    }
    const valueDisplay = isAmount ? validValue : validValue * 100;
    this.tipForm.get("tipAmount")?.setValue(valueDisplay || null);
  }

  setView(checkout: Checkout | undefined): void {
    if (!checkout?.total?.tipAmount && this.defaultTip) {
      this.setTip(this.defaultTip, false);
    } else if (checkout?.total?.tipPercentage && !this.tipOptions.includes(checkout?.total.tipPercentage)) {
      this.tipForm.patchValue({ tipAmount: checkout?.total?.tipAmount || null });
    }
  }
}
