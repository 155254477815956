@if(business && business.id){
<nav class="navbar fixed-bottom  py-0 ">
  <div class="container-md">
    <a [routerLink]="['/menu']" class="btn border-0" [class.text-primary]="currentRoute==='home'">
      <app-icon-svg [iconName]="currentRoute==='home' ?'home.fill' :'home'"></app-icon-svg>
      <span class="d-block caption mt-1 fw-light" translate="navbar.home"></span>
    </a>
    <a [routerLink]="['/menu','category']" class="btn  border-0" [class.text-primary]="currentRoute==='menu'"><app-icon-svg [iconName]="currentRoute==='menu' ?'menu.fill' :'menu'"></app-icon-svg>
      <span class="d-block caption mt-1 fw-light" translate="navbar.menu"></span>
    </a>
    <!-- <a [routerLink]="['/menu','search']" routerLinkActive="active" class="btn btn-outline-primary"><fa-icon size="lg" [icon]="faSearch"></fa-icon></a> -->
    <a [routerLink]="[ '/cart']" class="btn  border-0 position-relative" [class.text-primary]="currentRoute==='cart'">
      <app-icon-svg [iconName]="currentRoute==='cart' ?'shopping-cart.fill' :'shopping-cart'"></app-icon-svg>
      @if(cartCount){
      <span class="position-absolute badge-cart translate-middle badge rounded-pill text-bg-primary">
        {{cartCount > 99 ? '99+' : cartCount}}
        <span class="visually-hidden">unread messages</span>
      </span>
      }
      <span class="d-block caption mt-1 fw-light" translate="navbar.order"></span>

    </a>

    <a [routerLink]="['/account']" [class.text-primary]="currentRoute==='user'" class="btn  border-0 position-relative">
      @if(hasUnreadNotifications){
      <span class="position-absolute badge-cart translate-middle badge rounded-pill text-bg-danger p-1 end-0 me-2">
        <span class="visually-hidden">unread messages</span>
      </span>
      }
      <app-icon-svg [iconName]="currentRoute==='user' ?'user.fill' :'user'"></app-icon-svg>
      <span class="d-block caption mt-1 fw-light" translate="navbar.account"></span>
    </a>
  </div>
</nav>
}@else {
<nav class="navbar fixed-bottom  py-0  ">
  <div class="container-md">
    <div class="row text-center w-100">
      <div class="col">
        <a [routerLink]="['/']" [class.text-primary]="currentRoute==='qr-code'" class="btn   border-0"><fa-icon size="lg" [icon]="faQrcode"></fa-icon>
          <span class="d-block caption mt-1 fw-light" translate="navbar.scanner"></span>
        </a>
      </div>
      <!-- <div class="col">
        <a [routerLink]="[ '/cart']" [class.text-primary]="currentRoute==='cart'" class="btn  border-0 position-relative">
          <app-icon-svg [iconName]="currentRoute==='cart' ?'shopping-cart.fill' :'shopping-cart'"></app-icon-svg>
          @if(cartCount){
          <span class="position-absolute badge-cart translate-middle badge rounded-pill text-bg-primary">
            {{cartCount > 99 ? '99+' : cartCount}}
            <span class="visually-hidden">unread messages</span>
          </span>
          }
          <span class="d-block caption mt-1 fw-light" translate="navbar.order"></span>

        </a>
      </div> -->
      <div class="col">
        <a [routerLink]="['/account']" [class.text-primary]="currentRoute==='user'" class="btn border-0 position-relative">
          @if(hasUnreadNotifications){
          <span class="position-absolute badge-cart translate-middle badge rounded-pill text-bg-danger p-1  end-0 me-2">
            <span class="visually-hidden">unread messages</span>
          </span>
          }
          <app-icon-svg [iconName]="currentRoute==='user' ?'user.fill' :'user'"></app-icon-svg>
          <span class="d-block caption mt-1 fw-light" translate="navbar.account"></span>
        </a>
      </div>
    </div>

  </div>
</nav>
}

<ng-template #mustBeOverAge let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate="must-be-over-age.title"></h4>
  </div>

  <div class="modal-body">
    <p class="mb-3" translate="must-be-over-age.sub-title" [translateParams]="{businessName:businessName}"></p>
    <button type="button" class="btn btn-primary w-100" (click)="modal.close(true)" translate="must-be-over-age.confirm"></button>
    <p class="mt-3 text-muted text-center" translate="must-be-over-age.info" [translateParams]="{businessName:businessName}"></p>
  </div>
</ng-template>