import moment from "moment-timezone";
import { BillingPlan, Business, BusinessPreview, Currencies, ReviewCount, ServiceMenu, User, UserPreview } from "../_interfaces";
type LangCode = "fr" | "en-US" | "he" | "es" | "ru" | "ar";

const RTL_LANG_CODE: LangCode[] = ["he", "ar"];
const LANG_CODE: LangCode[] = ["en-US", "fr", "es", "ru", ...RTL_LANG_CODE];

const DEFAULT_LANG_CODE: LangCode = "en-US";
const categoriesList = [
  "restaurant",
  "meat",
  "italian",
  "asia",
  "international",
  "fish",
  "gastronomy",
  "kosher",
  "halal",
  "breakfast",
  "fast-food",
  "delivery",
  "bar-pub",
  "dessert",
  "ice-cream",
  "bakery",
  "vegan",
  "local-food",
  "vegetarian",
  "bio",
  "coffee",
  "diner",
  "juicies",
  "street-food",
  "burger",
  "pizza",
] as const;
type Categories = (typeof categoriesList)[number];

const ingredientsList = [
  "alkanet-root",
  "alum",
  "anise-seed",
  "arrowroot",
  "asafoetida",
  "basil-holy",
  "bay-leaf-indian",
  "black-cumin",
  "black-mustard-seeds",
  "black-mustard-seeds-cracked",
  "black-peppercorn",
  "black-rock-salt",
  "cardamom-black",
  "cardamom-green",
  "carom-seed",
  "cassia",
  "cayenne-pepper",
  "celery-seeds",
  "cilantro",
  "citric-acid",
  "cinnamon",
  "cloves",
  "coriander-seeds",
  "cumin-seeds",
  "curry-leaf",
  "dill",
  "fennel",
  "fenugreek-leaves-dried",
  "fenugreek-seeds",
  "garcinia-indica",
  "garlic-fresh",
  "ginger-fresh-rhizome",
  "ginger-dried-rhizome",
  "gold-leaf-edible",
  "gum-katira-sap",
  "green-chili",
  "honey",
  "illaichi",
  "licorice",
  "lime-pickling",
  "long-pepper",
  "lotus-seed-pops",
  "jaggery",
  "mace",
  "mango-powder",
  "mustard-seeds",
  "mustard-seeds-cracked",
  "mustard-oil",
  "nigella",
  "nutmeg",
  "onion",
  "paprika",
  "peppermint-fresh",
  "pomegranate-seeds",
  "poppy-seeds",
  "red-chili",
  "rose",
  "rose-water",
  "sage",
  "saffron",
  "salt",
  "salt-sea",
  "salt-sanchal-black",
  "scallion",
  "screwpine-essence",
  "sesame",
  "silver-edible-leaf",
  "star-anis",
  "sugar",
  "sumac",
  "tamarind",
  "turmeric",
  "fresh-rhizome",
  "thyme",
  "vinegar",
  "white-pepper",
  "yeast-live-sour-dough",
  "yeast",
  "rose-essence",
  "screwpine-essence",
  "vetiver-essence",
  "apricot-dried",
  "dates",
  "dates-dry-skin",
  "fig",
  "gooseberry-indian-emblica",
  "prunes",
  "raisins",
  "raisins-seedless-yellow",
  "almonds",
  "cantaloupe-seed",
  "cashews",
  "coconut",
  "flaxseeds",
  "gorgon-nut-puffed-kernel",
  "peanuts",
  "pine-nuts",
  "pistachio-nuts",
  "pistachio-soft",
  "pumpkin-seeds",
  "safflower",
  "sesame-seed",
  "sunflower",
  "watermelon-seed",
  "walnuts",
  "apple",
  "apricot",
  "avocado",
  "banana",
  "cantaloupe",
  "cranberry",
  "fig",
  "gooseberry-cape",
  "grapes",
  "grapefruit",
  "guavas",
  "jackfruit",
  "java-plum",
  "lime",
  "loquat",
  "lychee",
  "mango",
  "mulberry",
  "natal-plum",
  "olives",
  "orange",
  "orange-navel",
  "papaya",
  "peach",
  "pear",
  "pineapple",
  "plum",
  "pomegranate",
  "sapodilla-plum-sapota",
  "star-fruit",
  "sugar-cane",
  "sweet-lime",
  "watermelon",
  "barley",
  "buckwheat",
  "cracked-wheat",
  "cream-of-wheat",
  "farina",
  "flour",
  "flour-bread",
  "flour-chickpea",
  "flour-pastry-all-purpose",
  "maize",
  "millet-barnyard",
  "millet-finger",
  "millet-foxtail",
  "millet-great-sorghum",
  "millet-little",
  "millet-pearl-spiked",
  "oats",
  "rice",
  "rice-flattened-flakes",
  "rice-puffed",
  "semolina",
  "sorghum",
  "tapioca-cassava",
  "vermicelli",
  "wheat",
  "wheat-flour",
  "alubia",
  "black-matpe-black-beans",
  "chickpea",
  "fava",
  "field-pea-split",
  "garbanzo-beans",
  "mung-beans",
  "red-kidney-bean",
  "lentil",
  "pigeon-peas",
  "soya-bean",
  "whole-beans",
  "split-beans",
  "split-skinless-black-chickpeas",
  "decorticated-skinless-beans",
  "goat",
  "meat-generic",
  "pork",
  "rabbit",
  "venison",
  "chicken",
  "squab",
  "duck",
  "egg",
  "goose",
  "guinea-fowl",
  "partridge",
  "pigeon",
  "quail-grey",
  "bone",
  "brains",
  "feet-trotters",
  "head",
  "heart",
  "intestines",
  "kidney",
  "leg",
  "liver",
  "lung",
  "marrow",
  "minced-ground-meat",
  "stomach",
  "stomach-skin",
  "spleen",
  "testicles",
  "tongue",
  "udder",
  "bombay-duck",
  "butter-fish-indian",
  "carp-varieties",
  "cat-fish",
  "clams",
  "cod-rock",
  "crabs",
  "fish-generic",
  "herring",
  "king-fish",
  "lady-fish",
  "mackerel",
  "mullet",
  "mussels",
  "pearl-spot",
  "perch-climbing",
  "pomfret",
  "pomfret-silver",
  "prawn",
  "roe",
  "salmon-indian",
  "sardines",
  "sawfish",
  "shark",
  "shad-indian",
  "silver-belley",
  "snake-head",
  "sole",
  "sorrel-red",
  "squid",
  "tuna",
  "turbot-indian",
  "turtle",
  "white-bait",
  "aloe-vera",
  "beans-cluster-fresh",
  "beans-french-fresh",
  "aubergine",
  "beet-root",
  "bell-pepper-capsicum",
  "celery",
  "cabbage",
  "carrot",
  "cauliflower",
  "cilantro",
  "cluster-beans",
  "corn-kernel",
  "corn-on-the-cob",
  "cowpea",
  "cucumber",
  "cucumber-english-skinny",
  "eggplant",
  "gourd-ash",
  "gourd-bitter",
  "gourd-bottle",
  "gourd-ridged",
  "gourd-snake",
  "jackfruit",
  "kohl-rabi",
  "lotus-stem",
  "mushrooms",
  "mustard-greens",
  "natal-plum",
  "okra",
  "onion",
  "peas-green",
  "peppermint-leaves-fresh",
  "potato",
  "pumpkin",
  "radish",
  "red-sorrel",
  "scallion",
  "spinach",
  "sweet-potato",
  "tomato",
  "turnip",
  "water-chestnuts-indian",
  "beef",
  "cow",
  "red-snapper",
  "colocasia-taro-eddo",
  "gluten",
  "nuts",
  "gluten-free",
  "kosher",
  "lactose",
  "milk",
  "egg",
  "soy",
  "tree-nut",
  "shellfish",
  "fish",
  "sesame",
  "corn",
  "meat",
  "gelatin",
  "seed",
  "spice",
] as const;
type Ingredients = (typeof ingredientsList)[number];

const shorterNumber = (val: number | undefined, digits?: number): string => {
  if (!val) {
    return "0";
  }
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (val >= si[i].value) {
      break;
    }
  }
  return (val / si[i].value).toFixed(digits || 0).replace(rx, "$1") + si[i].symbol;
};

const generateScheduleString = (serviceMenu: ServiceMenu): string => {
  const formatTime = (time?: { hour: number; minute: number }): string => {
    if (!time) return "N/A";
    return `${time.hour.toString().padStart(2, "0")}:${time.minute.toString().padStart(2, "0")}`;
  };

  const daySchedule = (
    day: {
      active: boolean;
      on?: { hour: number; minute: number };
      off?: { hour: number; minute: number };
    },
    dayName: string,
  ): string | null => {
    if (day.active) {
      return `${dayName}: ${formatTime(day.on)} - ${formatTime(day.off)}`;
    } else {
      // return `${dayName}: Closed`;
      return null;
    }
  };

  const schedules = [
    daySchedule(serviceMenu.sunday, "Sunday"),
    daySchedule(serviceMenu.monday, "Monday"),
    daySchedule(serviceMenu.tuesday, "Tuesday"),
    daySchedule(serviceMenu.wednesday, "Wednesday"),
    daySchedule(serviceMenu.thursday, "Thursday"),
    daySchedule(serviceMenu.friday, "Friday"),
    daySchedule(serviceMenu.saturday, "Saturday"),
  ].filter(Boolean);

  return schedules.join(", ");
};

const schedulesToHuman = (
  services: ServiceMenu[] | undefined,
): {
  schedules: { day: string; label: string }[];
  today: string;
  status: "open" | "close" | "open-soon" | "close-soon";
  closeIn?: number;
  openIn?: number;
} => {
  if (!services || !services.length) {
    return {
      schedules: [],
      status: "close",
      today: "close",
    };
  }
  const DAY_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const formatTime = (time?: { hour: number; minute: number }): string => {
    if (!time) return "N/A";
    return `${time.hour.toString().padStart(2, "0")}:${time.minute.toString().padStart(2, "0")}`;
  };

  const daySchedule = (
    day: {
      active: boolean;
      on?: { hour: number; minute: number };
      off?: { hour: number; minute: number };
    },
    dayName: string,
  ): { day: string; label: string } => {
    if (day.active) {
      return {
        day: dayName,
        label: `${formatTime(day.on)} - ${formatTime(day.off)}`,
      };
    } else {
      return { day: dayName, label: "Closed" };
    }
  };

  const calculateStatus = (todaySchedule: { active: boolean; on?: { hour: number; minute: number }; off?: { hour: number; minute: number } }) => {
    const now = moment();
    if (!todaySchedule.active) return "close";

    const openTime = moment()
      .hours(todaySchedule.on?.hour ?? 0)
      .minutes(todaySchedule.on?.minute ?? 0);
    const closeTime = moment()
      .hours(todaySchedule.off?.hour ?? 0)
      .minutes(todaySchedule.off?.minute ?? 0);

    if (now.isBefore(openTime)) {
      return now.add(30, "minutes").isAfter(openTime) ? "open-soon" : "close";
    } else if (now.isAfter(closeTime)) {
      return now.add(30, "minutes").isBefore(closeTime) ? "close-soon" : "close";
    } else {
      return "open";
    }
  };

  const todayIndex = moment().day();
  const todayName = DAY_NAMES[todayIndex];
  const todayKey = todayName.toLowerCase() as "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";
  const todayService = services.find((service) => service[todayKey]?.active);

  if (!todayService) {
    return {
      schedules: [],
      today: todayName,
      status: "close",
    };
  }

  const todaySchedule = todayService[todayKey];

  const schedules = DAY_NAMES.map((dayName, index) => {
    const dayKey = dayName.toLowerCase() as "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";
    const service = services.find((service) => service[dayKey]?.active);
    return daySchedule(service ? service[dayKey] : { active: false }, dayName);
  });
  const status = calculateStatus(todaySchedule);

  let closeIn: number | undefined;
  let openIn: number | undefined;

  if (status === "open") {
    const closeTime = moment()
      .hours(todaySchedule.off?.hour ?? 0)
      .minutes(todaySchedule.off?.minute ?? 0);
    closeIn = closeTime.diff(moment(), "minutes");
  } else if (status === "close" || status === "open-soon") {
    const openTime = moment()
      .hours(todaySchedule.on?.hour ?? 0)
      .minutes(todaySchedule.on?.minute ?? 0);
    openIn = openTime.diff(moment(), "minutes");
  }

  return {
    schedules,
    today: todayName,
    status,
    closeIn,
    openIn,
  };
};

const timeToDuration = (time: number | undefined): string => {
  if (!time) {
    return "Date invalid!";
  }
  const now = moment.utc();
  const diff = time - now.valueOf();
  return moment.duration(diff, "milliseconds").humanize(true);
};
const timeToCountDown = (time: number | undefined): string => {
  if (!time) {
    return "--:--:--";
  }
  const now = moment.utc();
  const futureDate = moment(time);
  const diff = time - now.valueOf();
  if (diff < 0) {
    return "00:00:00";
  } else if (futureDate.diff(now, "days") > 0) {
    return timeToDuration(time);
  } else {
    const duration = moment.duration(diff, "milliseconds");
    const hours = `${duration.hours() < 10 ? "0" + duration.hours() : duration.hours()}`;
    const minutes = `${duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()}`;
    const seconds = `${duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()}`;
    return `${hours}:${minutes}:${seconds}`;
  }
};

const calculateRating = (reviewCountTotals: ReviewCount | undefined): number => {
  let rating = 0;
  let cpt = 0;
  if (reviewCountTotals) {
    for (const rate of Object.keys(reviewCountTotals)) {
      const count: number = reviewCountTotals[Number(rate) as keyof ReviewCount] || 0;
      cpt += count;
      rating += Number(rate) * count;
    }
  }
  return Math.round((cpt ? rating / cpt : 0) * 10) / 10;
};

const getCountryDetails = (name: string | undefined): { key: string; alpha2: string; alpha3: string; label: string; lang: LangCode } | null => {
  if (!name) {
    return null;
  }
  const cleanedName = name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
  let countryMatchkey = countryMatchKey[cleanedName];
  if (!countryMatchkey && name?.indexOf("The ") === 0) {
    countryMatchkey = countryMatchKey[name.replace("The ", "")];
  }

  const tempCountry = localCountryDetails[countryMatchkey];
  const defaultLang = tempCountry?.lang?.toLowerCase() || "en";
  return { ...tempCountry, lang: (defaultLang === "en" ? "en-US" : defaultLang) as LangCode };
};

const parseBusinessPreview = (business: Business): BusinessPreview => {
  return {
    id: business.id,
    name: business.name,
    phone: business.phone,
    email: business.email,
    logo: business.logo,
    currency: business.currency,
    description: business.description?.substring(0, 300), // limit the description on preview
    address: business.address,
    geohash: business.geohash,
    reviewCount: business.reviewCount,
    reviewCountTotals: business.reviewCountTotals,
    timeZone: business.timeZone,
    plan: business.plan || BillingPlan.basic,
  };
};
const parseUserPreview = (user: User | undefined): UserPreview => {
  return {
    id: user?.id,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    profileImage: user?.profileImage,
    stripeCustomerId: user?.stripeCustomerId,
    address: user?.address,
    phone: user?.phone,
  };
};

const textToCapital = (text: string | undefined): string | undefined => {
  return text?.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

const countryMatchKey: { [key: string]: string } = {
  "united states": "united states",
  us: "united states",
  usa: "united states",
  australia: "australia",
  au: "australia",
  aus: "australia",
  "new zealand": "new zealand",
  nz: "new zealand",
  nzl: "new zealand",
  france: "france",
  fr: "france",
  fra: "france",
  italy: "italy",
  it: "italy",
  ita: "italy",
  spain: "spain",
  es: "spain",
  esp: "spain",
  england: "england",
  gb: "united kingdom",
  gbr: "united kingdom",
  netherlands: "netherlands",
  nl: "netherlands",
  nld: "netherlands",
  albania: "albania",
  al: "albania",
  alb: "albania",
  algeria: "algeria",
  dz: "algeria",
  dza: "algeria",
  andorra: "andorra",
  ad: "andorra",
  and: "andorra",
  angola: "angola",
  ao: "angola",
  ago: "angola",
  anguilla: "anguilla",
  ai: "anguilla",
  aia: "anguilla",
  "antigua and barbuda": "antigua and barbuda",
  ag: "antigua and barbuda",
  atg: "antigua and barbuda",
  argentina: "argentina",
  ar: "argentina",
  arg: "argentina",
  armenia: "armenia",
  am: "armenia",
  arm: "armenia",
  aruba: "aruba",
  aw: "aruba",
  abw: "aruba",
  austria: "austria",
  at: "austria",
  aut: "austria",
  azerbaijan: "azerbaijan",
  az: "azerbaijan",
  aze: "azerbaijan",
  "the bahamas": "bahamas",
  bahamas: "bahamas",
  bs: "bahamas",
  bhs: "bahamas",
  bahrain: "bahrain",
  bh: "bahrain",
  bhr: "bahrain",
  bangladesh: "bangladesh",
  bd: "bangladesh",
  bgd: "bangladesh",
  barbados: "barbados",
  bb: "barbados",
  brb: "barbados",
  belarus: "belarus",
  by: "belarus",
  blr: "belarus",
  belgium: "belgium",
  be: "belgium",
  bel: "belgium",
  belize: "belize",
  bz: "belize",
  blz: "belize",
  benin: "benin",
  bj: "benin",
  ben: "benin",
  bermuda: "bermuda",
  bm: "bermuda",
  bmu: "bermuda",
  bhutan: "bhutan",
  bt: "bhutan",
  btn: "bhutan",
  bolivia: "bolivia",
  bo: "bolivia",
  bol: "bolivia",
  bonaire: "bonaire",
  bq: "caribbean netherlands",
  bes: "caribbean netherlands",
  "bosnia and herzegovina": "bosnia and herzegovina",
  ba: "bosnia and herzegovina",
  bih: "bosnia and herzegovina",
  botswana: "botswana",
  bw: "botswana",
  bwa: "botswana",
  brazil: "brazil",
  br: "brazil",
  bra: "brazil",
  "british virgin islands": "british virgin islands",
  vg: "british virgin islands",
  vgb: "british virgin islands",
  brunei: "brunei",
  bn: "brunei",
  brn: "brunei",
  bulgaria: "bulgaria",
  bg: "bulgaria",
  bgr: "bulgaria",
  "burkina faso": "burkina faso",
  bf: "burkina faso",
  bfa: "burkina faso",
  burundi: "burundi",
  bi: "burundi",
  bdi: "burundi",
  cambodia: "cambodia",
  kh: "cambodia",
  khm: "cambodia",
  cameroon: "cameroon",
  cm: "cameroon",
  cmr: "cameroon",
  canada: "canada",
  ca: "canada",
  can: "canada",
  "cape verde": "cape verde",
  cv: "cape verde",
  cpv: "cape verde",
  "cayman islands": "cayman islands",
  ky: "cayman islands",
  cym: "cayman islands",
  "central african republic": "central african republic",
  cf: "central african republic",
  caf: "central african republic",
  chad: "chad",
  td: "chad",
  tcd: "chad",
  "channel islands": "channel islands",
  gsy: "channel islands",
  chile: "chile",
  cl: "chile",
  chl: "chile",
  china: "china",
  cn: "china",
  chn: "china",
  colombia: "colombia",
  co: "colombia",
  col: "colombia",
  comoros: "comoros",
  km: "comoros",
  com: "comoros",
  congo: "congo",
  cg: "republic of the congo",
  cog: "republic of the congo",
  "cook islands": "cook islands",
  ck: "cook islands",
  cok: "cook islands",
  "costa rica": "costa rica",
  cr: "costa rica",
  cri: "costa rica",
  croatia: "croatia",
  hr: "croatia",
  hrv: "croatia",
  curacao: "curacao",
  cw: "curacao",
  cuw: "curacao",
  cyprus: "cyprus",
  cy: "cyprus",
  cyp: "cyprus",
  "czech republic": "czech republic",
  cz: "czech republic",
  cze: "czech republic",
  "cote d'ivoire": "cote d'ivoire",
  ci: "cote d'ivoire",
  civ: "cote d'ivoire",
  "democratic republic of congo": "democratic republic of congo",
  cd: "dr congo",
  cod: "dr congo",
  denmark: "denmark",
  dk: "denmark",
  dnk: "denmark",
  djibouti: "djibouti",
  dj: "djibouti",
  dji: "djibouti",
  dominica: "dominica",
  dm: "dominica",
  dma: "dominica",
  "dominican republic": "dominican republic",
  do: "dominican republic",
  dom: "dominican republic",
  "east timor": "east timor",
  tp: "east timor",
  tmp: "east timor",
  ecuador: "ecuador",
  ec: "ecuador",
  ecu: "ecuador",
  egypt: "egypt",
  eg: "egypt",
  egy: "egypt",
  "el salvador": "el salvador",
  sv: "el salvador",
  slv: "el salvador",
  "equatorial guinea": "equatorial guinea",
  gq: "equatorial guinea",
  gnq: "equatorial guinea",
  eritrea: "eritrea",
  er: "eritrea",
  eri: "eritrea",
  estonia: "estonia",
  ee: "estonia",
  est: "estonia",
  "eswatini (swaziland)": "eswatini (swaziland)",
  sz: "swaziland",
  swz: "swaziland",
  ethiopia: "ethiopia",
  et: "ethiopia",
  eth: "ethiopia",
  "falkland islands": "falkland islands",
  fk: "falkland islands",
  flk: "falkland islands",
  "faroe islands": "faroe islands",
  fo: "faroe islands",
  fro: "faroe islands",
  fiji: "fiji",
  fj: "fiji",
  fji: "fiji",
  finland: "finland",
  fi: "finland",
  fin: "finland",
  "french guiana": "french guiana",
  gf: "french guiana",
  guf: "french guiana",
  "french polynesia": "french polynesia",
  pf: "french polynesia",
  pyf: "french polynesia",
  gabon: "gabon",
  ga: "gabon",
  gab: "gabon",
  gambia: "gambia",
  gm: "gambia",
  gmb: "gambia",
  georgia: "georgia",
  ge: "georgia",
  geo: "georgia",
  germany: "germany",
  de: "germany",
  deu: "germany",
  ghana: "ghana",
  gh: "ghana",
  gha: "ghana",
  greece: "greece",
  gr: "greece",
  grc: "greece",
  greenland: "greenland",
  gl: "greenland",
  grl: "greenland",
  grenada: "grenada",
  gd: "grenada",
  grd: "grenada",
  guadeloupe: "guadeloupe",
  gp: "guadeloupe",
  glp: "guadeloupe",
  guam: "guam",
  gu: "guam",
  gum: "guam",
  guatemala: "guatemala",
  gt: "guatemala",
  gtm: "guatemala",
  guinea: "guinea",
  gn: "guinea",
  gin: "guinea",
  guyana: "guyana",
  gy: "guyana",
  guy: "guyana",
  haiti: "haiti",
  ht: "haiti",
  hti: "haiti",
  honduras: "honduras",
  hn: "honduras",
  hnd: "honduras",
  hungary: "hungary",
  hu: "hungary",
  hun: "hungary",
  iceland: "iceland",
  is: "iceland",
  isl: "iceland",
  india: "india",
  in: "india",
  ind: "india",
  indonesia: "indonesia",
  id: "indonesia",
  idn: "indonesia",
  iraq: "iraq",
  iq: "iraq",
  irq: "iraq",
  ireland: "ireland",
  ie: "ireland",
  irl: "ireland",
  israel: "israel",
  il: "israel",
  isr: "israel",
  jamaica: "jamaica",
  jm: "jamaica",
  jam: "jamaica",
  japan: "japan",
  jp: "japan",
  jpn: "japan",
  jordan: "jordan",
  jo: "jordan",
  jor: "jordan",
  kazakhstan: "kazakhstan",
  kz: "kazakhstan",
  kaz: "kazakhstan",
  kenya: "kenya",
  ke: "kenya",
  ken: "kenya",
  kosovo: "kosovo",
  xk: "kosovo",
  unk: "kosovo",
  kuwait: "kuwait",
  kw: "kuwait",
  kwt: "kuwait",
  kyrgyzstan: "kyrgyzstan",
  kg: "kyrgyzstan",
  kgz: "kyrgyzstan",
  laos: "laos",
  la: "laos",
  lao: "laos",
  latvia: "latvia",
  lv: "latvia",
  lva: "latvia",
  lebanon: "lebanon",
  lb: "lebanon",
  lbn: "lebanon",
  lesotho: "lesotho",
  ls: "lesotho",
  lso: "lesotho",
  liechtenstein: "liechtenstein",
  li: "liechtenstein",
  lie: "liechtenstein",
  lithuania: "lithuania",
  lt: "lithuania",
  ltu: "lithuania",
  luxembourg: "luxembourg",
  lu: "luxembourg",
  lux: "luxembourg",
  macedonia: "macedonia",
  mk: "macedonia",
  mkd: "macedonia",
  madagascar: "madagascar",
  mg: "madagascar",
  mdg: "madagascar",
  malawi: "malawi",
  mw: "malawi",
  mwi: "malawi",
  malaysia: "malaysia",
  my: "malaysia",
  mys: "malaysia",
  maldives: "maldives",
  mv: "maldives",
  mdv: "maldives",
  mali: "mali",
  ml: "mali",
  mli: "mali",
  malta: "malta",
  mt: "malta",
  mlt: "malta",
  "marshall islands": "marshall islands",
  mh: "marshall islands",
  mhl: "marshall islands",
  martinique: "martinique",
  mq: "martinique",
  mtq: "martinique",
  mauritania: "mauritania",
  mr: "mauritania",
  mrt: "mauritania",
  mauritius: "mauritius",
  mu: "mauritius",
  mus: "mauritius",
  mayotte: "mayotte",
  yt: "mayotte",
  myt: "mayotte",
  mexico: "mexico",
  mx: "mexico",
  mex: "mexico",
  moldova: "moldova",
  md: "moldova",
  mda: "moldova",
  monaco: "monaco",
  mc: "monaco",
  mco: "monaco",
  mongolia: "mongolia",
  mn: "mongolia",
  mng: "mongolia",
  montenegro: "montenegro",
  me: "montenegro",
  mne: "montenegro",
  montserrat: "montserrat",
  ms: "montserrat",
  msr: "montserrat",
  morocco: "morocco",
  ma: "morocco",
  mar: "morocco",
  mozambique: "mozambique",
  mz: "mozambique",
  moz: "mozambique",
  myanmar: "myanmar",
  mm: "myanmar",
  mmr: "myanmar",
  namibia: "namibia",
  na: "namibia",
  nam: "namibia",
  nepal: "nepal",
  np: "nepal",
  npl: "nepal",
  "new caledonia": "new caledonia",
  nc: "new caledonia",
  ncl: "new caledonia",
  nicaragua: "nicaragua",
  ni: "nicaragua",
  nic: "nicaragua",
  niger: "niger",
  ne: "niger",
  ner: "niger",
  nigeria: "nigeria",
  ng: "nigeria",
  nga: "nigeria",
  "northern ireland": "northern ireland",
  nir: "northern ireland",
  "northern mariana islands": "northern mariana islands",
  mp: "northern mariana islands",
  mnp: "northern mariana islands",
  norway: "norway",
  no: "norway",
  nor: "norway",
  oman: "oman",
  om: "oman",
  omn: "oman",
  pakistan: "pakistan",
  pk: "pakistan",
  pak: "pakistan",
  palau: "palau",
  pw: "palau",
  plw: "palau",
  "palestinian territories": "palestinian territories",
  ps: "palestine",
  pse: "palestine",
  panama: "panama",
  pa: "panama",
  pan: "panama",
  "papua new guinea": "papua new guinea",
  pg: "papua new guinea",
  png: "papua new guinea",
  paraguay: "paraguay",
  py: "paraguay",
  pry: "paraguay",
  peru: "peru",
  pe: "peru",
  per: "peru",
  philippines: "philippines",
  ph: "philippines",
  phl: "philippines",
  poland: "poland",
  pl: "poland",
  pol: "poland",
  portugal: "portugal",
  pt: "portugal",
  prt: "portugal",
  "puerto rico": "puerto rico",
  pr: "puerto rico",
  pri: "puerto rico",
  qatar: "qatar",
  qa: "qatar",
  qat: "qatar",
  romania: "romania",
  ro: "romania",
  rou: "romania",
  russia: "russia",
  ru: "russia",
  rus: "russia",
  rwanda: "rwanda",
  rw: "rwanda",
  rwa: "rwanda",
  "reunion island": "reunion island",
  re: "reunion island",
  reu: "reunion island",
  samoa: "samoa",
  ws: "samoa",
  wsm: "samoa",
  "san marino": "san marino",
  sm: "san marino",
  smr: "san marino",
  "saudi arabia": "saudi arabia",
  sa: "saudi arabia",
  sau: "saudi arabia",
  scotland: "scotland",
  sct: "scotland",
  senegal: "senegal",
  sn: "senegal",
  sen: "senegal",
  serbia: "serbia",
  rs: "serbia",
  srb: "serbia",
  seychelles: "seychelles",
  sc: "seychelles",
  syc: "seychelles",
  "sierra leone": "sierra leone",
  sl: "sierra leone",
  sle: "sierra leone",
  singapore: "singapore",
  sg: "singapore",
  sgp: "singapore",
  slovakia: "slovakia",
  sk: "slovakia",
  svk: "slovakia",
  slovenia: "slovenia",
  si: "slovenia",
  svn: "slovenia",
  somalia: "somalia",
  so: "somalia",
  som: "somalia",
  "south africa": "south africa",
  za: "south africa",
  zaf: "south africa",
  "south korea": "south korea",
  kr: "south korea",
  kor: "south korea",
  "sri lanka": "sri lanka",
  lk: "sri lanka",
  lka: "sri lanka",
  "saint barthelemy": "saint barthelemy",
  bl: "saint barthelemy",
  blm: "saint barthelemy",
  "saint kitts and nevis": "saint kitts and nevis",
  kn: "saint kitts and nevis",
  kna: "saint kitts and nevis",
  "st lucia": "st lucia",
  lc: "saint lucia",
  lca: "saint lucia",
  "sint maarten": "sint maarten",
  sx: "sint maarten",
  sxm: "sint maarten",
  "st vincent and the grenadines": "st vincent and the grenadines",
  vc: "saint vincent and the grenadines",
  vct: "saint vincent and the grenadines",
  suriname: "suriname",
  sr: "suriname",
  sur: "suriname",
  sweden: "sweden",
  se: "sweden",
  swe: "sweden",
  switzerland: "switzerland",
  ch: "switzerland",
  che: "switzerland",
  "sao tome and principe": "sao tome and principe",
  st: "sao tome and principe",
  stp: "sao tome and principe",
  taiwan: "taiwan",
  tw: "taiwan",
  twn: "taiwan",
  tajikistan: "tajikistan",
  tj: "tajikistan",
  tjk: "tajikistan",
  tanzania: "tanzania",
  tz: "tanzania",
  tza: "tanzania",
  thailand: "thailand",
  th: "thailand",
  tha: "thailand",
  togo: "togo",
  tg: "togo",
  tgo: "togo",
  tonga: "tonga",
  to: "tonga",
  ton: "tonga",
  "trinidad and tobago": "trinidad and tobago",
  tt: "trinidad and tobago",
  tto: "trinidad and tobago",
  tunisia: "tunisia",
  tn: "tunisia",
  tun: "tunisia",
  turkey: "turkey",
  tr: "turkey",
  tur: "turkey",
  turkmenistan: "turkmenistan",
  tm: "turkmenistan",
  tkm: "turkmenistan",
  "turks and caicos": "turks and caicos",
  tc: "turks and caicos islands",
  tca: "turks and caicos islands",
  "united states virgin islands": "united states virgin islands",
  vi: "united states virgin islands",
  vir: "united states virgin islands",
  uganda: "uganda",
  ug: "uganda",
  uga: "uganda",
  ukraine: "ukraine",
  ua: "ukraine",
  ukr: "ukraine",
  "united arab emirates": "united arab emirates",
  ae: "united arab emirates",
  are: "united arab emirates",
  uruguay: "uruguay",
  uy: "uruguay",
  ury: "uruguay",
  uzbekistan: "uzbekistan",
  uz: "uzbekistan",
  uzb: "uzbekistan",
  vanuatu: "vanuatu",
  vu: "vanuatu",
  vut: "vanuatu",
  venezuela: "venezuela",
  ve: "venezuela",
  ven: "venezuela",
  vietnam: "vietnam",
  vn: "vietnam",
  vnm: "vietnam",
  wales: "wales",
  wls: "wales",
  "western sahara": "western sahara",
  eh: "western sahara",
  esh: "western sahara",
  zambia: "zambia",
  zm: "zambia",
  zmb: "zambia",
  zimbabwe: "zimbabwe",
  zw: "zimbabwe",
  zwe: "zimbabwe",
  afghanistan: "afghanistan",
  af: "afghanistan",
  afg: "afghanistan",
  "aland islands": "aland islands",
  ax: "aland islands",
  ala: "aland islands",
  "american samoa": "american samoa",
  as: "american samoa",
  asm: "american samoa",
  antarctica: "antarctica",
  aq: "antarctica",
  ata: "antarctica",
  "french southern and antarctic lands": "french southern and antarctic lands",
  tf: "french southern and antarctic lands",
  atf: "french southern and antarctic lands",
  "bouvet island": "bouvet island",
  bv: "bouvet island",
  bvt: "bouvet island",
  "cocos (keeling) islands": "cocos (keeling) islands",
  cc: "cocos (keeling) islands",
  cck: "cocos (keeling) islands",
  "dr congo": "dr congo",
  "republic of the congo": "republic of the congo",
  cuba: "cuba",
  cu: "cuba",
  cub: "cuba",
  "christmas island": "christmas island",
  cx: "christmas island",
  cxr: "christmas island",
  micronesia: "micronesia",
  fm: "micronesia",
  fsm: "micronesia",
  "united kingdom": "united kingdom",
  guernsey: "guernsey",
  gg: "guernsey",
  ggy: "guernsey",
  gibraltar: "gibraltar",
  gi: "gibraltar",
  gib: "gibraltar",
  "guinea-bissau": "guinea-bissau",
  gw: "guinea-bissau",
  gnb: "guinea-bissau",
  "hong kong": "hong kong",
  hk: "hong kong",
  hkg: "hong kong",
  "heard island and mcdonald islands": "heard island and mcdonald islands",
  hm: "heard island and mcdonald islands",
  hmd: "heard island and mcdonald islands",
  "isle of man": "isle of man",
  im: "isle of man",
  imn: "isle of man",
  "british indian ocean territory": "british indian ocean territory",
  io: "british indian ocean territory",
  iot: "british indian ocean territory",
  iran: "iran",
  ir: "iran",
  irn: "iran",
  jersey: "jersey",
  je: "jersey",
  jey: "jersey",
  kiribati: "kiribati",
  ki: "kiribati",
  kir: "kiribati",
  liberia: "liberia",
  lr: "liberia",
  lbr: "liberia",
  libya: "libya",
  ly: "libya",
  lby: "libya",
  "saint lucia": "saint lucia",
  macau: "macau",
  mo: "macau",
  mac: "macau",
  "saint martin": "saint martin",
  mf: "saint martin",
  maf: "saint martin",
  "norfolk island": "norfolk island",
  nf: "norfolk island",
  nfk: "norfolk island",
  niue: "niue",
  nu: "niue",
  niu: "niue",
  nauru: "nauru",
  nr: "nauru",
  nru: "nauru",
  "pitcairn islands": "pitcairn islands",
  pn: "pitcairn islands",
  pcn: "pitcairn islands",
  "north korea": "north korea",
  kp: "north korea",
  prk: "north korea",
  palestine: "palestine",
  sudan: "sudan",
  sd: "sudan",
  sdn: "sudan",
  "south georgia": "south georgia",
  gs: "south georgia",
  sgs: "south georgia",
  "svalbard and jan mayen": "svalbard and jan mayen",
  sj: "svalbard and jan mayen",
  sjm: "svalbard and jan mayen",
  "solomon islands": "solomon islands",
  sb: "solomon islands",
  slb: "solomon islands",
  "saint pierre and miquelon": "saint pierre and miquelon",
  pm: "saint pierre and miquelon",
  spm: "saint pierre and miquelon",
  "south sudan": "south sudan",
  ss: "south sudan",
  ssd: "south sudan",
  swaziland: "swaziland",
  syria: "syria",
  sy: "syria",
  syr: "syria",
  "turks and caicos islands": "turks and caicos islands",
  tokelau: "tokelau",
  tk: "tokelau",
  tkl: "tokelau",
  "timor-leste": "timor-leste",
  tl: "timor-leste",
  tls: "timor-leste",
  tuvalu: "tuvalu",
  tv: "tuvalu",
  tuv: "tuvalu",
  "united states minor outlying islands": "united states minor outlying islands",
  um: "united states minor outlying islands",
  umi: "united states minor outlying islands",
  "vatican city": "vatican city",
  va: "vatican city",
  vat: "vatican city",
  "saint vincent and the grenadines": "saint vincent and the grenadines",
  "wallis and futuna": "wallis and futuna",
  wf: "wallis and futuna",
  wlf: "wallis and futuna",
  yemen: "yemen",
  ye: "yemen",
  yem: "yemen",
  "caribbean netherlands": "caribbean netherlands",
  "saint helena": "saint helena",
  sh: "saint helena",
  shn: "saint helena",
};

const localCountryDetails: { [key: string]: { key: string; label: string; alpha2: string; alpha3: string; lang?: string } } = {
  "united states": { key: "united states", label: "United States", lang: "en", alpha2: "US", alpha3: "USA" },
  australia: { key: "australia", label: "Australia", lang: "en", alpha2: "AU", alpha3: "AUS" },
  "new zealand": { key: "new zealand", label: "New Zealand", lang: "en", alpha2: "NZ", alpha3: "NZL" },
  france: { key: "france", label: "France", lang: "fr", alpha2: "FR", alpha3: "FRA" },
  italy: { key: "italy", label: "Italy", alpha2: "IT", alpha3: "ITA" },
  spain: { key: "spain", label: "Spain", alpha2: "ES", alpha3: "ESP" },
  england: { key: "england", label: "England", lang: "en", alpha2: "GB", alpha3: "GBR" },
  "united kingdom": { key: "united kingdom", label: "United Kingdom", lang: "en", alpha2: "GB", alpha3: "GBR" },
  netherlands: { key: "netherlands", label: "Netherlands", alpha2: "NL", alpha3: "NLD" },
  albania: { key: "albania", label: "Albania", alpha2: "AL", alpha3: "ALB" },
  algeria: { key: "algeria", label: "Algeria", alpha2: "DZ", alpha3: "DZA" },
  andorra: { key: "andorra", label: "Andorra", alpha2: "AD", alpha3: "AND" },
  angola: { key: "angola", label: "Angola", alpha2: "AO", alpha3: "AGO" },
  anguilla: { key: "anguilla", label: "Anguilla", alpha2: "AI", alpha3: "AIA" },
  "antigua and barbuda": { key: "antigua and barbuda", label: "Antigua and Barbuda", alpha2: "AG", alpha3: "ATG" },
  argentina: { key: "argentina", label: "Argentina", alpha2: "AR", alpha3: "ARG" },
  armenia: { key: "armenia", label: "Armenia", alpha2: "AM", alpha3: "ARM" },
  aruba: { key: "aruba", label: "Aruba", alpha2: "AW", alpha3: "ABW" },
  austria: { key: "austria", label: "Austria", alpha2: "AT", alpha3: "AUT" },
  azerbaijan: { key: "azerbaijan", label: "Azerbaijan", alpha2: "AZ", alpha3: "AZE" },
  bahamas: { key: "bahamas", label: "Bahamas", alpha2: "BS", alpha3: "BHS" },
  bahrain: { key: "bahrain", label: "Bahrain", alpha2: "BH", alpha3: "BHR" },
  bangladesh: { key: "bangladesh", label: "Bangladesh", alpha2: "BD", alpha3: "BGD" },
  barbados: { key: "barbados", label: "Barbados", alpha2: "BB", alpha3: "BRB" },
  belarus: { key: "belarus", label: "Belarus", alpha2: "BY", alpha3: "BLR" },
  belgium: { key: "belgium", label: "Belgium", alpha2: "BE", alpha3: "BEL" },
  belize: { key: "belize", label: "Belize", alpha2: "BZ", alpha3: "BLZ" },
  benin: { key: "benin", label: "Benin", alpha2: "BJ", alpha3: "BEN" },
  bermuda: { key: "bermuda", label: "Bermuda", alpha2: "BM", alpha3: "BMU" },
  bhutan: { key: "bhutan", label: "Bhutan", alpha2: "BT", alpha3: "BTN" },
  bolivia: { key: "bolivia", label: "Bolivia", alpha2: "BO", alpha3: "BOL" },
  bonaire: { key: "bonaire", label: "Bonaire", alpha2: "BQ", alpha3: "BES" },
  "caribbean netherlands": { key: "caribbean netherlands", label: "Caribbean Netherlands", alpha2: "BQ", alpha3: "BES" },
  "bosnia and herzegovina": { key: "bosnia and herzegovina", label: "Bosnia and Herzegovina", alpha2: "BA", alpha3: "BIH" },
  botswana: { key: "botswana", label: "Botswana", alpha2: "BW", alpha3: "BWA" },
  brazil: { key: "brazil", label: "Brazil", alpha2: "BR", alpha3: "BRA" },
  "british virgin islands": { key: "british virgin islands", label: "British Virgin Islands", alpha2: "VG", alpha3: "VGB" },
  brunei: { key: "brunei", label: "Brunei", alpha2: "BN", alpha3: "BRN" },
  bulgaria: { key: "bulgaria", label: "Bulgaria", alpha2: "BG", alpha3: "BGR" },
  "burkina faso": { key: "burkina faso", label: "Burkina Faso", alpha2: "BF", alpha3: "BFA" },
  burundi: { key: "burundi", label: "Burundi", alpha2: "BI", alpha3: "BDI" },
  cambodia: { key: "cambodia", label: "Cambodia", alpha2: "KH", alpha3: "KHM" },
  cameroon: { key: "cameroon", label: "Cameroon", alpha2: "CM", alpha3: "CMR" },
  canada: { key: "canada", label: "Canada", alpha2: "CA", alpha3: "CAN" },
  "cape verde": { key: "cape verde", label: "Cape Verde", alpha2: "CV", alpha3: "CPV" },
  "cayman islands": { key: "cayman islands", label: "Cayman Islands", alpha2: "KY", alpha3: "CYM" },
  "central african republic": { key: "central african republic", label: "Central African Republic", alpha2: "CF", alpha3: "CAF" },
  chad: { key: "chad", label: "Chad", alpha2: "TD", alpha3: "TCD" },
  "channel islands": { key: "channel islands", label: "Channel Islands", alpha2: "GB", alpha3: "GSY" },
  chile: { key: "chile", label: "Chile", alpha2: "CL", alpha3: "CHL" },
  china: { key: "china", label: "China", alpha2: "CN", alpha3: "CHN" },
  colombia: { key: "colombia", label: "Colombia", alpha2: "CO", alpha3: "COL" },
  comoros: { key: "comoros", label: "Comoros", alpha2: "KM", alpha3: "COM" },
  congo: { key: "congo", label: "Congo", alpha2: "CG", alpha3: "COG" },
  "republic of the congo": { key: "republic of the congo", label: "Republic of the Congo", alpha2: "CG", alpha3: "COG" },
  "cook islands": { key: "cook islands", label: "Cook Islands", alpha2: "CK", alpha3: "COK" },
  "costa rica": { key: "costa rica", label: "Costa Rica", alpha2: "CR", alpha3: "CRI" },
  croatia: { key: "croatia", label: "Croatia", alpha2: "HR", alpha3: "HRV" },
  curacao: { key: "curacao", label: "Curacao", alpha2: "CW", alpha3: "CUW" },
  cyprus: { key: "cyprus", label: "Cyprus", alpha2: "CY", alpha3: "CYP" },
  "czech republic": { key: "czech republic", label: "Czech Republic", alpha2: "CZ", alpha3: "CZE" },
  "cote d'ivoire": { key: "cote d'ivoire", label: "Côte d'Ivoire", alpha2: "CI", alpha3: "CIV" },
  "democratic republic of congo": { key: "democratic republic of congo", label: "Democratic Republic of Congo", alpha2: "CD", alpha3: "COD" },
  "dr congo": { key: "dr congo", label: "DR Congo", alpha2: "CD", alpha3: "COD" },
  denmark: { key: "denmark", label: "Denmark", alpha2: "DK", alpha3: "DNK" },
  djibouti: { key: "djibouti", label: "Djibouti", alpha2: "DJ", alpha3: "DJI" },
  dominica: { key: "dominica", label: "Dominica", alpha2: "DM", alpha3: "DMA" },
  "dominican republic": { key: "dominican republic", label: "Dominican Republic", alpha2: "DO", alpha3: "DOM" },
  "east timor": { key: "east timor", label: "East Timor", alpha2: "TP", alpha3: "TMP" },
  ecuador: { key: "ecuador", label: "Ecuador", alpha2: "EC", alpha3: "ECU" },
  egypt: { key: "egypt", label: "Egypt", alpha2: "EG", alpha3: "EGY" },
  "el salvador": { key: "el salvador", label: "El Salvador", alpha2: "SV", alpha3: "SLV" },
  "equatorial guinea": { key: "equatorial guinea", label: "Equatorial Guinea", alpha2: "GQ", alpha3: "GNQ" },
  eritrea: { key: "eritrea", label: "Eritrea", alpha2: "ER", alpha3: "ERI" },
  estonia: { key: "estonia", label: "Estonia", alpha2: "EE", alpha3: "EST" },
  "eswatini (swaziland)": { key: "eswatini (swaziland)", label: "Eswatini (Swaziland)", alpha2: "SZ", alpha3: "SWZ" },
  swaziland: { key: "swaziland", label: "Swaziland", alpha2: "SZ", alpha3: "SWZ" },
  ethiopia: { key: "ethiopia", label: "Ethiopia", alpha2: "ET", alpha3: "ETH" },
  "falkland islands": { key: "falkland islands", label: "Falkland Islands", alpha2: "FK", alpha3: "FLK" },
  "faroe islands": { key: "faroe islands", label: "Faroe Islands", alpha2: "FO", alpha3: "FRO" },
  fiji: { key: "fiji", label: "Fiji", alpha2: "FJ", alpha3: "FJI" },
  finland: { key: "finland", label: "Finland", alpha2: "FI", alpha3: "FIN" },
  "french guiana": { key: "french guiana", label: "French Guiana", alpha2: "GF", alpha3: "GUF" },
  "french polynesia": { key: "french polynesia", label: "French Polynesia", alpha2: "PF", alpha3: "PYF" },
  gabon: { key: "gabon", label: "Gabon", alpha2: "GA", alpha3: "GAB" },
  gambia: { key: "gambia", label: "Gambia", alpha2: "GM", alpha3: "GMB" },
  georgia: { key: "georgia", label: "Georgia", alpha2: "GE", alpha3: "GEO" },
  germany: { key: "germany", label: "Germany", alpha2: "DE", alpha3: "DEU" },
  ghana: { key: "ghana", label: "Ghana", alpha2: "GH", alpha3: "GHA" },
  greece: { key: "greece", label: "Greece", alpha2: "GR", alpha3: "GRC" },
  greenland: { key: "greenland", label: "Greenland", alpha2: "GL", alpha3: "GRL" },
  grenada: { key: "grenada", label: "Grenada", alpha2: "GD", alpha3: "GRD" },
  guadeloupe: { key: "guadeloupe", label: "Guadeloupe", alpha2: "GP", alpha3: "GLP" },
  guam: { key: "guam", label: "Guam", alpha2: "GU", alpha3: "GUM" },
  guatemala: { key: "guatemala", label: "Guatemala", alpha2: "GT", alpha3: "GTM" },
  guinea: { key: "guinea", label: "Guinea", alpha2: "GN", alpha3: "GIN" },
  guyana: { key: "guyana", label: "Guyana", alpha2: "GY", alpha3: "GUY" },
  haiti: { key: "haiti", label: "Haiti", alpha2: "HT", alpha3: "HTI" },
  honduras: { key: "honduras", label: "Honduras", alpha2: "HN", alpha3: "HND" },
  hungary: { key: "hungary", label: "Hungary", alpha2: "HU", alpha3: "HUN" },
  iceland: { key: "iceland", label: "Iceland", alpha2: "IS", alpha3: "ISL" },
  india: { key: "india", label: "India", alpha2: "IN", alpha3: "IND" },
  indonesia: { key: "indonesia", label: "Indonesia", alpha2: "ID", alpha3: "IDN" },
  iraq: { key: "iraq", label: "Iraq", alpha2: "IQ", alpha3: "IRQ" },
  ireland: { key: "ireland", label: "Ireland", alpha2: "IE", alpha3: "IRL" },
  israel: { key: "israel", label: "Israel", lang: "he", alpha2: "IL", alpha3: "ISR" },
  jamaica: { key: "jamaica", label: "Jamaica", alpha2: "JM", alpha3: "JAM" },
  japan: { key: "japan", label: "Japan", alpha2: "JP", alpha3: "JPN" },
  jordan: { key: "jordan", label: "Jordan", alpha2: "JO", alpha3: "JOR" },
  kazakhstan: { key: "kazakhstan", label: "Kazakhstan", alpha2: "KZ", alpha3: "KAZ" },
  kenya: { key: "kenya", label: "Kenya", alpha2: "KE", alpha3: "KEN" },
  kosovo: { key: "kosovo", label: "Kosovo", alpha2: "XK", alpha3: "UNK" },
  kuwait: { key: "kuwait", label: "Kuwait", alpha2: "KW", alpha3: "KWT" },
  kyrgyzstan: { key: "kyrgyzstan", label: "Kyrgyzstan", alpha2: "KG", alpha3: "KGZ" },
  laos: { key: "laos", label: "Laos", alpha2: "LA", alpha3: "LAO" },
  latvia: { key: "latvia", label: "Latvia", alpha2: "LV", alpha3: "LVA" },
  lebanon: { key: "lebanon", label: "Lebanon", alpha2: "LB", alpha3: "LBN" },
  lesotho: { key: "lesotho", label: "Lesotho", alpha2: "LS", alpha3: "LSO" },
  liechtenstein: { key: "liechtenstein", label: "Liechtenstein", alpha2: "LI", alpha3: "LIE" },
  lithuania: { key: "lithuania", label: "Lithuania", alpha2: "LT", alpha3: "LTU" },
  luxembourg: { key: "luxembourg", label: "Luxembourg", alpha2: "LU", alpha3: "LUX" },
  macedonia: { key: "macedonia", label: "Macedonia", alpha2: "MK", alpha3: "MKD" },
  madagascar: { key: "madagascar", label: "Madagascar", alpha2: "MG", alpha3: "MDG" },
  malawi: { key: "malawi", label: "Malawi", alpha2: "MW", alpha3: "MWI" },
  malaysia: { key: "malaysia", label: "Malaysia", alpha2: "MY", alpha3: "MYS" },
  maldives: { key: "maldives", label: "Maldives", alpha2: "MV", alpha3: "MDV" },
  mali: { key: "mali", label: "Mali", alpha2: "ML", alpha3: "MLI" },
  malta: { key: "malta", label: "Malta", alpha2: "MT", alpha3: "MLT" },
  "marshall islands": { key: "marshall islands", label: "Marshall Islands", alpha2: "MH", alpha3: "MHL" },
  martinique: { key: "martinique", label: "Martinique", alpha2: "MQ", alpha3: "MTQ" },
  mauritania: { key: "mauritania", label: "Mauritania", alpha2: "MR", alpha3: "MRT" },
  mauritius: { key: "mauritius", label: "Mauritius", alpha2: "MU", alpha3: "MUS" },
  mayotte: { key: "mayotte", label: "Mayotte", alpha2: "YT", alpha3: "MYT" },
  mexico: { key: "mexico", label: "Mexico", alpha2: "MX", alpha3: "MEX" },
  moldova: { key: "moldova", label: "Moldova", alpha2: "MD", alpha3: "MDA" },
  monaco: { key: "monaco", label: "Monaco", alpha2: "MC", alpha3: "MCO" },
  mongolia: { key: "mongolia", label: "Mongolia", alpha2: "MN", alpha3: "MNG" },
  montenegro: { key: "montenegro", label: "Montenegro", alpha2: "ME", alpha3: "MNE" },
  montserrat: { key: "montserrat", label: "Montserrat", alpha2: "MS", alpha3: "MSR" },
  morocco: { key: "morocco", label: "Morocco", alpha2: "MA", alpha3: "MAR" },
  mozambique: { key: "mozambique", label: "Mozambique", alpha2: "MZ", alpha3: "MOZ" },
  myanmar: { key: "myanmar", label: "Myanmar", alpha2: "MM", alpha3: "MMR" },
  namibia: { key: "namibia", label: "Namibia", alpha2: "NA", alpha3: "NAM" },
  nepal: { key: "nepal", label: "Nepal", alpha2: "NP", alpha3: "NPL" },
  "new caledonia": { key: "new caledonia", label: "New Caledonia", alpha2: "NC", alpha3: "NCL" },
  nicaragua: { key: "nicaragua", label: "Nicaragua", alpha2: "NI", alpha3: "NIC" },
  niger: { key: "niger", label: "Niger", alpha2: "NE", alpha3: "NER" },
  nigeria: { key: "nigeria", label: "Nigeria", alpha2: "NG", alpha3: "NGA" },
  "northern ireland": { key: "northern ireland", label: "Northern Ireland", alpha2: "GB", alpha3: "NIR" },
  "northern mariana islands": { key: "northern mariana islands", label: "Northern Mariana Islands", alpha2: "MP", alpha3: "MNP" },
  norway: { key: "norway", label: "Norway", alpha2: "NO", alpha3: "NOR" },
  oman: { key: "oman", label: "Oman", alpha2: "OM", alpha3: "OMN" },
  pakistan: { key: "pakistan", label: "Pakistan", alpha2: "PK", alpha3: "PAK" },
  palau: { key: "palau", label: "Palau", alpha2: "PW", alpha3: "PLW" },
  "palestinian territories": { key: "palestinian territories", label: "Palestinian Territories", alpha2: "PS", alpha3: "PSE" },
  palestine: { key: "palestine", label: "Palestine", alpha2: "PS", alpha3: "PSE" },
  panama: { key: "panama", label: "Panama", alpha2: "PA", alpha3: "PAN" },
  "papua new guinea": { key: "papua new guinea", label: "Papua New Guinea", alpha2: "PG", alpha3: "PNG" },
  paraguay: { key: "paraguay", label: "Paraguay", alpha2: "PY", alpha3: "PRY" },
  peru: { key: "peru", label: "Peru", alpha2: "PE", alpha3: "PER" },
  philippines: { key: "philippines", label: "Philippines", alpha2: "PH", alpha3: "PHL" },
  poland: { key: "poland", label: "Poland", alpha2: "PL", alpha3: "POL" },
  portugal: { key: "portugal", label: "Portugal", alpha2: "PT", alpha3: "PRT" },
  "puerto rico": { key: "puerto rico", label: "Puerto Rico", alpha2: "PR", alpha3: "PRI" },
  qatar: { key: "qatar", label: "Qatar", alpha2: "QA", alpha3: "QAT" },
  romania: { key: "romania", label: "Romania", alpha2: "RO", alpha3: "ROU" },
  russia: { key: "russia", label: "Russia", alpha2: "RU", alpha3: "RUS" },
  rwanda: { key: "rwanda", label: "Rwanda", alpha2: "RW", alpha3: "RWA" },
  "reunion island": { key: "reunion island", label: "Réunion Island", alpha2: "RE", alpha3: "REU" },
  samoa: { key: "samoa", label: "Samoa", alpha2: "WS", alpha3: "WSM" },
  "san marino": { key: "san marino", label: "San Marino", alpha2: "SM", alpha3: "SMR" },
  "saudi arabia": { key: "saudi arabia", label: "Saudi Arabia", alpha2: "SA", alpha3: "SAU" },
  scotland: { key: "scotland", label: "Scotland", alpha2: "GB", alpha3: "SCT" },
  senegal: { key: "senegal", label: "Senegal", alpha2: "SN", alpha3: "SEN" },
  serbia: { key: "serbia", label: "Serbia", alpha2: "RS", alpha3: "SRB" },
  seychelles: { key: "seychelles", label: "Seychelles", alpha2: "SC", alpha3: "SYC" },
  "sierra leone": { key: "sierra leone", label: "Sierra Leone", alpha2: "SL", alpha3: "SLE" },
  singapore: { key: "singapore", label: "Singapore", alpha2: "SG", alpha3: "SGP" },
  slovakia: { key: "slovakia", label: "Slovakia", alpha2: "SK", alpha3: "SVK" },
  slovenia: { key: "slovenia", label: "Slovenia", alpha2: "SI", alpha3: "SVN" },
  somalia: { key: "somalia", label: "Somalia", alpha2: "SO", alpha3: "SOM" },
  "south africa": { key: "south africa", label: "South Africa", alpha2: "ZA", alpha3: "ZAF" },
  "south korea": { key: "south korea", label: "South Korea", alpha2: "KR", alpha3: "KOR" },
  "sri lanka": { key: "sri lanka", label: "Sri Lanka", alpha2: "LK", alpha3: "LKA" },
  "saint barthelemy": { key: "saint barthelemy", label: "Saint Barthélemy", alpha2: "BL", alpha3: "BLM" },
  "saint kitts and nevis": { key: "saint kitts and nevis", label: "Saint Kitts and Nevis", alpha2: "KN", alpha3: "KNA" },
  "st lucia": { key: "st lucia", label: "St Lucia", alpha2: "LC", alpha3: "LCA" },
  "saint lucia": { key: "saint lucia", label: "Saint Lucia", alpha2: "LC", alpha3: "LCA" },
  "sint maarten": { key: "sint maarten", label: "Sint Maarten", alpha2: "SX", alpha3: "SXM" },
  "st vincent and the grenadines": { key: "st vincent and the grenadines", label: "St Vincent and the Grenadines", alpha2: "VC", alpha3: "VCT" },
  "saint vincent and the grenadines": { key: "saint vincent and the grenadines", label: "Saint Vincent and the Grenadines", alpha2: "VC", alpha3: "VCT" },
  suriname: { key: "suriname", label: "Suriname", alpha2: "SR", alpha3: "SUR" },
  sweden: { key: "sweden", label: "Sweden", alpha2: "SE", alpha3: "SWE" },
  switzerland: { key: "switzerland", label: "Switzerland", alpha2: "CH", alpha3: "CHE" },
  "sao tome and principe": { key: "sao tome and principe", label: "Sao Tome and Principe", alpha2: "ST", alpha3: "STP" },
  taiwan: { key: "taiwan", label: "Taiwan", alpha2: "TW", alpha3: "TWN" },
  tajikistan: { key: "tajikistan", label: "Tajikistan", alpha2: "TJ", alpha3: "TJK" },
  tanzania: { key: "tanzania", label: "Tanzania", alpha2: "TZ", alpha3: "TZA" },
  thailand: { key: "thailand", label: "Thailand", alpha2: "TH", alpha3: "THA" },
  togo: { key: "togo", label: "Togo", alpha2: "TG", alpha3: "TGO" },
  tonga: { key: "tonga", label: "Tonga", alpha2: "TO", alpha3: "TON" },
  "trinidad and tobago": { key: "trinidad and tobago", label: "Trinidad and Tobago", alpha2: "TT", alpha3: "TTO" },
  tunisia: { key: "tunisia", label: "Tunisia", alpha2: "TN", alpha3: "TUN" },
  turkey: { key: "turkey", label: "Turkey", alpha2: "TR", alpha3: "TUR" },
  turkmenistan: { key: "turkmenistan", label: "Turkmenistan", alpha2: "TM", alpha3: "TKM" },
  "turks and caicos": { key: "turks and caicos", label: "Turks and Caicos", alpha2: "TC", alpha3: "TCA" },
  "turks and caicos islands": { key: "turks and caicos islands", label: "Turks and Caicos Islands", alpha2: "TC", alpha3: "TCA" },
  "united states virgin islands": { key: "united states virgin islands", label: "United States Virgin Islands", alpha2: "VI", alpha3: "VIR" },
  uganda: { key: "uganda", label: "Uganda", alpha2: "UG", alpha3: "UGA" },
  ukraine: { key: "ukraine", label: "Ukraine", alpha2: "UA", alpha3: "UKR" },
  "united arab emirates": { key: "united arab emirates", label: "United Arab Emirates", alpha2: "AE", alpha3: "ARE" },
  uruguay: { key: "uruguay", label: "Uruguay", alpha2: "UY", alpha3: "URY" },
  uzbekistan: { key: "uzbekistan", label: "Uzbekistan", alpha2: "UZ", alpha3: "UZB" },
  vanuatu: { key: "vanuatu", label: "Vanuatu", alpha2: "VU", alpha3: "VUT" },
  venezuela: { key: "venezuela", label: "Venezuela", alpha2: "VE", alpha3: "VEN" },
  vietnam: { key: "vietnam", label: "Vietnam", alpha2: "VN", alpha3: "VNM" },
  wales: { key: "wales", label: "Wales", alpha2: "GB", alpha3: "WLS" },
  "western sahara": { key: "western sahara", label: "Western Sahara", alpha2: "EH", alpha3: "ESH" },
  zambia: { key: "zambia", label: "Zambia", alpha2: "ZM", alpha3: "ZMB" },
  zimbabwe: { key: "zimbabwe", label: "Zimbabwe", alpha2: "ZW", alpha3: "ZWE" },
  afghanistan: { key: "afghanistan", label: "Afghanistan", alpha2: "AF", alpha3: "AFG" },
  "aland islands": { key: "aland islands", label: "Åland Islands", alpha2: "AX", alpha3: "ALA" },
  "american samoa": { key: "american samoa", label: "American Samoa", alpha2: "AS", alpha3: "ASM" },
  antarctica: { key: "antarctica", label: "Antarctica", alpha2: "AQ", alpha3: "ATA" },
  "french southern and antarctic lands": { key: "french southern and antarctic lands", label: "French Southern and Antarctic Lands", alpha2: "TF", alpha3: "ATF" },
  "bouvet island": { key: "bouvet island", label: "Bouvet Island", alpha2: "BV", alpha3: "BVT" },
  "cocos (keeling) islands": { key: "cocos (keeling) islands", label: "Cocos (Keeling) Islands", alpha2: "CC", alpha3: "CCK" },
  cuba: { key: "cuba", label: "Cuba", alpha2: "CU", alpha3: "CUB" },
  "christmas island": { key: "christmas island", label: "Christmas Island", alpha2: "CX", alpha3: "CXR" },
  micronesia: { key: "micronesia", label: "Micronesia", alpha2: "FM", alpha3: "FSM" },
  guernsey: { key: "guernsey", label: "Guernsey", alpha2: "GG", alpha3: "GGY" },
  gibraltar: { key: "gibraltar", label: "Gibraltar", alpha2: "GI", alpha3: "GIB" },
  "guinea-bissau": { key: "guinea-bissau", label: "Guinea-Bissau", alpha2: "GW", alpha3: "GNB" },
  "hong kong": { key: "hong kong", label: "Hong Kong", alpha2: "HK", alpha3: "HKG" },
  "heard island and mcdonald islands": { key: "heard island and mcdonald islands", label: "Heard Island and McDonald Islands", alpha2: "HM", alpha3: "HMD" },
  "isle of man": { key: "isle of man", label: "Isle of Man", alpha2: "IM", alpha3: "IMN" },
  "british indian ocean territory": { key: "british indian ocean territory", label: "British Indian Ocean Territory", alpha2: "IO", alpha3: "IOT" },
  iran: { key: "iran", label: "Iran", alpha2: "IR", alpha3: "IRN" },
  jersey: { key: "jersey", label: "Jersey", alpha2: "JE", alpha3: "JEY" },
  kiribati: { key: "kiribati", label: "Kiribati", alpha2: "KI", alpha3: "KIR" },
  liberia: { key: "liberia", label: "Liberia", alpha2: "LR", alpha3: "LBR" },
  libya: { key: "libya", label: "Libya", alpha2: "LY", alpha3: "LBY" },
  macau: { key: "macau", label: "Macau", alpha2: "MO", alpha3: "MAC" },
  "saint martin": { key: "saint martin", label: "Saint Martin", alpha2: "MF", alpha3: "MAF" },
  "norfolk island": { key: "norfolk island", label: "Norfolk Island", alpha2: "NF", alpha3: "NFK" },
  niue: { key: "niue", label: "Niue", alpha2: "NU", alpha3: "NIU" },
  nauru: { key: "nauru", label: "Nauru", alpha2: "NR", alpha3: "NRU" },
  "pitcairn islands": { key: "pitcairn islands", label: "Pitcairn Islands", alpha2: "PN", alpha3: "PCN" },
  "north korea": { key: "north korea", label: "North Korea", alpha2: "KP", alpha3: "PRK" },
  sudan: { key: "sudan", label: "Sudan", alpha2: "SD", alpha3: "SDN" },
  "south georgia": { key: "south georgia", label: "South Georgia", alpha2: "GS", alpha3: "SGS" },
  "svalbard and jan mayen": { key: "svalbard and jan mayen", label: "Svalbard and Jan Mayen", alpha2: "SJ", alpha3: "SJM" },
  "solomon islands": { key: "solomon islands", label: "Solomon Islands", alpha2: "SB", alpha3: "SLB" },
  "saint pierre and miquelon": { key: "saint pierre and miquelon", label: "Saint Pierre and Miquelon", alpha2: "PM", alpha3: "SPM" },
  "south sudan": { key: "south sudan", label: "South Sudan", alpha2: "SS", alpha3: "SSD" },
  syria: { key: "syria", label: "Syria", alpha2: "SY", alpha3: "SYR" },
  tokelau: { key: "tokelau", label: "Tokelau", alpha2: "TK", alpha3: "TKL" },
  "timor-leste": { key: "timor-leste", label: "Timor-Leste", alpha2: "TL", alpha3: "TLS" },
  tuvalu: { key: "tuvalu", label: "Tuvalu", alpha2: "TV", alpha3: "TUV" },
  "united states minor outlying islands": { key: "united states minor outlying islands", label: "United States Minor Outlying Islands", alpha2: "UM", alpha3: "UMI" },
  "vatican city": { key: "vatican city", label: "Vatican City", alpha2: "VA", alpha3: "VAT" },
  "wallis and futuna": { key: "wallis and futuna", label: "Wallis and Futuna", alpha2: "WF", alpha3: "WLF" },
  yemen: { key: "yemen", label: "Yemen", alpha2: "YE", alpha3: "YEM" },
  "saint helena": { key: "saint helena", label: "Saint Helena", alpha2: "SH", alpha3: "SHN" },
};

const currenciesList: { [code: string]: Currencies } = {
  AED: { exchange: true, stripe: true, code: "AED", name: "United Arab Emirates Dirham", countries: ["AE"] },
  AUD: {
    exchange: true,
    stripe: true,
    symbol: "A$",
    logo: "dollar-sign",
    code: "AUD",
    name: "Australian Dollar",
    countries: ["AU", "CX", "CC", "HM", "KI", "NR", "NF", "TV"],
  },
  BRL: { exchange: true, stripe: true, code: "BRL", name: "Brazilian Real", countries: ["BR"] },
  CAD: {
    exchange: true,
    stripe: true,
    symbol: "C$",
    logo: "dollar-sign",
    code: "CAD",
    name: "Canadian Dollar",
    countries: ["CA"],
  },
  CHF: { exchange: true, stripe: true, code: "CHF", name: "Swiss Franc", countries: ["LI", "CH"] },
  CNY: { exchange: true, stripe: true, code: "CNY", name: "Chinese Yuan Renminbi", countries: [] },
  CLP: { exchange: false, stripe: true, code: "CLP", name: "Chilean Peso", countries: ["CL"] },
  CZK: { exchange: false, stripe: true, code: "CZK", name: "Czech Koruna", countries: ["CZ"] },
  DKK: { exchange: true, stripe: true, code: "DKK", name: "Danish Krone", countries: ["DK", "FO", "GL"] },
  EGP: {
    exchange: false,
    stripe: true,
    symbol: "E£",
    logo: "pound-sign",
    code: "EGP",
    name: "Egyptian Pound",
    countries: ["EG"],
  },
  EUR: {
    exchange: true,
    stripe: true,
    symbol: "€",
    logo: "euro-sign",
    code: "EUR",
    name: "Euro",
    countries: [
      "AX",
      "AD",
      "AT",
      "BE",
      "CY",
      "EE",
      "FI",
      "FR",
      "GF",
      "TF",
      "DE",
      "GR",
      "GP",
      "IE",
      "IT",
      "LU",
      "LT",
      "MT",
      "MQ",
      "YT",
      "MC",
      "ME",
      "NL",
      "PT",
      "RE",
      "BL",
      "MF",
      "PM",
      "RU",
      "SM",
      "SK",
      "SI",
      "ES",
      "VA",
    ],
  },
  FJD: {
    exchange: true,
    stripe: true,
    symbol: "F$",
    logo: "dollar-sign",
    code: "FJD",
    name: "Fijian Dollar",
    countries: ["FJ"],
  },
  GBP: {
    exchange: true,
    stripe: true,
    symbol: "£",
    logo: "pound-sign",
    code: "GBP",
    name: "British Pound",
    countries: ["GG", "IM", "JE", "GB"],
  },
  GEL: { exchange: false, stripe: true, code: "GEL", name: "Georgian Lari", countries: ["GE"] },
  GTQ: { exchange: false, stripe: true, code: "GTQ", name: "Guatemalan Quetzal", countries: ["GT"] },
  HKD: {
    exchange: true,
    stripe: true,
    symbol: "H$",
    logo: "dollar-sign",
    code: "HKD",
    name: "Hong Kong Dollar",
    countries: ["HK"],
  },
  IDR: { exchange: true, stripe: true, code: "IDR", name: "Indonesian Rupiah", countries: ["ID"] },
  ILS: {
    exchange: false,
    stripe: true,
    symbol: "₪",
    logo: "shekel-sign",
    code: "ILS",
    name: "Israeli New Shekel",
    countries: ["IL"],
  },
  INR: {
    exchange: true,
    stripe: true,
    symbol: "₹",
    logo: "rupee-sign",
    code: "INR",
    name: "Indian Rupee",
    countries: ["BT", "IN"],
  },
  ISK: { exchange: true, stripe: true, code: "ISK", name: "Icelandic Króna", countries: ["IS"] },
  JPY: {
    exchange: true,
    stripe: true,
    symbol: "¥",
    logo: "yen-sign",
    code: "JPY",
    name: "Japanese Yen",
    countries: ["JP"],
  },
  KRW: {
    exchange: true,
    stripe: true,
    symbol: "₩",
    logo: "won-sign",
    code: "KRW",
    name: "Korean Won",
    countries: [],
  },
  KES: { exchange: false, stripe: true, code: "KES", name: "Kenyan Shilling", countries: ["KE"] },
  KHR: { exchange: false, stripe: true, code: "KHR", name: "Cambodian Riel", countries: ["KH"] },
  LKR: {
    exchange: false,
    stripe: true,
    symbol: "₹",
    logo: "rupee-sign",
    code: "LKR",
    name: "Sri Lankan Rupee",
    countries: ["LK"],
  },
  MAD: { exchange: false, stripe: true, code: "MAD", name: "Moroccan Dirham", countries: ["MA", "EH"] },
  MUR: {
    exchange: false,
    stripe: true,
    symbol: "₹",
    logo: "rupee-sign",
    code: "MUR",
    name: "Mauritian Rupee",
    countries: ["MR"],
  },
  MXN: { exchange: true, stripe: true, code: "MXN", name: "Mexican Peso", countries: ["MX"] },
  MYR: { exchange: true, stripe: true, code: "MYR", name: "Malaysian Ringgit", countries: [] },
  MMK: { exchange: false, stripe: true, code: "MMK", name: "Myanmar Kyat", countries: ["MM"] },
  NOK: { exchange: true, stripe: true, code: "NOK", name: "Norwegian Krone", countries: ["BV", "NO", "SJ"] },
  NPR: {
    exchange: false,
    stripe: true,
    symbol: "₹",
    logo: "rupee-sign",
    code: "NPR",
    name: "Nepalese Rupee",
    countries: ["NP"],
  },
  NZD: {
    exchange: true,
    stripe: true,
    symbol: "N$",
    logo: "dollar-sign",
    code: "NZD",
    name: "New Zealand Dollar",
    countries: ["CK", "NZ", "NU", "PN", "TK"],
  },
  PHP: { exchange: false, stripe: true, code: "PHP", name: "Philippine Peso", countries: ["PK"] },
  PKR: {
    exchange: false,
    stripe: true,
    symbol: "₹",
    logo: "rupee-sign",
    code: "PKR",
    name: "Pakistani Rupee",
    countries: ["PH"],
  },
  RUB: { exchange: true, stripe: true, code: "RUB", name: "Russian Ruble", countries: ["RU"] },
  SGD: {
    exchange: true,
    stripe: true,
    symbol: "S$",
    logo: "dollar-sign",
    code: "SGD",
    name: "Singapore Dollar",
    countries: ["SG"],
  },
  SEK: { exchange: true, stripe: true, code: "SEK", name: "Swedish Krona", countries: ["SE"] },
  THB: { exchange: true, stripe: true, code: "THB", name: "Thai Baht", countries: ["TH"] },
  TZS: { exchange: false, stripe: true, code: "TZS", name: "Tanzanian Shilling", countries: ["TZ"] },
  UGX: { exchange: false, stripe: true, code: "UGX", name: "Ugandan Shilling", countries: ["UG"] },
  USD: {
    exchange: true,
    stripe: true,
    symbol: "$",
    logo: "dollar-sign",
    code: "USD",
    name: "US Dollar",
    countries: ["AS", "BQ", "IO", "EC", "SV", "GU", "HT", "MH", "FM", "MP", "PW", "PA", "PR", "TL", "TC", "US", "UM", "VG", "VI"],
  },
  VND: { exchange: true, stripe: true, code: "VND", name: "Vietnamese Dong", countries: ["VN"] },
  XPF: { exchange: false, stripe: true, code: "XPF", name: "CFP Franc", countries: ["PF", "NC", "WF"] },
  ZAR: { exchange: true, stripe: true, code: "ZAR", name: "South African Rand", countries: ["LS", "NA", "ZA"] },
};

// set static function
const setKeyWords = (value: string): string[] => {
  return value
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/['#[\]!]+/g, "")
    .replace(/[/,.#!_-]+/g, " ")
    .split(" ")
    .slice(0, 29);
};

export {
  calculateRating,
  Categories,
  categoriesList,
  currenciesList,
  DEFAULT_LANG_CODE,
  generateScheduleString,
  getCountryDetails,
  Ingredients,
  ingredientsList,
  LANG_CODE,
  LangCode,
  localCountryDetails,
  parseBusinessPreview,
  parseUserPreview,
  RTL_LANG_CODE,
  schedulesToHuman,
  setKeyWords,
  shorterNumber,
  textToCapital,
  timeToCountDown,
  timeToDuration,
};
