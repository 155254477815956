import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AlertComponent } from "../../_component/alert/alert.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { DEFAULT_LANG_CODE, LangCode, RTL_LANG_CODE } from "../../_global/_services/app.service";
import { AuthenticationComponent } from "../../authentication/authentication.component";

@Component({
  selector: "app-language",
  standalone: true,
  imports: [AuthenticationComponent, AlertComponent, RouterModule, FontAwesomeModule, TranslateModule, SvgIconComponent],
  templateUrl: "./language.component.html",
  styleUrl: "./language.component.scss",
})
export class LanguageComponent {
  languageCode: LangCode;
  activeOffcanvas = inject(NgbActiveOffcanvas);

  languageDir;

  needToRealod = false;
  constructor(public translate: TranslateService) {
    const tempLang = (localStorage.getItem("languageCode") as LangCode) || DEFAULT_LANG_CODE;
    this.languageCode = tempLang;
    this.languageDir = RTL_LANG_CODE.includes(tempLang) ? "rtl" : "ltr";
  }

  selectLanguage(language: LangCode) {
    this.needToRealod = RTL_LANG_CODE.includes(this.languageCode) || RTL_LANG_CODE.includes(language);
    this.translate.use(language);
    localStorage.setItem("languageCode", language);
    this.languageCode = language;

    if (this.needToRealod) {
      // we should change the direction of the page
      // so relaod the page
      window.location.reload();
    }
  }
}
