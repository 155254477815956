export const environment = {
  useEmulators: true,
  recaptcha3SiteKey: "6Le8M5ckAAAAAG8ifTzco7VlMi75y1GNLjtuJojk",
  firebase: {
    apiKey: "AIzaSyDS9aBk_VJrJEbKJdRDfm-bo1dPdm3lBLY",
    authDomain: "dineas-dev.firebaseapp.com",
    projectId: "dineas-dev",
    storageBucket: "dineas-dev.appspot.com",
    messagingSenderId: "441177263087",
    appId: "1:441177263087:web:5ec6594b3a0bcbf557c41a",
    measurementId: "G-JX7MLR34WZ",
    vapidKey: "BEfFTbDdqD5pG5ArL5rlIeIKGkUQ6YOFgxMaykkSqhmq1ibxuYrw7pDVa2B6H1fIV7tGWQH7ryoLQHbQ0qjkmWI",
  },
  production: false,
  menuUrl: 'https://dineas-menu-dev.web.app',
  stripePublishableKey: "pk_test_51OgpNSF1b05LBlwqLWd4FWBrg4eyBI4FaxCtRwPe6e0D8Cw4TYjXaXkAlMRp2sq7ZMQkxAaVE3wVTzQczFjfcWvA00iekWFMH4",
  manifestBaseUrl: "http://127.0.0.1:5001/dineas-dev/us-central1/sendTestMessage",
};
