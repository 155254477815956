import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Pricing } from "../../_global/_interfaces";

@Pipe({
  name: "pricingDisplay",
  standalone: true,
})
export class PricingDisplayPipe implements PipeTransform {
  constructor(public currencyPipe: CurrencyPipe) {}

  transform(pricings: Pricing[], showFrom: boolean, currency?: string): string {
    if (!pricings || pricings.length === 0) {
      return "No pricing available";
    }

    // filter pricing tu avoud having same price
    const pricesList: number[] = [];
    [];
    for (const pricing of pricings) {
      if (pricing.price && !pricesList.includes(pricing.price)) {
        pricesList.push(pricing.price);
      }
    }

    if (pricesList.length === 0) {
      return "No pricing available";
    }

    const [singlePricing] = pricings;

    if (pricesList.length === 1 && singlePricing.priceOrg) {
      const { price, priceOrg } = singlePricing;
      const formattedPriceOrg = this.currencyPipe.transform(priceOrg, currency, "symbol", "1.2-2");
      const formattedPrice = this.currencyPipe.transform(price, currency, "symbol", "1.2-2");
      return `${formattedPrice} <del>${formattedPriceOrg}</del>`;
    }

    if (pricesList.length === 1) {
      const { price } = singlePricing;
      const formattedPrice = this.currencyPipe.transform(price, currency, "symbol", "1.2-2");
      return formattedPrice || "No pricing available";
    }

    if (showFrom) {
      const minPrice = Math.min(...pricesList);

      return `From ${this.currencyPipe.transform(minPrice, currency, "symbol", "1.2-2")}`;
    }

    const prices = pricesList.map((p) => this.currencyPipe.transform(p, currency, "symbol", "1.2-2"));
    return prices.join(" | ");
  }
}
