import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, documentId, getDocs, query, where } from "@angular/fire/firestore";
import { Observable, forkJoin, of, switchMap } from "rxjs";
import { CollectionName, Product, PushSells, PushSellsPopulated } from "../_global/_interfaces";
import { BusinessService } from "./business.service";
import { ProductService } from "./product.service";

@Injectable({
  providedIn: "root",
})
export class PushSellService {
  private firestore: Firestore = inject(Firestore);
  private productService: ProductService = inject(ProductService);
  collectionRef;
  businessId: string;
  constructor() {
    this.businessId = BusinessService.getSelectedBusinessId();
    this.collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessPushSells);
  }

  list(): Observable<PushSells[]> {
    const q = query(this.collectionRef, where("isActive", "==", true));
    const data = collectionData(q, {
      idField: "id",
    }) as Observable<PushSells[]>;
    return data;
  }

  getFromIds(ids: string[]): Observable<PushSells[]> {
    if (!ids.length) {
      return of([]);
    }
    // need observable to real time update
    const q = query(this.collectionRef, where("isActive", "==", true), where(documentId(), "in", ids));
    const data = collectionData(q, {
      idField: "id",
    }) as Observable<PushSells[]>;
    return data as Observable<PushSells[]>;

    // return data.pipe(
    //   tap((deals) => console.log("Fetched push sell:", deals)),
    //   map((deals) => deals as PushSells[]),
    //   catchError((error) => {
    //     console.error("Error fetching push sell:", error);
    //     return of([]);
    //   }),
    // );
  }
  getFromIdsPopulated(ids: string[]): Observable<PushSellsPopulated[]> {
    if (!ids.length) {
      return of([]);
    }

    // need observable to real time update
    const q = query(this.collectionRef, where("isActive", "==", true), where(documentId(), "in", ids));
    const data = collectionData(q, {
      idField: "id",
    }) as Observable<PushSells[]>;

    return data.pipe(
      switchMap((deals) => {
        const populatedPushSell$ = deals.map(async (deal) => {
          let products: Product[] = [];
          if (deal.productIds?.length) {
            products = await this.productService.getFromIdsPromise(deal.productIds);
          }
          return { ...deal, products };
        });
        return forkJoin(populatedPushSell$);
      }),
    );
  }
  async getFromIdsPromise(ids: string[]): Promise<PushSells[]> {
    // need observable to real time update
    const q = query(this.collectionRef, where(documentId(), "in", ids));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as PushSells;
      data.id = doc.id;
      return data;
    });
  }
}
