import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "timeUntilNow",
  standalone: true,
})
export class TimeUntilNowPipe implements PipeTransform {
  transform(date: any, formatInput?: string, timeZone?: string): string {
    const now = moment();
    let pastDate;
    if (!date) {
      return "";
    } else if (formatInput === "unix") {
      pastDate = moment.unix(Number(date));
      if (timeZone) {
        pastDate.tz(timeZone);
      }
    } else if (formatInput === "milliseconds") {
      pastDate = moment(Number(date));
      if (timeZone) {
        pastDate.tz(timeZone);
      }
    } else if (date > 0) {
      pastDate = moment.unix(date);
    } else if (date.seconds) {
      pastDate = moment.unix(date.seconds);
    } else {
      pastDate = moment(date);
    }
    if (now.diff(pastDate, "years")) {
      const years = now.diff(pastDate, "years");
      return years + " year" + (years > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "months")) {
      const months = now.diff(pastDate, "months");
      return months + " month" + (months > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "weeks")) {
      const weeks = now.diff(pastDate, "weeks");
      return weeks + " week" + (weeks > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "days")) {
      const days = now.diff(pastDate, "days");
      return days + " day" + (days > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "hours")) {
      const hours = now.diff(pastDate, "hours");
      return hours + " hour" + (hours > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "minutes")) {
      const minutes = now.diff(pastDate, "minutes");
      return minutes + " minute" + (minutes > 1 ? "s" : "") + " ago";
    } else if (now.diff(pastDate, "seconds")) {
      const seconds = now.diff(pastDate, "seconds");
      return seconds + " second" + (seconds > 1 ? "s" : "") + " ago";
    } else {
      return "Date invalid!";
    }
  }
}

@Pipe({
  name: "timeToLabel",
  standalone: true,
})
export class TimeToLabelPipe implements PipeTransform {
  transform(date: any, formatInput?: string, formatOutput?: string, timeZone?: string): string {
    let tempDate: moment.Moment;
    if (!date) {
      return "";
    } else if (formatInput === "unix") {
      tempDate = moment.unix(Number(date));
      if (timeZone) {
        tempDate.tz(timeZone);
      }
    } else if (formatInput === "milliseconds") {
      tempDate = moment(Number(date));
      if (timeZone) {
        tempDate.tz(timeZone);
      }
    } else if (Number(date)) {
      tempDate = moment(date);
      if (timeZone) {
        tempDate.tz(timeZone);
      }
    } else if (timeZone) {
      tempDate = formatInput ? moment.tz(`${date}`, formatInput, timeZone) : moment.tz(`${date}`, timeZone);
    } else {
      tempDate = moment(`${date}`, formatInput);
    }

    if (formatOutput) {
      return tempDate.format(formatOutput);
    } else {
      return tempDate.format("dddd DD/MMM/YYYY H:mm");
    }
  }
}

@Pipe({
  name: "durationToTime",
  standalone: true,
})
export class DurationToTimePipe implements PipeTransform {
  transform(value: number): string {
    const num = value || 0;
    const hours = num / 60;
    let rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const rday = Math.floor(rhours / 24);
    if (rday > 0) {
      rhours = rhours % 24;
    }
    if (rhours && rminutes) {
      return (rday ? rday + " jour" + (rday > 1 ? "s" : "") + " et " : "") + rhours + "h" + rminutes;
    } else if (rhours && !rminutes) {
      return (rday ? rday + " jour" + (rday > 1 ? "s" : "") + " et " : "") + rhours + " heure" + (rhours > 1 ? "s" : "");
    } else {
      return (rday ? rday + " jour" + (rday > 1 ? "s" : "") + " et " : "") + rminutes + " minute" + (rminutes > 1 ? "s" : "");
    }
  }
}
