import { Injectable, inject } from "@angular/core";
import { Firestore, collection, collectionData, doc, docData, documentId, getDocs, query, where } from "@angular/fire/firestore";
import { Observable, map } from "rxjs";
import { CollectionName, ServiceMenu } from "../_global/_interfaces";
import { setKeyWords } from "../_global/_services/app.service";
import { BusinessService } from "./business.service";
import { PushSellService } from "./push-sell.service";

@Injectable({
  providedIn: "root",
})
export class ServiceMenuService {
  private firestore: Firestore = inject(Firestore);
  private businessService: BusinessService = inject(BusinessService);
  private pushSellService: PushSellService = inject(PushSellService);
  collectionRef;
  businessId: string;
  constructor() {
    this.businessId = BusinessService.getSelectedBusinessId();
    this.collectionRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessServices);
  }

  get(id: string): Observable<ServiceMenu> {
    const docRef = doc(this.collectionRef, id);
    return docData(docRef, {
      idField: "id",
    }) as Observable<ServiceMenu>;
  }

  getFromIds(ids: string[]): Observable<ServiceMenu[]> {
    // need observable to real time update
    const docsRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.products);
    const q = query(this.collectionRef, where(documentId(), "in", ids));

    const data = collectionData(q, {
      idField: "id",
    });
    return data as Observable<ServiceMenu[]>;
  }

  getActive(): Observable<ServiceMenu[]> {
    // get current day example monday, tuesday, etc
    const currentDay = new Date().toLocaleString("en-US", { weekday: "long" }).toLowerCase() as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
    // get the current hours and minutes
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    // the  get all the services that has the current day in the days as true
    const docsRefServices = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.businessServices);
    const qServices = query(docsRefServices, where(`${currentDay}.active`, "==", true));
    // const docsServices = await getDocs(qServices);
    // and filter based on the start and end time

    const data = collectionData(qServices, {
      idField: "id",
    }) as Observable<ServiceMenu[]>;
    return data.pipe(
      map((sevices) =>
        sevices.filter((service) => {
          const currentDayService = service[currentDay];
          const onHour = currentDayService.on?.hour || 0;
          const onMinute = currentDayService.on?.minute || 0;
          let offHour = currentDayService.off?.hour || 0;
          const offMinute = currentDayService.off?.minute || 0;

          if (onHour > offHour) {
            offHour += 24; // in next day add 24h
          }

          if (currentHour === onHour) {
            return onMinute <= currentMinute;
          } else if (currentHour === offHour) {
            return offMinute >= currentMinute;
          }
          return onHour <= currentHour && offHour >= currentHour;
        }),
      ),
    );
  }

  async getFromIdsPromise(ids: string[]): Promise<ServiceMenu[]> {
    // need observable to real time update
    const docsRef = collection(this.firestore, CollectionName.businesses, this.businessId, CollectionName.products);
    const q = query(this.collectionRef, where(documentId(), "in", ids));

    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as ServiceMenu;
      data.id = doc.id;
      return data;
    });
  }

  async listServices(serachByName?: string): Promise<ServiceMenu[]> {
    const businessId = BusinessService.getSelectedBusinessId();

    const docsRef = collection(this.firestore, CollectionName.businesses, businessId, CollectionName.businessServices);

    const whereList = [where("businessId", "==", businessId)];
    if (serachByName) {
      // the searchByName is a string with multiple words, maximum 29 words
      // split the searchByName string into an array of words
      const searchByNameArray = setKeyWords(serachByName).slice(0, 29);
      whereList.push(where("keyWords", "array-contains-any", searchByNameArray));
    }
    const q = query(docsRef, ...whereList);

    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
      const data = doc.data() as ServiceMenu;
      data.id = doc.id;
      return data;
    });
  }

  checkIfLive(service: ServiceMenu): boolean {
    const currentDay = new Date().toLocaleString("en-US", { weekday: "long" }).toLowerCase() as "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

    const isDayDactive = service[currentDay] && service[currentDay].active;
    const day = service[currentDay];
    if (!isDayDactive || !day || !day.on || !day.off) {
      return false;
    }
    // get the current hours and minutes
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    if (currentHour === day.on.hour) {
      return day.on.minute <= currentMinute;
    } else if (currentHour === day.off.hour) {
      return day.off.minute >= currentMinute;
    }

    return day.on.hour <= currentHour && day.off.hour >= currentHour;
  }
}
