@if(!checkout ){
<p>No active checkout</p>
} @else {
<div id="checkoutView" class="d-flex flex-column justify-content-center">
  <nav class="navbar my-2">
    <div class="container-md">
      <div class="d-flex w-100 ">
        <a [routerLink]="['/cart']" class="btn py-0 ">
          <app-icon-svg class="icon-arrow-go-back" iconName="arrow-go-back"></app-icon-svg>
        </a>
        <h2 class="my-auto mx-3"> <span [translate]="'split-payment.title'"></span></h2>
      </div>
    </div>
  </nav>
  <hr class="mt-0">

  <section class="container-md">
    <app-alert [message]="alertCheckout.message" [dismissible]="true" [active]="alertCheckout.active" [type]="alertCheckout.type"></app-alert>
  </section>

  @if(isCustomerValid){
  <section class="container-md ">
    <h6 class="text-muted" translate="checkout.contact-info"></h6>
    <div class="d-flex w-100 justify-content-between" (click)="openOffcanvas('account-details')">
      <h5 class="my-auto  text-truncate">{{checkout.customer?.email }}</h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>
    <hr />
  </section>
  }

  @if(selectPaymentMethod){
  <section class="container-md ">
    <h6 class="text-muted" translate="account.main.payment-methods"></h6>

    @if(paymentTypeAvailable.businessManaged && !paymentTypeAvailable.stripe){
    <p class="mb-0" translate="checkout.payment-manage-business-tips"></p>
    }@else {
    <div class="d-flex w-100 justify-content-between" (click)="onEditPaymentMethod()">
      <h5 class="my-auto  text-truncate" dir="ltr">
        @if(selectPaymentMethod.brand === 'visa'){
        <app-icon-svg class="icon-visa" iconName="visa"></app-icon-svg>
        }@else {
        {{selectPaymentMethod.brand}}
        }

        @if(selectPaymentMethod.last4){
        <span>**** {{selectPaymentMethod.last4}}</span>
        }
      </h5>
      <app-icon-svg class="icon-arrow-right" iconName="arrow-right"></app-icon-svg>
    </div>

    }
    <hr />
  </section>
  }

  <section class="container-md ">
    <h4 class="mb-1" translate="split-payment.how-to-pay"></h4>
    <p class="mb-3 small text-muted" translate="split-payment.split-amount-info"></p>

    <section>
      <form id="paymentForm" [formGroup]="paymentForm">

        <ul class="list-group list-group-flush mb-3">
          <li class="list-group-item px-0 py-3" dir="ltr">
            <label class="form-check-label small" for="full-amount" [style.width]="'calc(100% - 16px)'">
              <div class="d-flex w-100 justify-content-between">
                <span class="text-capitalize " translate="split-payment.full-amount"></span>
              </div>
            </label>
            <input class="form-check-input " type="radio" formControlName="splitPaymentType" value="full-amount" id="full-amount">

            <p class="text-muted caption mb-0 " for="full-amount" translate="split-payment.full-amount-tip"></p>
          </li>
          <li class="list-group-item px-0  py-3" dir="ltr">
            <label class="form-check-label small" for="split-amount" [style.width]="'calc(100% - 16px)'">
              <div class="d-flex w-100 justify-content-between">
                <span class="text-capitalize " translate="split-payment.split-amount"></span>
              </div>
            </label>
            <input class="form-check-input " type="radio" formControlName="splitPaymentType" value="split-amount" id="split-amount">

            <p class="text-muted caption mb-0 " translate="split-payment.split-amount-tip"></p>

            <div [hidden]="paymentForm.value.splitPaymentType !== 'split-amount'">
              <div id="tipList" class=" mt-3 ">
                <!-- List of categories button to switch the selected category -->
                @for (split of splitOptions; track split;){
                <button class="btn btn-outline-primary me-3" (click)="paymentForm.get('splitBy')?.setValue(split)" [class.active]="paymentForm.value.splitBy === split" type="button">{{split}}</button>
                }

              </div>
              @if(paymentForm.get('splitBy')?.invalid){ <p class="text-danger caption mt-2 " translate="form.filed-required">
              </p>
              }
            </div>

          </li>
          <li class="list-group-item px-0 py-3" dir="ltr">
            <label class="form-check-label small" for="specific-amount" [style.width]="'calc(100% - 16px)'">
              <div class="d-flex w-100 justify-content-between">
                <span class="text-capitalize " translate="split-payment.specific-amount"></span>
              </div>
            </label>
            <input class="form-check-input " type="radio" formControlName="splitPaymentType" value="specific-amount" id="specific-amount">

            <p class="text-muted caption mb-0 " translate="split-payment.specific-amount-tip"></p>

            <div class="mt-3 w-50" [hidden]="paymentForm.value.splitPaymentType !== 'specific-amount'">

              <div class="input-group ">
                <input type="number" [min]="0" class="form-control  " formControlName="amount" id="amount" [class.border-0]="paymentForm.get('amount')?.valid"
                  [class.is-invalid]="paymentForm.get('amount')?.invalid">
                <button class="btn bg-transparent text-muted px-3" type="button" id="button-addon2"><span>{{currencySymbol}}</span></button>

              </div>

            </div>

          </li>
        </ul>
      </form>

    </section>

    <hr />
  </section>
  <section class="container-md  mt-auto">
    <h4 class="mb-1" translate="checkout.tip-title"></h4>
    <p class="mb-3 small text-muted" translate="checkout.tip-subtitle"></p>
    <app-tip-form [maxAmount]="currentPaymentStatus.subtotal" [defaultTip]="checkout.total?.tipPercentage" (onTipChange)="onTipChange($event)"></app-tip-form>

  </section>
  <hr class="border-4 mt-auto" />
  <section class="container-md ">

    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.sub-total"></p>
      <p class="mb-2 small text-muted">{{currentPaymentStatus.subtotal | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between text-primary">
      <p class="mb-2 small text-primary" translate="checkout.discount"></p>
      <p class="mb-2 small text-primary" dir="ltr"> {{- (currentPaymentStatus.discount) | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted" translate="checkout.fee"></p>
      <p class="mb-2 small text-muted"> {{0 | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted"> <span translate="checkout.tip"></span> <span [hidden]="!checkout.total?.tipPercentage" class="ps-2">({{checkout.total?.tipPercentage | percent}})</span></p>
      <p class="mb-2 small text-muted"> {{checkout.total?.tipAmount || 0 | currency: checkout.business?.currency}}</p>
    </div>
    <hr class="my-3 " />
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-2 small text-muted fw-bold"> <span translate="checkout.paid"></span> </p>
      <p class="mb-2 small text-muted fw-bold"> {{-(currentPaymentStatus.paid) | currency: checkout.business?.currency}}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p class="mb-0 small text-muted fw-bold"> <span translate="checkout.due"></span> </p>
      <p class="mb-0 small text-muted fw-bold"> {{(currentPaymentStatus.due) | currency: checkout.business?.currency}}</p>
    </div>
    <hr class="my-3 " />
    <div class="d-flex w-100 justify-content-between">
      <h4 class="mb-0 " translate="checkout.total"></h4>
      <h4 class="mb-0 ">{{amountToCharge | currency: checkout.business?.currency}}</h4>
    </div>
  </section>

  <section class="container-md mt-3 mb-3">
    @if(userPayment ){
    <button type="button" class="btn btn-outline-primary w-100 " disabled>
      <span translate="checkout.paid"></span>
      <span class="fw-bold"> {{(userPayment.participationAmount || 0) + (userPayment.participationTip || 0)| currency: checkout.business?.currency}}</span>
    </button>
    }
    @else if(!isCustomerValid){
    <button type="button" class="btn btn-outline-primary w-100 " (click)="openOffcanvas('account-details')" translate="account.details.title"></button>
    } @else if(!selectPaymentMethod){
    <button type="button" class="btn btn-primary w-100 " [disabled]="!isCustomerValid || isLoading" (click)="onEditPaymentMethod()">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>
      }@else {
      <span translate="checkout.select-payment-method"></span>
      }
    </button>
    }@else {
    <button type="button" class="btn btn-primary w-100 " [disabled]="isLoading || paymentForm.invalid" (click)="onSplitPayment()">
      @if(isLoading){
      <span class="spinner-border spinner-border-sm mx-2" aria-hidden="true"></span>
      <span role="status" class=" mx-2" translate="form.loading"></span>
      }@else {
      <span [translate]="'form.confirm'"></span>
      }
    </button>
    }
  </section>

</div>
}