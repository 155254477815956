import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { AlertComponent } from "../../_component/alert/alert.component";
import { OrderCardComponent } from "../../_component/order/card/card.component";
import { SvgIconComponent } from "../../_component/svg-icon/index.component";
import { Business, Order, User } from "../../_global/_interfaces";
import { AppService } from "../../_services/app.service";
import { OrderService } from "../../_services/order.service";

@Component({
  selector: "app-order-list",
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, ReactiveFormsModule, AlertComponent, AsyncPipe, OrderCardComponent, TranslateModule, SvgIconComponent],
  templateUrl: "./list.component.html",
  styleUrl: "./list.component.scss",
})
export class OrderListComponent implements OnDestroy {
  user?: User;
  subscription: Subscription;

  isLoading = false;

  business?: Business;
  subscriptionBusiness: Subscription;

  // allOrders$: Observable<Order[]> = new Observable<Order[]>();
  ordersPerMonth: { monthLabel: string; orders: Order[] }[] = [];
  hasOrders: boolean = true;
  selectedOrder: Order | null = null;
  limitToLoad = 100;
  private appService = inject(AppService);

  constructor(private orderService: OrderService) {
    const { user, userSubscription, business, subscriptionBusiness, checkout, subscriptionCheckout } = this.appService.getLocalStorageAndSubscrition();
    this.user = user;
    this.business = business;

    this.subscription = userSubscription.subscribe((user) => {
      this.user = user;
    });
    this.subscriptionBusiness = subscriptionBusiness.subscribe((business) => {
      this.business = business;
    });
    // this.allOrders$ = this.orderService.listOrders(this.limitToLoad);
    this.orderService.listOrders(this.limitToLoad).subscribe((orders) => this.setOrderPerMonth(orders));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBusiness.unsubscribe();
  }

  loadMoreOrder(): void {
    this.limitToLoad += 100;
    this.orderService.listOrders(this.limitToLoad).subscribe((orders) => this.setOrderPerMonth(orders));
  }

  setOrderPerMonth(orders: Order[]): void {
    this.hasOrders = orders.length > 0;
    const ordersPerMonthMap: { [monthLabel: string]: Order[] } = {};
    for (const order of orders) {
      const date = order.requestedAt || order.updatedAt || order.processingAt || order.completedAt || order.cancelledAt;
      const monthLabel = moment(date).format("MMM YYYY");
      if (!ordersPerMonthMap[monthLabel]) {
        ordersPerMonthMap[monthLabel] = [];
      }
      ordersPerMonthMap[monthLabel].push(order);
    }

    this.ordersPerMonth = Object.keys(ordersPerMonthMap).map((monthLabel) => {
      return { monthLabel, orders: ordersPerMonthMap[monthLabel] };
    });

    // now sort by month
    this.ordersPerMonth.sort((a, b) => {
      return moment(b.monthLabel, "MMM YYYY").valueOf() - moment(a.monthLabel, "MMM YYYY").valueOf();
    });
  }
}
